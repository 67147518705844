import React, { useState, useEffect, useCallback } from 'react';
import { Controller, useForm } from "react-hook-form";
import { Button, Form, Label, Input } from 'reactstrap';
import { FormGroup } from '@material-ui/core';
import { NotificationManager } from 'react-notifications';
import { MuiThemeProvider } from '@material-ui/core/styles';
import MUIDataTable from "mui-datatables";
import IconButton from '@material-ui/core/IconButton';
import DeleteForever from '@material-ui/icons/DeleteForever';
import Edit from '@material-ui/icons/Edit';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import NumberFormat from 'react-number-format';
import MatButton from '@material-ui/core/Button';
import Modal from 'react-modal';

import Main from '../Main';
import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import LinearProgress from '../Util/LinearProgress';
import * as Util from '../Util/util';
import * as Constants from '../Util/constants';
import * as unitsAPI from '../../Api/units';
import * as propertyAPI from '../../Api/property';

const modalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-8%',
        transform: 'translate(-50%, -50%)',
        width: '600px',
    },
};

const ViewAll = () => {
    const history = useHistory();
    const login = useSelector((state) => state.login);
    const propertyID = login.selectedPropertyID;

    const [loading, setLoading] = useState(false);
    const [units, setUnits] = useState([]);
    const [deleteID, setDeleteID] = useState(0);
    const [updated, setUpdated] = useState(false);
    const [openReplicateModel, setOpenReplicateModal] = useState(false);
    const [replicateUnitDetails, setReplicateUnitDetails] = useState(null);
    const [residenceType, setResidenceType] = useState([]);
    const [unitFurnished, setUnitFurnished] = useState([]);
    const [smoking, setSmoking] = useState([]);
    const [ada, setAda] = useState([]);
    const [pets, setPets] = useState([]);
    const [parking, setParking] = useState([]);
    const [laundry, setLaundry] = useState([]);

    const { handleSubmit, control, formState: { errors } } = useForm();

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            const arr = [];
            const untis = await unitsAPI.get(propertyID);
            for (const u of untis) {
                arr.push({
                    unit: u.UnitName,
                    unitAddress: u.Address,
                    unitType: u.UnitType,
                    unitCharge: u.UnitCharge,
                    replicate: u,
                    images: u,
                    edit: u,
                    delete: u.UnitID,
                });
            }
            setUnits(arr);
            const unitSelectValues = await unitsAPI.getUnitSelectValues();
            if (unitSelectValues) {
                setResidenceType(unitSelectValues.residenceTypes);
                setUnitFurnished(unitSelectValues.unitFurnished);
                setSmoking(unitSelectValues.smoking);
                setAda(unitSelectValues.ada);
                setPets(unitSelectValues.pets);
                setParking(unitSelectValues.parking);
                setLaundry(unitSelectValues.laundry);
            }
            setLoading(false);
        }
        fetchData();
    }, [propertyID, updated])

    const propertyAddress = useCallback(async () => {
        const getProperty = await propertyAPI.getProperty(propertyID);
        if (!getProperty) return "";
        return getProperty.PropertyAddress1;
    }, [propertyID])

    const columns = [
        { name: 'unit', label: 'Unit', },
        { name: 'unitAddress', label: 'Unit Address', },
        { name: 'unitType', label: 'Unit Type', },
        {
            name: 'unitCharge', label: 'Unit Charge',
            options: {
                customBodyRender: (value) => {
                    return <NumberFormat value={value} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                }
            },
        },
        {
            name: 'replicate', label: 'Replicate',
            options: {
                customBodyRender: (value) => {
                    return (
                        <MatButton color="primary" onClick={() => handleReplicateUnit(value)}>
                            Replicate
                        </MatButton>
                    );
                }
            },
        },
        {
            name: 'images', label: 'Images',
            options: {
                customBodyRender: (value) => {
                    return (
                        <MatButton color="primary" onClick={() => {
                            const location = {
                                pathname: '/units/uploadFile',
                                state: {
                                    unitTypeID: parseInt(value.UnitTypeID),
                                }
                            }
                            history.push(location);
                        }}>
                            {value.MarketingFileTypeID ? 'Open' : 'Add'}
                        </MatButton>
                    );
                }
            },
        },
        {
            name: 'edit', label: 'Edit',
            options: {
                customBodyRender: (value) => {
                    return (
                        <IconButton
                            onClick={() => {
                                const location = {
                                    pathname: '/units/editUnit',
                                    state: {
                                        unitID: parseInt(value.UnitID),
                                        unitTypeID: parseInt(value.UnitTypeID),
                                    }
                                }
                                history.push(location);
                            }}
                        >
                            <Edit />
                        </IconButton>
                    )
                }
            },
        },
        {
            name: 'delete', label: 'Delete',
            options: {
                customBodyRender: (value) => {
                    return (
                        <IconButton
                            onClick={() => {
                                setDeleteID(parseInt(value));
                            }}
                        >
                            <DeleteForever />
                        </IconButton>
                    )
                }
            },
        },
    ];

    const options = {
        filterType: 'dropdown',
        selectableRows: "none",
        rowsPerPage: 10,
    }

    const deleteUnit = async () => {
        setLoading(true);
        const res = await unitsAPI.deleteUnit({
            unitID: deleteID,
            unitTypeID: units.filter(u => u.delete === deleteID).map(filtered => filtered.edit.UnitTypeID)[0],
        });
        setDeleteID(0);
        setLoading(false);
        if (res !== 0) {
            NotificationManager.error(Constants.DEFAULT_ERROR, "Error");
            return;
        }
        NotificationManager.success("Unit deleted!", "Success");
        setUpdated(!updated);
    }

    const submitForm = async (data) => {
        setLoading(true);
        if (data.unitCharge[0] === '$') data.unitCharge = data.unitCharge.substring(1, data.unitCharge.length);
        if (data.adminFee[0] === '$') data.adminFee = data.adminFee.substring(1, data.adminFee.length);
        if (data.hoaFee && data.hoaFee[0] === '$') data.hoaFee = data.hoaFee.substring(1, data.hoaFee.length);
        const res = await unitsAPI.addUnit({
            unitName: data.unitName,
            unitType: data.unitType,
            propertyID: propertyID,
            unitCharge: parseFloat(data.unitCharge.replace(",", "")).toFixed(2),
            adminFee: parseFloat(data.adminFee.replace(",", "")).toFixed(2),
            isFurnished: data.furnished,
            isSmokingAllowed: data.smokingUnit,
            isWheelChairAccessible: data.ada,
            petsAllowed: data.pets,
            maxAllowedPeople: data.maxTenants || 0,
            leaseDurationMonth: data.leaseDuration,
            parkingType: data.parking,
            laundryType: data.laundry,
            squareFootage: data.squareFootage,
            unitResidenceTypeID: data.residenceType,
            totalBedrooms: data.totalBedrooms,
            totalBathrooms: data.totalBathrooms,
            HOAFee: parseFloat(data.hoaFee).toFixed(2),
        });
        setLoading(false);
        if (res === -1) {
            NotificationManager.error(Constants.DEFAULT_ERROR, "Error");
            return;
        }
        if (res !== 0) {
            NotificationManager.error(res, "Error");
            return;
        }
        NotificationManager.success("Unit Added!", "Success");
        setUpdated(!updated);
    }

    const handleReplicateUnit = async (details) => {
        setReplicateUnitDetails({ ...details, unitAddress: await propertyAddress() });
        setOpenReplicateModal(true);
    }

    const saveReplicateUnit = async () => {
        if (replicateUnitDetails.newUnitName === '') {
            NotificationManager.error("Unit name is required.", "Error");
            return;
        }
        if (replicateUnitDetails.unitAddress === '') {
            NotificationManager.error("Unit address is required.", "Error");
            return;
        }
        if (units.filter(u => u.UnitName === replicateUnitDetails.newUnitName).length > 0) {
            NotificationManager.error(`Unit: ${replicateUnitDetails.newUnitName} already exist.`, "Error");
            return;
        }
        setLoading(true);
        const res = await unitsAPI.replicateUnit({
            unitName: replicateUnitDetails.newUnitName,
            unitTypeID: replicateUnitDetails.UnitTypeID,
            propertyID,
            unitAddress: replicateUnitDetails.unitAddress,
        });
        setLoading(false);
        setOpenReplicateModal(false);
        if (res === -1) {
            NotificationManager.error(Constants.DEFAULT_ERROR, "Error");
            return;
        }
        if (res !== 0) {
            NotificationManager.error(res, "Error");
            return;
        }
        NotificationManager.success("Unit replicated!", "Success");
        setUpdated(!updated);
    }

    const renderOpenReplicateModalContent = () => {
        if (!replicateUnitDetails) return;
        return (
            <Form>
                <p>Replicate unit: {replicateUnitDetails.UnitName}</p>
                <div className="row">
                    <div className="col-sm-6">
                        <FormGroup className="mr-10 mb-10">
                            <Label for="unitName" className="mr-sm-10">Unit Name</Label>
                            <Input type="text" id="unitName" name="unitName"
                                onChange={(e) => setReplicateUnitDetails({ ...replicateUnitDetails, newUnitName: e.target.value })} />
                        </FormGroup>
                    </div>
                    <div className="col-sm-6">
                        <FormGroup className="mr-10 mb-10">
                            <Label for="unitAddress" className="mr-sm-10">Unit Address</Label>
                            <Input type="text" id="unitAddress" name="unitAddress"
                                value={replicateUnitDetails.unitAddress} onChange={(e) => setReplicateUnitDetails({ ...replicateUnitDetails, unitAddress: e.target.value })}
                            />
                        </FormGroup>
                    </div>
                </div>
                <Button type="button" color="primary" size="sm" className="w-auto" onClick={saveReplicateUnit}>Confirm</Button>
                <Button style={{ marginLeft: '10px' }} type="button" color="danger" size="sm" className="w-auto" onClick={() => setOpenReplicateModal(false)}>Cancel</Button>
            </Form>
        );
    }

    if (loading) {
        return (
            <RctCollapsibleCard
                colClasses="col-xs-12 col-sm-12 col-md-12"
                heading={"Loading Units..."}
            >
                <LinearProgress />
            </RctCollapsibleCard>
        );
    }

    return (
        <Main>
            <SweetAlert
                warning
                btnSize="sm"
                show={deleteID !== 0}
                showCancel
                confirmBtnText="Yes, delete it!"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="success"
                title="Are you sure?"
                onConfirm={() => deleteUnit()}
                onCancel={() => setDeleteID(0)}
            >
                You will not be able to recover this unit!
            </SweetAlert>
            <Modal
                isOpen={openReplicateModel}
                onRequestClose={() => setOpenReplicateModal(false)}
                contentLabel="Replicate Unit"
                style={modalStyles}
            >
                {renderOpenReplicateModalContent()}
            </Modal>
            <div className="formelements-wrapper" style={Constants.margins}>
                <div className="page-title d-flex justify-content-between align-items-center">
                    <div className="page-title-wrap">
                        <h2>
                            <span>Units</span>
                        </h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-xl-12">
                        <RctCollapsibleCard heading="Unit Details">
                            <Form onSubmit={handleSubmit(submitForm)}>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <FormGroup className="mr-10 mb-10">
                                            <Label for="unitName" className="mr-sm-10">Unit Name</Label>
                                            <Controller
                                                name="unitName"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <Input {...field} type="text" id="unitName" style={Util.setErrorStyle(errors.unitName)} />
                                                )}
                                            />
                                            {errors.unitName && (
                                                <span style={{ color: "red" }} role="alert">required</span>
                                            )}
                                        </FormGroup>
                                    </div>
                                    <div className="col-sm-3">
                                        <FormGroup className="mr-10 mb-10">
                                            <Label for="unitType" className="mr-sm-10">Unit Type</Label>
                                            <Controller
                                                name="unitType"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <Input {...field} type="text" id="unitType" style={Util.setErrorStyle(errors.unitType)} />
                                                )}
                                            />
                                            {errors.unitType && (
                                                <span style={{ color: "red" }} role="alert">required</span>
                                            )}
                                        </FormGroup>
                                    </div>
                                    <div className="col-sm-2">
                                        <Label for="unitCharge" className="mr-sm-10">Unit Charge</Label>
                                        <Controller
                                            name="unitCharge"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <NumberFormat {...field} className="form-control" id="unitCharge" thousandSeparator={true} prefix={'$'}
                                                    style={Util.setErrorStyle(errors.unitCharge)} />
                                            )}
                                        />
                                        {errors.unitCharge && (
                                            <span style={{ color: "red" }} role="alert">required</span>
                                        )}
                                    </div>
                                    <div className="col-sm-2">
                                        <Label for="adminFee" className="mr-sm-10">Admin Fee</Label>
                                        <Controller
                                            name="adminFee"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <NumberFormat {...field} className="form-control" id="adminFee" thousandSeparator={true} prefix={'$'}
                                                    style={Util.setErrorStyle(errors.adminFee)} />
                                            )}
                                        />
                                        {errors.adminFee && (
                                            <span style={{ color: "red" }} role="alert">required</span>
                                        )}
                                    </div>
                                    <div className="col-sm-2">
                                        <Label for="hoaFee" className="mr-sm-10">HOA Fee</Label>
                                        <Controller
                                            name="hoaFee"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <NumberFormat {...field} className="form-control" id="hoaFee" thousandSeparator={true} prefix={'$'} />
                                            )}
                                        />
                                        {errors.hoaFee && (
                                            <span style={{ color: "red" }} role="alert">required</span>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-2">
                                        <FormGroup className="mr-10 mb-10">
                                            <Label for="maxTenants" className="mr-sm-10">Max Tenants</Label>
                                            <Controller
                                                name="maxTenants"
                                                control={control}
                                                render={({ field }) => (
                                                    <Input {...field} type="number" id="maxTenants" />
                                                )}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className="col-sm-3">
                                        <Label for="residenceType" className="mr-sm-10">Residence Type</Label>
                                        <Controller
                                            name="residenceType"
                                            control={control}
                                            defaultValue={residenceType.length > 0 ? residenceType[0].UnitResidenceTypeID : ''}
                                            render={({ field }) => (
                                                <Input {...field} type="select" id="residenceType">
                                                    {residenceType.map((obj, idx) =>
                                                        <option key={idx} value={obj.UnitResidenceTypeID}>{obj.Residence}</option>
                                                    )}
                                                </Input>
                                            )}
                                        />
                                    </div>
                                    <div className="col-sm-2">
                                        <FormGroup className="mr-10 mb-10">
                                            <Label for="totalBedrooms" className="mr-sm-10">Total Bedrooms</Label>
                                            <Controller
                                                name="totalBedrooms"
                                                control={control}
                                                render={({ field }) => (
                                                    <Input {...field} type="text" id="totalBedrooms" />
                                                )}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className="col-sm-2">
                                        <FormGroup className="mr-10 mb-10">
                                            <Label for="totalBathrooms" className="mr-sm-10">Total Baths</Label>
                                            <Controller
                                                name="totalBathrooms"
                                                control={control}
                                                render={({ field }) => (
                                                    <Input {...field} type="text" id="totalBathrooms" />
                                                )}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className="col-sm-2">
                                        <FormGroup className="mr-10 mb-10">
                                            <Label for="squareFootage" className="mr-sm-10">Square Footage</Label>
                                            <Controller
                                                name="squareFootage"
                                                control={control}
                                                render={({ field }) => (
                                                    <Input {...field} type="text" id="squareFootage" />
                                                )}
                                            />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <Label for="furnished" className="mr-sm-10">Furnished</Label>
                                        <Controller
                                            name="furnished"
                                            control={control}
                                            defaultValue={unitFurnished.length > 0 ? unitFurnished[0].UnitFurnishedID : ''}
                                            render={({ field }) => (
                                                <Input {...field} type="select" id="furnished">
                                                    {unitFurnished.map((obj, idx) =>
                                                        <option key={idx} value={obj.UnitFurnishedID}>{obj.Furnished}</option>
                                                    )}
                                                </Input>
                                            )}
                                        />
                                    </div>
                                    <div className="col-sm-3">
                                        <Label for="smokingUnit" className="mr-sm-10">Smoking</Label>
                                        <Controller
                                            name="smokingUnit"
                                            control={control}
                                            defaultValue={smoking.length > 0 ? smoking[0].UnitSmokingID : ''}
                                            render={({ field }) => (
                                                <Input {...field} type="select" id="smokingUnit">
                                                    {smoking.map((obj, idx) =>
                                                        <option key={idx} value={obj.UnitSmokingID}>{obj.Smoking}</option>
                                                    )}
                                                </Input>
                                            )}
                                        />
                                    </div>
                                    <div className="col-sm-3">
                                        <Label for="ada" className="mr-sm-10">ADA</Label>
                                        <Controller
                                            name="ada"
                                            control={control}
                                            defaultValue={ada.length > 0 ? ada[0].UnitADAID : ''}
                                            render={({ field }) => (
                                                <Input {...field} type="select" id="ada">
                                                    {ada.map((obj, idx) =>
                                                        <option key={idx} value={obj.UnitADAID}>{obj.ADA}</option>
                                                    )}
                                                </Input>
                                            )}
                                        />
                                    </div>
                                    <div className="col-sm-3">
                                        <Label for="pets" className="mr-sm-10">Pets</Label>
                                        <Controller
                                            name="pets"
                                            control={control}
                                            defaultValue={pets.length > 0 ? pets[0].UnitPETSID : ''}
                                            render={({ field }) => (
                                                <Input {...field} type="select" id="pets">
                                                    {pets.map((obj, idx) =>
                                                        <option key={idx} value={obj.UnitPETSID}>{obj.PETS}</option>
                                                    )}
                                                </Input>
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-2">
                                        <FormGroup className="mr-10 mb-10">
                                            <Label for="leaseDuration" className="mr-sm-10">Lease Duration (Months)</Label>
                                            <Controller
                                                name="leaseDuration"
                                                control={control}
                                                render={({ field }) => (
                                                    <Input {...field} type="text" id="leaseDuration" />
                                                )}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className="col-sm-3">
                                        <Label for="parking" className="mr-sm-10">Parking</Label>
                                        <Controller
                                            name="parking"
                                            control={control}
                                            defaultValue={parking.length > 0 ? parking[0].Parking : ''}
                                            render={({ field }) => (
                                                <Input {...field} type="select" id="parking">
                                                    {parking.map((obj, idx) =>
                                                        <option key={idx} value={obj.Parking}>{obj.Parking}</option>
                                                    )}
                                                </Input>
                                            )}
                                        />
                                    </div>
                                    <div className="col-sm-3">
                                        <Label for="laundry" className="mr-sm-10">Laundry</Label>
                                        <Controller
                                            name="laundry"
                                            control={control}
                                            defaultValue={laundry.length > 0 ? laundry[0].Laundry : ''}
                                            render={({ field }) => (
                                                <Input {...field} type="select" id="laundry">
                                                    {laundry.map((obj, idx) =>
                                                        <option key={idx} value={obj.Laundry}>{obj.Laundry}</option>
                                                    )}
                                                </Input>
                                            )}
                                        />
                                    </div>
                                </div>
                                <Button type="submit" color="primary" style={{ marginTop: '10px' }}>Add unit</Button>
                            </Form>
                        </RctCollapsibleCard>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-xl-12">
                        <MuiThemeProvider theme={Constants.getMuiTheme()}>
                            <MUIDataTable
                                title={`Existing Units - ${units.length}`}
                                data={units}
                                columns={columns}
                                options={options}
                            />
                        </MuiThemeProvider>
                    </div>
                </div>
            </div>
        </Main>
    )
}

export default ViewAll;
