import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import { Typography } from '@material-ui/core';
import * as Constants from '../Util/constants';
import * as reportsAPI from '../../Api/reports';
import { currencyFormat, formatDate } from './helperFunctions';
import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import LinearProgress from '../Util/LinearProgress';
import AttachFile from '@material-ui/icons/AttachFile';
import { useHistory } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import DeleteForever from '@material-ui/icons/DeleteForever';
import * as billsAPI from '../../Api/bills';
import * as tenantAPI from '../../Api/tenants';
import { NotificationManager } from 'react-notifications';
import { Input, Button } from 'reactstrap';
import Swal from 'sweetalert2';

const EscrowReserve = (props) => {
  const [loading, setLoading] = useState(false);
  const [itemData, setItemData] = useState([]);
  const [laborData, setLaborData] = useState([]);
  var [itemTotal, setItemTotal] = useState(0);
  var [laborTotal, setLaborTotal] = useState(0);
  const propertyName = useSelector((state) => state.login.properties);
  const [filteredName] = propertyName.filter(
    (property) => property.PropertyID === props.propertyId
  );
  const history = useHistory();

  const login = useSelector((state) => state.login);
  const user = login.user;
  const [email, setEmail] = useState(user.email);
  const deleteBill = async (checkregisterId) => {
    const res = await billsAPI.deleteBill(checkregisterId);
    if (res !== 0) {
      NotificationManager.error(res, 'Error');
    } else {
      NotificationManager.success('Bill deleted successfully', 'Success!');
    }
  };
  const deleteWK = async (workorder) => {
    const res = await tenantAPI.deleteWK(workorder);
    if (res !== 0) {
      NotificationManager.error(
        'Error processing your request. Please, contact us.',
        'Error'
      );
      return;
    }
  };
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const reportItem = await reportsAPI.getEscrowItem(props.reportSearch);
      const reportLabor = await reportsAPI.getEscrowLabor(props.reportSearch);
      for (let i = 0; i < reportItem.length; i++) {
        const receipt = reportItem[i].receipts.filter(
          (receipt) => receipt.UploadDate === reportItem[i].CheckDate
        );
        itemData.push({
          invoice_number: reportItem[i].InvoiceNumber,
          date: formatDate(reportItem[i].CheckDate),
          payee: reportItem[i].vendor.VendorName + ' - ' + reportItem[i].id,
          account: reportItem[i].expensetype.ExpenseType,
          memo: reportItem[i].Memo,
          debitAmt: currencyFormat(+reportItem[i].Amount),
          receipt:
            receipt.length !== 0 ? (
              <IconButton
                aria-label="Receipt"
                onClick={() => {
                  const location = {
                    pathname: `/Receipts/${props.reportSearch.pID}/${reportItem[
                      i
                    ].CheckDate.substring(0, 4)}/${reportItem[
                      i
                    ].CheckDate.substring(5, 7)}/${reportItem[i].id}.pdf`,
                  };
                  history.push(location);
                }}
              >
                <AttachFile />
              </IconButton>
            ) : (
              ''
            ),
          remove: reportItem[i].id,
          // eslint-disable-next-line react-hooks/exhaustive-deps
          total: currencyFormat((itemTotal += (+reportItem[i].Amount))),
        });
      }
      for (let i = 0; i < reportLabor.length; i++) {
        laborData.push({
          date: formatDate(reportLabor[i].WorkOrderSubmitDate),
          unit: reportLabor[i].unit?.UnitName,
          workDescription: reportLabor[i].WorkOrderDescription,
          laborHours: reportLabor[i].EscrowHours,
          laborCost:
            reportLabor[i].tfworkorders.length !== 0
              ? currencyFormat(+reportLabor[i].tfworkorders[0]?.LaborTotal)
              : '',
          remove: reportLabor[i].id,
          total: currencyFormat(
            +reportLabor[i].tfworkorders.length !== 0
              ? (laborTotal += +reportLabor[i].tfworkorders[0]?.LaborTotal)
              : 0
          ),
        });
      }
      setLoading(false);
    }
    fetchData();
  }, []);
  if (loading) {
    return (
      <RctCollapsibleCard
        colClasses="col-xs-12 col-sm-12 col-md-12"
        heading={'Loading Reports...'}
      >
        <LinearProgress />
      </RctCollapsibleCard>
    );
  }
  const itemColumns = [
    { name: 'date', label: 'Date' },
    { name: 'invoice_number', label: 'Invoice Number' },
    { name: 'payee', label: 'Payee' },
    { name: 'account', label: 'Account' },
    { name: 'memo', label: 'Memo' },
    { name: 'debitAmt', label: 'Debit Amt' },
    { name: 'receipt', label: 'Receipt' },
    {
      name: 'remove', label: 'Remove', options: {
        customBodyRender: (value) => {
          return <IconButton
            aria-label="Print"
            onClick={() => {
              deleteWK(value);
            }}
          >
            <DeleteForever />
          </IconButton>
        }
      }
    },
    { name: 'total', label: 'Total' },
  ];

  const laborColumns = [
    { name: 'date', label: 'Date' },
    { name: 'unit', label: 'Unit' },
    { name: 'workDescription', label: 'Work Description' },
    { name: 'laborHours', label: 'Labor Hours' },
    { name: 'laborCost', label: 'Labor Cost' },
    {
      name: 'remove', label: 'Remove', options: {
        customBodyRender: (value) => {
          return <IconButton
            aria-label="Print"
            onClick={() => {
              deleteWK(value);
            }}
          >
            <DeleteForever />
          </IconButton>
        }
      }
    },
    { name: 'total', label: 'Total' },
  ];

  const sendEmail = async () => {
    const result = await reportsAPI.sendEscrowEmail({
      ...props.reportSearch,
      email,
      propertyName: filteredName.PropertyName,
    });
    if (result === 0) {
      Swal.fire({
        icon: 'success',
        text: 'Email sent successfully.',
      });
    } else {
      Swal.fire({
        icon: 'error',
        text: 'Error processing your request. Please, contact us.',
      });
    }
  };

  return (
    <div>
      <div className="page-title d-flex justify-content-between align-items-center">
        <div className="page-title-wrap">
          <h2>
            <span>
              {props.reportName +
                ' - ' +
                filteredName.PropertyName +
                ' - Date Range of Report: ' +
                props.startDate +
                ' to ' +
                props.endDate}
            </span>
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-xl-12">
          <MuiThemeProvider theme={Constants.getMuiTheme()}>
            <MUIDataTable
              title={'Capital Item'}
              data={itemData}
              columns={itemColumns}
              options={props.reportOptions}
            />
          </MuiThemeProvider>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-xl-12">
          <MuiThemeProvider theme={Constants.getMuiTheme()}>
            <MUIDataTable
              title={'Capital Labor'}
              data={laborData}
              columns={laborColumns}
              options={props.reportOptions}
            />
          </MuiThemeProvider>
        </div>
      </div>

      <div className="row" style={{ marginTop: '20px' }}>
        <div className="col-sm-12 col-md-12 col-xl-12">
          <RctCollapsibleCard heading="">
            <div className="d-flex align-items-center" style={{ gap: '10px' }}>
              <Input
                type="email"
                style={{ maxWidth: '250px' }}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <Button
                type="submit"
                color="primary"
                size="sm"
                className="w-auto"
                onClick={() => sendEmail()}
              >
                email PDF file
              </Button>
            </div>
          </RctCollapsibleCard>
        </div>
      </div>
    </div>
  );
};

export default EscrowReserve;
