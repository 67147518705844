import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import * as Constants from "../Util/constants";
import * as reportsAPI from "../../Api/reports";
import { currencyFormat } from "./helperFunctions";
import RctCollapsibleCard from "../Helpers/RctCollapsibleCard/RctCollapsibleCard";
import LinearProgress from "../Util/LinearProgress";

const RentRollActual = (props) => {
  const [loading, setLoading] = useState(false);
  const propertyName = useSelector((state) => state.login.properties);
  const [filteredName] = propertyName.filter(
    (property) => property.PropertyID === props.propertyId
  );
  const [units, setUnits] = useState([]);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const transactionSum = await reportsAPI.getRentRollTransactionsSum(
        props.reportSearch
      );

      let vacantCount = 0;
      const totals = {
        tenantRentValue: 0,
        housingAmountValue: 0,
        petRentValue: 0,
        parkingChargesValue: 0,
        utilitiesValue: 0,
        totalCharges: 0,
        totalBalance: 0,
      };
      let tableData = transactionSum.map((transaction) => {
        let total =
          (transaction.RentalAmount === "" ? 0 : +transaction.RentalAmount) +
          (transaction.HousingAmount === "" ? 0 : +transaction.HousingAmount) +
          (transaction.PetRent === "" ? 0 : +transaction.PetRent);
        total +=
          (transaction.ParkingCharge === "" ? 0 : +transaction.ParkingCharge) +
          (transaction.UtilityCharge === "" ? 0 : +transaction.UtilityCharge);
        let tenantBalance =
          (transaction.TotalDebit === "" ? 0 : +transaction.TotalDebit) -
          (transaction.TotalCredit === "" ? 0 : +transaction.TotalCredit);
        let housingBalance =
          (transaction.HousingDebit === "" ? 0 : +transaction.HousingDebit) -
          (transaction.HousingCredit === "" ? 0 : +transaction.HousingCredit);
        let balance = tenantBalance + housingBalance;

        // Add to the Totals
        totals["tenantRentValue"] +=
          transaction.RentalAmount === "" ? 0 : +transaction.RentalAmount;
        totals["housingAmountValue"] +=
          transaction.HousingAmount === "" ? 0 : +transaction.HousingAmount;
        totals["petRentValue"] +=
          transaction.PetRent === "" ? 0 : +transaction.PetRent;
        totals["parkingChargesValue"] +=
          transaction.ParkingCharge === "" ? 0 : +transaction.ParkingCharge;
        totals["utilitiesValue"] +=
          transaction.UtilityCharge === "" ? 0 : +transaction.UtilityCharge;
        totals["totalCharges"] += total;
        totals["totalBalance"] += balance;

        if (transaction.TenantFName) {
          return {
            unit: transaction.UnitName,
            unitType: transaction.UnitType,
            squareFootage: transaction.SquareFootage,
            tenant: transaction.TenantFName + " " + transaction.TenantLName,
            originalMoveIn: transaction.MoveInDate,
            leaseStartDate: transaction.LeaseStartDate,
            leaseEndDate: transaction.LeaseEndDate,
            tenantRent: currencyFormat(+transaction.RentalAmount),
            housingAmount: currencyFormat(+transaction.HousingAmount),
            petRent: currencyFormat(+transaction.PetRent),
            parkingCharges: currencyFormat(+transaction.ParkingCharge),
            utilities: currencyFormat(+transaction.UtilityCharge),
            totalCharges: currencyFormat(+total),
            balance: currencyFormat(+balance),
          };
        } else {
          vacantCount++;
          return {
            unit: transaction.UnitName,
            unitType: transaction.UnitType,
            squareFootage: transaction.SquareFootage,
            tenant: "Vacant",
          };
        }
      });

      tableData = [
        ...tableData,
        {
          unit: "TOTALS",
          squareFootage: `Non-Vacant Units: ${
            transactionSum.length - vacantCount
          }`,
          tenant: `Vacant Units: ${vacantCount}`,
          tenantRent: currencyFormat(+totals["tenantRentValue"]),
          housingAmount: currencyFormat(+totals["housingAmountValue"]),
          petRent: currencyFormat(+totals["petRentValue"]),
          parkingCharges: currencyFormat(+totals["parkingChargesValue"]),
          utilities: currencyFormat(+totals["utilitiesValue"]),
          totalCharges: currencyFormat(+totals["totalCharges"]),
          balance: currencyFormat(+totals["totalBalance"]),
        },
      ];
      setUnits(tableData);
      setLoading(false);
    }
    fetchData();
  }, []);
  if (loading) {
    return (
      <RctCollapsibleCard
        colClasses="col-xs-12 col-sm-12 col-md-12"
        heading={"Loading Reports..."}
      >
        <LinearProgress />
      </RctCollapsibleCard>
    );
  }
  const unitsColumns = [
    { name: "unit", label: "Unit" },
    { name: "unitType", label: "Unit Type" },
    { name: "squareFootage", label: "Square Footage" },
    { name: "tenant", label: "Tenant" },
    { name: "originalMoveIn", label: "Original Move In" },
    { name: "leaseStartDate", label: "Lease Start Date" },
    { name: "leaseEndDate", label: "Lease End Date" },
    { name: "tenantRent", label: "Tenant Rent" },
    { name: "housingAmount", label: "Housing Amount" },
    { name: "petRent", label: "Pet Rent" },
    { name: "parkingCharges", label: "Parking Charges" },
    { name: "utilities", label: "Utilities" },
    { name: "totalCharges", label: "Total Charges" },
    { name: "balance", label: "Balance" },
  ];

  return (
    <div>
      <div className="page-title d-flex justify-content-between align-items-center">
        <div className="page-title-wrap">
          <h2>
            <span>{props.reportName + " - " + filteredName.PropertyName}</span>
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-xl-12">
          <MuiThemeProvider theme={Constants.getMuiTheme()}>
            <MUIDataTable
              title={"Rent Roll and Actuals"}
              data={units}
              columns={unitsColumns}
              options={props.reportOptions}
            />
          </MuiThemeProvider>
        </div>
      </div>
    </div>
  );
};

export default RentRollActual;
