import { Types } from '../constants';

const initialState = {
  scrollInfo: {
    scrollTop: 0,
    scrollHeight: 0,
    clientHeight: 0
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
      case Types.SCROLLINFO:
          return {
              ...state,
              scrollInfo: action.payload
          }
      default:
          return state;
  }
}


export default reducer;
