import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import * as Constants from "../Util/constants";
import * as reportsAPI from "../../Api/reports";
import { currencyFormat } from "./helperFunctions";
import RctCollapsibleCard from "../Helpers/RctCollapsibleCard/RctCollapsibleCard";
import LinearProgress from "../Util/LinearProgress";

const Quickbooks = (props) => {
  const [loading, setLoading] = useState(false);
  const [quickData, setQuickData] = useState([]);
  const propertyName = useSelector((state) => state.login.properties);
  const [filteredName] = propertyName.filter(
    (property) => property.PropertyID === props.propertyId
  );

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      let reportDetails = await reportsAPI.getQuickbookExport(
        props.reportSearch
      );
      Object.entries(reportDetails).forEach(([key, value]) => {
        reportDetails[key] = currencyFormat(+value);
      })
      setQuickData([reportDetails]);
      setLoading(false);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <RctCollapsibleCard
        colClasses="col-xs-12 col-sm-12 col-md-12"
        heading={"Loading Reports..."}
      >
        <LinearProgress />
      </RctCollapsibleCard>
    );
  }
  const quickColumns = [
    { name: "rentBilled", label: "Tenant Rent Billed" },
    { name: "rentPaid", label: "Tenant Payments Received" },
    { name: "houseRentBilled", label: "Rental Assistance Billed" },
    { name: "houseRentPaid", label: "Rental Assistance Received" },
    {
      name: "nonRentCharged",
      label: "Tenant Additional Charges Billed",
    },
    {
      name: "nonRentPaid",
      label: "Tenant Additional Charges Received",
    },
  ];

  return (
    <div>
      <div className="page-title d-flex justify-content-between align-items-center">
        <div className="page-title-wrap">
          <h2>
            <span>{props.reportName + " - " + filteredName.PropertyName}</span>
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-xl-12">
          <MuiThemeProvider theme={Constants.getMuiTheme()}>
            <MUIDataTable
              title={"Quickbooks Export"}
              data={quickData}
              columns={quickColumns}
              options={props.reportOptions}
            />
          </MuiThemeProvider>
        </div>
      </div>
    </div>
  );
};

export default Quickbooks;
