import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import * as Constants from '../Util/constants';
import * as reportsAPI from '../../Api/reports';
import { currencyFormat } from './helperFunctions';
import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import LinearProgress from '../Util/LinearProgress';

const BoxScore = (props) => {
    const [loading, setLoading] = useState(false);
    const [units] = useState([]);
    const [unitIds] = useState([]);
    const [boxData, setBoxData] = useState([]);
    const [trafficData, setTrafficData] = useState([]);
    const [activityData, setActivityData] = useState([]);
    const [totals, setTotals] = useState({
        others: 0,
        approved: 0,
        denied: 0,
        pending: 0,
        notReviewed: 0,
        moveIn: 0,
        moveOut: 0,
        eviction: 0,
    });
    const propertyName = useSelector((state) => state.login.properties);
    const [filteredName] = propertyName.filter((property) => property.PropertyID === props.propertyId);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            const boxUnits = await reportsAPI.getBoxUnits(props.reportSearch);
            const occupiedNoNotice = await reportsAPI.getBoxOccupiedNoNotice(props.reportSearch);
            const totalOccupied = await reportsAPI.getBoxTotalOccupied(props.reportSearch);
            const vacantUnrented = await reportsAPI.getBoxVacantUnrent(props.reportSearch);
            const vacantRent = await reportsAPI.getBoxVacantRent(props.reportSearch);
            const noticedRent = await reportsAPI.getBoxNotice(props.reportSearch);
            const noticedUnrent = await reportsAPI.getBoxNoticeUnrent(props.reportSearch);
            const boxTraffic = await reportsAPI.getBoxTrafficBackground(props.reportSearch);
            const residentActivity = await reportsAPI.getBoxOccupancy(props.reportSearch);
            for (let i = 0; i < boxUnits.length; i++) {
                units[boxUnits[i].id] = {
                    type: boxUnits[i].UnitType,
                    averageSqFeet: boxUnits[i].SquareFootage,
                    avgRent: currencyFormat(+boxUnits[i].UnitCharge),
                    totalUnits: boxUnits[i].units.length,
                    occupiedNoNotice: 0,
                    vacantRented: 0,
                    vacantUnrented: 0,
                    noticeRented: 0,
                    noticeUnrented: 0,
                    available: 0,
                    downUnits: 0,
                    totalOccupied: 0,
                    perOccupied: 0,
                    perOccupiedWithDown: 0,
                    perOccupiedLast: 'NA',
                    perOccupiedVar: 'NA',
                    perTrendWithDown: 'NA',
                    call: 'NA',
                    walkIn: 'NA',
                    eMail: 'NA',
                    other: 0,
                    show: 'NA',
                    applied: 'NA',
                    approved: 0,
                    grossConversionRatio: 0,
                    denied: 0,
                    pending: 0,
                    notReviewed: 0,
                    cancelApplication: 'NA',
                    reApply: 'NA',
                    netConversionRatio: 0,
                    moveIn: 0,
                    moveOut: 0,
                    gainOrLoss: 0,
                    noticeSkipEarlyTerm: 'NA',
                    onSiteTransfer: 'NA',
                    monthToMonth: 'NA',
                    renewal: 'NA',
                    cancelMoveIn: 'NA',
                    eviction: 0,
                    cancelEviction: 'NA',
                    cancelMoveOut: 'NA',
                };
                unitIds.push(boxUnits[i].id);
            }
            for (let i = 0; i < occupiedNoNotice.length; i++) {
                units[occupiedNoNotice[i].id].occupiedNoNotice = occupiedNoNotice[i].units.length;
            }
            for (let i = 0; i < totalOccupied.length; i++) {
                units[totalOccupied[i].id].totalOccupied = totalOccupied[i].units.length;
                units[totalOccupied[i].id].available = boxUnits[i].units.length - totalOccupied[i].units.length;
                units[totalOccupied[i].id].perOccupied =
                    Math.round((totalOccupied[i].units.length / boxUnits[i].units.length) * 100).toString() + '%';
                units[totalOccupied[i].id].perOccupiedWithDown =
                    Math.round((totalOccupied[i].units.length / boxUnits[i].units.length) * 100).toString() + '%';
            }
            for (let i = 0; i < vacantRent.length; i++) {
                if (new Date(vacantRent[i].MoveInDate).getTime() > new Date().getTime()) {
                    units[vacantRent[i].UnitTypeID].vacantRented += 1;
                }
            }
            for (let i = 0; i < vacantUnrented.length; i++) {
                units[vacantUnrented[i].id].vacantUnrented = vacantUnrented[i].units.length;
            }
            for (let i = 0; i < noticedRent.length; i++) {
                units[noticedUnrent[i].UnitTypeID].noticeRented += 1;
            }
            for (let i = 0; i < noticedUnrent.length; i++) {
                units[noticedUnrent[i].UnitTypeID].noticeUnrented += 1;
            }
            for (let i = 0; i < residentActivity.length; i++) {
                if (
                    new Date(residentActivity[i].MoveInDate).getTime() >= new Date(props.reportSearch.sDt).getTime() &&
                    new Date(residentActivity[i].MoveInDate).getTime() <= new Date(props.reportSearch.eDt).getTime() &&
                    units[residentActivity[i].UnitTypeID]
                ) {
                    units[residentActivity[i].UnitTypeID].moveIn += 1;
                    totals.moveIn += 1;
                }
                if (
                    new Date(residentActivity[i].MoveOutDate).getTime() >= new Date(props.reportSearch.sDt).getTime() &&
                    new Date(residentActivity[i].MoveOutDate).getTime() <= new Date(props.reportSearch.eDt).getTime() &&
                    units[residentActivity[i].UnitTypeID]
                ) {
                    units[residentActivity[i].UnitTypeID].moveOut += 1;
                    units[residentActivity[i].UnitTypeID].eviction += 1;
                    totals.moveOut += 1;
                    totals.eviction += 1;
                }
                if (units[residentActivity[i].UnitTypeID]) {
                    units[residentActivity[i].UnitTypeID].gainOrLoss =
                        units[residentActivity[i].UnitTypeID].moveIn - units[residentActivity[i].UnitTypeID].moveOut;
                }
            }
            for (let i = 0; i < boxTraffic.length; i++) {
                units[boxTraffic[i].tenant.unit.UnitTypeID].other += 1;
                totals.others += 1;
                if (boxTraffic[i].ApproveID === 1) {
                    units[boxTraffic[i].tenant.unit.UnitTypeID].approved += 1;
                    totals.approved += 1;
                }
                if (boxTraffic[i].ApproveID === 3) {
                    units[boxTraffic[i].tenant.unit.UnitTypeID].pending += 1;
                    totals.pending += 1;
                }
                if (boxTraffic[i].ApproveID === 4) {
                    units[boxTraffic[i].tenant.unit.UnitTypeID].denied += 1;
                    totals.denied += 1;
                }
                if (boxTraffic[i].ApproveID === 5) {
                    units[boxTraffic[i].tenant.unit.UnitTypeID].notReviewed += 1;
                    totals.notReviewed += 1;
                }
                units[boxTraffic[i].tenant.unit.UnitTypeID].grossConversionRatio =
                    Math.round(
                        (units[boxTraffic[i].tenant.unit.UnitTypeID].approved /
                            units[boxTraffic[i].tenant.unit.UnitTypeID].other) *
                            100,
                    ).toString() + '%';
                units[boxTraffic[i].tenant.unit.UnitTypeID].netConversionRatio =
                    Math.round(
                        (units[boxTraffic[i].tenant.unit.UnitTypeID].approved /
                            units[boxTraffic[i].tenant.unit.UnitTypeID].other) *
                            100,
                    ).toString() + '%';
            }
            const boxData = unitIds.map((id) => {
                return units[id];
            });
            setTrafficData([
                ...boxData,
                {
                    type: 'Totals',
                    call: 'NA',
                    walkIn: 'NA',
                    eMail: 'NA',
                    cancelApplication: 'NA',
                    reApply: 'NA',
                    show: 'NA',
                    applied: 'NA',
                    approved: totals.approved,
                    denied: totals.denied,
                    other: totals.others,
                    pending: totals.pending,
                    notReviewed: totals.notReviewed,
                    grossConversionRatio: Math.round((totals.approved / totals.others) * 100).toString() + '%',
                    netConversionRatio: Math.round((totals.approved / totals.others) * 100).toString() + '%',
                },
            ]);
            setActivityData([
                ...boxData,
                {
                    type: 'Totals',
                    moveIn: totals.moveIn,
                    moveOut: totals.moveOut,
                    gainOrLoss: totals.moveIn - totals.moveOut,
                    noticeSkipEarlyTerm: 'NA',
                    onSiteTransfer: 'NA',
                    monthToMonth: 'NA',
                    applied: 'NA',
                    renewal: 'NA',
                    cancelMoveIn: 'NA',
                    eviction: totals.eviction,
                    cancelEviction: 'NA',
                    cancelMoveOut: 'NA',
                },
            ]);
            setBoxData(boxData);
            setLoading(false);
        }
        fetchData();
    }, []);
    if (loading) {
        return (
            <RctCollapsibleCard colClasses="col-xs-12 col-sm-12 col-md-12" heading={'Loading Reports...'}>
                <LinearProgress />
            </RctCollapsibleCard>
        );
    }
    const availableColumns = [
        { name: 'type', label: 'Type' },
        { name: 'averageSqFeet', label: 'Average Sq Feet' },
        { name: 'avgRent', label: 'Avg Rent' },
        { name: 'totalUnits', label: 'Total Units' },
        { name: 'occupiedNoNotice', label: 'Occupied No Notice' },
        { name: 'vacantRented', label: 'Vacant Rented' },
        { name: 'vacantUnrented', label: 'Vacant Un-Rented' },
        { name: 'noticeRented', label: 'Notice Rented' },
        { name: 'noticeUnrented', label: 'Notice Un-Rented' },
        { name: 'available', label: 'Available' },
        { name: 'downUnits', label: 'Down Units' },
        { name: 'totalOccupied', label: 'Total Occupied' },
        { name: 'perOccupied', label: '% Occupied' },
        { name: 'perOccupiedWithDown', label: '% Occupied w/Down' },
        { name: 'perOccupiedLast', label: '% Occupied Last' },
        { name: 'perOccupiedVar', label: '% Occupied Var' },
        { name: 'perTrendWithDown', label: '% Trend w/Down' },
    ];
    const residentColumns = [
        { name: 'type', label: 'Type' },
        { name: 'moveIn', label: 'Move In' },
        { name: 'moveOut', label: 'Move Out' },
        { name: 'gainOrLoss', label: 'Gain or Loss' },
        { name: 'noticeSkipEarlyTerm', label: 'Notice/Skip Early Term' },
        { name: 'onSiteTransfer', label: 'On Site Transfer' },
        { name: 'monthToMonth', label: 'Month to Month' },
        { name: 'renewal', label: 'Renewal' },
        { name: 'cancelMoveIn', label: 'Cancel Move In' },
        { name: 'eviction', label: 'Eviction' },
        { name: 'cancelEviction', label: 'Cancel Eviction' },
        { name: 'cancelMoveOut', label: 'Cancel Move Out' },
    ];
    const trafficColumns = [
        { name: 'type', label: 'Type' },
        { name: 'call', label: 'Call' },
        { name: 'walkIn', label: 'Walk In' },
        { name: 'eMail', label: 'EMail' },
        { name: 'other', label: 'Other' },
        { name: 'show', label: 'Show' },
        { name: 'applied', label: 'Applied' },
        { name: 'approved', label: 'Approved' },
        { name: 'grossConversionRatio', label: 'Gross Conversion Ratio' },
        { name: 'denied', label: 'Denied' },
        { name: 'pending', label: 'Pending' },
        { name: 'notReviewed', label: 'Not Reviewed' },
        { name: 'cancelApplication', label: 'Cancel Application' },
        { name: 'reApply', label: 'Re Apply' },
        { name: 'netConversionRatio', label: 'Net Conversion Ratio' },
    ];
    return (
        <div>
            <div className="page-title d-flex justify-content-between align-items-center">
                <div className="page-title-wrap">
                    <h2>
                        <span>
                            {props.reportName +
                                ' - ' +
                                filteredName.PropertyName +
                                ' - Date Range of Report: ' +
                                props.startDate +
                                ' to ' +
                                props.endDate}
                        </span>
                    </h2>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-xl-12">
                    <MuiThemeProvider theme={Constants.getMuiTheme()}>
                        <MUIDataTable
                            title={'Availability:'}
                            data={boxData}
                            columns={availableColumns}
                            options={props.reportOptions}
                        />
                    </MuiThemeProvider>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-xl-12">
                    <MuiThemeProvider theme={Constants.getMuiTheme()}>
                        <MUIDataTable
                            title={'Resident Activity:'}
                            data={activityData}
                            columns={residentColumns}
                            options={props.reportOptions}
                        />
                    </MuiThemeProvider>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-xl-12">
                    <MuiThemeProvider theme={Constants.getMuiTheme()}>
                        <MUIDataTable
                            title={'Traffic:'}
                            data={trafficData}
                            columns={trafficColumns}
                            options={props.reportOptions}
                        />
                    </MuiThemeProvider>
                </div>
            </div>
        </div>
    );
};

export default BoxScore;
