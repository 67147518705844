import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import * as Constants from "../Util/constants";
import * as reportsAPI from "../../Api/reports";
import { currencyFormat, formatDate } from "./helperFunctions";
import RctCollapsibleCard from "../Helpers/RctCollapsibleCard/RctCollapsibleCard";
import LinearProgress from "../Util/LinearProgress";
import { useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Edit from "@material-ui/icons/Edit";
import { Input } from "reactstrap";
import Print from "@material-ui/icons/Print";

const Payables = (props) => {
  const [loading, setLoading] = useState(false);
  const propertyName = useSelector((state) => state.login.properties);
  const [filteredName] = propertyName.filter(
    (property) => property.PropertyID === props.propertyId
  );

  const [allPayData, setAllPayData] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [payData, setPayData] = useState([]);

  const handleVendor = (event) => {
    const selectedVendorId = event.target.value;
    if (+selectedVendorId < 0) {
      setPayData(allPayData);
    } else {
      let sum = 0;
      let result = allPayData.reduce((total, ele) => {
        if (+ele.vendorId === +selectedVendorId) {
          total = [...total, ele];
          sum += +ele.amount;
        }

        return total;
      }, []);

      result = [
        ...result,
        {
          invoiceDate: "TOTAL PAID",
          invoiceNumber: currencyFormat(+sum),
        },
      ];
      setPayData(result);
    }
  };

  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const reportDetails = await reportsAPI.getPayables(props.reportSearch);
      const vendorData = await reportsAPI.getVendors(props.reportSearch);
      setVendors([{ id: -1, VendorName: "All Vendors" }, ...vendorData]);
      const reportTotal = reportDetails.reduce((result, report) => {
        return result + +report.Amount;
      }, 0);

      const result = reportDetails.map((report) => ({
        vendorId: +report.VendorID,
        amount: +report.Amount,
        invoiceDate: formatDate(report.InvoiceDate),
        invoiceNumber: report.InvoiceNumber,
        paidDate: formatDate(report.PaidDate),
        payee: report.vendor?.VendorName,
        account: report?.expensetype?.accounttype.AccountType,
        memo: report.Memo,
        debitAmt: currencyFormat(+report.Amount),
        escrow: report.Escrow === 1 ? "Yes" : "No",
        receipt: report.receipts.length !== 0 && (
          <IconButton
            aria-label="Receipt"
            onClick={() => {
              const location = {
                pathname: `/Receipts/${
                  props.reportSearch.pID
                }/${report.PaidDate.substring(
                  0,
                  4
                )}/${report.PaidDate.substring(
                  5,
                  7
                )}/${report.id.toString()}.pdf`,
              };
              history.push(location);
            }}
          >
            <AttachFileIcon />
          </IconButton>
        ),
        edit: (
          <IconButton
            aria-label="Edit"
            onClick={() => {
              const location = {
                pathname: "/Bills/edit",
                state: {
                  checkRegisterID: report.id,
                },
              };
              history.push(location);
            }}
          >
            <Edit />
          </IconButton>
        ),
        delete: "",
      }));

      const totalResult = [
        ...result,
        {
          invoiceDate: "TOTAL PAID",
          invoiceNumber: currencyFormat(+reportTotal),
        },
      ];
      setPayData(totalResult);
      setAllPayData(totalResult);
      setLoading(false);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (loading) {
    return (
      <RctCollapsibleCard
        colClasses="col-xs-12 col-sm-12 col-md-12"
        heading={"Loading Reports..."}
      >
        <LinearProgress />
      </RctCollapsibleCard>
    );
  }
  const payColumns = [
    { name: "invoiceDate", label: "Invoice Date" },
    { name: "invoiceNumber", label: "Invoice Number" },
    { name: "paidDate", label: "Paid Date" },
    { name: "payee", label: "Payee" },
    { name: "account", label: "Account" },
    { name: "memo", label: "Memo" },
    { name: "debitAmt", label: "Debit Amt" },
    { name: "escrow", label: "Escrow?" },
    { name: "receipt", label: "Receipt" },
    { name: "edit", label: "Edit" },
    { name: "delete", label: "Delete?" },
  ];

  return (
    <div>
      <div className="page-title d-flex justify-content-between align-items-center">
        <div className="page-title-wrap">
          <h2>
            <span>
              {props.reportName +
                " - " +
                filteredName.PropertyName +
                " - Date Range of Report: " +
                props.startDate +
                " to " +
                props.endDate}
            </span>
          </h2>
        </div>
      </div>
      <div className="row" style={{ marginBottom: "30px" }}>
        <div className="col-sm-12 col-md-8 col-xl-4">
          <Input
            type="select"
            name="reportType"
            id="reportType"
            onChange={handleVendor}
          >
            {vendors?.map((vendor) => {
              return (
                <option value={vendor.id} name={vendor.VendorName}>
                  {vendor.VendorName}
                </option>
              );
            })}
          </Input>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-xl-12">
          <MuiThemeProvider theme={Constants.getMuiTheme()}>
            <MUIDataTable
              title={"Payables"}
              data={payData}
              columns={payColumns}
              options={props.reportOptions}
            />
          </MuiThemeProvider>
        </div>
      </div>
    </div>
  );
};

export default Payables;
