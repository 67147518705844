import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import * as Constants from "../Util/constants";
import * as reportsAPI from "../../Api/reports";
import { currencyFormat, formatDate } from "./helperFunctions";
import RctCollapsibleCard from "../Helpers/RctCollapsibleCard/RctCollapsibleCard";
import LinearProgress from "../Util/LinearProgress";

const ExpensesUnit = (props) => {
  const [loading, setLoading] = useState(false);
  const [expenseData, setExpenseData] = useState([]);
  const [expenseTotal, setExpenseTotal] = useState([]);
  const [unitNames, setUnitNames] = useState([]);
  const propertyName = useSelector((state) => state.login.properties);
  const [filteredName] = propertyName.filter(
    (property) => property.PropertyID === props.propertyId
  );
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const reportDetails = await reportsAPI.getExpenseUnit(props.reportSearch);
      for (let i = 0; i < reportDetails.length; i++) {
        if (expenseData[reportDetails[i].unit.UnitName]) {
          expenseData[reportDetails[i].unit.UnitName].push({
            invoiceDate: formatDate(reportDetails[i].InvoiceDate),
            payee: reportDetails[i].vendor.VendorName,
            memo: reportDetails[i].Memo,
            invoiceNumber: reportDetails[i].InvoiceNumber,
            paid: reportDetails[i].Paid === 1 ? "Yes" : "No",
            debitAmt: currencyFormat(+reportDetails[i].Amount),
          });
          expenseTotal[reportDetails[i].unit.UnitName] +=
            +reportDetails[i].Amount;
        } else {
          unitNames.push(reportDetails[i].unit.UnitName);
          expenseData[reportDetails[i].unit.UnitName] = [
            {
              invoiceDate: formatDate(reportDetails[i].InvoiceDate),
              payee: reportDetails[i].vendor.VendorName,
              memo: reportDetails[i].Memo,
              invoiceNumber: reportDetails[i].InvoiceNumber,
              paid: reportDetails[i].Paid === 1 ? "Yes" : "No",
              debitAmt: currencyFormat(+reportDetails[i].Amount),
            },
          ];
          expenseTotal[reportDetails[i].unit.UnitName] =
            +reportDetails[i].Amount;
        }
      }
      unitNames.map((unit) =>
        expenseData[unit].push({
          paid: "TOTAL",
          debitAmt: currencyFormat(+expenseTotal[unit]),
        })
      );
      setLoading(false);
    }
    fetchData();
  }, []);
  if (loading) {
    return (
      <RctCollapsibleCard
        colClasses="col-xs-12 col-sm-12 col-md-12"
        heading={"Loading Reports..."}
      >
        <LinearProgress />
      </RctCollapsibleCard>
    );
  }
  const expenseColumns = [
    { name: "invoiceDate", label: "Invoice Date" },
    { name: "payee", label: "Payee" },
    { name: "memo", label: "Memo" },
    { name: "invoiceNumber", label: "Invoice Number" },
    { name: "paid", label: "Paid?" },
    { name: "debitAmt", label: "Debit Amt" },
  ];

  return (
    <div>
      <div className="page-title d-flex justify-content-between align-items-center">
        <div className="page-title-wrap">
          <h2>
            <span>
              {props.reportName +
                " - " +
                filteredName.PropertyName +
                " - Date Range of Report: " +
                props.startDate +
                " to " +
                props.endDate}
            </span>
          </h2>
        </div>
      </div>
      <div className="row">
        {unitNames.map((unit) => {
          return (
            <div className="col-sm-12 col-md-12 col-xl-12">
              <MuiThemeProvider theme={Constants.getMuiTheme()}>
                <MUIDataTable
                  title={`Unit: ${unit}`}
                  data={expenseData[unit]}
                  columns={expenseColumns}
                  options={props.reportOptions}
                />
              </MuiThemeProvider>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ExpensesUnit;
