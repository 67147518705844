import React, { useState, useEffect } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import * as Constants from '../Util/constants';
import * as reportsAPI from '../../Api/reports';
import { currencyFormat } from './helperFunctions';
import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import LinearProgress from '../Util/LinearProgress';
import { Link } from 'react-router-dom';
import Edit from '@material-ui/icons/Edit';

const Delinquency = (props) => {
    const [loading, setLoading] = useState(false);
    const [delinqData, setDelinqData] = useState([]);
    var [tenantTotal, setTenantTotal] = useState(0);
    var [housingTotal, setHousingTotal] = useState(0);
    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            const delinqData = {};
            console.log('props.selectedValue', props.selectedValue);
            for (let i = 0; i < props.selectedValue.length; i++) {
                props.reportSearch.pID = props.selectedValue[i].id;
                const delinq = await reportsAPI.getDelinquency(props.reportSearch);

                if (!delinqData[props.selectedValue[i].name]) {
                    for (let j = 0; j < delinq.length; j++) {
                        if (0 < +delinq[j].TotalTenant + +delinq[j].TotalHousing) {
                            const promiss = await reportsAPI.getTenantsDelinquent({
                                pID: props.reportSearch.pID,
                                tID: delinq[j].tenant.id.toString(),
                            });
                            if (delinqData[props.selectedValue[i].name]) {
                                delinqData[props.selectedValue[i].name].push({
                                    unit: delinq[j].tenant.unit?.UnitName,
                                    tenantId: delinq[j].tenant.id,
                                    tenant: delinq[j].tenant.TenantFName + ' ' + delinq[j].tenant.TenantLName,

                                    delinquencyComments: delinq[j].tenant.DelinquencyComments,

                                    tenantPromise: promiss?.Promiss,
                                    staffComments: promiss?.StaffComment,
                                    missedP2p: promiss?.Success !== 1 ? ' ' : 'Yes',
                                    tenantPortion: currencyFormat(+delinq[j].TotalTenant),
                                    housingPortion: currencyFormat(+delinq[j].TotalHousing),
                                    balanceOwed: currencyFormat(+delinq[j].TotalTenant + +delinq[j].TotalHousing),
                                });
                                tenantTotal += +delinq[j].TotalTenant;
                                housingTotal += +delinq[j].TotalHousing;
                            } else {
                                delinqData[props.selectedValue[i].name] = [
                                    {
                                        unit: delinq[j].tenant.unit?.UnitName,
                                        tenantId: delinq[j].tenant.id,
                                        tenant: delinq[j].tenant.TenantFName + ' ' + delinq[j].tenant.TenantLName,

                                        delinquencyComments: delinq[j].tenant.DelinquencyComments,

                                        tenantPromise: promiss?.Promiss,
                                        staffComments: promiss?.StaffComment,
                                        missedP2p: promiss?.Success !== 1 ? ' ' : 'Yes',
                                        tenantPortion: currencyFormat(+delinq[j].TotalTenant),
                                        housingPortion: currencyFormat(+delinq[j].TotalHousing),
                                        balanceOwed: currencyFormat(+delinq[j].TotalTenant + +delinq[j].TotalHousing),
                                    },
                                ];
                                tenantTotal += +delinq[j].TotalTenant;
                                housingTotal += +delinq[j].TotalHousing;
                            }
                        }
                    }
                    delinqData[props.selectedValue[i].name]
                        ? delinqData[props.selectedValue[i].name].push({
                              delinquencyComments: `Total Delinquent Tenants: ${
                                  delinqData[props.selectedValue[i].name].length
                              }`,
                              tenantPortion: currencyFormat(tenantTotal),
                              housingPortion: currencyFormat(housingTotal),
                              balanceOwed: currencyFormat(tenantTotal + housingTotal),
                          })
                        : (delinqData[props.selectedValue[i].name] = [
                              {
                                  delinquencyComments: `Total Delinquent Tenants: 0`,
                                  tenantPortion: currencyFormat(tenantTotal),
                                  housingPortion: currencyFormat(housingTotal),
                                  balanceOwed: currencyFormat(tenantTotal + housingTotal),
                              },
                          ]);
                }
            }
            setDelinqData(delinqData);

            setLoading(false);
        }
        fetchData();
    }, [props.selectedValue]);
    if (loading) {
        return (
            <RctCollapsibleCard colClasses="col-xs-12 col-sm-12 col-md-12" heading={'Loading Reports...'}>
                <LinearProgress />
            </RctCollapsibleCard>
        );
    }
    const delinqColumns = [
        { name: 'unit', label: 'Unit' },
        {
            name: 'tenantId',
            label: 'Tenant Id',
            options: {
                display: false,
            },
        },
        {
            name: 'tenant',
            label: 'Tenant',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Link
                            to={{
                                pathname: '/tenants/details',
                                state: {
                                    tenantID: tableMeta.rowData[1],
                                },
                            }}
                        >
                            {value}
                        </Link>
                    );
                },
            },
        },
        {
            name: 'delinquencyComments',
            label: 'Delinquency Comments',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => (
                    <Link
                        to={{
                            pathname: '/editDelinquencyComment',
                            state: {
                                tenantID: tableMeta.rowData[1],
                                reportSearch: props.reportSearch,
                                selectedValue: props.selectedValue,
                            },
                        }}
                    >
                        {value}
                        <Edit />
                    </Link>
                ),
            },
        },
        { name: 'tenantPromise', label: 'Tenant Promise' },
        { name: 'staffComments', label: 'Staff Comments' },
        { name: 'missedP2p', label: 'Missed P2P' },
        { name: 'tenantPortion', label: 'Tenant Portion' },
        { name: 'housingPortion', label: 'Housing Portion' },
        { name: 'balanceOwed', label: 'Balance Owed' },
    ];
    return (
        <div>
            <div className="page-title d-flex justify-content-between align-items-center">
                <div className="page-title-wrap">
                    <h2>
                        <span>{props.reportName + ' - Date Range of Report: ' + ' Up to ' + props.endDate}</span>
                    </h2>
                </div>
            </div>
            <div className="row">
                {props.selectedValue.map((property) => {
                    return (
                        <div className="col-sm-12 col-md-12 col-xl-12">
                            <MuiThemeProvider theme={Constants.getMuiTheme()}>
                                <MUIDataTable
                                    title={`${property.name} Delinquency`}
                                    data={delinqData[property.name]}
                                    columns={delinqColumns}
                                    options={{
                                        ...props.reportOptions,
                                        responsive: 'standard',
                                    }}
                                />
                            </MuiThemeProvider>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Delinquency;

