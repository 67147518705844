export const Types = {
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',
    UPDSELECTEDPROPERTY: 'UPDSELECTEDPROPERTY',
    UPDUSERPREFERENCES: 'UPDUSERPREFERENCES',
    ADDPROPERTY: 'ADDPROPERTY',
    REMOVEPROPERTY: 'REMOVEPROPERTY',

    /* ---- report types */
    SCROLLINFO: 'SCROLLINFO',
    SEARCHKEYWORD: 'SEARCH_KEYWORD',
};
