import React, {useState, useEffect} from 'react';
import { Controller, useForm } from "react-hook-form";
import { Button, Form, Label, Input } from 'reactstrap';
import { NotificationManager } from 'react-notifications';
import { FormGroup, Radio, FormControlLabel, RadioGroup } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Main from '../Main';
import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import LinearProgress from '../Util/LinearProgress';
import * as Constants from '../Util/constants';
import * as propertyAPI from '../../Api/property';
import * as Util from '../Util/util';

const EditProperty = (props) => {
    const history = useHistory();
    const login = useSelector((state) => state.login);
    const company = login.company
    const companyID = company.id;
    const propertyID = props.location.state ? props.location.state.propertyID : null;
        
    const [loading, setLoading] = useState(false);
    const [isUK, setIsUK] = useState(true);
    const [propertyName, setPropertyName] = useState('');
    const [updated, setUpdated] = useState(false);

    const { handleSubmit, control, setValue, formState: { errors }} = useForm();

    useEffect(() => {
        async function fetchData() {
            if(propertyID === null) {
                history.goBack();
                return;
            }
            setLoading(true);
            const property = await propertyAPI.getProperty(propertyID);
            if(property) {
                setValue('propertyName', property.PropertyName);
                setValue('propertyPhone', property.PropertyPhone ? property.PropertyPhone : '');
                setValue('propertyEmail', property.PropertyEmail);
                setValue('propertyURL', property.PropertyURL ? property.PropertyURL : '');
                setValue('propertyAddress1', property.PropertyAddress1);
                setValue('propertyAddress2', property.PropertyAddress2 ? property.PropertyAddress2 : '');
                setValue('propertyCity', property.PropertyCity ? property.PropertyCity : '');
                setValue('propertyState', property.PropertyState ? property.PropertyState : '');
                setValue('propertyPostalCode', property.PropertyZip ? parseInt(property.PropertyZip) : '');
                if(companyID === 7514 && property.Country === 'UK') {
                    setIsUK(true);
                }
                setPropertyName(property.PropertyName)
            }
            setLoading(false);
        }
        fetchData();
    }, [companyID, propertyID, history, setValue, updated])

    const submitForm = async (data) => {
        setLoading(true);
        const res = await propertyAPI.updatePropertySimple({
            propertyName: data.propertyName,
            address1: data.propertyAddress1,
            address2: data.propertyAddress2 ? data.propertyAddress2 : '',
            city: data.propertyCity,
            state: data.propertyState,
            zip: data.propertyPostalCode.toString(),
            propertyEmail: data.propertyEmail,
            propertyPhone: data.propertyPhone,
            propertyURL: data.propertyURL ? data.propertyURL : '',
            backgroundScreening: 1,
            country: companyID !== 7514 ? 'United States' : isUK ? 'UK' : 'Canada',
            propertyID,
        });
        setLoading(false);
        if(res !== 0) {
            NotificationManager.error(Constants.DEFAULT_ERROR, "Error");
            return;
        }
        setUpdated(!updated);
        NotificationManager.success("Property updated!", "Success");
    }

    const handleSelectCountry = () => setIsUK(!isUK);

    const renderCountry = () => {
        if(companyID === 7514) {
            return (
                <div className="col-sm-3">
                    <RadioGroup aria-label="anonymous" name="anonymous" value={true} row>
                        <Label className="mr-sm-10">Property Country</Label>
                        <FormControlLabel value="cc" control={
                            <Radio 
                                checked={isUK}
                                value="UK"
                                name="selectCountry"
                                onChange={handleSelectCountry}
                            />} 
                            label="UK" 
                        />
                        <FormControlLabel value="ach" control={
                            <Radio 
                                checked={!isUK}
                                value="Canada"
                                name="selectCountry"
                                onChange={handleSelectCountry}
                            />} 
                            label="Canada" />
                    </RadioGroup>
                </div>
            );
        }
    }

    if(loading) {
        return (
            <RctCollapsibleCard
                colClasses="col-xs-12 col-sm-12 col-md-12"
                heading={"Loading Edit Property..."}
            >
                <LinearProgress />
            </RctCollapsibleCard>
        );
    }

    return (
        <Main>
            <div className="formelements-wrapper" style={Constants.margins}>
                <div className="page-title d-flex justify-content-between align-items-center">
                    <div className="page-title-wrap">
                        <i className="ti-angle-left" style={{cursor: 'pointer'}} onClick={() => history.goBack()}></i>
                        <h2>
                            <span>Property: {propertyName}</span>
                        </h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-xl-12">
                        <RctCollapsibleCard heading="Property Details">
                            <Form onSubmit={handleSubmit(submitForm)}>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <FormGroup className="mr-10 mb-10">
                                            <Label for="propertyName" className="mr-sm-10">Property Name</Label>
                                            <Controller
                                                name="propertyName"
                                                control={control}
                                                rules={{required: true}}
                                                render={({ field }) => (
                                                    <Input {...field} type="text" id="propertyName" style={Util.setErrorStyle(errors.propertyName)} />
                                                )}
                                            />
                                            {errors.propertyName && (
                                                <span style={{ color: "red" }} role="alert">required</span>
                                            )}
                                        </FormGroup>
                                    </div>
                                    <div className="col-sm-3">
                                        <Label for="propertyPhone" className="mr-sm-10">Property Phone</Label>
                                        <Controller
                                            name="propertyPhone"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <NumberFormat className="form-control" {...field} format="+1 (###) ###-####" mask="_" id="propertyPhone" style={Util.setErrorStyle(errors.propertyPhone)} />
                                            )}
                                        />
                                        {errors.propertyPhone && (
                                            <span style={{ color: "red" }} role="alert">required</span>
                                        )}
                                    </div>
                                    <div className="col-sm-4">
                                        <FormGroup className="mr-10 mb-10">
                                            <Label for="propertyEmail" className="mr-sm-10">Property Email</Label>
                                            <Controller
                                                name="propertyEmail"
                                                control={control}
                                                rules={{required: true}}
                                                render={({ field }) => (
                                                    <Input {...field} type="text" id="propertyEmail" style={Util.setErrorStyle(errors.propertyEmail)} />
                                                )}
                                            />
                                            {errors.propertyEmail && (
                                                <span style={{ color: "red" }} role="alert">required</span>
                                            )}
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-5">
                                        <FormGroup className="mr-10 mb-10">
                                            <Label for="propertyURL" className="mr-sm-10">Property URL</Label>
                                            <Controller
                                                name="propertyURL"
                                                control={control}
                                                render={({ field }) => (
                                                    <Input {...field} type="text" id="propertyURL" />
                                                )}
                                            />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <FormGroup className="mr-10 mb-10">
                                            <Label for="propertyAddress1" className="mr-sm-10">Property Address</Label>
                                            <Controller
                                                name="propertyAddress1"
                                                control={control}
                                                rules={{required: true}}
                                                render={({ field }) => (
                                                    <Input {...field} type="text" id="propertyAddress1" style={Util.setErrorStyle(errors.propertyAddress1)} />
                                                )}
                                            />
                                            {errors.propertyAddress1 && (
                                                <span style={{ color: "red" }} role="alert">required</span>
                                            )}
                                        </FormGroup>
                                    </div>
                                    <div className="col-sm-4">
                                        <FormGroup className="mr-10 mb-10">
                                            <Label for="propertyAddress2" className="mr-sm-10">Property Address 2</Label>
                                            <Controller
                                                name="propertyAddress2"
                                                control={control}
                                                render={({ field }) => (
                                                    <Input {...field} type="text" id="propertyAddress2" />
                                                )}
                                            />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <Label for="propertyCity" className="mr-sm-10">Property City</Label>
                                        <Controller
                                            name="propertyCity"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <Input {...field} type="text" id="propertyCity" style={Util.setErrorStyle(errors.propertyCity)} />
                                            )}
                                        />
                                        {errors.propertyCity && (
                                            <span style={{ color: "red" }} role="alert">required</span>
                                        )}
                                    </div>
                                    <div className="col-sm-3">
                                        <Label for="propertyState" className="mr-sm-10">Property State</Label>
                                        <Controller
                                            name="propertyState"
                                            control={control}
                                            render={({ field }) => (
                                                <Input {...field} type="select" id="propertyState">
                                                    {Constants.usStates.map((obj, idx) => 
                                                        <option key={idx} value={obj.abbreviation}>{obj.name}</option>
                                                    )}
                                                </Input>
                                            )}
                                        />
                                    </div>
                                    <div className="col-sm-3">
                                        <Label for="propertyPostalCode" className="mr-sm-10">Property Postal Code</Label>
                                        <Controller
                                            name="propertyPostalCode"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <Input {...field} type="number" id="propertyPostalCode" style={Util.setErrorStyle(errors.propertyPostalCode)} />
                                            )}
                                        />
                                        {errors.propertyPostalCode && (
                                            <span style={{ color: "red" }} role="alert">required</span>
                                        )}
                                    </div>
                                    {renderCountry()}
                                </div>
                                <Button type="submit" color="primary" style={{marginTop: '10px'}}>Update Property</Button>
                            </Form>
                        </RctCollapsibleCard>
                    </div>
                </div>
            </div>
        </Main>
    )
}

export default EditProperty;
