import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import * as Constants from '../Util/constants';
import * as reportsAPI from '../../Api/reports';
import { currencyFormat } from './helperFunctions';
import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import LinearProgress from '../Util/LinearProgress';

const BalanceSheet = (props) => {
    const [loading, setLoading] = useState(false);
    const [assetsData] = useState([]);
    const [liabData] = useState([]);
    const [equityData] = useState([]);
    var [totalLiabData] = useState(0);
    const propertyName = useSelector((state) => state.login.properties);
    const [filteredName] = propertyName.filter((property) => property.PropertyID === props.propertyId);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            const reportDetails = await reportsAPI.getBalanceSheet(props.reportSearch);
            const reportLoan = await reportsAPI.getLoanTotals(props.reportSearch);
            const reportDeposit = await reportsAPI.getTenantDeposit(props.reportSearch);
            assetsData.push(
                {
                    category: 'Assets',
                },
                {
                    category: 'Bank Account',
                    assetType: 'Bank Accounts',
                    amount: currencyFormat(+reportDetails[0].BankTotal),
                },
                {
                    category: 'Buildings',
                    assetType: 'Fixed Assets',
                    amount: currencyFormat(+reportDetails[0].BuildingTotal),
                },
                {
                    category: 'Land',
                    assetType: 'Fixed Assets',
                    amount: currencyFormat(+reportDetails[0].LandTotal),
                },
                {
                    category: 'Accumulated Depreciation',
                    assetType: 'Fixed Assets',
                    amount: currencyFormat(+reportDetails[0].AccumDepTotal),
                },
                {
                    category: 'Utility Deposit',
                    assetType: 'Fixed Assets',
                    amount: currencyFormat(+reportDetails[0].UtilityTotal),
                },
                {
                    category: 'TOTAL FIXED ASSETS',
                    amount: currencyFormat(
                        +reportDetails[0].BuildingTotal +
                            +reportDetails[0].LandTotal +
                            +reportDetails[0].AccumDepTotal +
                            +reportDetails[0].UtilityTotal,
                    ),
                },
                {
                    category: 'TOTAL ASSETS',
                    amount: currencyFormat(
                        +reportDetails[0].BankTotal +
                            +reportDetails[0].BuildingTotal +
                            +reportDetails[0].LandTotal +
                            +reportDetails[0].AccumDepTotal +
                            +reportDetails[0].UtilityTotal,
                    ),
                },
            );
            liabData.push({
                lender: '',
                amount: '',
            });
            liabData.push({
                category: 'Liability',
                amount: '',
            });
            for (let i = 0; i < reportLoan.length; i++) {
                if (+reportLoan[i].LoanTotal !== 0) {
                    liabData.push({
                        lender: reportLoan[i].JournalDescription,
                        amount: currencyFormat(+reportLoan[i].LoanTotal),
                    });
                    totalLiabData += +reportLoan[i].LoanTotal;
                }
            }
            liabData.push({
                lender: 'Tenant Deposit',
                amount: currencyFormat(+reportDeposit[0].Total),
            });
            liabData.push({
                lender: 'TOTAL LIABILITY',
                amount: currencyFormat(totalLiabData + +reportDeposit[0].Total),
            });
            equityData.push(
                {
                    category: '',
                    amount: '',
                },
                {
                    category: 'Equity',
                    amount: '',
                },
                {
                    category: 'Net Income',
                    amount: currencyFormat(+reportDetails[0].NetIncomeTotal),
                },
                {
                    category: 'Retained Earnings',
                    amount: currencyFormat(+reportDetails[0].RetainedTotal),
                },
                {
                    category: 'TOTAL EQUITY',
                    amount: currencyFormat(
                        -(totalLiabData + +reportDeposit[0].Total) +
                            (+reportDetails[0].BankTotal +
                                +reportDetails[0].BuildingTotal +
                                +reportDetails[0].LandTotal +
                                +reportDetails[0].AccumDepTotal +
                                +reportDetails[0].UtilityTotal),
                    ),
                },
                {
                    category: 'TOTAL LIABILITY AND EQUITY',
                    amount: currencyFormat(
                        +reportDetails[0].BankTotal +
                            +reportDetails[0].BuildingTotal +
                            +reportDetails[0].LandTotal +
                            +reportDetails[0].AccumDepTotal +
                            +reportDetails[0].UtilityTotal,
                    ),
                },
            );
            setLoading(false);
        }
        fetchData();
    }, []);
    if (loading) {
        return (
            <RctCollapsibleCard colClasses="col-xs-12 col-sm-12 col-md-12" heading={'Loading Reports...'}>
                <LinearProgress />
            </RctCollapsibleCard>
        );
    }

    const assetColumns = [
        { name: 'category', label: 'Category' },
        { name: 'assetType', label: 'Asset Type' },
        { name: 'quantity', label: 'Amount' },
    ];

    const liabilityColumns = [
        { name: 'lender', label: 'Lender' },
        { name: 'amount', label: 'Amount' },
    ];

    const equityColumns = [
        { name: 'category', label: 'Category' },
        { name: 'amount', label: 'Amount' },
    ];

    const columns = [
        { name: 'category', label: 'Category' },
        { name: 'lender', label: 'Lender' },
        { name: 'assetType', label: 'Asset Type' },
        { name: 'amount', label: 'Amount' },
    ];

    const tableData = [...assetsData, ...liabData, ...equityData];
    const tableTitle = `BALANCE SHEET - ${filteredName.PropertyName} - ${props.reportSearch.sDt} ~ ${props.reportSearch.eDt}`;

    return (
        <div>
            <div className="page-title d-flex justify-content-between align-items-center">
                <div className="page-title-wrap">
                    <h2>
                        <span>{props.reportName + ' - ' + filteredName.PropertyName}</span>
                    </h2>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-xl-12">
                    <MuiThemeProvider theme={Constants.getMuiTheme()}>
                        <MUIDataTable
                            title={tableTitle}
                            data={tableData}
                            columns={columns}
                            options={{ ...props.reportOptions, downloadOptions: { filename: tableTitle } }}
                        />
                    </MuiThemeProvider>
                </div>
                {/* <div className="col-sm-12 col-md-12 col-xl-12">
                    <MuiThemeProvider theme={Constants.getMuiTheme()}>
                        <MUIDataTable
                            title={'Liability'}
                            data={liabData}
                            columns={liabilityColumns}
                            options={props.reportOptions}
                        />
                    </MuiThemeProvider>
                </div>
                <div className="col-sm-12 col-md-12 col-xl-12">
                    <MuiThemeProvider theme={Constants.getMuiTheme()}>
                        <MUIDataTable
                            title={'Equity'}
                            data={equityData}
                            columns={equityColumns}
                            options={props.reportOptions}
                        />
                    </MuiThemeProvider>
                </div> */}
            </div>
        </div>
    );
};

export default BalanceSheet;
