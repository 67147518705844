import React, { useState, useEffect } from 'react';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import { Controller, useForm } from "react-hook-form";
import { Button, Form, Label, Input, FormText } from 'reactstrap';
import { FormGroup } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import Main from '../Main';
import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import LinearProgress from '../Util/LinearProgress';
import * as unitsAPI from '../../Api/units';
import * as Constants from '../Util/constants';

const imgStyle = {
    maxHeight: '350px',
    maxWidth: '200px',
    cursor: 'pointer',
}

const UploadImagesAndVideos = (props) => {
    const history = useHistory();
    const unitTypeID = props.location.state ? props.location.state.unitTypeID : null;
    const login = useSelector((state) => state.login);
    const user = login.user;
    const userID = user.id;
    const propertyID = login.selectedPropertyID;

    const [ loading, setLoading ] = useState(true);
    const [ marketingFileType, setMarketingFileType ] = useState([]);
    const [ existingPropertyImages, setExistingPropertyImages ] = useState([]);
    const [ existingFloorPlanImages, setExistingFloorPlanImages ] = useState([]);
    const [ existingPropertyVideos, setExistingPropertyVideos ] = useState([]);
    const [ updated, setUpdated ] = useState(false);

    const { handleSubmit, control} = useForm();

    useEffect(() => {
        async function fetchData() {
            if(unitTypeID === null) {
                history.goBack();
                return;
            }
            setLoading(true);
            const getUnitPhotosVideos = await unitsAPI.getUnitPhotoVideos(unitTypeID);
            if(getUnitPhotosVideos !== null) {
                setExistingFloorPlanImages(getUnitPhotosVideos.floorPLanImages);
                setExistingPropertyImages(getUnitPhotosVideos.propertyImages);
                setExistingPropertyVideos(getUnitPhotosVideos.propertyVideos);
            }
            setMarketingFileType(await unitsAPI.getMarketingFileTypes());
            setLoading(false);
        }
        fetchData();
    }, [unitTypeID, history, updated]);

    const submitForm = async (data) => {
        console.log('s');
        if(data.file === undefined || data.file.length <= 0 ) {
            NotificationManager.warning("Image is required.", "Warning");
            return;
        }
        console.log('m');
        // Save image
        setLoading(true);
        const res = await unitsAPI.uploadUnitFile({
            propertyID,
            userID,
            file: data.file,
            unitTypeID,
            marketingFileTypeID: parseInt(data.type),
            fileName: data.file.substring(data.file.lastIndexOf('path')+5, data.file.length),
        });
        console.log('end');
        setLoading(false);
        console.log('response', res);
        if(res !== 0) {
            NotificationManager.errors(Constants.DEFAULT_ERROR, "Error")
            return;
        }
        setUpdated(!updated);
    }

    const deletePhotoVideo = async (id) => {
        setLoading(true);
        const res = await unitsAPI.deleteUnitPhoto(id);
        setLoading(false);
        if(res !== 0) {
            NotificationManager.errors(Constants.DEFAULT_ERROR, "Error")
            return;
        }
        setUpdated(!updated);
    }

    const renderFloorPlanImages = () => {
        if(!existingFloorPlanImages.length)
            return <></>;
        
        return (
            <div className="row">
                <div className="col-sm-12 col-md-12 col-xl-12">
                    <RctCollapsibleCard heading="Existing Floor Plans for Unit Type">
                        <div className="row">
                            {existingFloorPlanImages.map((obj, idx) => {
                                const name = `${obj.FileName}`;
                                const link = `https://myirent.com/rent/UnitPhotos/${propertyID}/${obj.FileName}`;
                                return (
                                    <div key={idx} className="col-md-3">
                                        <div>
                                            <img src={link} alt={name} style={imgStyle} onClick={() => {
                                                    window.open(link, '_blank');
                                                }} />
                                            <br/>
                                            <Button className="mr-10 mb-10" color="link"
                                                onClick={() => deletePhotoVideo(parseInt(obj.UnitPhotosID))}  
                                            >
                                                Delete
                                            </Button>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </RctCollapsibleCard>
                </div>
            </div>
        );
    }

    const renderPropertyImages = () => {
        if(!existingPropertyImages.length)
            return <></>;
        
        return (
            <div className="row">
                <div className="col-sm-12 col-md-12 col-xl-12">
                    <RctCollapsibleCard heading="Existing Property Images">
                        <div className="row">
                            {existingPropertyImages.map((obj, idx) => {
                                const name = `${obj.FileName}`;
                                const link = `https://myirent.com/rent/UnitPhotos/${propertyID}/${obj.FileName}`;
                                return (
                                    <div key={idx} className="col-md-3">
                                        <div>
                                            <img src={link} alt={name} style={imgStyle} onClick={() => {
                                                window.open(link, '_blank');
                                            }} />
                                            <br/>
                                            <Button className="mr-10 mb-10" color="link"
                                                onClick={() => deletePhotoVideo(parseInt(obj.UnitPhotosID))}  
                                            >
                                                Delete
                                            </Button>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </RctCollapsibleCard>
                </div>
            </div>
        );
    }

    const renderProeprtyVideos = () => {
        if(!existingPropertyVideos.length)
            return <></>;
        
        return (
            <div className="row">
                <div className="col-sm-12 col-md-12 col-xl-12">
                    <RctCollapsibleCard heading="Existing Property Videos">
                        <div className="row">
                            {existingPropertyVideos.map((obj, idx) => {
                                const link = `https://myirent.com/rent/UnitPhotos/${propertyID}/${obj.FileName}`;
                                return (
                                    <div key={idx} className="col-md-3">
                                        <div>
                                            <video width="200" height="250" controls>
                                                <source src={link} type="video/mp4" />
                                            </video>
                                            <br/>
                                            <Button className="mr-10 mb-10" color="link"
                                                onClick={() => deletePhotoVideo(parseInt(obj.UnitPhotosID))}  
                                            >
                                                Delete
                                            </Button>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </RctCollapsibleCard>
                </div>
            </div>
        );
    }

    const render = () => {
        if(loading) {
            return (
                <RctCollapsibleCard
                colClasses="col-xs-12 col-sm-12 col-md-12"
                heading={"Loading Upload Images and Videos..."}
                >
                    <LinearProgress />
                </RctCollapsibleCard>
            );
        } else {
            return (
                <Main>
                    <div className="formelements-wrapper" style={Constants.margins}>
                        <div className="page-title d-flex justify-content-between align-items-center">
                            <div className="page-title-wrap">
                            <i className="ti-angle-left" style={{cursor: 'pointer'}} onClick={() => history.goBack()}></i>
                                <h2>
                                    <span>Upload Images and Videos</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-xl-12">
                            <RctCollapsibleCard heading="Property Details">
                                <Form onSubmit={handleSubmit(submitForm)}>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Label for="type" className="mr-sm-10">Type</Label>
                                            <Controller
                                                name="type"
                                                control={control}
                                                defaultValue={marketingFileType[0].MarketingFileTypeID}
                                                render={({ field }) => (
                                                    <Input {...field} type="select" id="type">
                                                        {marketingFileType.map((obj, idx) => 
                                                            <option key={idx} value={obj.MarketingFileTypeID}>{obj.MarketingFileType}</option>
                                                        )}
                                                    </Input>
                                                )}
                                            />
                                        </div>
                                        <div className="col-sm-3">
                                        <FormGroup>
                                            <Label for="file">Attach Image</Label>
                                            <Controller
                                                name="file"
                                                control={control}
                                                render={({ field }) => (
                                                    <Input {...field} type="file" id="file" />
                                                )}
                                            />
                                            <FormText color="muted">
                                                Attach image file
                                            </FormText>
                                        </FormGroup>
                                    </div>    
                                    </div> 
                                    <Button type="submit" color="primary" style={{marginTop: '10px'}}>Upload Image</Button>
                                </Form>
                            </RctCollapsibleCard>
                        </div>
                    </div>
                    {renderPropertyImages()}
                    {renderFloorPlanImages()}
                    {renderProeprtyVideos()}
                </Main>
            )
        }
    }

    return render();
}

export default UploadImagesAndVideos;
