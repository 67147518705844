import React, { useState, useEffect } from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import * as Constants from "../Util/constants";
import * as reportsAPI from "../../Api/reports";
import * as tenantsAPI from "../../Api/tenants";
import RctCollapsibleCard from "../Helpers/RctCollapsibleCard/RctCollapsibleCard";
import LinearProgress from "../Util/LinearProgress";
import {
  differenceInCalendarWeeks,
  getDay,
  addDays,
  differenceInDays,
} from "date-fns";
import moment from "moment";
import MUIDataTable from "mui-datatables";

const ProjectedOccupancy = (props) => {
  const [loading, setLoading] = useState(false);
  const [occupancyData, setOccupancyData] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const _startDate = props.startDate.replace(/-/gi, "/");
      const _endDate = props.endDate.replace(/-/gi, "/");
      debugger;
      const diffToFriday = 6 - getDay(new Date(_startDate));
      const nextFriday = addDays(new Date(_startDate), diffToFriday);
      const diffToNextWeek = differenceInDays(
        new Date(_endDate),
        nextFriday
      );
      if (diffToNextWeek < 0) {
        setLoading(false);
        setIsEmpty(true);
        return;
      }

      const numWeeks = differenceInCalendarWeeks(
        new Date(_endDate),
        new Date(_startDate)
      );
      const totalUnits = await reportsAPI.getUnitsQuantity(props.reportSearch);
      const totalOccupiedUnits = totalUnits.filter(
        (unit) => unit.Occupied === 1
      );

      let firstDayWeek = addDays(nextFriday, -5);
      let lastDayWeek = addDays(nextFriday, +1);
      let weekMoveIns = await tenantsAPI.getOccupiedMoveIns({
        pID: props.propertyId,
        sDt: moment(firstDayWeek).utc().format("YYYY-MM-DD"),
        eDt: moment(lastDayWeek).utc().format("YYYY-MM-DD"),
      });
      let weekMoveOuts = await tenantsAPI.getOccupiedMoveOuts({
        pID: props.propertyId,
        sDt: moment(firstDayWeek).utc().format("YYYY-MM-DD"),
        eDt: moment(lastDayWeek).utc().format("YYYY-MM-DD"),
      });
      let occupancyPerc =
        ((totalOccupiedUnits.length +
          weekMoveIns.length -
          weekMoveOuts.length) /
          totalUnits.length) *
        100;

      let currentFriday = nextFriday;
      let moveInsTotal = weekMoveIns.length;
      let moveOutsTotal = weekMoveOuts.length;

      let result = [
        {
          week: moment(nextFriday).utc().format("YYYY-MM-DD"),
          moveIns: weekMoveIns.length,
          moveOuts: weekMoveOuts.length,
          occupancy: Math.round(occupancyPerc * 100) / 100,
        },
      ];
      const iterableArray = numWeeks && new Array(numWeeks - 1).fill(1);
      if (numWeeks > 0) {
        // eslint-disable-next-line no-unused-vars
        for (const x of iterableArray) {
          currentFriday = addDays(currentFriday, 7);
          const currentFridayStr = moment(currentFriday)
            .utc()
            .format("YYYY-MM-DD");
          if (differenceInDays(new Date(_endDate), currentFriday) > 0) {
            firstDayWeek = addDays(currentFriday, -5);
            lastDayWeek = addDays(currentFriday, +1);
            const firstDayWeekStr = moment(firstDayWeek)
              .utc()
              .format("YYYY-MM-DD");
            const lastDayWeekStr = moment(lastDayWeek)
              .utc()
              .format("YYYY-MM-DD");

            weekMoveIns = await tenantsAPI.getOccupiedMoveIns({
              pID: props.propertyId,
              sDt: firstDayWeekStr,
              eDt: lastDayWeekStr,
            });
            moveInsTotal += weekMoveIns.length;
            weekMoveOuts = await tenantsAPI.getOccupiedMoveOuts({
              pID: props.propertyId,
              sDt: firstDayWeekStr,
              eDt: lastDayWeekStr,
            });
            moveOutsTotal += weekMoveOuts.length;
            occupancyPerc =
              ((totalOccupiedUnits.length + moveInsTotal - moveOutsTotal) /
                totalUnits.length) *
              100;

            result = [
              ...result,
              {
                week: currentFridayStr,
                moveIns: weekMoveIns.length,
                moveOuts: weekMoveOuts.length,
                occupancy: Math.round(occupancyPerc * 100) / 100,
              },
            ];
          }
        }
      }
      setOccupancyData(result);
      setLoading(false);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (loading) {
    return (
      <RctCollapsibleCard
        colClasses="col-xs-12 col-sm-12 col-md-12"
        heading={"Loading Reports..."}
      >
        <LinearProgress />
      </RctCollapsibleCard>
    );
  }

  if (isEmpty) {
    return (
      <div>
        <h3>Please select your date range</h3>
      </div>
    );
  }

  const occupancyColumns = [
    {
      name: "week",
      label: "Week of",
    },
    {
      name: "moveIns",
      label: "Move Ins",
      options: {
        customBodyRender: (value) => {
          return (
            <>
              {value > 0 ? (
                <div className="text-primary">{value}</div>
              ) : (
                <div>{value}</div>
              )}
            </>
          );
        },
      },
    },
    {
      name: "moveOuts",
      label: "Move Outs",
      options: {
        customBodyRender: (value) => {
          return (
            <>
              {value > 0 ? (
                <div className="text-danger">{value}</div>
              ) : (
                <div>{value}</div>
              )}
            </>
          );
        },
      },
    },
    {
      name: "occupancy",
      label: "Occupancy",
      options: {
        customBodyRender: (value, tableMeta) => {
          const { rowIndex, rowData } = tableMeta;
          let customClasses = "";
          if (rowIndex !== 0) {
            if (rowData[1] === 0 && rowData[2] === 0) {
              customClasses = {};
            } else if (rowData[1] > rowData[2]) {
              customClasses = "text-primary";
            } else if (rowData[1] < rowData[2]) {
              customClasses = "text-danger";
            }
          }

          if (isNaN(value)) {
            return <div className={customClasses}>0</div>;
          } else {
            return <div className={customClasses}>{value}%</div>;
          }
        },
      },
    },
  ];

  const occupancyOptions = {
    ...props.reportOptions,
  };

  return (
    <div>
      <MuiThemeProvider theme={Constants.getMuiTheme()}>
        <MUIDataTable
          title={"ProjectedOccupancy Export"}
          data={occupancyData}
          columns={occupancyColumns}
          options={occupancyOptions}
        />
      </MuiThemeProvider>
    </div>
  );
};

export default ProjectedOccupancy;
