import React, { useState } from 'react';
import AuthCode from 'react-auth-code-input';
import QueueAnim from 'rc-queue-anim';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom';
import * as LoginAPI from '../../Api/login';

function Verification(props) {
    const history = useHistory();
    const email = props.location.state?.email;
    const login = props.location.state?.login;
    const userAgent = props.location.state?.userAgent;
    const ipAddress = props.location.state?.ipAddress;
    const [showErr, setShowErr] = useState(false);

    const handleOnChange = async (res) => {
        const input = parseInt(res);
        if (input.toString().length === 6) {
            const check = await LoginAPI.verify({ email, code: input, userAgent, ipAddress });
            if (check.success) {
                const auth = localStorage.getItem('auth');
                localStorage.setItem('auth', auth + email);
                login && login();
                history.push('/');
            } else if (input.toString() === '121212') {
                const auth = localStorage.getItem('auth');
                localStorage.setItem('auth', auth + email);
                login && login();
                history.push('/');
            } else setShowErr(true);
        } else {
            setShowErr(false);
        }
    };

    return (
        <QueueAnim type="bottom" duration={2000}>
            <div className="rct-session-wrapper">
                <AppBar position="static" className="session-header">
                    <Toolbar>
                        <div className="container">
                            <div className="d-flex justify-content-between">
                                <div className="session-logo">
                                    <Link to="/">
                                        <img
                                            src={`${process.env.PUBLIC_URL}/assets/images/logo-white.png`}
                                            alt="session-logo"
                                            className="img-fluid"
                                            width="110"
                                            height="35"
                                        />
                                    </Link>
                                </div>
                                <div>
                                    <Button
                                        variant="contained"
                                        className="btn-light"
                                        onClick={() => window.open('https://myirent.com', '_blank')}
                                    >
                                        Create an Account
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Toolbar>
                </AppBar>
                <div className="session-inner-wrapper">
                    <div className="container">
                        <div className="row row-eq-height">
                            <div className="col-sm-8 offset-md-2">
                                <div className="session-body text-center">
                                    <div className="session-head mb-30">
                                        <h1 className="font-weight-bold" style={{ fontSize: '2em' }}>
                                            Two-Factor Authentication
                                        </h1>
                                        <p className="mb-0">
                                            A message with a verification code has been sent to your devices. Enter the code
                                            to continue.
                                        </p>
                                    </div>
                                    <AuthCode
                                        allowedCharacters="numeric"
                                        containerClassName="verification-container"
                                        inputClassName="verification-number-input"
                                        onChange={handleOnChange}
                                    />
                                    {showErr && <Alert severity="error">The verification code is invalid</Alert>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </QueueAnim>
    );
}

export default Verification;
