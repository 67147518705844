import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import * as Constants from "../Util/constants";
import * as reportsAPI from "../../Api/reports";
import { formatDate } from "./helperFunctions";
import RctCollapsibleCard from "../Helpers/RctCollapsibleCard/RctCollapsibleCard";
import LinearProgress from "../Util/LinearProgress";
import { AgChartsReact } from "ag-charts-react";

const MoveOutReason = (props) => {
  const [loading, setLoading] = useState(false);
  const [moveSummData, setMoveSummData] = useState([]);
  const [moveDetailsData, setMoveDetailsData] = useState([]);
  const [reasonsData, setReasonsData] = useState([]);
  const propertyName = useSelector((state) => state.login.properties);
  const [filteredName] = propertyName.filter(
    (property) => property.PropertyID === props.propertyId
  );
  const [options, setOptions] = useState({
    data: [],
    series: [
      {
        type: "pie",
        angleKey: "value",
        calloutLabelKey: "label",
        sectorLabelKey: "value",
        sectorLabel: {
          color: "white",
          fontWeight: "bold",
        },
      },
    ],
  });
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const reportDetails = await reportsAPI.getMoveOutReason(
        props.reportSearch
      );
      for (let i = 0; i < reportDetails.length; i++) {
        moveDetailsData.push({
          moveOutReason: reportDetails[i].moveoutreason.MoveOutReason,
          property: reportDetails[i].tenant.property.PropertyName,
          date: formatDate(
            reportDetails[i].tenant.ProspectStartDate.substring(0, 10)
          ),
        });
        if (moveSummData[reportDetails[i].moveoutreason.MoveOutReason]) {
          moveSummData[reportDetails[i].moveoutreason.MoveOutReason].value += 1;
        } else {
          reasonsData.push(reportDetails[i].moveoutreason.MoveOutReason);
          moveSummData[reportDetails[i].moveoutreason.MoveOutReason] = {
            label: reportDetails[i].moveoutreason.MoveOutReason,
            value: 1,
          };
        }
      }
      options.data = reasonsData.map((reason) => {
        return moveSummData[reason];
      });
      setMoveSummData(options.data);
      setLoading(false);
    }
    fetchData();
  }, []);
  if (loading) {
    return (
      <RctCollapsibleCard
        colClasses="col-xs-12 col-sm-12 col-md-12"
        heading={"Loading Reports..."}
      >
        <LinearProgress />
      </RctCollapsibleCard>
    );
  }
  const moveSummColumns = [
    { name: "label", label: "Move Out Reason" },
    { name: "value", label: "Total Count" },
  ];
  const moveDetailsColumns = [
    { name: "moveOutReason", label: "Move Out Reason" },
    { name: "property", label: "Property" },
    { name: "date", label: "Date" },
  ];
  return (
    <div>
      <div className="page-title d-flex justify-content-between align-items-center">
        <div className="page-title-wrap">
          <h2>
            <span>
              {props.reportName +
                " - " +
                filteredName.PropertyName +
                " - Date Range of Report: " +
                props.startDate +
                " to " +
                props.endDate}
            </span>
          </h2>
        </div>
      </div>
      <AgChartsReact options={options} />
      <div className="row">
        <div className="col-sm-12 col-md-12 col-xl-12">
          <MuiThemeProvider theme={Constants.getMuiTheme()}>
            <MUIDataTable
              title={"Move Out Summary"}
              data={moveSummData}
              columns={moveSummColumns}
              options={props.reportOptions}
            />
          </MuiThemeProvider>
        </div>
        <div className="col-sm-12 col-md-12 col-xl-12">
          <MuiThemeProvider theme={Constants.getMuiTheme()}>
            <MUIDataTable
              title={"Move Out Details"}
              data={moveDetailsData}
              columns={moveDetailsColumns}
              options={props.reportOptions}
            />
          </MuiThemeProvider>
        </div>
      </div>
    </div>
  );
};

export default MoveOutReason;
