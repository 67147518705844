import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import * as Constants from "../Util/constants";
import * as reportsAPI from "../../Api/reports";
import { currencyFormat, formatDate } from "./helperFunctions";
import RctCollapsibleCard from "../Helpers/RctCollapsibleCard/RctCollapsibleCard";
import LinearProgress from "../Util/LinearProgress";
import CheckIcon from "@material-ui/icons/Check";
import { Link } from "react-router-dom";

const DepositList = (props) => {
  const [loading, setLoading] = useState(false);
  const [depositData, setDepositData] = useState([]);
  const [unitNames, setUnitNames] = useState([]);
  const propertyName = useSelector((state) => state.login.properties);
  const [filteredName] = propertyName.filter(
    (property) => property.PropertyID === props.propertyId
  );
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const reportDetails = await reportsAPI.getDepositList(props.reportSearch);
      for (let i = 0; i < reportDetails.length; i++) {
        for (let j = 0; j < reportDetails[i].tenanttransactions.length; j++) {
          depositData.push({
            date: formatDate(reportDetails[i].CheckDate),
            payee: (
              <Link
                to={{
                  pathname: "/deposits/breakdown",
                  state: {
                    checkRegisterID: reportDetails[i].id,
                  },
                }}
              >
                Deposit
              </Link>
            ),
            memo: reportDetails[i].expensetype.ExpenseType,
            depositAmt:
              reportDetails[i].expensetypeId === 20
                ? currencyFormat(+reportDetails[i].Amount)
                : " ",
            checkregisterId: reportDetails[i].id,
            reconciled:
              reportDetails[i].Reconciled === 1 ? (
                <CheckIcon size="medium" />
              ) : (
                ""
              ),
            unitNumber:
              reportDetails[i].tenanttransactions[j].tenant?.UnitID === 0
                ? "Prospect/Applicant"
                : reportDetails[i].tenanttransactions[j].tenant?.unit.UnitName,
            tenantApplicant: reportDetails[i].tenanttransactions[j].tenant
              ? reportDetails[i].tenanttransactions[j].tenant.TenantFName +
                " " +
                reportDetails[i].tenanttransactions[j].tenant.TenantLName
              : "",
            monthlyRent: reportDetails[i].tenanttransactions[j].tenant
              ? currencyFormat(
                  +reportDetails[i].tenanttransactions[j].tenant?.RentalAmount +
                    +reportDetails[i].tenanttransactions[j].tenant
                      ?.HousingAmount +
                    +reportDetails[i].tenanttransactions[j].tenant?.PetRent +
                    +reportDetails[i].tenanttransactions[j].tenant
                      ?.ParkingCharge +
                    +reportDetails[i].tenanttransactions[j].tenant
                      ?.UtilityCharge
                )
              : "",
            payment: reportDetails[i].tenanttransactions[j].Comment,
            creditAmount:
              reportDetails[i].expensetypeId === 6
                ? currencyFormat(
                    +reportDetails[i].tenanttransactions[j].TransactionAmount
                  )
                : " ",
            currency: reportDetails[i].tenanttransactions[j].paymenttype
              ? reportDetails[i].tenanttransactions[j].paymenttype.PaymentType
              : "",
            checkNumber: reportDetails[i].tenanttransactions[j].CheckNumber,
            creditDate: formatDate(
              reportDetails[i].tenanttransactions[j].TenantTransactionDate
            ),
            tenantTransactionId: reportDetails[i].tenanttransactions[j].id,
          });
        }
      }
      setLoading(false);
    }
    fetchData();
  }, []);
  if (loading) {
    return (
      <RctCollapsibleCard
        colClasses="col-xs-12 col-sm-12 col-md-12"
        heading={"Loading Reports..."}
      >
        <LinearProgress />
      </RctCollapsibleCard>
    );
  }
  const depositColumns = [
    { name: "date", label: "Date" },
    { name: "payee", label: "Payee" },
    { name: "memo", label: "Memo" },
    { name: "depositAmt", label: "Deposit Amt" },
    { name: "checkregisterId", label: "Transaction ID" },
    { name: "reconciled", label: "Reconciled" },
    { name: "unitNumber", label: "Unit Number" },
    { name: "tenantApplicant", label: "Tenant/Applicant" },
    { name: "monthlyRent", label: "Monthly Rent" },
    { name: "payment", label: "Payment" },
    { name: "creditAmount", label: "Credit Amount" },
    { name: "currency", label: "Currency" },
    { name: "checkNumber", label: "Check Number" },
    { name: "creditDate", label: "Credit Date" },
    { name: "tenantTransactionId", label: "Transaction ID" },
  ];
  return (
    <div>
      <div className="page-title d-flex justify-content-between align-items-center">
        <div className="page-title-wrap">
          <h2>
            <span>
              {props.reportName +
                " - " +
                filteredName.PropertyName +
                " - Date Range of Report: " +
                props.startDate +
                " to " +
                props.endDate}
            </span>
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-xl-12">
          <MuiThemeProvider theme={Constants.getMuiTheme()}>
            <MUIDataTable
              title={"Deposit List"}
              data={depositData}
              columns={depositColumns}
              options={props.reportOptions}
            />
          </MuiThemeProvider>
        </div>
      </div>
    </div>
  );
};

export default DepositList;
