import axios from 'axios';
import * as Constants from '../components/Util/constants';

const URL = Constants.REQ_URL;

export const getAll = async () => {
    let output = [];
    await axios.get(`${URL}/cron`).then((res) => {
        output = res.data;
    });
    return output;
};

export const clearFailedJobs = async () => {
    let output = [];
    await axios.get(`${URL}/cron/clearfailedJobs`).then((res) => {
        output = res.data;
    });
    return output;
};
