import React, { useState, useEffect } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import MUIDataTable from "mui-datatables";
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Main from '../Main';
import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import LinearProgress from '../Util/LinearProgress';
import * as Constants from '../Util/constants';
import * as workOrdersAPI from '../../Api/workOrders';
import { getMakeReadyTasks } from '../../Api/company';
import Flag from '@material-ui/icons/Flag';
import Done from '@material-ui/icons/Done';
import Edit from '@material-ui/icons/Edit';
import { Button, Form, Label, Input } from 'reactstrap';
import { NotificationManager } from 'react-notifications';
import Modal from 'react-modal';
import Select from 'react-select';
import { FormGroup, IconButton } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import * as helper from '../Util/myFunctions';
import { currencyFormat } from '../Reports/helperFunctions';
import { getLeaseAuditDocuments } from '../../Api/home';
import axios from 'axios';
import * as url from '../Util/constants';
import { addCommentToDocument, auditDocument } from '../../Api/tenants';

const modalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-8%',
        transform: 'translate(-50%, -50%)',
        width: '35vw',
    },
    overlay: { zIndex: 1000 },
};


const AuditDetails = () => {
    const { register, handleSubmit, control, formState: { errors } } = useForm();

    const history = useHistory();
    const login = useSelector((state) => state.login);
    const propertyID = login.selectedPropertyID;
    const [loading, setLoading] = useState(true);
    const [leases, setLeases] = useState([]);
    const [comment, setComment] = useState([]);

    const columns = [
        { name: "tenant", label: "Tenant" },
        { name: "UnitType", label: "UnitType" },
        { name: "RentalAmount", label: "Market Rent" },
        { name: "UnitName", label: "Unit" },
        { name: "RentalAmount", label: "Tenant Rent" },
        { name: "HousingAmount", label: "Housing" },
        { name: "PetRent", label: "Pet Rent" },
        { name: "TVCharge", label: "TV Charges" },
        { name: "UtilityCharge", label: "Utility Charges" },
        { name: "total_charges", label: "Total Charges" },
        { name: "Comment", label: "Comment" },
        { name: "LeaseStartDate", label: "Lease Start" },
        { name: "LeaseEndDate", label: "Lease End" },
        {
            name: "doc", label: "Documents", options: {
                display: "excluded"
            }
        },
        {
            name: "obj", label: "", options: {
                display: "excluded"
            }
        }
    ]

    async function fetchData() {

        setLoading(true);
        const lease = await getLeaseAuditDocuments(propertyID);
        const data = lease.data, total = lease.total;
        console.log("sdaa", lease)
        var leases = []
        for (let i = 0; i < data.length; i++) {
            const d = data[i];

            let doc = "";
            const docLink = `https://myirent.com/rent/TenantFiles/${propertyID}/${d.TenantID}/${d.DocumentName}`;
            // const response = await axios.post(`${url.REQ_URL}/helper/checkFileExist`, { file: docLink });
            // console.log("D", response)
            doc = docLink

            const total = (d.RentalAmount === "" ? 0 : +d.RentalAmount) +
                (d.HousingAmount === "" ? 0 : +d.HousingAmount) +
                (d.PetRent === "" ? 0 : +d.PetRent) +
                (d.ParkingCharge === "" ? 0 : +d.ParkingCharge) +
                (d.UtilityCharge === "" ? 0 : +d.UtilityCharge)
            const obj = {
                ...d,
                tenant: `${d.TenantFName} ${d.TenantLName}`,
                total_charges: currencyFormat(total),
                RentalAmount: currencyFormat(+d.RentalAmount),
                HousingAmount: currencyFormat(+d.HousingAmount),
                PetRent: currencyFormat(+d.PetRent),
                TVCharge: currencyFormat(+d.TVCharge),
                UtilityCharge: currencyFormat(+d.UtilityCharge),
                LeaseStartDate: helper.formatDate(new Date(d.LeaseStartDate)),
                LeaseEndDate: helper.formatDate(new Date(d.LeaseEndDate)),
                doc: doc,
                obj: d
            }
            leases.push(obj)
        }
        setLeases(leases)
        setLoading(false);
    }
    useEffect(() => {

        fetchData();
    }, []);

    const handleAudit = async (dID) => {
        const res = await auditDocument(dID);
        console.log("res", res)
        fetchData()
    }

    const handleDecline = async (dID) => {
        const res = await addCommentToDocument({
            DocumentID: dID,
            Comment: comment
        });
        console.log("res", res)
        fetchData()
    }

    const options = {
        filterType: 'dropdown',
        pagination: false,
        selectableRows: "none",
        download: false,
        print: false,
        expandableRows: true,
        expandableRowsHeader: false,
        renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1;

            const obj = rowData[14];
            return (
                <>
                    <TableRow>
                        <TableCell align={'center'} colSpan={Math.floor(colSpan / 2)}>
                            <a href='#' onClick={async (e) => {
                                e.preventDefault();
                                await handleAudit(obj.DocumentID);
                            }}>Click to confirm the information above matches the lease.</a>
                        </TableCell>
                        <TableCell colSpan={Math.floor(colSpan / 2)}>
                            <Label for="reason" className="mr-sm-10">Comment</Label>
                            <Input type="textarea" id="reason" row={4} placeholder='Comment' onBlur={e => setComment(e.target.value)} />
                            <Button className='mt-2' onClick={() => handleDecline(obj.DocumentID)}> Decline </Button>
                        </TableCell>


                    </TableRow>
                    <TableRow >
                        <TableCell colSpan={2} >
                        </TableCell>
                        <TableCell colSpan={colSpan - 4} style={{ position: 'relative' }} align={'center'}>
                            {rowData[13] && <iframe style={{ position: 'relative' }} src={rowData[13]} width={600} height={800} />}
                        </TableCell>
                    </TableRow>
                </>

            );
        },
    }

    if (loading) {
        return (
            <RctCollapsibleCard
                colClasses="col-xs-12 col-sm-12 col-md-12"
                heading={"Loading Make Readies..."}
            >
                <LinearProgress />
            </RctCollapsibleCard>
        );
    }

    return (
        <Main>

            <div className="page-title d-flex justify-content-between align-items-center">
                <div className="page-title-wrap">
                    <h2>
                        <span>Audit Leases</span>
                    </h2>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12 col-md-12 col-xl-12">
                    <MuiThemeProvider theme={Constants.getMuiTheme()}>
                        <MUIDataTable
                            title={`${leases.length} to audit`}
                            data={leases}
                            columns={columns}
                            options={options}
                        />
                    </MuiThemeProvider>
                </div>
            </div>
        </Main>
    )
}

export default AuditDetails;
