import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import { MuiThemeProvider } from '@material-ui/core/styles';

import Main from '../Main';
import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import LinearProgress from '../Util/LinearProgress';
import * as Constants from '../Util/constants';
import moment from 'moment';

import * as cronAPI from '../../Api/cron';

const CronJobs = () => {
    const [jobs, setjobs] = useState([]);
    const [loadingTable, setLoadingTable] = useState(true);

    const load = async () => {
        setLoadingTable(true);
        const jobs = await cronAPI.getAll();
        console.log('jobs', jobs);
        setjobs(jobs.filter((j) => j.Status === 'Failed'));
        setLoadingTable(false);
    };
    const options = {
        filterType: 'dropdown',
        selectableRows: 'none',
        rowsPerPage: 10,
    };
    useEffect(() => {
        load();
    }, []);

    const columns = [
        {
            name: 'Subject',
            label: 'Subject',
        },
        {
            name: 'Status',
            label: 'Status',
        },
        {
            name: 'Type',
            label: 'Type',
        },
        {
            name: 'FailedReason',
            label: 'Failed Reason',
        },
        {
            name: 'CreatedAt',
            label: 'Date',
            options: {
                customBodyRender: (value) => {
                    return moment.utc(value).format('MM/DD/YYYY, HH:mm:ss');
                },
            },
        },
    ];

    const renderTable = () => {
        if (loadingTable) {
            return (
                <RctCollapsibleCard colClasses="col-xs-12 col-sm-12 col-md-12" heading={'Loading Cron Job logs...'}>
                    <LinearProgress />
                </RctCollapsibleCard>
            );
        } else {
            return (
                <div className="data-table-wrapper" style={Constants.margins}>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-xl-12">
                            <MuiThemeProvider theme={Constants.getMuiTheme()}>
                                <MUIDataTable
                                    title={
                                        <>
                                            {jobs.length} Failed Cron Jobs
                                            <a
                                                href="/"
                                                style={{ textDecoration: 'underline', marginLeft: '10px' }}
                                                onClick={async (e) => {
                                                    e.preventDefault();
                                                    await cronAPI.clearFailedJobs();
                                                    setjobs([]);
                                                }}
                                            >
                                                Clear
                                            </a>
                                        </>
                                    }
                                    data={jobs}
                                    columns={columns}
                                    options={options}
                                />
                            </MuiThemeProvider>
                        </div>
                    </div>
                </div>
            );
        }
    };
    return (
        <Main>
            <div className="formelements-wrapper" style={Constants.margins}>
                <div className="page-title d-flex justify-content-between align-items-center">
                    <div className="page-title-wrap">
                        <h2>
                            <span>Cron Jobs Report</span>
                        </h2>
                    </div>
                </div>
            </div>
            {renderTable()}
        </Main>
    );
};

export default CronJobs;
