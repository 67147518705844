import React, {useState, useEffect} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, Form, Label, Input } from 'reactstrap';
import { NotificationManager } from 'react-notifications';
import IconButton from '@material-ui/core/IconButton';
import DeleteForever from '@material-ui/icons/DeleteForever';
import Edit from '@material-ui/icons/Edit';
import DatePicker from "reactstrap-date-picker";
import moment from 'moment';
import NumberFormat from 'react-number-format';
import SweetAlert from 'react-bootstrap-sweetalert';
import { MuiThemeProvider } from '@material-ui/core/styles';
import MUIDataTable from "mui-datatables";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';

import Main from '../Main';
import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import LinearProgress from '../Util/LinearProgress';
import * as Util from '../Util/util';
import * as Constants from '../Util/constants';
import * as companyAPI from '../../Api/company';

const BudgetCreator = (props) => {
    const history = useHistory();
    const login = useSelector((state) => state.login);
    const user = login.user;
    const userID = user.id;
    const propertyID = login.selectedPropertyID;
    const company = login.company
    const companyID = company.id;

    const [loading, setLoading] = useState(false);
    const [listExpenseTypes, setListExpenseTypes] = useState([]);
    const [listDepositSources, setListDepositSources] = useState([]);
    const [existingBudgetExpenses, setExistingBudgetExpenses] = useState([]);
    const [projectedIncome, setProjectedIncome] = useState([]);
    const [deleteBudgetExpense, setDeleteBudgetExpense] = useState(0);
    const [deleteBudgetIncome, setDeleteBudgetIncome] = useState(0);
    const [update, setUpdate] = useState(false);

    const {  
        handleSubmit: handleSubmitExpenses,  
        control: controlExpenses,
        formState: { errors: errorsExpenses }
    } = useForm();
    const { 
        handleSubmit: handleSubmitIncome, 
        control: controlIncome,
        formState: { errors: errorsIncome } 
    } = useForm();

    useEffect(() => {
        async function fetchData() {
            setLoading(true)
            setListExpenseTypes(await companyAPI.getListExpenseTypes(companyID));
            setListDepositSources(await companyAPI.getListDepositSources());

            const expenses = await companyAPI.getBudgetExpenses(propertyID);
            let arr = [];
            for(const e of expenses) {
                arr.push({
                    item: e.ExpenseType,
                    monthlyAmt: parseFloat(e.Amount).toFixed(2),
                    dtFrom: moment.utc(e.BudgetStartDate).format('MM/DD/YYYY'),
                    dtTo: moment.utc(e.BudgetEndDate).format('MM/DD/YYYY'),
                    submittedBy: `${e.UserFName} ${e.UserLName}`,
                    dtSubmitted: moment.utc(e.DateSubmitted).format('MM/DD/YYYY'),
                    edit: e.BudgetID,
                    delete: e.BudgetID,
                });
            }
            setExistingBudgetExpenses(arr);

            const incomes = await companyAPI.getBudgetIncomes(propertyID);
            let arr2 = [];
            for(const i of incomes) {
                arr2.push({
                    item: i.DepositSource,
                    monthlyAmt: parseFloat(i.Amount).toFixed(2),
                    dtFrom: moment.utc(i.BudgetStartDate).format('MM/DD/YYYY'),
                    dtTo: moment.utc(i.BudgetEndDate).format('MM/DD/YYYY'),
                    submittedBy: `${i.UserFName} ${i.UserLName}`,
                    dtSubmitted: moment.utc(i.DateSubmitted).format('MM/DD/YYYY'),
                    edit: i.BudgetIncomeID,
                    delete: i.BudgetIncomeID,
                });
            }
            setProjectedIncome(arr2);

            setLoading(false)
        }
        fetchData();
    }, [propertyID, companyID, update])

    const columnsExisting = [
        { name: 'item', label: 'Item', },
        { name: 'monthlyAmt', label: 'Monthly Amount', 
            options: {
                customBodyRender: (value) => {
                    return <NumberFormat displayType={'text'} thousandSeparator={true} prefix={'$'} value={value} />;
                }
            }
        },
        { name: 'dtFrom', label: 'Date From', },
        { name: 'dtTo', label: 'Date To', },
        { name: 'submittedBy', label: 'Submitted By', },
        { name: 'dtSubmitted', label: 'Date Submitted', },
        { name: 'edit', label: 'Edit', 
            options: {
                customBodyRender: (value) => {
                    return (
                        <IconButton 
                            onClick={() => {
                                const location = {
                                    pathname: '/company/updateBudget',
                                    state: { 
                                        id: parseInt(value),
                                        expense: true
                                    }
                                }
                                history.push(location);
                            }}
                        >
                            <Edit />
                        </IconButton>
                    )
                }
            },
        },
        { name: 'delete', label: 'Delete', 
            options: {
                customBodyRender: (value) => {
                    return (
                        <IconButton 
                            onClick={() => {
                                setDeleteBudgetExpense(parseInt(value));
                            }}
                        >
                            <DeleteForever />
                        </IconButton>
                    )
                }
            },
        },
    ];

    const columnsProjectedIncome = [
        { name: 'item', label: 'Item', },
        { name: 'monthlyAmt', label: 'Monthly Amount', 
            options: {
                customBodyRender: (value) => {
                    return <NumberFormat displayType={'text'} thousandSeparator={true} prefix={'$'} value={value} />;
                }
            }
        },
        { name: 'dtFrom', label: 'Date From', },
        { name: 'dtTo', label: 'Date To', },
        { name: 'submittedBy', label: 'Submitted By', },
        { name: 'dtSubmitted', label: 'Date Submitted', },
        { name: 'edit', label: 'Edit', 
            options: {
                customBodyRender: (value) => {
                    return (
                        <IconButton 
                            onClick={() => {
                                const location = {
                                    pathname: '/company/updateBudget',
                                    state: { 
                                        id: parseInt(value),
                                        expense: false
                                    }
                                }
                                history.push(location);
                            }}
                        >
                            <Edit />
                        </IconButton>
                    )
                }
            },
        },
        { name: 'delete', label: 'Delete', 
            options: {
                customBodyRender: (value) => {
                    return (
                        <IconButton 
                            onClick={() => {
                                setDeleteBudgetIncome(parseInt(value));
                            }}
                        >
                            <DeleteForever />
                        </IconButton>
                    )
                }
            },
        },
    ];

    const options = {
        filterType: 'dropdown',
        pagination: true,
        selectableRows: "none",
        rowsPerPage: 10,
    }

    const submitFormExpenses = async (data) => {
        const amt = parseFloat(data.monthlyAmount.toString().replace(/\$|,/g, '')).toFixed(2);
        if(amt <= 0) {
            NotificationManager.warning("Please, enter a valid monthly budget amount", "Error");
            return;
        }
        const dtFrom = moment(data.dateFrom);
        if(!dtFrom.isValid()) {
            NotificationManager.warning("Please enter a valid date from.", "Warning");
            return;
        }
        const dtTo = moment(data.dateTo);
        if(!dtTo.isValid()) {
            NotificationManager.warning("Please enter a valid date to.", "Warning");
            return;
        }
        if(!moment(dtTo).isAfter(moment(dtFrom))) {
            NotificationManager.warning("Date To must be after Date From.", "Warning");
            return;
        }
        setUpdate(!update);
        const res = await companyAPI.addBudget({
            propertyID: propertyID,
            expenseTypeID: parseInt(data.item),
            amount: amt,
            startDate: dtFrom,
            endDate: dtTo,
            userID: userID,
        });
        if(res !== 0) {
            NotificationManager.error(Constants.DEFAULT_ERROR, "Error");
            return
        }
        setUpdate(!update);
        NotificationManager.success("Budget Expense added.", "Success");
    }

    const submitFormIncome = async (data) => {
        const amt = parseFloat(data.monthlyAmountIncome.toString().replace(/\$|,/g, '')).toFixed(2);
        if(amt <= 0) {
            NotificationManager.warning("Please, enter a valid monthly budget amount", "Error");
            return;
        }
        const dtFrom = moment(data.dateFromIncome);
        if(!dtFrom.isValid()) {
            NotificationManager.warning("Please enter a valid date from.", "Warning");
            return;
        }
        const dtTo = moment(data.dateToIncome);
        if(!dtTo.isValid()) {
            NotificationManager.warning("Please enter a valid date to.", "Warning");
            return;
        }
        if(!moment(dtTo).isAfter(moment(dtFrom))) {
            NotificationManager.warning("Date To must be after Date From.", "Warning");
            return;
        }
        setUpdate(!update);
        const res = await companyAPI.addBudgetIncome({
            propertyID: propertyID,
            depositSourceID: parseInt(data.itemIncome),
            amount: amt,
            startDate: dtFrom,
            endDate: dtTo,
            userID: userID,
        });
        if(res !== 0) {
            NotificationManager.error(Constants.DEFAULT_ERROR, "Error");
            return
        }
        setUpdate(!update);
        NotificationManager.success("Budget Income added.", "Success");
    }

    const handleDeleteExpense = async () => {
        setUpdate(!update);
        const res = await companyAPI.deleteBudget(deleteBudgetExpense);
        if(res !== 0) {
            NotificationManager.error(Constants.DEFAULT_ERROR, "Error");
            return
        }
        setDeleteBudgetExpense(0);
        setUpdate(!update);
        NotificationManager.success("Budget Expense deleted.", "Success");
    }

    const handleDeleteIncome = async () => {
        setUpdate(!update);
        const res = await companyAPI.deleteBudgetIncome(deleteBudgetIncome);
        if(res !== 0) {
            NotificationManager.error(Constants.DEFAULT_ERROR, "Error");
            return
        }
        setDeleteBudgetIncome(0);
        setUpdate(!update);
        NotificationManager.success("Budget Income deleted.", "Success");
    }

    if(loading) {
        return (
            <RctCollapsibleCard
                colClasses="col-xs-12 col-sm-12 col-md-12"
                heading={`Loading Budget Creator...`}
            >
                <LinearProgress />
            </RctCollapsibleCard>
        );
    }

    return (
        <Main>  
            <SweetAlert
                warning
                btnSize="sm"
                show={deleteBudgetExpense !== 0}
                showCancel
                confirmBtnText="Yes, delete it!"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="success"
                title="Are you sure?"
                onConfirm={() => handleDeleteExpense()}
                onCancel={() => setDeleteBudgetExpense(0)}
            >
                You won't be able to recover this budget expense. 
            </SweetAlert>
            <SweetAlert
                warning
                btnSize="sm"
                show={deleteBudgetIncome !== 0}
                showCancel
                confirmBtnText="Yes, delete it!"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="success"
                title="Are you sure?"
                onConfirm={() => handleDeleteIncome()}
                onCancel={() => setDeleteBudgetIncome(0)}
            >
                You won't be able to recover this projected income. 
            </SweetAlert>
            <div className="page-title d-flex justify-content-between align-items-center">
                <div className="page-title-wrap">
                    <h2>
                        <span>Budget Creator</span>
                    </h2>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12 col-md-12 col-xl-12">
                    <RctCollapsibleCard heading="Add Budget Expense">
                        <Form onSubmit={handleSubmitExpenses(submitFormExpenses)}>
                            <div className="row">
                                <div className="col-sm-3">
                                    <Label for="item" className="mr-sm-10">
                                        Item
                                        <IconButton
                                            aria-label="Add Expense Type"
                                            onClick={() => {
                                                const location = {
                                                    pathname: '/company/expenseTypes',
                                                }
                                                history.push(location);
                                            }}
                                            style={{color: 'blue', maxHeight: '10px'}}
                                        >
                                            <AddCircleOutline />
                                        </IconButton>
                                    </Label>
                                    <Controller
                                        name="item"
                                        control={controlExpenses}
                                        render={({ field }) => (
                                            <Input {...field} type="select" id="item">
                                                {listExpenseTypes.map((obj, idx) => {
                                                    return <option key={idx} value={obj.ExpenseTypeID}>{obj.ExpenseType}</option>
                                                })}
                                            </Input>
                                        )}
                                    />
                                </div>
                                <div className="col-sm-2">
                                    <Label for="monthlyAmount" className="mr-sm-10">Monthly Budget Amount</Label>
                                    <Controller
                                        name="monthlyAmount"
                                        control={controlExpenses}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <NumberFormat className="form-control" {...field} thousandSeparator={true} prefix={'$'} id="monthlyAmount" style={Util.setErrorStyle(errorsExpenses.monthlyAmount)} />
                                        )}
                                    />
                                    {errorsExpenses.monthlyAmount && (
                                        <span style={{ color: "red" }} role="alert">required</span>
                                    )}
                                </div>
                                <div className="col-sm-2">
                                    <Label for="dateFrom" className="mr-sm-10">Date From</Label>
                                    <Controller
                                        name="dateFrom"
                                        control={controlExpenses}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <DatePicker {...field} id="dateFrom" style={Util.setErrorStyle(errorsExpenses.dateFrom)} />
                                        )}
                                    />
                                    {errorsExpenses.dateFrom && (
                                        <span style={{ color: "red" }} role="alert">required</span>
                                    )}
                                </div>
                                <div className="col-sm-2">
                                    <Label for="dateTo" className="mr-sm-10">Date To</Label>
                                    <Controller
                                        name="dateTo"
                                        control={controlExpenses}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <DatePicker {...field} id="dateTo" style={Util.setErrorStyle(errorsExpenses.dateTo)} />
                                        )}
                                    />
                                    {errorsExpenses.dateTo && (
                                        <span style={{ color: "red" }} role="alert">required</span>
                                    )}
                                </div>
                            </div>
                            <Button type="submit" color="primary" style={{marginTop: '1rem'}}>Add Item</Button>
                        </Form>
                    </RctCollapsibleCard>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-xl-12">
                    <MuiThemeProvider theme={Constants.getMuiTheme()}>                                                    
                        <MUIDataTable
                            title={`${existingBudgetExpenses.length} Existing Budget Expenses`}
                            data={existingBudgetExpenses}
                            columns={columnsExisting}
                            options={options}
                        />
                    </MuiThemeProvider>
                </div>
            </div>
            <br/>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-xl-12">
                    <RctCollapsibleCard heading="Add Budget Income Projections">
                        <Form onSubmit={handleSubmitIncome(submitFormIncome)}>
                            <div className="row">
                                <div className="col-sm-3">
                                    <Label for="itemIncome" className="mr-sm-10">Item</Label>
                                    <Controller
                                        name="itemIncome"
                                        control={controlIncome}
                                        render={({ field }) => (
                                            <Input {...field} type="select" id="itemIncome">
                                                {listDepositSources.map((obj, idx) => {
                                                    return <option key={idx} value={obj.DepositSourceID}>{obj.DepositSource}</option>
                                                })}
                                            </Input>
                                        )}
                                    />
                                </div>
                                <div className="col-sm-2">
                                    <Label for="monthlyAmountIncome" className="mr-sm-10">Monthly Budget Amount</Label>
                                    <Controller
                                        name="monthlyAmountIncome"
                                        control={controlIncome}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <NumberFormat className="form-control" {...field} thousandSeparator={true} prefix={'$'} id="monthlyAmountIncome" style={Util.setErrorStyle(errorsIncome.monthlyAmountIncome)} />
                                        )}
                                    />
                                    {errorsIncome.monthlyAmount && (
                                        <span style={{ color: "red" }} role="alert">required</span>
                                    )}
                                </div>
                                <div className="col-sm-2">
                                    <Label for="dateFromIncome" className="mr-sm-10">Date From</Label>
                                    <Controller
                                        name="dateFromIncome"
                                        control={controlIncome}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <DatePicker {...field} id="dateFromIncome" style={Util.setErrorStyle(errorsIncome.dateFromIncome)} />
                                        )}
                                    />
                                    {errorsIncome.dateFromIncome && (
                                        <span style={{ color: "red" }} role="alert">required</span>
                                    )}
                                </div>
                                <div className="col-sm-2">
                                    <Label for="dateToIncome" className="mr-sm-10">Date To</Label>
                                    <Controller
                                        name="dateToIncome"
                                        control={controlIncome}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <DatePicker {...field} id="dateToIncome" style={Util.setErrorStyle(errorsIncome.dateToIncome)} />
                                        )}
                                    />
                                    {errorsIncome.dateToIncome && (
                                        <span style={{ color: "red" }} role="alert">required</span>
                                    )}
                                </div>
                            </div>
                            <Button type="submit" color="primary" style={{marginTop: '1rem'}}>Add Item</Button>
                        </Form>
                    </RctCollapsibleCard>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-xl-12">
                    <MuiThemeProvider theme={Constants.getMuiTheme()}>                                                    
                        <MUIDataTable
                            title={`${projectedIncome.length} Projected Income`}
                            data={projectedIncome}
                            columns={columnsProjectedIncome}
                            options={options}
                        />
                    </MuiThemeProvider>
                </div>
            </div>
            <br/>
        </Main>
    );
}

export default BudgetCreator;
