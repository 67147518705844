import React, {useState} from 'react';
import { Controller, useForm } from "react-hook-form";
import { Button, Form, Label, Input } from 'reactstrap';
import { FormGroup } from '@material-ui/core';
import { useSelector } from "react-redux";
import { NotificationManager } from 'react-notifications';

import Main from '../../Main';
import RctCollapsibleCard from '../../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import * as Util from '../../Util/util';
import * as Constants from '../../Util/constants';
import LinearProgress from '../../Util/LinearProgress';
import * as reportsAPI from '../../../Api/reports';

const LeadSource = () => {
    const login = useSelector((state) => state.login);
    const company = login.company
    const companyID = company.id;

    const [loading, setLoading] = useState(false);
    // const [leadSource, setLeadSource] = useState(null);

    const { handleSubmit, control, register, formState: { errors }} = useForm();

    const submit = async (data) => {
        setLoading(true);
        const res = await reportsAPI.addLeadSource({
            leadSource: data.leadSource,
            companyID
        });
        setLoading(false);
        if(res !== 0) {
            NotificationManager.error(Constants.DEFAULT_ERROR, "Error");
            return;
        }
        NotificationManager.success("Lead Source Added.", "Success");
    }

    if(loading) {
        return (
            <RctCollapsibleCard
            colClasses="col-xs-12 col-sm-12 col-md-12"
            heading={"Saving Lead Source..."}
            >
                <LinearProgress />
            </RctCollapsibleCard>
        );
    }

    return (
        <Main>
            <div className="formelements-wrapper" style={Constants.margins}>
                <div className="page-title d-flex justify-content-between align-items-center">
                    <div className="page-title-wrap">
                        <h2>
                            <span>Lead Source</span>
                        </h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-xl-12">
                        <RctCollapsibleCard heading="Add Lead Source">
                            <Form onSubmit={handleSubmit(submit)}>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <FormGroup className="mr-10 mb-10">
                                            <Label for="leadSource" className="mr-sm-10">Lead Source</Label>
                                            <Controller
                                                name="leadSource"
                                                control={control}
                                                rules={{required: true}}
                                                render={({ field }) => (
                                                    <Input {...field} {...register("leadSource")} type="text" id="leadSource" style={Util.setErrorStyle(errors.leadSource)} />
                                                )}
                                            />
                                            {errors.leadSource && (
                                                <span style={{ color: "red" }} role="alert">required</span>
                                            )}
                                        </FormGroup>
                                    </div>
                                </div>
                                <Button type="submit" color="primary" size="sm" className="w-auto">Add Lead Source</Button>
                            </Form>
                        </RctCollapsibleCard>
                    </div>
                </div>
            </div>
        </Main>
    );
}

export default LeadSource;