import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import * as Constants from "../Util/constants";
import * as reportsAPI from "../../Api/reports";
import { currencyFormat } from "./helperFunctions";
import RctCollapsibleCard from "../Helpers/RctCollapsibleCard/RctCollapsibleCard";
import LinearProgress from "../Util/LinearProgress";

const PositiveCredit = (props) => {
  const [loading, setLoading] = useState(false);
  const [creditData, setCreditData] = useState([]);
  const propertyName = useSelector((state) => state.login.properties);
  const [filteredName] = propertyName.filter(
    (property) => property.PropertyID === props.propertyId
  );

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const reportDetails = await reportsAPI.getPositiveCredit(
        props.reportSearch
      );

      let result = [];
      reportDetails.forEach((report) => {
        const tenantBalance =
          (report.TotalCredit === "0.00" ? 0 : +report.TotalCredit) -
          (report.TotalDebit === "0.00" ? 0 : +report.TotalDebit);
        const housingBalance =
          (report.HousingCredit === "0.00" ? 0 : +report.HousingCredit) -
          (report.HousingDebit === "0.00" ? 0 : +report.HousingDebit);

        if (tenantBalance > 0 || housingBalance > 0) {
          result = [
            ...result,
            {
              unit: report.UnitName,
              tenant: report.TenantFName + " " + report.TenantLName,
              tenantBalance: currencyFormat(+tenantBalance),
              housingBalance: currencyFormat(+housingBalance),
            },
          ];
        }
      });
      result = [
        ...result,
        {
          unit: `Total Positive Balances: ${result.length}`,
        },
      ];

      setCreditData(result);
      setLoading(false);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (loading) {
    return (
      <RctCollapsibleCard
        colClasses="col-xs-12 col-sm-12 col-md-12"
        heading={"Loading Reports..."}
      >
        <LinearProgress />
      </RctCollapsibleCard>
    );
  }
  const creditColumns = [
    { name: "unit", label: "Unit" },
    { name: "tenant", label: "Tenant" },
    { name: "tenantBalance", label: "Tenant Balance" },
    { name: "housingBalance", label: "Housing Balance" },
  ];

  return (
    <div>
      <div className="page-title d-flex justify-content-between align-items-center">
        <div className="page-title-wrap">
          <h2>
            <span>
              {props.reportName +
                " - " +
                filteredName.PropertyName +
                " - Date Range of Report: " +
                props.startDate +
                " to " +
                props.endDate}
            </span>
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-xl-12">
          <MuiThemeProvider theme={Constants.getMuiTheme()}>
            <MUIDataTable
              title={"Positive Credit Balance"}
              data={creditData}
              columns={creditColumns}
              options={props.reportOptions}
            />
          </MuiThemeProvider>
        </div>
      </div>
    </div>
  );
};

export default PositiveCredit;
