import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import * as Constants from "../Util/constants";
import * as reportsAPI from "../../Api/reports";
import { currencyFormat } from "./helperFunctions";
import RctCollapsibleCard from "../Helpers/RctCollapsibleCard/RctCollapsibleCard";
import LinearProgress from "../Util/LinearProgress";

const TenantPayments = (props) => {
  const [loading, setLoading] = useState(false);
  const [paymentData, setPaymentData] = useState([]);
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      for (let i = 0; i < props.selectedValue.length; i++) {
        if (!paymentData[props.selectedValue[i].name]) {
          props.reportSearch.pID = props.selectedValue[i].id;
          const reportDetails = await reportsAPI.getTenantPaymentReport(
            props.reportSearch
          );
          const reportTotal = await reportsAPI.getTenantPaymentReportTotal(
            props.reportSearch
          );
          const reportPaymentsTotal =
            await reportsAPI.getTenantPaymentReportPaymentsTotal(
              props.reportSearch
            );
          const reportAllTotal =
            await reportsAPI.getTenantPaymentReportAllTotal(props.reportSearch);
          let detailIndex = 0;
          let totalIndex = 0;
          while (
            detailIndex < reportDetails.length &&
            totalIndex <= reportTotal.length
          ) {
            if (reportTotal.length === 0) {
              if (paymentData[props.selectedValue[i].name]) {
                paymentData[props.selectedValue[i].name].push({
                  unit: reportDetails[detailIndex].tenant.unit.UnitName,
                  tenant:
                    reportDetails[detailIndex].tenant.TenantFName +
                    " " +
                    reportDetails[detailIndex].tenant.TenantLName,
                  amountPaid: currencyFormat(0),
                  totalCash: currencyFormat(0),
                  totalCashierCheck: currencyFormat(0),
                  totalCertifiedBankCheck: currencyFormat(0),
                  totalCheck: currencyFormat(0),
                  totalHousingDirectDeposit: currencyFormat(0),
                  totalMoneyOrder: currencyFormat(0),
                  totalOnlinePayment: currencyFormat(0),
                  totalSection8Voucher: currencyFormat(0),
                  totalOthers: currencyFormat(0),
                  balance: currencyFormat(
                    +reportDetails[detailIndex].TotalBalance
                  ),
                });
                detailIndex += 1;
              } else {
                paymentData[props.selectedValue[i].name] = [
                  {
                    unit: reportDetails[detailIndex].tenant.unit.UnitName,
                    tenant:
                      reportDetails[detailIndex].tenant.TenantFName +
                      " " +
                      reportDetails[detailIndex].tenant.TenantLName,
                    amountPaid: currencyFormat(0),
                    totalCash: currencyFormat(0),
                    totalCashierCheck: currencyFormat(0),
                    totalCertifiedBankCheck: currencyFormat(0),
                    totalCheck: currencyFormat(0),
                    totalHousingDirectDeposit: currencyFormat(0),
                    totalMoneyOrder: currencyFormat(0),
                    totalOnlinePayment: currencyFormat(0),
                    totalSection8Voucher: currencyFormat(0),
                    totalOthers: currencyFormat(0),
                    balance: currencyFormat(
                      +reportDetails[detailIndex].TotalBalance
                    ),
                  },
                ];
                detailIndex += 1;
              }
            }
            if (reportTotal.length > 0) {
              if (paymentData[props.selectedValue[i].name]) {
                if (
                  totalIndex === reportTotal.length - 1 &&
                  reportTotal.length !== 0 &&
                  reportDetails[detailIndex].tenant.id ===
                    reportTotal[totalIndex].tenant.id
                ) {
                  paymentData[props.selectedValue[i].name].push({
                    unit: reportDetails[detailIndex].tenant.unit.UnitName,
                    tenant:
                      reportDetails[detailIndex].tenant.TenantFName +
                      " " +
                      reportDetails[detailIndex].tenant.TenantLName,
                    amountPaid: currencyFormat(
                      +reportTotal[totalIndex].TotalPaid
                    ),
                    totalCash: currencyFormat(
                      +reportTotal[totalIndex].TotalCash
                    ),
                    totalCashierCheck: currencyFormat(
                      +reportTotal[totalIndex].TotalCashierCheck
                    ),
                    totalCertifiedBankCheck: currencyFormat(
                      +reportTotal[totalIndex].TotalBankCheck
                    ),
                    totalCheck: currencyFormat(
                      +reportTotal[totalIndex].TotalCheck
                    ),
                    totalHousingDirectDeposit: currencyFormat(
                      +reportTotal[totalIndex].TotalHousing
                    ),
                    totalMoneyOrder: currencyFormat(
                      +reportTotal[totalIndex].TotalMoneyOrder
                    ),
                    totalOnlinePayment: currencyFormat(
                      +reportTotal[totalIndex].TotalOnline
                    ),
                    totalSection8Voucher: currencyFormat(
                      +reportTotal[totalIndex].TotalSection8
                    ),
                    totalOthers: currencyFormat(
                      +reportTotal[totalIndex].TotalOthers
                    ),
                    balance: currencyFormat(
                      +reportDetails[detailIndex].TotalBalance
                    ),
                  });
                  totalIndex += 1;
                } else if (totalIndex === reportTotal.length) {
                  paymentData[props.selectedValue[i].name].push({
                    unit: reportDetails[detailIndex].tenant.unit.UnitName,
                    tenant:
                      reportDetails[detailIndex].tenant.TenantFName +
                      " " +
                      reportDetails[detailIndex].tenant.TenantLName,
                    amountPaid: currencyFormat(0),
                    totalCash: currencyFormat(0),
                    totalCashierCheck: currencyFormat(0),
                    totalCertifiedBankCheck: currencyFormat(0),
                    totalCheck: currencyFormat(0),
                    totalHousingDirectDeposit: currencyFormat(0),
                    totalMoneyOrder: currencyFormat(0),
                    totalOnlinePayment: currencyFormat(0),
                    totalSection8Voucher: currencyFormat(0),
                    totalOthers: currencyFormat(0),
                    balance: currencyFormat(
                      +reportDetails[detailIndex].TotalBalance
                    ),
                  });
                  detailIndex += 1;
                } else if (
                  reportDetails[detailIndex].tenant.id ===
                  reportTotal[totalIndex].tenant.id
                ) {
                  paymentData[props.selectedValue[i].name].push({
                    unit: reportDetails[detailIndex].tenant.unit.UnitName,
                    tenant:
                      reportDetails[detailIndex].tenant.TenantFName +
                      " " +
                      reportDetails[detailIndex].tenant.TenantLName,
                    amountPaid: currencyFormat(
                      +reportTotal[totalIndex].TotalPaid
                    ),
                    totalCash: currencyFormat(
                      +reportTotal[totalIndex].TotalCash
                    ),
                    totalCashierCheck: currencyFormat(
                      +reportTotal[totalIndex].TotalCashierCheck
                    ),
                    totalCertifiedBankCheck: currencyFormat(
                      +reportTotal[totalIndex].TotalBankCheck
                    ),
                    totalCheck: currencyFormat(
                      +reportTotal[totalIndex].TotalCheck
                    ),
                    totalHousingDirectDeposit: currencyFormat(
                      +reportTotal[totalIndex].TotalHousing
                    ),
                    totalMoneyOrder: currencyFormat(
                      +reportTotal[totalIndex].TotalMoneyOrder
                    ),
                    totalOnlinePayment: currencyFormat(
                      +reportTotal[totalIndex].TotalOnline
                    ),
                    totalSection8Voucher: currencyFormat(
                      +reportTotal[totalIndex].TotalSection8
                    ),
                    totalOthers: currencyFormat(
                      +reportTotal[totalIndex].TotalOthers
                    ),
                    balance: currencyFormat(
                      +reportDetails[detailIndex].TotalBalance
                    ),
                  });
                  detailIndex += 1;
                  totalIndex += 1;
                } else {
                  paymentData[props.selectedValue[i].name].push({
                    unit: reportDetails[detailIndex].tenant.unit.UnitName,
                    tenant:
                      reportDetails[detailIndex].tenant.TenantFName +
                      " " +
                      reportDetails[detailIndex].tenant.TenantLName,
                    amountPaid: currencyFormat(0),
                    totalCash: currencyFormat(0),
                    totalCashierCheck: currencyFormat(0),
                    totalCertifiedBankCheck: currencyFormat(0),
                    totalCheck: currencyFormat(0),
                    totalHousingDirectDeposit: currencyFormat(0),
                    totalMoneyOrder: currencyFormat(0),
                    totalOnlinePayment: currencyFormat(0),
                    totalSection8Voucher: currencyFormat(0),
                    totalOthers: currencyFormat(0),
                    balance: currencyFormat(
                      +reportDetails[detailIndex].TotalBalance
                    ),
                  });
                  detailIndex += 1;
                }
              } else {
                if (
                  reportDetails[detailIndex].tenant.id ===
                  reportTotal[totalIndex].tenant.id
                ) {
                  paymentData[props.selectedValue[i].name] = [
                    {
                      unit: reportDetails[detailIndex].tenant.unit.UnitName,
                      tenant:
                        reportDetails[detailIndex].tenant.TenantFName +
                        " " +
                        reportDetails[detailIndex].tenant.TenantLName,
                      amountPaid: currencyFormat(
                        +reportTotal[totalIndex].TotalPaid
                      ),
                      totalCash: currencyFormat(
                        +reportTotal[totalIndex].TotalCash
                      ),
                      totalCashierCheck: currencyFormat(
                        +reportTotal[totalIndex].TotalCashierCheck
                      ),
                      totalCertifiedBankCheck: currencyFormat(
                        +reportTotal[totalIndex].TotalBankCheck
                      ),
                      totalCheck: currencyFormat(
                        +reportTotal[totalIndex].TotalCheck
                      ),
                      totalHousingDirectDeposit: currencyFormat(
                        +reportTotal[totalIndex].TotalHousing
                      ),
                      totalMoneyOrder: currencyFormat(
                        +reportTotal[totalIndex].TotalMoneyOrder
                      ),
                      totalOnlinePayment: currencyFormat(
                        +reportTotal[totalIndex].TotalOnline
                      ),
                      totalSection8Voucher: currencyFormat(
                        +reportTotal[totalIndex].TotalSection8
                      ),
                      totalOthers: currencyFormat(
                        +reportTotal[totalIndex].TotalOthers
                      ),
                      balance: currencyFormat(
                        +reportDetails[detailIndex].TotalBalance
                      ),
                    },
                  ];
                  detailIndex += 1;
                  totalIndex += 1;
                } else {
                  paymentData[props.selectedValue[i].name] = [
                    {
                      unit: reportDetails[detailIndex].tenant.unit.UnitName,
                      tenant:
                        reportDetails[detailIndex].tenant.TenantFName +
                        " " +
                        reportDetails[detailIndex].tenant.TenantLName,
                      amountPaid: currencyFormat(0),
                      totalCash: currencyFormat(0),
                      totalCashierCheck: currencyFormat(0),
                      totalCertifiedBankCheck: currencyFormat(0),
                      totalCheck: currencyFormat(0),
                      totalHousingDirectDeposit: currencyFormat(0),
                      totalMoneyOrder: currencyFormat(0),
                      totalOnlinePayment: currencyFormat(0),
                      totalSection8Voucher: currencyFormat(0),
                      totalOthers: currencyFormat(0),
                      balance: currencyFormat(
                        +reportDetails[detailIndex].TotalBalance
                      ),
                    },
                  ];
                  detailIndex += 1;
                }
              }
            }
          }
          paymentData[props.selectedValue[i].name].push({
            tenant: "TOTAL",
            amountPaid: currencyFormat(+reportAllTotal[0].TotalPaid),
            totalCash: currencyFormat(+reportAllTotal[0].TotalCash),
            totalCashierCheck: currencyFormat(
              +reportAllTotal[0].TotalCashierCheck
            ),
            totalCertifiedBankCheck: currencyFormat(
              +reportAllTotal[0].TotalBankCheck
            ),
            totalCheck: currencyFormat(+reportAllTotal[0].TotalCheck),
            totalHousingDirectDeposit: currencyFormat(
              +reportAllTotal[0].TotalHousing
            ),
            totalMoneyOrder: currencyFormat(+reportAllTotal[0].TotalMoneyOrder),
            totalOnlinePayment: currencyFormat(+reportAllTotal[0].TotalOnline),
            totalSection8Voucher: currencyFormat(
              +reportAllTotal[0].TotalSection8
            ),
            totalOthers: currencyFormat(+reportAllTotal[0].TotalOthers),
            balance: currencyFormat(+reportPaymentsTotal[0].TotalBalance),
          });
        }
      }
      setLoading(false);
    }
    fetchData();
  }, [props.selectedValue]);
  if (loading) {
    return (
      <RctCollapsibleCard
        colClasses="col-xs-12 col-sm-12 col-md-12"
        heading={"Loading Reports..."}
      >
        <LinearProgress />
      </RctCollapsibleCard>
    );
  }
  const paymentColumns = [
    { name: "unit", label: "unit" },
    { name: "tenant", label: "tenant" },
    { name: "amountPaid", label: "Amount Paid" },
    { name: "totalCash", label: "Total Cash" },
    { name: "totalCashierCheck", label: "Total Cashier Check" },
    { name: "totalCertifiedBankCheck", label: "Total Certified Bank Check" },
    { name: "totalCheck", label: "Total Check" },
    {
      name: "totalHousingDirectDeposit",
      label: "Total Housing Direct Deposit",
    },
    { name: "totalMoneyOrder", label: "Total Money Order" },
    { name: "totalOnlinePayment", label: "Total Online Payment" },
    { name: "totalSection8Voucher", label: "Total Section 8 Voucher" },
    { name: "totalOthers", label: "Total Others" },
    { name: "balance", label: "Balance" },
  ];
  return (
    <div>
      <div className="page-title d-flex justify-content-between align-items-center">
        <div className="page-title-wrap">
          <h2>
            <span>
              {props.reportName +
                " - Date Range of Report: " +
                props.startDate +
                " to " +
                props.endDate}
            </span>
          </h2>
        </div>
      </div>
      <div className="row">
        {props.selectedValue.map((property) => {
          return (
            <div className="col-sm-12 col-md-12 col-xl-12">
              <MuiThemeProvider theme={Constants.getMuiTheme()}>
                <MUIDataTable
                  title={`${property.name} Tenant Payments`}
                  data={paymentData[property.name]}
                  columns={paymentColumns}
                  options={props.reportOptions}
                />
              </MuiThemeProvider>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TenantPayments;
