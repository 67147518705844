import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import * as Constants from "../Util/constants";
import * as reportsAPI from "../../Api/reports";
import { currencyFormat, formatDate } from "./helperFunctions";
import RctCollapsibleCard from "../Helpers/RctCollapsibleCard/RctCollapsibleCard";
import LinearProgress from "../Util/LinearProgress";
import IconButton from "@material-ui/core/IconButton";
import Edit from "@material-ui/icons/Edit";
import { useHistory } from "react-router-dom";
import AssignmentIndOutlined from "@material-ui/icons/AssignmentIndOutlined";
import Button from "@material-ui/core/Button";
import { NotificationManager } from "react-notifications";
import DeleteIcon from "@material-ui/icons/Delete";

const UnitAvailList = (props) => {
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const [noticeData, setNoticeData] = useState([]);
  const [evictionsData, setEvictionsData] = useState([]);
  const [leaseOccupData, setLeaseOccupData] = useState([]);
  const [leaseVacantData, setLeaseVaccantData] = useState([]);
  const propertyName = useSelector((state) => state.login.properties);
  const [filteredName] = propertyName.filter(
    (property) => property.PropertyID === props.propertyId
  );
  const history = useHistory();
  const deletePrelease = async (id) => {
    const res = await reportsAPI.deletePrelease({
      preID: id,
    });
    console.log(res);
    if (res !== 1) {
      NotificationManager.error(
        "Error processing your request. Please, contact us.",
        "Error"
      );
      return;
    }
    NotificationManager.success("Pre Lease Deleted Successfully!", "Success");
  };
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const reportListing = await reportsAPI.getUnitAvailList(
        props.reportSearch
      );
      const reportNotice = await reportsAPI.getUnitAvailNotice(
        props.reportSearch
      );
      const reportEvictions = await reportsAPI.getUnitAvailEvictions(
        props.reportSearch
      );
      const reportLeaseOccup = await reportsAPI.getUnitAvailLeaseOccup(
        props.reportSearch
      );
      const reportLeaseVacant = await reportsAPI.getUnitAvailLeaseVacant(
        props.reportSearch
      );
      const reportListingVacant = [];
      const tenantModels = [reportListingVacant, reportNotice, reportEvictions];
      const preleaseModels = [reportLeaseOccup, reportLeaseVacant];
      for (let i = 0; i < reportListing.length; i++) {
        if (
          new Date(
            reportListing[i].tenants[
              reportListing[i].tenants.length - 1
            ].MoveOutDate
          ) < new Date()
        ) {
          reportListingVacant.push(reportListing[i]);
        }
      }
      if (
        reportListingVacant.length > 0 ||
        reportNotice.length > 0 ||
        reportEvictions.length > 0
      ) {
        for (let model = 0; model < tenantModels.length; model++) {
          for (let i = 0; i < tenantModels[model].length; i++) {
            if (tenantModels[model] === reportListingVacant) {
              listData.push({
                tenant:
                  tenantModels[model][i].tenants[
                    tenantModels[model][i].tenants.length - 1
                  ].Prospect === 2
                    ? tenantModels[model][i].tenants[
                        tenantModels[model][i].tenants.length - 1
                      ].TenantFName +
                      " " +
                      tenantModels[model][i].tenants[
                        tenantModels[model][i].tenants.length - 1
                      ].TenantLName
                    : "Vacant",
                unit: tenantModels[model][i].UnitName,
                unitType: tenantModels[model][i].unittype.UnitType,
                moveOutDate: formatDate(
                  tenantModels[model][i].tenants[
                    tenantModels[model][i].tenants.length - 1
                  ].MoveOutDate
                ),
                days: Math.floor(
                  (new Date().getTime() -
                    new Date(
                      tenantModels[model][i].tenants[
                        tenantModels[model][i].tenants.length - 1
                      ].MoveOutDate
                    ).getTime()) /
                    (24 * 60 * 60 * 1000)
                ),
                mktRent: currencyFormat(
                  +tenantModels[model][i].unittype.UnitCharge
                ),
                comments: tenantModels[model][i].Comment,
                edit: (
                  <IconButton
                    aria-label="Print"
                    onClick={() => {
                      const location = {
                        pathname: "/tenants/editTenant",
                        state: {
                          tenantID:
                            tenantModels[model][i].tenants[
                              tenantModels[model][i].tenants.length - 1
                            ].id,
                          tenantName: `${
                            tenantModels[model][i].tenants[
                              tenantModels[model][i].tenants.length - 1
                            ].TenantFName
                          } ${
                            tenantModels[model][i].tenants[
                              tenantModels[model][i].tenants.length - 1
                            ].TenantLName
                          }`,
                        },
                      };
                      history.push(location);
                    }}
                  >
                    <Edit />
                  </IconButton>
                ),
                prelease: (
                  <td className="text-center">
                    <IconButton
                      aria-label="Edit"
                      onClick={() => {
                        const location = {
                          pathname: "/preLease",
                          state: {
                            unitID: tenantModels[model][i].id,
                          },
                        };
                        history.push(location);
                      }}
                    >
                      <AssignmentIndOutlined style={{ color: "#228B22" }} />
                    </IconButton>
                  </td>
                ),
              });
            }
            if (
              tenantModels[model] === reportNotice ||
              tenantModels[model] === reportEvictions
            ) {
              let theArray;
              theArray =
                tenantModels[model] === reportNotice
                  ? noticeData
                  : evictionsData;
              theArray.push({
                tenant:
                  tenantModels[model][i].TenantFName +
                  " " +
                  tenantModels[model][i].TenantLName,
                unit: tenantModels[model][i].unit.UnitName,
                unitType: tenantModels[model][i].unit.unittype.UnitType,
                moveOutDate: formatDate(tenantModels[model][i]?.MoveOutDate),
                days: Math.floor(
                  (new Date().getTime() -
                    new Date(tenantModels[model][i].MoveOutDate).getTime()) /
                    (24 * 60 * 60 * 1000)
                ),
                mktRent: currencyFormat(
                  +tenantModels[model][i].unit.unittype?.UnitCharge
                ),
                comments: tenantModels[model][i].unit.Comment,
                edit: (
                  <IconButton
                    aria-label="Print"
                    onClick={() => {
                      const location = {
                        pathname: "/tenants/editTenant",
                        state: {
                          tenantID: tenantModels[model][i].id,
                          tenantName: `${tenantModels[model][i].TenantFName} ${tenantModels[model][i].TenantLName}`,
                        },
                      };
                      history.push(location);
                    }}
                  >
                    <Edit />
                  </IconButton>
                ),
                prelease: (
                  <td className="text-center">
                    <IconButton
                      aria-label="Edit"
                      onClick={() => {
                        const location = {
                          pathname: "/preLease",
                          state: {
                            unitID: tenantModels[model][i].unit.UnitID,
                          },
                        };
                        history.push(location);
                      }}
                    >
                      <AssignmentIndOutlined style={{ color: "#228B22" }} />
                    </IconButton>
                  </td>
                ),
              });
            }
          }
        }
      }
      if (reportLeaseOccup.length > 0 || reportLeaseVacant.length > 0) {
        for (let model = 0; model < preleaseModels.length; model++) {
          for (let i = 0; i < preleaseModels[model].length; i++) {
            if (preleaseModels[model] === reportLeaseOccup) {
              console.log("prelease models", preleaseModels[model]);
              leaseOccupData.push({
                exTenant: (
                  <Button
                    aria-label="Print"
                    onClick={() => {
                      const location = {
                        pathname: "/tenants/editTenant",
                        state: {
                          tenantID: preleaseModels[model][i].unit.tenants[0].id,
                          tenantName: `${preleaseModels[model][i].unit.tenants[0].TenantFName} ${preleaseModels[model][i].unit.tenants[0].TenantLName}`,
                        },
                      };
                      history.push(location);
                    }}
                  >
                    {preleaseModels[model][i].unit.tenants[0].TenantFName +
                      " " +
                      preleaseModels[model][i].unit.tenants[0].TenantLName}
                  </Button>
                ),
                newTenant: (
                  <Button
                    aria-label="Print"
                    onClick={() => {
                      const location = {
                        pathname: "/tenants/editTenant",
                        state: {
                          tenantID: preleaseModels[model][i].tenant.id,
                          tenantName: `${preleaseModels[model][i].tenant.TenantFName} ${preleaseModels[model][i].tenant.TenantLName}`,
                        },
                      };
                      history.push(location);
                    }}
                  >
                    {preleaseModels[model][i].tenant.TenantFName +
                      " " +
                      preleaseModels[model][i].tenant.TenantLName}
                  </Button>
                ),
                unit: preleaseModels[model][i].unit.UnitName,
                unitType: preleaseModels[model][i].unit.unittype.UnitType,
                moveInDate: formatDate(preleaseModels[model][i].PMoveInDate),
                days: Math.floor(
                  (new Date().getTime() -
                    new Date(
                      preleaseModels[model][i].unit.tenants[0].MoveInDate
                    ).getTime()) /
                    (24 * 60 * 60 * 1000)
                ),
                mktRent: currencyFormat(
                  +preleaseModels[model][i].unit.unittype.UnitCharge
                ),
                comments: preleaseModels[model][i].tenant.ProspectComments,
                edit: (
                  <IconButton
                    aria-label="Print"
                    onClick={() => {
                      const location = {
                        pathname: "/tenants/editTenant",
                        state: {
                          tenantID: preleaseModels[model][i].tenant.TenantID,
                          tenantName: `${preleaseModels[model][i].tenant.TenantFName} ${preleaseModels[model][i].tenant.TenantLName}`,
                        },
                      };
                      history.push(location);
                    }}
                  >
                    <Edit />
                  </IconButton>
                ),
                remove: (
                  <IconButton
                    aria-label="Delete"
                    onClick={() =>
                      deletePrelease(preleaseModels[model][i].PreLeasedID)
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                ),
              });
            }
            if (preleaseModels[model] === reportLeaseVacant) {
              leaseVacantData.push({
                tenant:
                  preleaseModels[model][i].tenant.TenantFName +
                  " " +
                  preleaseModels[model][i].tenant.TenantLName,
                unit: preleaseModels[model][i].unit.UnitName,
                unitType: preleaseModels[model][i].unit.unittype.UnitType,
                moveInDate: formatDate(preleaseModels[model][i].PMoveInDate),
                days: Math.floor(
                  (new Date().getTime() -
                    new Date(preleaseModels[model][i].PMoveInDate).getTime()) /
                    (24 * 60 * 60 * 1000)
                ),
                mktRent: currencyFormat(
                  +preleaseModels[model][i].unit.unittype.UnitCharge
                ),
                comments: preleaseModels[model][i].tenant.ProspectComments,
                edit: (
                  <IconButton
                    aria-label="Print"
                    onClick={() => {
                      const location = {
                        pathname: "/tenants/editTenant",
                        state: {
                          tenantID: preleaseModels[model][i].tenant.TenantID,
                          tenantName: `${preleaseModels[model][i].tenant.TenantFName} ${preleaseModels[model][i].tenant.TenantLName}`,
                        },
                      };
                      history.push(location);
                    }}
                  >
                    <Edit />
                  </IconButton>
                ),
                remove: (
                  <IconButton
                    aria-label="Delete"
                    onClick={() =>
                      deletePrelease(preleaseModels[model][i].PreLeasedID)
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                ),
              });
            }
          }
        }
      }
      listData.push({ tenant: "TOTAL", unit: reportListingVacant.length });
      noticeData.push({ tenant: "TOTAL", unit: reportNotice.length });
      evictionsData.push({ tenant: "TOTAL", unit: reportEvictions.length });
      leaseOccupData.push({
        exTenant: "TOTAL",
        newTenant: reportLeaseOccup.length,
      });
      leaseVacantData.push({
        tenant: "TOTAL",
        unit: reportLeaseVacant.length,
      });
      setLoading(false);
    }
    fetchData();
  }, []);
  if (loading) {
    return (
      <RctCollapsibleCard
        colClasses="col-xs-12 col-sm-12 col-md-12"
        heading={"Loading Reports..."}
      >
        <LinearProgress />
      </RctCollapsibleCard>
    );
  }
  const unitColumns = [
    { name: "tenant", label: "Tenant" },
    { name: "unit", label: "Unit" },
    { name: "unitType", label: "Unit Type" },
    { name: "moveOutDate", label: "Move Out Date" },
    { name: "days", label: "Days" },
    { name: "mktRent", label: "Mkt Rent" },
    { name: "comments", label: "Comments" },
    { name: "edit", label: "Edit" },
    { name: "prelease", label: "Prelease" },
  ];
  const preleaseOccupColumns = [
    { name: "exTenant", label: "Existing Tenant" },
    { name: "newTenant", label: "New Tenant" },
    { name: "unit", label: "Unit" },
    { name: "unitType", label: "Unit Type" },
    { name: "moveInDate", label: "Move In Date" },
    { name: "days", label: "Days" },
    { name: "mktRent", label: "Mkt Rent" },
    { name: "comments", label: "Comments" },
    { name: "edit", label: "Edit" },
    { name: "remove", label: "Remove" },
  ];
  const preleaseUnitColumns = [
    { name: "tenant", label: "Tenant" },
    { name: "unit", label: "Unit" },
    { name: "unitType", label: "Unit Type" },
    { name: "moveInDate", label: "Move In Date" },
    { name: "days", label: "Days" },
    { name: "mktRent", label: "Mkt Rent" },
    { name: "comments", label: "Comments" },
    { name: "edit", label: "Edit" },
    { name: "remove", label: "Remove" },
  ];
  return (
    <div>
      <div className="page-title d-flex justify-content-between align-items-center">
        <div className="page-title-wrap">
          <h2>
            <span>{props.reportName + " - " + filteredName.PropertyName}</span>
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-xl-12">
          <MuiThemeProvider theme={Constants.getMuiTheme()}>
            <MUIDataTable
              title={"Unit Availability Listing"}
              data={listData}
              columns={unitColumns}
              options={props.reportOptions}
            />
          </MuiThemeProvider>
        </div>
        <div className="col-sm-12 col-md-12 col-xl-12">
          <MuiThemeProvider theme={Constants.getMuiTheme()}>
            <MUIDataTable
              title={"Tenant Gave Notice"}
              data={noticeData}
              columns={unitColumns}
              options={props.reportOptions}
            />
          </MuiThemeProvider>
        </div>
        <div className="col-sm-12 col-md-12 col-xl-12">
          <MuiThemeProvider theme={Constants.getMuiTheme()}>
            <MUIDataTable
              title={"Evictions"}
              data={evictionsData}
              columns={unitColumns}
              options={props.reportOptions}
            />
          </MuiThemeProvider>
        </div>
        <div className="col-sm-12 col-md-12 col-xl-12">
          <MuiThemeProvider theme={Constants.getMuiTheme()}>
            <MUIDataTable
              title={"Pre Lease (Occupied)"}
              data={leaseOccupData}
              columns={preleaseOccupColumns}
              options={props.reportOptions}
            />
          </MuiThemeProvider>
        </div>
        <div className="col-sm-12 col-md-12 col-xl-12">
          <MuiThemeProvider theme={Constants.getMuiTheme()}>
            <MUIDataTable
              title={"Pre Lease (Vacant)"}
              data={leaseVacantData}
              columns={preleaseUnitColumns}
              options={props.reportOptions}
            />
          </MuiThemeProvider>
        </div>
      </div>
    </div>
  );
};

export default UnitAvailList;
