import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import * as Constants from "../Util/constants";
import * as reportsAPI from "../../Api/reports";
import { currencyFormat, formatDate } from "./helperFunctions";
import RctCollapsibleCard from "../Helpers/RctCollapsibleCard/RctCollapsibleCard";
import LinearProgress from "../Util/LinearProgress";

const CreditCheck = (props) => {
  const [loading, setLoading] = useState(false);
  const [creditData, setConcessionData] = useState([]);
  var [total, setTotal] = useState(0);
  const propertyName = useSelector((state) => state.login.properties);
  const [filteredName] = propertyName.filter(
    (property) => property.PropertyID === props.propertyId
  );
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const reportDetails = await reportsAPI.getCreditCheckLog(
        props.reportSearch
      );
      const reportCosts = await reportsAPI.getReportCosts();
      for (let i = 0; i < reportDetails.length; i++) {
        if (reportDetails[i].TenantID !== 0) {
          creditData.push({
            tenant: reportDetails[i].tenant
              ? reportDetails[i].tenant.TenantFName +
                " " +
                reportDetails[i].tenant.TenantLName
              : " ",
            reportRun: formatDate(reportDetails[i].SubmittedOn.slice(0, 10)),
            reportId: +reportDetails[i].id,
            cost: currencyFormat(+reportCosts[0].Credit),
          });
        }
      }
      creditData.push({
        tenant: "TOTAL",
        cost: currencyFormat(reportDetails.length * +reportCosts[0].Credit),
      });
      setLoading(false);
    }
    fetchData();
  }, []);
  if (loading) {
    return (
      <RctCollapsibleCard
        colClasses="col-xs-12 col-sm-12 col-md-12"
        heading={"Loading Reports..."}
      >
        <LinearProgress />
      </RctCollapsibleCard>
    );
  }
  const creditColumns = [
    { name: "tenant", label: "Tenant" },
    { name: "reportRun", label: "Report Run" },
    { name: "reportId", label: "Report ID" },
    { name: "cost", label: "Cost" },
  ];
  return (
    <div>
      <div className="page-title d-flex justify-content-between align-items-center">
        <div className="page-title-wrap">
          <h2>
            <span>
              {props.reportName +
                " - " +
                filteredName.PropertyName +
                " - Date Range of Report: " +
                props.startDate +
                " to " +
                props.endDate}
            </span>
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-xl-12">
          <MuiThemeProvider theme={Constants.getMuiTheme()}>
            <MUIDataTable
              title={"Credit Check Runs"}
              data={creditData}
              columns={creditColumns}
              options={props.reportOptions}
            />
          </MuiThemeProvider>
        </div>
      </div>
    </div>
  );
};

export default CreditCheck;
