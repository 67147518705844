import axios from 'axios';
import * as Constants from '../components/Util/constants';

const URL = Constants.REQ_URL;

export const getHelpVideos = async () => {
    let output = [];
    await axios.get(`${URL}/help/getHelpVideos`)
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const addHelpVideo = async (data) => {
    let output = [];
    await axios.post(`${URL}/help/addHelpVideo`, { ...data })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const updateHelpVideo = async (data) => {
    let output = [];
    await axios.patch(`${URL}/help/updateHelpVideo`, { ...data })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const deleteVideo = async (id) => {
    let output = [];
    await axios.delete(`${URL}/help/deleteHelpVideo?id=${id}`)
        .then((res) => {
            output = res.data;
        });
    return output;
};