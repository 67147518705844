import { createMuiTheme } from '@material-ui/core/styles';

// export const REQ_URL = 'http://localhost:3006';
export const REQ_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:3006' : 'https://myirent.com/NodeJS/iRent';
export const DEFAULT_ERROR = 'Error processing your request. Please contact us.';
export const TINYMCEKEY = '771zhy8nf9edmn3yv4nh8hgsgxk8sw3bs20ljmehxp2fu7wx';

export const usStates = [
    { name: 'ALABAMA', abbreviation: 'AL' },
    { name: 'ALASKA', abbreviation: 'AK' },
    { name: 'AMERICAN SAMOA', abbreviation: 'AS' },
    { name: 'ARIZONA', abbreviation: 'AZ' },
    { name: 'ARKANSAS', abbreviation: 'AR' },
    { name: 'CALIFORNIA', abbreviation: 'CA' },
    { name: 'COLORADO', abbreviation: 'CO' },
    { name: 'CONNECTICUT', abbreviation: 'CT' },
    { name: 'DELAWARE', abbreviation: 'DE' },
    { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC' },
    { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM' },
    { name: 'FLORIDA', abbreviation: 'FL' },
    { name: 'GEORGIA', abbreviation: 'GA' },
    { name: 'GUAM', abbreviation: 'GU' },
    { name: 'HAWAII', abbreviation: 'HI' },
    { name: 'IDAHO', abbreviation: 'ID' },
    { name: 'ILLINOIS', abbreviation: 'IL' },
    { name: 'INDIANA', abbreviation: 'IN' },
    { name: 'IOWA', abbreviation: 'IA' },
    { name: 'KANSAS', abbreviation: 'KS' },
    { name: 'KENTUCKY', abbreviation: 'KY' },
    { name: 'LOUISIANA', abbreviation: 'LA' },
    { name: 'MAINE', abbreviation: 'ME' },
    { name: 'MARSHALL ISLANDS', abbreviation: 'MH' },
    { name: 'MARYLAND', abbreviation: 'MD' },
    { name: 'MASSACHUSETTS', abbreviation: 'MA' },
    { name: 'MICHIGAN', abbreviation: 'MI' },
    { name: 'MINNESOTA', abbreviation: 'MN' },
    { name: 'MISSISSIPPI', abbreviation: 'MS' },
    { name: 'MISSOURI', abbreviation: 'MO' },
    { name: 'MONTANA', abbreviation: 'MT' },
    { name: 'NEBRASKA', abbreviation: 'NE' },
    { name: 'NEVADA', abbreviation: 'NV' },
    { name: 'NEW HAMPSHIRE', abbreviation: 'NH' },
    { name: 'NEW JERSEY', abbreviation: 'NJ' },
    { name: 'NEW MEXICO', abbreviation: 'NM' },
    { name: 'NEW YORK', abbreviation: 'NY' },
    { name: 'NORTH CAROLINA', abbreviation: 'NC' },
    { name: 'NORTH DAKOTA', abbreviation: 'ND' },
    { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP' },
    { name: 'OHIO', abbreviation: 'OH' },
    { name: 'OKLAHOMA', abbreviation: 'OK' },
    { name: 'OREGON', abbreviation: 'OR' },
    { name: 'PALAU', abbreviation: 'PW' },
    { name: 'PENNSYLVANIA', abbreviation: 'PA' },
    { name: 'PUERTO RICO', abbreviation: 'PR' },
    { name: 'RHODE ISLAND', abbreviation: 'RI' },
    { name: 'SOUTH CAROLINA', abbreviation: 'SC' },
    { name: 'SOUTH DAKOTA', abbreviation: 'SD' },
    { name: 'TENNESSEE', abbreviation: 'TN' },
    { name: 'TEXAS', abbreviation: 'TX' },
    { name: 'UTAH', abbreviation: 'UT' },
    { name: 'VERMONT', abbreviation: 'VT' },
    { name: 'VIRGIN ISLANDS', abbreviation: 'VI' },
    { name: 'VIRGINIA', abbreviation: 'VA' },
    { name: 'WASHINGTON', abbreviation: 'WA' },
    { name: 'WEST VIRGINIA', abbreviation: 'WV' },
    { name: 'WISCONSIN', abbreviation: 'WI' },
    { name: 'WYOMING', abbreviation: 'WY' },
];

export const themeColors = {
    primary: '#5D92F4',
    secondary: '#677080',
    success: '#00D014',
    danger: '#FF3739',
    warning: '#FFB70F',
    info: '#00D0BD',
    dark: '#464D69',
    default: '#FAFAFA',
    greyLighten: '#A5A7B2',
    grey: '#677080',
    white: '#FFFFFF',
    purple: '#896BD6',
    yellow: '#D46B08',
};

// dark theme colors
export const darkThemeColors = {
    darkBgColor: '#424242',
};

const { primary, info, danger, success, warning, purple, secondary, yellow, white, greyLighten, grey } = themeColors;
export const ChartConfig = {
    color: {
        primary: primary,
        info: info,
        warning: warning,
        danger: danger,
        success: success,
        default: '#DEE4E8',
        purple: purple,
        secondary: secondary,
        yellow: yellow,
        white: '#FFFFFF',
        dark: white,
        greyLighten: greyLighten,
        grey: grey,
    },
    legendFontColor: '#AAAEB3', // only works on react chart js 2
    chartGridColor: '#EAEAEA',
    axesColor: '#657786',
    shadowColor: 'rgba(0,0,0,0.6)',
};

// Tooltip Styles
export const tooltipStyle = {
    backgroundColor: 'rgba(0,0,0,0.6)',
    border: '1px solid rgba(0,0,0,0.6)',
    borderRadius: '5px',
};

export const tooltipTextStyle = {
    color: '#FFF',
    fontSize: '12px',
    paddingTop: '5px',
    paddingBottom: '5px',
    lineHeight: '1',
};

export const getMuiTheme = () =>
    createMuiTheme({
        overrides: {
            MuiTableCell: {
                head: {
                    backgroundColor: '#c1e1ec !important',
                },
                stickyHeader: true,
            },
        },
    });

export const margins = {
    marginTop: '10px',
    marginBottom: '30px',
    marginLeft: '10px',
    marginRight: '10px',
};

export const signatureFonts = [
    { name: 'Aguafina Script', value: 'Aguafina Script' },
    { name: 'Alex Brush', value: 'Alex Brush' },
    { name: 'Bilbo', value: 'Bilbo' },
    { name: 'Condiment', value: 'Condiment' },
    { name: 'Great Vibes', value: 'Great Vibes' },
    { name: 'Herr Von Muellerhoff', value: 'Herr Von Muellerhoff' },
    { name: 'Kristi', value: 'Kristi' },
    { name: 'Meddon', value: 'Meddon' },
    { name: 'Monsieur La Doulaise', value: 'Monsieur La Doulaise' },
    { name: 'Norican', value: 'Norican' },
    { name: 'Parisienne', value: 'Parisienne' },
    { name: 'Permanent Marker', value: 'Permanent Marker' },
    { name: 'Sacramento', value: 'Sacramento' },
    { name: 'Yellowtail', value: 'Yellowtail' },
];

export const workHours = [
    { value: -1, label: 'Closed' },
    { value: 0, label: '12:00 AM' },
    { value: 1, label: '1:00 AM' },
    { value: 2, label: '2:00 AM' },
    { value: 3, label: '3:00 AM' },
    { value: 4, label: '4:00 AM' },
    { value: 5, label: '5:00 AM' },
    { value: 6, label: '6:00 AM' },
    { value: 7, label: '7:00 AM' },
    { value: 8, label: '8:00 AM' },
    { value: 9, label: '9:00 AM' },
    { value: 10, label: '10:00 AM' },
    { value: 11, label: '11:00 AM' },
    { value: 12, label: '12:00 PM' },
    { value: 13, label: '1:00 PM' },
    { value: 14, label: '2:00 PM' },
    { value: 15, label: '3:00 PM' },
    { value: 16, label: '4:00 PM' },
    { value: 17, label: '5:00 PM' },
    { value: 18, label: '6:00 PM' },
    { value: 19, label: '7:00 PM' },
    { value: 20, label: '8:00 PM' },
    { value: 21, label: '9:00 PM' },
    { value: 22, label: '10:00 PM' },
    { value: 23, label: '11:00 PM' },
];

export const Utilities = ['Gas', 'Electricity', 'Water', 'Trash', 'TV', 'Internet'];

export const UserAccess = {
    Home: [1, 2, 4, 5, 6, 7, 8],
    Login: [1, 2, 4, 5, 6, 7, 8],
    OwesMoney: [1, 2, 4, 5, 6, 7, 8],
    EditMoveOutDate: [1, 2, 5, 6],
    EditNote: [1, 2, 5, 6],
    PreLease: [1, 2, 4, 5, 6],
    EditActionItem: [1, 2, 5, 6],
    EditPromissToPay: [1, 2, 5, 6],
    EditDelinquencyComment: [1, 2, 5, 6],
    Profile: [1, 2, 4, 5, 6, 7],
    CompanyDetails: [1, 5],
    ExpenseTypes: [1, 5],
    JournalEntry: [1],
    EditJournal: [1],
    MakeReadyTask: [1, 2, 5, 6],
    EditMakeReadyTask: [1, 2, 5, 6],
    FormsCreator: [1, 5],
    GLCategories: [1],
    CorporateDocs: [1, 8],
    BudgetCreator: [1, 5],
    EditBudget: [1, 5],
    Units: [1, 5],
    EditUnit: [1, 5],
    UploadFiles: [1, 2, 5, 6],
    PropertyRules: [1, 5],
    ViewAllProperties: [1, 2, 4, 5, 6, 7],
    EditProperty: [1, 2, 5],
    PaymentVendor: [1, 2, 5, 6],
    PropertyWizard: [1],
    ViewAllUsers: [1, 2, 5],
    AddUser: [1],
    EditUser: [1],
    ViewAllOwners: [1],
    AddOwner: [1],
    EditOwner: [1],
    CheckRegister: [1, 2, 5, 6],
    EditCheckRegister: [1, 2, 5],
    Reconcile: [1],
    UnpaidBills: [1, 2, 5, 6],
    PaidBills: [1, 2, 5, 6],
    RecurringBills: [1],
    EditBill: [1, 2, 5, 6],
    PrintBill: [1, 2, 5, 6],
    EditRecurringBill: [1],
    Deposits: [1, 2, 5, 6],
    EditDeposit: [1, 2, 5, 6],
    AddLender: [1],
    History: [1, 2, 5, 6],
    EditDeposirHistory: [1, 2, 5, 6],
    EnterTenantCCPayment: [1, 2, 5, 6],
    ViewAll: [1, 2, 5, 6],
    TenantDetails: [1, 2, 4, 5, 6],
    AddLeaseHolder: [1, 2, 4, 5, 6],
    AddVehicle: [1, 2, 4, 5, 6],
    EditTenant: [1, 2, 4, 5, 6],
    TransferTenant: [1, 2, 4, 5, 6],
    AddTenant: [1, 2, 4, 5, 6],
    PreviousTenants: [1, 2, 4, 5, 6],
    ReconcilePrevious: [1, 2, 5],
    ApplyAdditionalFee: [1, 2, 5, 6],
    ApplyAdditionalUtilityCharge: [1, 2, 5, 6],
    ApplyElectricCharge: [1, 2, 5, 6],
    RequestConcession: [1, 2, 5, 6],
    NotifyAllTenants: [1, 2, 5, 6],
    EmailLeaseAgreements: [1, 5],
    ViewProspects: [1, 2, 4, 5, 6],
    EditProspectApplicant: [1, 2, 4, 5, 6],
    ConvertToApplicant: [1, 2, 4, 5, 6],
    AddProspectApplicant: [1, 2, 4, 5, 6],
    LeadSource: [1, 2, 4, 5, 6],
    ViewApplicants: [1, 2, 4, 5, 6],
    ViewReport: [1, 2, 5, 6],
    AddApplicantLeaseHolder: [1, 2, 4, 5, 6],
    ConvertToTenant: [1, 2, 5],
    RunBackgroundScreening: [1, 2, 5],
    SignUpBackgroundScreening: [1, 2, 5],
    ViewApplication: [1, 2, 4, 5, 6],
    DeniedApplicants: [1, 2, 4, 5, 6],
    AccountSummaryTenantStatement: [1, 2, 4, 5, 6],
    ZeroBalanceSummaryTeanantStatement: [1, 2, 4, 5, 6],
    ViewWorkOrder: [1, 2, 4, 5, 6, 7],
    PrintTenantLedger: [1, 2, 4, 5, 6],
    OpenForm: [1, 2, 4, 5, 6],
    DepositSlip: [1, 2, 5, 6],
    AllTenantsStatements: [1, 2, 5, 6],
    AllDeliquentStatements: [1, 2, 5, 6],
    AddWorkOrder: [1, 2, 4, 5, 6],
    EditWorkOrder: [1, 2, 4, 5, 6, 7],
    ClosedWorkOrders: [1, 2, 4, 5, 6, 7],
    RecurringWorkOrders: [1, 2, 4, 5, 6, 7],
    UpdateRecurring: [1, 2, 4, 5, 6, 7],
    MakeReadies: [1, 2, 4, 5, 6, 7],
    DepositBreakDown: [1, 2, 5, 6],
    EditTransaction: [1, 2, 5, 6],
    Vendors: [1, 2, 5, 6],
    EditVendor: [1, 2, 5, 6],
    Reports: [1, 2, 5, 6, 8],
    SearchResults: [1, 2, 5, 6, 8],
    TestReports: [1, 2, 5, 6, 8],
    MarketingText: [1, 2, 5, 6],
    PostVacancies: [1, 2, 5, 6],
    VideoTutorial: [1, 2, 4, 5, 6, 7],
    UserGuide: [1, 2, 4, 5, 6, 7],
    LicenseAgreement: [1, 2, 4, 5, 6, 7],
    ProductSupport: [1, 2, 4, 5, 6, 7],
    FAQ: [1, 2, 4, 5, 6, 7],
    RequestHistory: [1, 2, 4, 5, 6, 7],
    EditRequestHistory: [1, 2, 4, 5, 6, 7],
    EnhancementRequest: [1, 2, 4, 5, 6, 7],
    ForceLogOut: [1, 2, 4, 5, 6, 7, 8],
    IntForms: [1, 2, 4, 5, 6],
    CronJobs: [1],
    RegisteredCompanies: [1],
};

export const Components = [
    {
        ComponentID: 1,
        ComponentName: 'Home',
        ParentComponent: null,
        SecurityLevel: [1, 2, 4, 5, 6, 7, 8],
    },
    {
        ComponentID: 2,
        ComponentName: 'Vacancy Snapshot',
        ParentComponent: 'Home',
        SecurityLevel: [1, 2, 4, 5, 6, 7, 8],
    },
    {
        ComponentID: 3,
        ComponentName: 'Work Orders Snapshot',
        ParentComponent: 'Home',
        SecurityLevel: [1, 2, 4, 5, 6, 7, 8],
    },
    {
        ComponentID: 4,
        ComponentName: 'Concession Requests',
        ParentComponent: 'Home',
        SecurityLevel: [1, 2, 4, 5, 6, 8],
    },
    {
        ComponentID: 5,
        ComponentName: 'Concession Request Review/Approve',
        ParentComponent: 'Home',
        SecurityLevel: [1, 5],
    },
    {
        ComponentID: 6,
        ComponentName: 'Lease Audit Snapshot',
        ParentComponent: 'Home',
        SecurityLevel: [1, 2, 4, 5, 6, 8],
    },
    {
        ComponentID: 7,
        ComponentName: 'Security Deposit Refunds to Process',
        ParentComponent: 'Home',
        SecurityLevel: [1, 2, 4, 5, 6, 8],
    },
    {
        ComponentID: 8,
        ComponentName: 'Missed Promise to Pay',
        ParentComponent: 'Home',
        SecurityLevel: [1, 2, 4, 5, 6, 8],
    },
    {
        ComponentID: 9,
        ComponentName: 'Delinquency Snapshot',
        ParentComponent: 'Home',
        SecurityLevel: [1, 2, 5, 6],
    },
    {
        ComponentID: 10,
        ComponentName: 'Delinquencies over $X',
        ParentComponent: 'Home',
        SecurityLevel: [1, 2, 5, 6],
    },
    {
        ComponentID: 11,
        ComponentName: 'P&L Snapshot',
        ParentComponent: 'Home',
        SecurityLevel: [1, 2, 5, 6],
    },
    {
        ComponentID: 12,
        ComponentName: 'Action Items',
        ParentComponent: 'Home',
        SecurityLevel: [1, 2, 5, 6, 7],
    },
    {
        ComponentID: 13,
        ComponentName: 'Missing Phone and Emails',
        ParentComponent: 'Home',
        SecurityLevel: [1, 2, 5, 6],
    },
    // Category1
    {
        ComponentID: 14,
        ComponentName: 'Company',
        ParentComponent: null,
        menu_title: 'sidebar.company',
        SecurityLevel: [1],
    },
    {
        ComponentID: 141,
        ComponentName: 'Company Details',
        path: '/company/details',
        ParentComponent: 'Company',
        menu_title: 'sidebar.companyDetails',
        SecurityLevel: [1],
    },
    {
        ComponentID: 142,
        path: '/company/expenseTypes',
        ComponentName: 'Expense Types',
        ParentComponent: 'Company',
        menu_title: 'sidebar.expenseTypes',
        SecurityLevel: [1],
    },
    {
        ComponentID: 143,
        path: '/company/journalEntry',
        ComponentName: 'Journal Entries',
        ParentComponent: 'Company',
        menu_title: 'sidebar.journalEntries',
        SecurityLevel: [1],
    },
    {
        ComponentID: 144,
        path: '/company/corporateDocs',
        ComponentName: 'Corporate Documents',
        ParentComponent: 'Company',
        menu_title: 'sidebar.corporateDocs',
        SecurityLevel: [1],
    },
    {
        ComponentID: 145,
        path: '/company/makeReadyTask',
        ComponentName: 'Make Ready Tasks',
        ParentComponent: 'Company',
        menu_title: 'sidebar.makeReadyTasks',
        SecurityLevel: [1],
    },
    {
        ComponentID: 146,
        path: '/company/formsCreator',
        ComponentName: 'Form Creator',
        ParentComponent: 'Company',
        menu_title: 'sidebar.formCreator',
        SecurityLevel: [1],
    },
    {
        ComponentID: 147,
        path: '/company/budgetCreator',
        ComponentName: 'Budget Creator',
        ParentComponent: 'Company',
        menu_title: 'sidebar.budgetCreator',
        SecurityLevel: [1],
    },
    {
        ComponentID: 148,
        path: '/company/glCategories',
        ComponentName: 'GL Categories',
        ParentComponent: 'Company',
        menu_title: 'sidebar.glCategories',
        SecurityLevel: [1],
    },

    {
        ComponentID: 15,
        ComponentName: 'Properties',
        ParentComponent: null,
        menu_title: 'sidebar.properties',
        SecurityLevel: [1],
    },
    {
        ComponentID: 151,
        path: '/properties/viewall',
        ComponentName: 'View All',
        ParentComponent: 'Properties',
        menu_title: 'sidebar.viewProperties',
        SecurityLevel: [1],
    },
    {
        ComponentID: 152,
        path: '/properties/rules',
        ComponentName: 'Property Rules',
        ParentComponent: 'Properties',
        menu_title: 'sidebar.propertyRules',
        SecurityLevel: [1],
    },
    {
        ComponentID: 153,
        path: '/properties/wizard',
        ComponentName: 'Property Wizard',
        ParentComponent: 'Properties',
        menu_title: 'sidebar.propertyWizard',
        SecurityLevel: [1],
    },

    {
        ComponentID: 16,
        ComponentName: 'Users',
        menu_title: 'sidebar.users',
        ParentComponent: null,
        SecurityLevel: [1],
    },
    {
        ComponentID: 161,
        ComponentName: 'View All',
        menu_title: 'sidebar.viewUsers',
        path: '/users/viewAll',
        ParentComponent: 'Users',
        SecurityLevel: [1],
    },

    {
        ComponentID: 17,
        ComponentName: 'Owners',
        menu_title: 'sidebar.owners',
        ParentComponent: null,
        SecurityLevel: [1],
    },
    {
        ComponentID: 171,
        path: '/owners/viewAll',
        menu_title: 'sidebar.viewOwners',
        ComponentName: 'View All',
        ParentComponent: 'Owners',
        SecurityLevel: [1],
    },

    {
        ComponentID: 18,
        ComponentName: 'Units',
        menu_title: 'sidebar.untis',
        ParentComponent: null,
        SecurityLevel: [1],
    },
    {
        ComponentID: 181,
        menu_title: 'sidebar.viewUnits',
        ComponentName: 'Units',
        path: '/units',
        ParentComponent: 'Units',
        SecurityLevel: [1],
    },

    {
        ComponentID: 19,
        menu_title: 'sidebar.checkRegister',
        ComponentName: 'Check Register',
        path: '/checkRegister',
        ParentComponent: null,
        SecurityLevel: [1],
    },
    {
        ComponentID: 20,
        menu_title: 'sidebar.bills',
        ComponentName: 'Bills',
        ParentComponent: null,
        SecurityLevel: [1, 2, 4, 5, 6, 8],
    },
    {
        ComponentID: 201,
        menu_title: 'sidebar.unpaidBills',
        path: '/bills/unpaidBills',
        ComponentName: 'Unpaid Bills',
        ParentComponent: 'Bills',
        SecurityLevel: [1, 2, 4, 5, 6, 8],
    },
    {
        ComponentID: 202,
        menu_title: 'sidebar.paidBills',
        path: '/bills/paidBills',
        ComponentName: 'Paid Bills',
        ParentComponent: 'Bills',
        SecurityLevel: [1, 2, 4, 5, 6, 8],
    },
    {
        ComponentID: 203,
        menu_title: 'sidebar.recurringBills',
        path: '/bills/recurring',
        ComponentName: 'Recurring Bills',
        ParentComponent: 'Bills',
        SecurityLevel: [1, 2, 4, 5, 6, 8],
    },
    {
        ComponentID: 21,
        menu_title: 'sidebar.deposits',
        ComponentName: 'Deposits',
        ParentComponent: null,
        SecurityLevel: [1, 2, 4, 5, 6, 8],
    },
    {
        ComponentID: 211,
        menu_title: 'sidebar.currentDeposit',
        path: '/deposits',
        ComponentName: 'Current Deposit',
        ParentComponent: 'Deposits',
        SecurityLevel: [1, 2, 4, 5, 6, 8],
    },
    {
        ComponentID: 212,
        menu_title: 'sidebar.depositHistory',
        path: '/deposits/history',
        ComponentName: 'Deposit History',
        ParentComponent: 'Deposits',
        SecurityLevel: [1, 2, 4, 5, 6, 8],
    },
    {
        ComponentID: 213,
        menu_title: 'sidebar.enterCCPayment',
        path: '/deposits/enterCCPayment',
        ComponentName: 'Enter CCPayment',
        ParentComponent: 'Deposits',
        SecurityLevel: [1, 2, 4, 5, 6, 8],
    },

    {
        ComponentID: 22,
        menu_title: 'sidebar.vendors',
        path: '/vendor',
        ComponentName: 'Vendors',
        ParentComponent: null,
        SecurityLevel: [1, 2, 4, 5, 6, 7, 8],
    },

    {
        ComponentID: 23,
        menu_title: 'sidebar.applicants',
        ComponentName: 'Applicants',
        ParentComponent: null,
        SecurityLevel: [1, 2, 4, 5, 6, 8],
    },
    {
        ComponentID: 231,
        path: '/prospects/viewAll',
        menu_title: 'sidebar.viewProspects',
        ComponentName: 'View Prospects',
        ParentComponent: 'Applicants',
        SecurityLevel: [1, 2, 4, 5, 6, 8],
    },
    {
        ComponentID: 232,
        path: '/applicants/viewAll',
        menu_title: 'sidebar.viewApplicants',
        ComponentName: 'View Applicants',
        ParentComponent: 'Applicants',
        SecurityLevel: [1, 2, 4, 5, 6, 8],
    },
    {
        ComponentID: 233,
        path: '/prospects/add',
        menu_title: 'sidebar.addApplicant',
        ComponentName: 'Add Applicant',
        ParentComponent: 'Applicants',
        SecurityLevel: [1, 2, 4, 5, 6, 8],
    },
    {
        ComponentID: 234,
        path: '/applicants/denied',
        menu_title: 'sidebar.deniedApplicants',
        ComponentName: 'Denied Applicants',
        ParentComponent: 'Applicants',
        SecurityLevel: [1, 2, 4, 5, 6, 8],
    },

    {
        ComponentID: 24,
        menu_title: 'sidebar.tenants',
        ComponentName: 'Tenants',
        ParentComponent: null,
        SecurityLevel: [1, 2, 4, 5, 6, 8],
    },
    {
        ComponentID: 241,
        path: '/tenants/viewAll',
        menu_title: 'sidebar.viewAllTenants',
        ComponentName: 'View All',
        ParentComponent: 'Tenants',
        SecurityLevel: [1, 2, 4, 5, 6, 8],
    },
    {
        ComponentID: 242,
        path: '/tenants/add',
        menu_title: 'sidebar.addTenant',
        ComponentName: 'Add Tenant',
        ParentComponent: 'Tenants',
        SecurityLevel: [1, 2, 4, 5, 6, 8],
    },
    {
        ComponentID: 243,
        path: '/tenants/previous',
        menu_title: 'sidebar.previousTenants',
        ComponentName: 'Previous Tenants',
        ParentComponent: 'Tenants',
        SecurityLevel: [1, 2, 4, 5, 6, 8],
    },
    {
        ComponentID: 244,
        path: '/tenants/addFee',
        menu_title: 'sidebar.applyAdditionalFee',
        ComponentName: 'Apply Additional Fee',
        ParentComponent: 'Tenants',
        SecurityLevel: [1, 2, 4, 5, 6, 8],
    },
    {
        ComponentID: 245,
        path: '/tenants/addUtilityCharge',
        menu_title: 'sidebar.enterAdditionalUtilityCharge',
        ComponentName: 'Additional Utility Charge',
        ParentComponent: 'Tenants',
        SecurityLevel: [1, 2, 4, 5, 6, 8],
    },
    {
        ComponentID: 2455,
        path: '/tenants/addElectricCharge',
        menu_title: 'sidebar.enterElectricCharge',
        ComponentName: 'Apply Electric Charge',
        ParentComponent: 'Tenants',
        SecurityLevel: [1, 2, 4, 5, 6, 8],
    },
    {
        ComponentID: 246,
        path: '/tenants/reqConcession',
        menu_title: 'sidebar.requestConcession',
        ComponentName: 'Request Concession',
        ParentComponent: 'Tenants',
        SecurityLevel: [1, 2, 4, 5, 6, 8],
    },
    {
        ComponentID: 247,
        path: '/tenants/reconcilePrevious',
        menu_title: 'sidebar.reconcilePreviousTenants',
        ComponentName: 'Reconcile Previous Tenants',
        ParentComponent: 'Tenants',
        SecurityLevel: [1, 2, 4, 5, 6, 8],
    },
    {
        ComponentID: 248,
        path: '/printable/',
        menu_title: 'sidebar.tenantsPrintableDocs',
        ComponentName: 'Tenants Printable Docs',
        ParentComponent: 'Tenants',
        SecurityLevel: [1, 2, 4, 5, 6, 8],
    },
    {
        ComponentID: 249,
        path: '/tenants/notifyAll',
        menu_title: 'sidebar.communication',
        ComponentName: 'Communication',
        ParentComponent: 'Tenants',
        SecurityLevel: [1, 2, 4, 5, 6, 8],
    },

    {
        ComponentID: 25,
        ComponentName: 'Work Orders',
        menu_title: 'sidebar.workOrders',
        ParentComponent: null,
        SecurityLevel: [1, 2, 4, 5, 6, 7, 8],
    },
    {
        ComponentID: 251,
        path: '/workOrders/add',
        ComponentName: 'Add/View Work Order',
        menu_title: 'sidebar.addViewWorkOrder',
        ParentComponent: 'Work Orders',
        SecurityLevel: [1, 2, 4, 5, 6, 7, 8],
    },
    {
        ComponentID: 251,
        path: '/workOrders/add',
        ComponentName: 'Add/View Work Order',
        menu_title: 'sidebar.addViewWorkOrder',
        ParentComponent: 'Work Orders',
        SecurityLevel: [1, 2, 4, 5, 6, 7, 8],
    },
    {
        ComponentID: 252,
        path: '/workOrders/closed',
        ComponentName: 'Closed Work Order',
        menu_title: 'sidebar.closedWorkOrder',
        ParentComponent: 'Work Orders',
        SecurityLevel: [1, 2, 4, 5, 6, 7, 8],
    },
    {
        ComponentID: 253,
        path: '/workOrders/recurring',
        ComponentName: 'Recurring Work Order',
        menu_title: 'sidebar.recurringWorkOrder',
        ParentComponent: 'Work Orders',
        SecurityLevel: [1, 2, 4, 5, 6, 7, 8],
    },
    {
        ComponentID: 254,
        path: '/workOrders/makeReadies',
        ComponentName: 'Make Ready Board',
        menu_title: 'sidebar.makeReadyBoard',
        ParentComponent: 'Work Orders',
        SecurityLevel: [1, 2, 4, 5, 6, 7, 8],
    },

    {
        ComponentID: 26,
        menu_title: 'sidebar.reports',
        ComponentName: 'Reports',
        path: '/reports',
        ParentComponent: null,
        SecurityLevel: [1, 2, 4, 5, 6, 8],
    },

    {
        ComponentID: 27,
        ComponentName: 'Marketing',
        menu_title: 'marketing',
        ParentComponent: null,
        SecurityLevel: [1, 2, 4, 5, 6, 7, 8],
    },
    {
        ComponentID: 271,
        menu_title: 'sidebar.marketingText',
        ComponentName: 'Marketing',
        path: '/marketing/text',
        ParentComponent: 'Marketing',
        SecurityLevel: [1, 2, 4, 5, 6, 7, 8],
    },
    {
        ComponentID: 272,
        menu_title: 'sidebar.postVacancies',
        ComponentName: 'Marketing',
        path: '/marketing/postVacancies',
        ParentComponent: 'Marketing',
        SecurityLevel: [1, 2, 4, 5, 6, 7, 8],
    },

    {
        ComponentID: 28,
        ComponentName: 'Help',
        menu_title: 'help',
        ParentComponent: null,
        SecurityLevel: [1, 2, 4, 5, 6, 7, 8],
    },
    {
        ComponentID: 281,
        ComponentName: 'Support Request',
        menu_title: 'sidebar.supportRequest',
        path: '/help/support',
        ParentComponent: 'Help',
        SecurityLevel: [1, 2, 4, 5, 6, 7, 8],
    },
    {
        ComponentID: 282,
        ComponentName: 'Suggest Feature Improvement',
        menu_title: 'sidebar.suggestFeatureImprovement',
        path: '/help/enhancementRequest',
        ParentComponent: 'Help',
        SecurityLevel: [1, 2, 4, 5, 6, 7, 8],
    },
    {
        ComponentID: 283,
        ComponentName: 'Video Tutorial',
        menu_title: 'sidebar.videoTutorial',
        path: '/help/videoTutorial',
        ParentComponent: 'Help',
        SecurityLevel: [1, 2, 4, 5, 6, 7, 8],
    },
    {
        ComponentID: 284,
        ComponentName: 'User Guide',
        menu_title: 'sidebar.userGuide',
        path: '/help/userGuide',
        ParentComponent: 'Help',
        SecurityLevel: [1, 2, 4, 5, 6, 7, 8],
    },
    {
        ComponentID: 285,
        ComponentName: 'FAQ',
        menu_title: 'sidebar.FAQ',
        path: '/help/faq',
        ParentComponent: 'Help',
        SecurityLevel: [1, 2, 4, 5, 6, 7, 8],
    },
    {
        ComponentID: 286,
        ComponentName: 'Request History',
        menu_title: 'sidebar.requestHistory',
        path: '/help/requestHistory',
        ParentComponent: 'Help',
        SecurityLevel: [1, 2, 4, 5, 6, 7, 8],
    },
    {
        ComponentID: 287,
        ComponentName: 'License Agreement',
        menu_title: 'sidebar.licenseAgreement',
        path: '/help/licenseAgreement',
        ParentComponent: 'Help',
        SecurityLevel: [1, 2, 4, 5, 6, 7, 8],
    },
    {
        ComponentID: 288,
        ComponentName: 'Cron jobs',
        menu_title: 'sidebar.cronjobs',
        path: '/help/cronjobs',
        ParentComponent: 'Help',
        SecurityLevel: [],
    },
    {
        ComponentID: 289,
        ComponentName: 'Regstered Companies',
        menu_title: 'sidebar.companies',
        path: '/help/companies',
        ParentComponent: 'Help',
        SecurityLevel: [1],
    },

    {
        ComponentID: 29,
        ComponentName: 'Log Out',
        ParentComponent: null,
        SecurityLevel: [1, 2, 4, 5, 6, 7, 8],
    },
    {
        ComponentID: 30,
        ComponentName: 'Admin',
        ParentComponent: null,
        SecurityLevel: [1],
    },
    {
        ComponentID: 31,
        ComponentName: 'Recieves Notifications of Tenant Transactions',
        ParentComponent: null,
        SecurityLevel: [2, 5, 7],
    },
];
