import React from 'react';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import SessionTimeout from './SessionTimeOut';
import Login from './Login/Login';
import Verification from './Login/Verification';
import OwesMoney from './Login/OwesMoney';
// Home
import Home from './Home/Home';
import AuditDetails from './Home/AuditDetails';
import EditMoveOutDate from './Home/SubPages/EditMoveOutDate';
import EditNote from './Home/SubPages/EditNote';
import PreLease from './Home/SubPages/PreLease';
import EditActionItem from './Home/SubPages/EditActionItem';
import EditPromissToPay from './Home/SubPages/EditPromissToPay';
import EditDelinquencyComment from './Home/SubPages/EditDeliquencyComment';
// Profile
import Profile from './Profile/Profile';
// Company
import CompanyDetails from './Company/CompanyDetails';
import ExpenseTypes from './Company/ExpenseTypes';
import JournalEntry from './Company/JournalEntry';
import EditJournal from './Company/EditJournal';
import MakeReadyTask from './Company/MakeReadyTask';
import EditMakeReadyTask from './Company/EditMakeReadyTask';
import FormsCreator from './Company/FormsCreator';
import GLCategories from './Company/GLCategories';
import CorporateDocs from './Company/CorporateDocs';
import BudgetCreator from './Company/BudgetCreator';
import EditBudget from './Company/EditBudgetItem';
// Units
import Units from './Units/ViewAll';
import EditUnit from './Units/EditUnit';
import UploadFiles from './Units/UploadImagesAndVideos';
// Properties
import PropertyRules from './Properties/PropertyRules';
import ViewAllProperties from './Properties/ViewAll';
import EditProperty from './Properties/EditProperty';
import PaymentVendor from './Properties/PaymentVendor';
import PropertyWizard from './Properties/Wizard';
// Users
import ViewAllUsers from './Users/ViewAll';
import AddUser from './Users/AddUser';
import EditUser from './Users/EditUser';
// Owners
import ViewAllOwners from './Owners/ViewAll';
import AddOwner from './Owners/AddOwner';
import EditOwner from './Owners/EditOwner';
// Check Register
import CheckRegister from './CheckRegister/CheckRegister';
import Reconcile from './CheckRegister/Reconcile';
import EditCheckRegister from './CheckRegister/EditCheckRegister';
// Bills
import UnpaidBills from './Bills/Unpaid/UnpaidBills';
import PaidBills from './Bills/Paid/PaidBills';
import RecurringBills from './Bills/Recurring/RecurringBills';
import EditBill from './Bills/EditBill';
import PrintBill from './Bills/PrintBill';
import EditRecurringBill from './Bills/Recurring/Edit';
// Tenants
import ViewAll from './Tenants/ViewAll';
import TenantDetails from './Tenants/TenantDetails';
import AddLeaseHolder from './Tenants/AddLeaseHolder';
import EditLeaseHolder from './Tenants/EditLeaseHolder';
import AddVehicle from './Tenants/AddVehicle';
import EditTenant from './Tenants/EditTenant';
import TransferTenant from './Tenants/TransferTenant';
import AddTenant from './Tenants/AddTenant';
import PreviousTenants from './Tenants/PreviousTenants';
import ReconcilePrevious from './Tenants/ReconcilePrevious';
import ApplyAdditionalFee from './Tenants/ApplyAddtionalFee';
import ApplyAdditionalUtilityCharge from './Tenants/ApplyAddtionalUtilityCharge';
import ApplyElectricCharge from './Tenants/ApplyElectricCharge';
import RequestConcession from './Tenants/RequestConcession';
import NotifyAllTenants from './Tenants/NotifyAll';
import SendLedger from './Tenants/SendLedger';
import EmailLeaseAgreements from './Tenants/EmailAllLeaseAgreements';
import AllocatePayment from './Tenants/AllocatePayment';
// Applicants
import ViewProspects from './Applicants/Prospect/ViewAll';
import EditProspectApplicant from './Applicants/Prospect/EditProspectApplicant';
import ConvertToApplicant from './Applicants/Prospect/ConvertToApplicant';
import ViewApplicants from './Applicants/Applicant/ViewAll';
import ViewReport from './Applicants/Applicant/ViewBackgroundReport';
import AddApplicantLeaseHolder from './Applicants/Applicant/AddLeaseHolder';
import ConvertToTenant from './Applicants/Applicant/ConvertToTenant';
import RunBackgroundScreening from './Applicants/Applicant/RunBackgroundScreening';
import SignUpBackgroundScreening from './Applicants/Applicant/SignUpBackgroundScreening';
import ViewApplication from './Applicants/Applicant/ViewApplication';
import AddProspectApplicant from './Applicants/Prospect/Add';
import LeadSource from './Applicants/Prospect/LeadSource';
import DeniedApplicants from './Applicants/Denied/Denied';
import NotifyAllApplicants from './Applicants/NotifyAll';
// Printable
import AccountSummaryTenantStatement from './Printable/AccountSummaryTenantStatement';
import ViewWorkOrder from './Printable/ViewWorkOrder';
import PrintTenantLedger from './Printable/PrintTenantLedger';
import OpenForm from './Printable/OpenForm';
import DepositSlip from './Printable/DepositSlip';
import AllTenantsStatements from './Printable/AllTenantsStatement';
import AllDeliquentStatements from './Printable/AllDeliquentStatements';
import IntMiscForms from './Reports/DocumentViewer';
// Work Orders
import AddWorkOrder from './WorkOrders/AddWorkOrder';
import EditWorkOrder from './WorkOrders/UpdateWorkOrder';
import ClosedWorkOrders from './WorkOrders/ClosedWorkOrders';
import RecurringWorkOrders from './WorkOrders/RecurringWorkOrders';
import UpdateRecurring from './WorkOrders/UpdateRecurring';
import MakeReadies from './WorkOrders/MakeReadies';
// Deposits
import Deposits from './Deposits/Deposits';
import EditDeposit from './Deposits/EditDeposit';
import AddLender from './Deposits/AddLender';
import DepositBreakDown from './Deposits/DepositBreakDown';
import EditTransaction from './Deposits/EditTransaction';
import History from './Deposits/History';
import EditDeposirHistory from './Deposits/EditDepositHistory';
import EnterTenantCCPayment from './Deposits/EnterTenantCCPayment';
// Vendor
import Vendors from './Vendors/Vendors';
import EditVendor from './Vendors/EditVendor';
// Reports
import Reports from './Reports/Reports';
import TestReports from './Reports/TestReports';
import SearchResults from './Search';
//Marketing
import MarketingText from './Marketing/MarketingText';
import PostVacancies from './Marketing/PostVacancies';
// Help
import VideoTutorial from './Help/VideoTutorial';
import UserGuide from './Help/UserGuide';
import LicenseAgreement from './Help/LicenseAgreement';
import ProductSupport from './Help/ProductSupport';
import FAQ from './Help/FAQ';
import RequestHistory from './Help/RequestHistory';
import EditRequestHistory from './Help/EditRequestHistory';
import EnhancementRequest from './Help/EnhancementRequest';
import CronJobs from './Help/CronJobs';
import RegisteredCompanies from './Help/RegisteredCompanies';
// Force Logout
import ForceLogOut from './Login/ForceLogOut';

import * as Constants from './Util/constants';
import ZeroBalanceSummaryTeanantStatement from './Printable/ZeroBalanceSummaryTeanantStatement';

const PrivateRoute = ({ component, componentName, ...options }) => {
    const login = useSelector((state) => state.login);
    const path = options.path;

    const menuItems = login?.user?.components || [];
    const secLevel = login?.user?.securityLevel || 0;

    var accessibleMenuItems = [];
    if (menuItems.length > 0) {
        accessibleMenuItems = Constants.Components.filter((c) => menuItems.includes(c.ComponentID)).map((c) => c.path);
    } else {
        accessibleMenuItems = Constants.Components.filter((c) => c.SecurityLevel.includes(secLevel)).map((c) => c.path);
    }

    accessibleMenuItems = accessibleMenuItems.filter((c) => !!c);

    if (!login.logged) {
        return <Route {...options} component={Login} />;
    }
    console.log(componentName);

    var isAccessible = false;
    for (let i = 0; i < accessibleMenuItems.length; i++) {
        const m = accessibleMenuItems[i];
        if (m === path) {
            isAccessible = true;
            break;
        }
        if (m.endsWith('/')) {
            if (path.includes(m)) {
                isAccessible = true;
                break;
            }
        }
    }

    if (isAccessible) {
        return <Route {...options} component={component} />;
    }

    if (!accessibleMenuItems.includes(path)) {
        return <Route {...options} component={component} />;
    }

    // if (Constants.UserAccess[componentName] === undefined) {
    //     return <Route {...options} component={Home} />;
    // }
    // const securityLevel = login.user.securityLevel;
    // if (Constants.UserAccess[componentName].includes(securityLevel)) {
    //     return <Route {...options} component={component} />;
    // }
    NotificationManager.warning(`You do not have access to ${componentName}.`, 'Warning');
    return <Route {...options} component={Home} />;
};

const isDev = process.env.NODE_ENV === 'development';
const Navigation = () => {
    return (
        <Router>
            {!isDev && <SessionTimeout />}
            <Switch>
                <PrivateRoute exact path="/" component={Home} componentName="Home" />
                <PrivateRoute exact path="/auditleases" component={AuditDetails} componentName="AuditLeases" />
                <Route exact path="/verification" component={Verification} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/paymentDue" component={OwesMoney} />
                <PrivateRoute exact path="/editMoveOutDate" component={EditMoveOutDate} componentName="EditMoveOutDate" />
                <PrivateRoute exact path="/editNote" component={EditNote} componentName="EditNote" />
                <PrivateRoute exact path="/preLease" component={PreLease} componentName="PreLease" />
                <PrivateRoute exact path="/editActionItem" component={EditActionItem} componentName="EditActionItem" />
                <PrivateRoute exact path="/editPromissToPay" component={EditPromissToPay} componentName="EditPromissToPay" />
                <PrivateRoute
                    exact
                    path="/editDelinquencyComment"
                    component={EditDelinquencyComment}
                    componentName="EditDelinquencyComment"
                />
                <PrivateRoute exact path="/profile" component={Profile} componentName="Profile" />
                <PrivateRoute exact path="/company/details" component={CompanyDetails} componentName="CompanyDetails" />
                <PrivateRoute exact path="/company/expenseTypes" component={ExpenseTypes} componentName="ExpenseTypes" />
                <PrivateRoute exact path="/company/journalEntry" component={JournalEntry} componentName="JournalEntry" />
                <PrivateRoute exact path="/company/journal/edit" component={EditJournal} componentName="EditJournal" />
                <PrivateRoute exact path="/company/makeReadyTask" component={MakeReadyTask} componentName="MakeReadyTask" />
                <PrivateRoute
                    exact
                    path="/company/makeReadyTask/edit"
                    component={EditMakeReadyTask}
                    componentName="EditMakeReadyTask"
                />
                <PrivateRoute exact path="/company/formsCreator" component={FormsCreator} componentName="FormsCreator" />
                <PrivateRoute exact path="/company/glCategories" component={GLCategories} componentName="GLCategories" />
                <PrivateRoute exact path="/company/corporateDocs" component={CorporateDocs} componentName="CorporateDocs" />
                <PrivateRoute exact path="/company/budgetCreator" component={BudgetCreator} componentName="BudgetCreator" />
                <PrivateRoute exact path="/company/updateBudget" component={EditBudget} componentName="EditBudget" />
                <PrivateRoute exact path="/units" component={Units} componentName="Units" />
                <PrivateRoute exact path="/units/editUnit" component={EditUnit} componentName="EditUnit" />
                <PrivateRoute exact path="/units/uploadFile" component={UploadFiles} componentName="UploadFiles" />
                <PrivateRoute exact path="/properties/rules" component={PropertyRules} componentName="PropertyRules" />
                <PrivateRoute
                    exact
                    path="/properties/viewall"
                    component={ViewAllProperties}
                    componentName="ViewAllProperties"
                />
                <PrivateRoute exact path="/properties/edit" component={EditProperty} componentName="EditProperty" />
                <PrivateRoute exact path="/properties/addMerchant" component={PaymentVendor} componentName="PaymentVendor" />
                <PrivateRoute exact path="/properties/wizard" component={PropertyWizard} componentName="PropertyWizard" />
                <PrivateRoute exact path="/users/viewAll" component={ViewAllUsers} componentName="ViewAllUsers" />
                <PrivateRoute exact path="/users/add" component={AddUser} componentName="AddUser" />
                <PrivateRoute exact path="/users/edit" component={EditUser} componentName="EditUser" />
                <PrivateRoute exact path="/owners/viewAll" component={ViewAllOwners} componentName="ViewAllOwners" />
                <PrivateRoute exact path="/owners/add" component={AddOwner} componentName="AddOwner" />
                <PrivateRoute exact path="/owners/edit" component={EditOwner} componentName="EditOwner" />
                <PrivateRoute exact path="/checkRegister" component={CheckRegister} componentName="CheckRegister" />
                <PrivateRoute
                    exact
                    path="/checkRegister/edit"
                    component={EditCheckRegister}
                    componentName="EditCheckRegister"
                />
                <PrivateRoute exact path="/reconcile" component={Reconcile} componentName="Reconcile" />
                <PrivateRoute exact path="/bills/unpaidBills" component={UnpaidBills} componentName="UnpaidBills" />
                <PrivateRoute exact path="/bills/paidBills" component={PaidBills} componentName="PaidBills" />
                <PrivateRoute exact path="/bills/recurring" component={RecurringBills} componentName="RecurringBills" />
                <PrivateRoute exact path="/bills/edit" component={EditBill} componentName="EditBill" />
                <PrivateRoute exact path="/bills/print" component={PrintBill} componentName="PrintBill" />
                <PrivateRoute
                    exact
                    path="/bills/recurring/edit"
                    component={EditRecurringBill}
                    componentName="EditRecurringBill"
                />
                <PrivateRoute exact path="/deposits" component={Deposits} componentName="Deposits" />
                <PrivateRoute exact path="/deposits/edit" component={EditDeposit} componentName="EditDeposit" />
                <PrivateRoute exact path="/deposits/addLender" component={AddLender} componentName="AddLender" />
                <PrivateRoute exact path="/deposits/history" component={History} componentName="History" />
                <PrivateRoute
                    exact
                    path="/deposits/history/edit"
                    component={EditDeposirHistory}
                    componentName="EditDeposirHistory"
                />
                <PrivateRoute
                    exact
                    path="/deposits/enterCCPayment"
                    component={EnterTenantCCPayment}
                    componentName="EnterTenantCCPayment"
                />
                <PrivateRoute exact path="/tenants/viewAll" component={ViewAll} componentName="ViewAll" />
                <PrivateRoute exact path="/tenants/details" component={TenantDetails} componentName="TenantDetails" />
                <PrivateRoute
                    exact
                    path="/tenants/addLeaseHolder"
                    component={AddLeaseHolder}
                    componentName="AddLeaseHolder"
                />
                <PrivateRoute
                    exact
                    path="/tenants/editLeaseHolder"
                    component={EditLeaseHolder}
                    componentName="EditLeaseHolder"
                />
                <PrivateRoute exact path="/tenants/addVehicle" component={AddVehicle} componentName="AddVehicle" />
                <PrivateRoute exact path="/tenants/editTenant" component={EditTenant} componentName="EditTenant" />
                <PrivateRoute exact path="/tenants/transfer" component={TransferTenant} componentName="TransferTenant" />
                <PrivateRoute exact path="/tenants/add" component={AddTenant} componentName="AddTenant" />
                <PrivateRoute exact path="/tenants/previous" component={PreviousTenants} componentName="PreviousTenants" />
                <PrivateRoute
                    exact
                    path="/tenants/reconcilePrevious"
                    component={ReconcilePrevious}
                    componentName="ReconcilePrevious"
                />
                <PrivateRoute
                    exact
                    path="/tenants/addFee"
                    component={ApplyAdditionalFee}
                    componentName="ApplyAdditionalFee"
                />
                <PrivateRoute
                    exact
                    path="/tenants/addUtilityCharge"
                    component={ApplyAdditionalUtilityCharge}
                    componentName="ApplyAdditionalUtilityCharge"
                />
                <PrivateRoute
                    exact
                    path="/tenants/addElectricCharge"
                    component={ApplyElectricCharge}
                    componentName="ApplyElectricCharge"
                />
                <PrivateRoute
                    exact
                    path="/tenants/reqConcession"
                    component={RequestConcession}
                    componentName="RequestConcession"
                />
                <PrivateRoute
                    exact
                    path="/tenants/notifyAll"
                    component={NotifyAllTenants}
                    componentName="NotifyAllTenants"
                />
                <PrivateRoute
                    exact
                    path="/tenants/sendledger"
                    component={SendLedger}
                    componentName="SendLedger"
                />
                <PrivateRoute
                    exact
                    path="/tenants/emailLeaseAgreements"
                    component={EmailLeaseAgreements}
                    componentName="EmailLeaseAgreements"
                />
                <PrivateRoute
                    exact
                    path="/tenants/allocatedPayments"
                    component={AllocatePayment}
                    componentName="AllocatePayment"
                />
                <PrivateRoute exact path="/prospects/viewAll" component={ViewProspects} componentName="ViewProspects" />
                <PrivateRoute
                    exact
                    path="/prospects/edit"
                    component={EditProspectApplicant}
                    componentName="EditProspectApplicant"
                />
                <PrivateRoute
                    exact
                    path="/prospects/convertToApplicant"
                    component={ConvertToApplicant}
                    componentName="ConvertToApplicant"
                />
                <PrivateRoute
                    exact
                    path="/prospects/add"
                    component={AddProspectApplicant}
                    componentName="AddProspectApplicant"
                />
                <PrivateRoute exact path="/prospects/add/leadSource" component={LeadSource} componentName="LeadSource" />
                <PrivateRoute exact path="/applicants/viewAll" component={ViewApplicants} componentName="ViewApplicants" />
                <PrivateRoute exact path="/applicants/viewReport" component={ViewReport} componentName="ViewReport" />
                <PrivateRoute
                    exact
                    path="/applicants/addLeaseHolder"
                    component={AddApplicantLeaseHolder}
                    componentName="AddApplicantLeaseHolder"
                />
                <PrivateRoute
                    exact
                    path="/applicants/convertToTenant"
                    component={ConvertToTenant}
                    componentName="ConvertToTenant"
                />
                <PrivateRoute
                    exact
                    path="/applicants/runBackgroundScreening"
                    component={RunBackgroundScreening}
                    componentName="RunBackgroundScreening"
                />
                <PrivateRoute
                    exact
                    path="/applicants/signupBackground"
                    component={SignUpBackgroundScreening}
                    componentName="SignUpBackgroundScreening"
                />
                <PrivateRoute
                    exact
                    path="/applicants/viewApplication"
                    component={ViewApplication}
                    componentName="ViewApplication"
                />
                <PrivateRoute
                    exact
                    path="/applicants/denied"
                    component={DeniedApplicants}
                    componentName="DeniedApplicants"
                />
                <PrivateRoute
                    exact
                    path="/applicants/notifyAll"
                    component={NotifyAllApplicants}
                    componentName="NotifyAllApplicants"
                />
                <PrivateRoute
                    exact
                    path="/printable/accSummaryStatement"
                    component={AccountSummaryTenantStatement}
                    componentName="AccountSummaryTenantStatement"
                />
                <PrivateRoute
                    exact
                    path="/printable/zeroBalanceStatement"
                    component={ZeroBalanceSummaryTeanantStatement}
                    componentName="ZeroBalanceSummaryTeanantStatement"
                />
                <PrivateRoute
                    exact
                    path="/printable/viewWorkOrder"
                    component={ViewWorkOrder}
                    componentName="ViewWorkOrder"
                />
                <PrivateRoute
                    exact
                    path="/printable/printTenantLedger"
                    component={PrintTenantLedger}
                    componentName="PrintTenantLedger"
                />
                <PrivateRoute exact path="/printable/openForm" component={OpenForm} componentName="OpenForm" />
                <PrivateRoute exact path="/printable/depositSlip" component={DepositSlip} componentName="DepositSlip" />
                <PrivateRoute
                    exact
                    path="/printable/allTenantStatements"
                    component={AllTenantsStatements}
                    componentName="AllTenantsStatements"
                />
                <PrivateRoute
                    exact
                    path="/printable/allDeliquentStatements"
                    component={AllDeliquentStatements}
                    newTab={true}
                    componentName="AllDeliquentStatements"
                />
                <PrivateRoute exact path="/printable/IntMiscForms" component={IntMiscForms} componentName="IntForms" />
                <PrivateRoute exact path="/workOrders/add" component={AddWorkOrder} componentName="AddWorkOrder" />
                <PrivateRoute exact path="/workOrders/update" component={EditWorkOrder} componentName="EditWorkOrder" />
                <PrivateRoute
                    exact
                    path="/workOrders/closed"
                    component={ClosedWorkOrders}
                    componentName="ClosedWorkOrders"
                />
                <PrivateRoute
                    exact
                    path="/workOrders/recurring"
                    component={RecurringWorkOrders}
                    componentName="RecurringWorkOrders"
                />
                <PrivateRoute
                    exact
                    path="/workOrders/updateRecurring"
                    component={UpdateRecurring}
                    componentName="UpdateRecurring"
                />
                <PrivateRoute exact path="/workOrders/makeReadies" component={MakeReadies} componentName="MakeReadies" />
                <PrivateRoute
                    exact
                    path="/deposits/breakdown"
                    component={DepositBreakDown}
                    componentName="DepositBreakDown"
                />
                <PrivateRoute
                    exact
                    path="/deposits/editTransaction"
                    component={EditTransaction}
                    componentName="EditTransaction"
                />
                <PrivateRoute exact path="/vendor" component={Vendors} componentName="Vendors" />
                <PrivateRoute exact path="/vendor/edit" component={EditVendor} componentName="EditVendor" />
                <PrivateRoute exact path="/reports" component={Reports} componentName="Reports" />
                <PrivateRoute exact path="/search-results" component={SearchResults} componentName="SearchResults" />
                {/*<PrivateRoute exact path="/reports" component={TestReports} />*/}
                <PrivateRoute exact path="/marketing/text" component={MarketingText} componentName="MarketingText" />
                <PrivateRoute
                    exact
                    path="/marketing/postVacancies"
                    component={PostVacancies}
                    componentName="PostVacancies"
                />
                <PrivateRoute exact path="/help/videoTutorial" component={VideoTutorial} componentName="VideoTutorial" />
                <PrivateRoute exact path="/help/userGuide" component={UserGuide} componentName="UserGuide" />
                <PrivateRoute
                    exact
                    path="/help/licenseAgreement"
                    component={LicenseAgreement}
                    componentName="LicenseAgreement"
                />
                <PrivateRoute exact path="/help/support" component={ProductSupport} componentName="ProductSupport" />
                <PrivateRoute exact path="/help/faq" component={FAQ} componentName="FAQ" />
                <PrivateRoute exact path="/help/requestHistory" component={RequestHistory} componentName="RequestHistory" />
                <PrivateRoute
                    exact
                    path="/help/reditRequestHistory"
                    component={EditRequestHistory}
                    componentName="EditRequestHistory"
                />
                <PrivateRoute
                    exact
                    path="/help/enhancementRequest"
                    component={EnhancementRequest}
                    componentName="EnhancementRequest"
                />
                <PrivateRoute exact path="/help/cronjobs" component={CronJobs} componentName="CronJobs" />
                <PrivateRoute
                    exact
                    path="/help/companies"
                    component={RegisteredCompanies}
                    componentName="RegisteredCompanies"
                />
                <PrivateRoute exact path="/forceLogOut" component={ForceLogOut} componentName="ForceLogOut" />
            </Switch>
        </Router>
    );
};

export default Navigation;
