import React from 'react';
import navLinks from './NavLinks';
import NavMenuItem from './NavMenuItem';
import { useSelector } from 'react-redux';
import * as Constants from '../Util/constants';

const HorizontalMenu = () => {
    const login = useSelector((state) => state.login);
    const superAdmins = ['wkelty@gmail.com', 'joshuapaige1982@gmail.com', 'giovanniperazzo07@gmail.com'];

    const isAdmin = login?.user?.email ? superAdmins.includes(login.user.email) : false;
    const menuItems = login?.user?.components || [];
    const secLevel = login?.user?.securityLevel || 0;

    var accessibleMenuItems = [];
    if (menuItems.length > 0) {
        accessibleMenuItems = Constants.Components.filter((c) => menuItems.includes(c.ComponentID)).map((c) => c.menu_title);
    } else {
        accessibleMenuItems = Constants.Components.filter((c) => c.SecurityLevel.includes(secLevel)).map(
            (c) => c.menu_title,
        );
    }
    accessibleMenuItems = accessibleMenuItems.filter((c) => !!c);
    return (
        <div className="horizontal-menu">
            <ul className="list-unstyled nav">
                <li className="nav-item">
                    <a href="!#" onClick={(e) => e.preventDefault()} className="nav-link">
                        <i className="zmdi zmdi-view-dashboard"></i>
                        <span className="menu-title">Company Profile</span>
                    </a>
                    <ul className="list-unstyled sub-menu">
                        {navLinks &&
                            navLinks.category1.map((m, key) => {
                                let menu = { ...m };
                                const childs =
                                    menu.child_routes?.filter((ch) => accessibleMenuItems.includes(ch.menu_title)) || null;
                                menu.child_routes = childs;
                                if (isAdmin) return <NavMenuItem menu={menu} key={key} />;
                                if (accessibleMenuItems.includes(menu.menu_title)) {
                                    return <NavMenuItem menu={menu} key={key} />;
                                } else {
                                    if (childs && childs.length > 0) return <NavMenuItem menu={menu} key={key} />;
                                    else return '';
                                }
                            })}
                    </ul>
                </li>
                <li className="nav-item">
                    <a href="!#" onClick={(e) => e.preventDefault()} className="nav-link">
                        <i className="zmdi zmdi-money"></i>
                        <span className="menu-title">Financial</span>
                    </a>
                    <ul className="list-unstyled sub-menu">
                        {navLinks &&
                            navLinks.category2.map((m, key) => {
                                let menu = { ...m };
                                const childs =
                                    menu.child_routes?.filter((ch) => accessibleMenuItems.includes(ch.menu_title)) || null;
                                menu.child_routes = childs;
                                if (isAdmin) return <NavMenuItem menu={menu} key={key} />;
                                if (accessibleMenuItems.includes(menu.menu_title)) {
                                    return <NavMenuItem menu={menu} key={key} />;
                                } else {
                                    if (childs && childs.length > 0) return <NavMenuItem menu={menu} key={key} />;
                                    else return '';
                                }
                            })}
                    </ul>
                </li>
                <li className="nav-item">
                    <a href="!#" onClick={(e) => e.preventDefault()} className="nav-link">
                        <i className="zmdi zmdi-home"></i>
                        <span className="menu-title">Tenancy</span>
                    </a>
                    <ul className="list-unstyled sub-menu">
                        {navLinks &&
                            navLinks.category3.map((m, key) => {
                                let menu = { ...m };
                                const childs =
                                    menu.child_routes?.filter(
                                        (ch) => isAdmin || accessibleMenuItems.includes(ch.menu_title),
                                    ) || null;
                                menu.child_routes = childs;
                                if (accessibleMenuItems.includes(menu.menu_title)) {
                                    return <NavMenuItem menu={menu} key={key} />;
                                } else {
                                    if (childs && childs.length > 0) return <NavMenuItem menu={menu} key={key} />;
                                    else return '';
                                }
                            })}
                    </ul>
                </li>
                <li className="nav-item">
                    <a href="!#" onClick={(e) => e.preventDefault()} className="nav-link">
                        <i className="zmdi zmdi-chart"></i>
                        <span className="menu-title">Reports</span>
                    </a>
                    <ul className="list-unstyled sub-menu">
                        {navLinks &&
                            navLinks.category4.map((m, key) => {
                                let menu = { ...m };
                                const childs =
                                    menu.child_routes?.filter((ch) => accessibleMenuItems.includes(ch.menu_title)) || null;
                                menu.child_routes = childs;
                                if (isAdmin) return <NavMenuItem menu={menu} key={key} />;
                                if (accessibleMenuItems.includes(menu.menu_title)) {
                                    return <NavMenuItem menu={menu} key={key} />;
                                } else {
                                    if (childs && childs.length > 0) return <NavMenuItem menu={menu} key={key} />;
                                    else return '';
                                }
                            })}
                    </ul>
                </li>
                <li className="nav-item">
                    <a href="!#" onClick={(e) => e.preventDefault()} className="nav-link">
                        <i className="zmdi zmdi-assignment-check"></i>
                        <span className="menu-title">Marketing</span>
                    </a>
                    <ul className="list-unstyled sub-menu">
                        {navLinks &&
                            navLinks.category5.map((m, key) => {
                                let menu = { ...m };
                                const childs =
                                    menu.child_routes?.filter((ch) => accessibleMenuItems.includes(ch.menu_title)) || null;
                                menu.child_routes = childs;
                                if (isAdmin) return <NavMenuItem menu={menu} key={key} />;
                                if (accessibleMenuItems.includes(menu.menu_title)) {
                                    return <NavMenuItem menu={menu} key={key} />;
                                } else {
                                    if (childs && childs.length > 0) return <NavMenuItem menu={menu} key={key} />;
                                    else return '';
                                }
                            })}
                    </ul>
                </li>
                <li className="nav-item">
                    <a href="!#" onClick={(e) => e.preventDefault()} className="nav-link">
                        <i className="zmdi zmdi-info-outline"></i>
                        <span className="menu-title">Help</span>
                    </a>
                    <ul className="list-unstyled sub-menu">
                        {navLinks &&
                            navLinks.category6.map((m, key) => {
                                let menu = { ...m };
                                const childs =
                                    menu.child_routes?.filter((ch) => accessibleMenuItems.includes(ch.menu_title)) || null;
                                menu.child_routes = childs;
                                if (isAdmin) return <NavMenuItem menu={menu} key={key} />;
                                if (accessibleMenuItems.includes(menu.menu_title)) {
                                    return <NavMenuItem menu={menu} key={key} />;
                                } else {
                                    if (childs && childs.length > 0) return <NavMenuItem menu={menu} key={key} />;
                                    else return '';
                                }
                            })}
                    </ul>
                </li>
            </ul>
        </div>
    );
};

export default HorizontalMenu;
