import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import * as Constants from "../Util/constants";
import * as reportsAPI from "../../Api/reports";
import { currencyFormat, formatDate } from "./helperFunctions";
import RctCollapsibleCard from "../Helpers/RctCollapsibleCard/RctCollapsibleCard";
import LinearProgress from "../Util/LinearProgress";
import { Link } from "react-router-dom";

const OnlinePaymentSummary = (props) => {
  const [loading, setLoading] = useState(false);
  const [summaryData, setSummaryData] = useState([]);
  const [summaryId, setSummaryId] = useState([]);
  const propertyName = useSelector((state) => state.login.properties);
  const [filteredName] = propertyName.filter(
    (property) => property.PropertyID === props.propertyId
  );

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const reportDetails = await reportsAPI.getOnlinePaymentSummary(
        props.reportSearch
      );
      for (let i = 0; i < reportDetails.length; i++) {
        for (
          let j = 0;
          j < reportDetails[i].epicpaydepositdetails.length;
          j++
        ) {
          if (summaryData[reportDetails[i].id]) {
            summaryData[reportDetails[i].id].push({
              description:
                reportDetails[i].epicpaydepositdetails[j].Description,
              amount: currencyFormat(
                +reportDetails[i].epicpaydepositdetails[j].Amount
              ),
            });
          } else {
            summaryId.push(reportDetails[i].id);
            summaryData[reportDetails[i].id] = [
              {
                description:
                  reportDetails[i].epicpaydepositdetails[j].Description,
                amount: currencyFormat(
                  +reportDetails[i].epicpaydepositdetails[j].Amount
                ),
              },
            ];
          }
        }
        summaryData[reportDetails[i].id].push({
          description: "Total",
          amount: currencyFormat(+reportDetails[i].Amount),
        });
        summaryData[reportDetails[i].id].title =
          "Payout Date: " +
          formatDate(reportDetails[i].EffectiveDate.substring(0, 10)) +
          " Bank: " +
          reportDetails[i].Account;
      }
      setLoading(false);
    }
    fetchData();
  }, []);
  if (loading) {
    return (
      <RctCollapsibleCard
        colClasses="col-xs-12 col-sm-12 col-md-12"
        heading={"Loading Reports..."}
      >
        <LinearProgress />
      </RctCollapsibleCard>
    );
  }
  const summaryColumns = [
    { name: "description", label: "Description" },
    { name: "amount", label: "Amount" },
  ];

  return (
    <div>
      <div className="page-title d-flex justify-content-between align-items-center">
        <div className="page-title-wrap">
          <h2>
            <span>
              {props.reportName +
                " - " +
                filteredName.PropertyName +
                " - Date Range of Report: " +
                props.startDate +
                " to " +
                props.endDate}
            </span>
          </h2>
          <a>
            If you have any question regarding your payouts, feel free contact
            the payment vendor. Business Hours: 7AM - 7PM CST. 1-866-377-3287
            (option 2, then 2).
          </a>
          <br />
          <a
            href="mailto:customerservice@epicpay.com"
            target="_blank"
            rel="noreferrer"
          >
            customerservice@epicpay.com
          </a>
          <br />
          <a
            href="https://secure.myepicpay.com/merchant"
            target="_blank"
            rel="noreferrer"
          >
            Merchant Account Link
          </a>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-xl-12">
          {summaryId.map((ID) => (
            <MuiThemeProvider theme={Constants.getMuiTheme()}>
              <MUIDataTable
                title={`${summaryData[ID].title}`}
                data={summaryData[ID]}
                columns={summaryColumns}
                options={props.reportOptions}
              />
            </MuiThemeProvider>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OnlinePaymentSummary;
