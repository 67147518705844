import React, {useState, useEffect} from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Typography} from '@material-ui/core';
import { Badge } from 'reactstrap';

import Main from '../Main';
import * as Constants from '../Util/constants';
import * as companyAPI from '../../Api/company';

const FAQ = () => {

    const [faq, setFaq] = useState([]);
    const [expanded, setExpanded] = useState('panel0');

    useEffect(() => {
        async function fetchData() {
            setFaq(await companyAPI.getFaq());
        }
        fetchData()
    }, []);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const buildFaq = (f, i) => {
        return (
            <Accordion expanded={expanded === `panel${i}`} onChange={handleChange(`panel${i}`)}>
                <AccordionSummary aria-controls={`panel${i}d-content`} id={`panel${i}d-header`}>
                <Typography>{f.Question}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    <Badge color="primary" style={{marginBottom: '10px'}}>Answer</Badge>
                    <p style={{marginLeft: '6px'}} dangerouslySetInnerHTML={{__html:f.Answer}} />
                </Typography>
                </AccordionDetails>
            </Accordion>
        );
    }

    return (
        <Main>
            <div className="formelements-wrapper" style={Constants.margins}>
                <div className="page-title d-flex justify-content-between align-items-center">
                    <div className="page-title-wrap">
                        <h2>
                            <span>FAQ</span>
                        </h2>
                    </div>
                </div>
                {faq.map((obj, idx) => {
                    return buildFaq(obj, idx);
                })}
            </div>
        </Main>
    );
}

export default FAQ;