import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import * as Constants from '../Util/constants';
import * as reportsAPI from '../../Api/reports';
import { currencyFormat, dateRange } from './helperFunctions';
import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import LinearProgress from '../Util/LinearProgress';

const BudgetProject = (props) => {
    const [loading, setLoading] = useState(false);
    const [incomeData, setIncomeData] = useState([]);
    const [expensesData, setExpensesData] = useState([]);
    const [nonOperatingData, setNonOperatingData] = useState([]);
    const [summaryData, setSummaryData] = useState([]);
    const propertyName = useSelector((state) => state.login.properties);
    const [filteredName] = propertyName.filter((property) => property.PropertyID === props.propertyId);
    const [budgetColumns] = useState([{ name: 'category', label: 'Category' }]);
    const [months] = useState([]);
    const [monthList] = useState([]);
    const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            const reportIncome = await reportsAPI.getBudgetProjectIncome(props.reportSearch);
            const reportExpenses = await reportsAPI.getBudgetProjectExpenses(props.reportSearch);
            const reportNonOperating = await reportsAPI.getBudgetProjectNonOperating(props.reportSearch);
            const reportIncomeTotal = await reportsAPI.getBudgetProjectIncomeTotal(props.reportSearch);
            const reportExpensesTotal = await reportsAPI.getBudgetProjectExpensesTotal(props.reportSearch);
            const reportNonOperatingTotal = await reportsAPI.getBudgetProjectNonOperatingTotal(props.reportSearch);
            const allMonths = dateRange(props.reportSearch.sDt, props.reportSearch.eDt);
            for (let i = 0; i < allMonths.length; i++) {
                months[
                    monthNames[new Date(allMonths[i]).getUTCMonth()] + new Date(allMonths[i]).getUTCFullYear().toString()
                ] = {
                    date: allMonths[i],
                    tenantPayment: 0,
                    housingPayment: 0,
                    prospectPayment: 0,
                    totalIncome: 0,
                    totalExpenses: 0,
                    totalNonOperating: 0,
                    total: 0,
                    indexMonth: new Date(allMonths[i]).getUTCMonth(),
                    indexYear: new Date(allMonths[i]).getUTCFullYear(),
                };
                budgetColumns.push({
                    name:
                        monthNames[new Date(allMonths[i]).getUTCMonth()] +
                        new Date(allMonths[i]).getUTCFullYear().toString(),
                    label:
                        monthNames[new Date(allMonths[i]).getUTCMonth()] +
                        ' ' +
                        new Date(allMonths[i]).getUTCFullYear().toString(),
                });
                monthList.push(
                    monthNames[new Date(allMonths[i]).getUTCMonth()] + new Date(allMonths[i]).getUTCFullYear().toString(),
                );
            }
            budgetColumns.push({ name: 'total', label: 'Total' });
            let incomeCase = true;
            let monthIndex = 0;
            let incomeIndex = 0;
            if (reportIncome.length !== 0) {
                while (incomeCase) {
                    if (monthIndex >= monthList.length) {
                        incomeCase = false;
                        monthIndex = 0;
                    } else {
                        if (reportIncome.length > 0 && reportIncome.length > incomeIndex) {
                            let tenantDate = new Date(reportIncome[incomeIndex].TenantTransactionDate).getTime();
                            let monthLess = new Date(months[monthList[monthIndex]].date).getTime();
                            if (
                                (tenantDate >= monthLess && incomeIndex === reportIncome.length - 1) ||
                                (tenantDate >= monthLess &&
                                    tenantDate < new Date(months[monthList[monthIndex + 1]].date).getTime())
                            ) {
                                months[monthList[monthIndex]].tenantPayment = +reportIncome[incomeIndex].TotalTenantPayment;
                                months[monthList[monthIndex]].housingPayment =
                                    +reportIncome[incomeIndex].TotalHousingPayment;
                                months[monthList[monthIndex]].prospectPayment = +reportIncome[incomeIndex].TotalProspect;
                                months[monthList[monthIndex]].totalIncome = +reportIncome[incomeIndex].TOTALINCOME;
                                monthIndex += 1;
                                incomeIndex += 1;
                            } else {
                                monthIndex += 1;
                            }
                        }
                    }
                }
            }
            let expenseCase = true;
            let expenseIndex = 0;
            while (expenseCase) {
                if (monthIndex >= monthList.length) {
                    expenseCase = false;
                    monthIndex = 0;
                } else {
                    if (reportExpenses.length > 0 && reportExpenses.length > expenseIndex) {
                        let invoiceDate = new Date(reportExpenses[expenseIndex].InvoiceDate).getTime();
                        let monthLess = new Date(months[monthList[monthIndex]].date).getTime();
                        if (
                            (invoiceDate >= monthLess && expenseIndex === reportExpenses.length - 1) ||
                            (invoiceDate >= monthLess &&
                                invoiceDate < new Date(months[monthList[monthIndex + 1]].date).getTime())
                        ) {
                            months[monthList[monthIndex]].totalExpenses = +reportExpenses[expenseIndex].TotalExpenses;
                            monthIndex += 1;
                            expenseIndex += 1;
                        } else {
                            monthIndex += 1;
                        }
                    } else {
                        expenseCase = false;
                    }
                }
            }
            let operatingCase = true;
            let operatingIndex = 0;
            while (operatingCase) {
                if (monthIndex >= monthList.length) {
                    operatingCase = false;
                    monthIndex = 0;
                } else {
                    if (reportNonOperating.length > 0 && reportNonOperating.length > operatingIndex) {
                        let invoiceDate = new Date(reportNonOperating[operatingIndex].InvoiceDate).getTime();
                        let monthLess = new Date(months[monthList[monthIndex]].date).getTime();
                        if (
                            (invoiceDate >= monthLess && operatingIndex === reportNonOperating.length - 1) ||
                            (invoiceDate >= monthLess &&
                                invoiceDate < new Date(months[monthList[monthIndex + 1]].date).getTime())
                        ) {
                            months[monthList[monthIndex]].totalNonOperating =
                                +reportNonOperating[operatingIndex].TotalExpenses;
                            monthIndex += 1;
                            operatingIndex += 1;
                        } else {
                            monthIndex += 1;
                        }
                    } else {
                        operatingCase = false;
                    }
                }
            }
            let tenantObject;
            let tenantIncome = monthList.map((month, i) => {
                if (i === 0) {
                    tenantObject = {
                        category: 'Tenant Payment:',
                        [month]: currencyFormat(months[month].tenantPayment),
                    };
                } else if (monthList.length - 1 === i) {
                    return (tenantObject = {
                        ...tenantObject,
                        [month]: currencyFormat(months[month].tenantPayment),
                        total: currencyFormat(+reportIncomeTotal[0].TotalTenantPayment),
                    });
                } else {
                    tenantObject = {
                        ...tenantObject,
                        [month]: currencyFormat(months[month].tenantPayment),
                    };
                }
                return undefined;
            });
            let housingObject;
            let housingIncome = monthList.map((month, i) => {
                if (i === 0) {
                    housingObject = {
                        category: 'Housing Payment:',
                        [month]: currencyFormat(months[month].housingPayment),
                    };
                } else if (monthList.length - 1 === i) {
                    return (housingObject = {
                        ...housingObject,
                        [month]: currencyFormat(months[month].housingPayment),
                        total: currencyFormat(+reportIncomeTotal[0].TotalHousingPayment),
                    });
                } else {
                    housingObject = {
                        ...housingObject,
                        [month]: currencyFormat(months[month].housingPayment),
                    };
                }
                return undefined;
            });
            let prospectObject;
            let prospectIncome = monthList.map((month, i) => {
                if (i === 0) {
                    prospectObject = {
                        category: 'Prospect Payment:',
                        [month]: currencyFormat(months[month].prospectPayment),
                    };
                } else if (monthList.length - 1 === i) {
                    return (prospectObject = {
                        ...prospectObject,
                        [month]: currencyFormat(months[month].prospectPayment),
                        total: currencyFormat(+reportIncomeTotal[0].TotalProspect),
                    });
                } else {
                    prospectObject = {
                        ...prospectObject,
                        [month]: currencyFormat(months[month].prospectPayment),
                    };
                }
                return undefined;
            });
            let totalObject;
            let totalIncome = monthList.map((month, i) => {
                if (i === 0) {
                    totalObject = {
                        category: 'Gross Income:',
                        [month]: currencyFormat(months[month].totalIncome),
                    };
                } else if (monthList.length - 1 === i) {
                    return (totalObject = {
                        ...totalObject,
                        [month]: currencyFormat(months[month].totalIncome),
                        total: currencyFormat(+reportIncomeTotal[0].TOTALINCOME),
                    });
                } else {
                    totalObject = {
                        ...totalObject,
                        [month]: currencyFormat(months[month].totalIncome),
                    };
                }
                return undefined;
            });
            let expensesObject;
            let totalExpenses = monthList.map((month, i) => {
                if (i === 0) {
                    expensesObject = {
                        category: 'Total Expenses:',
                        [month]: currencyFormat(months[month].totalExpenses),
                    };
                } else if (monthList.length - 1 === i) {
                    return (expensesObject = {
                        ...expensesObject,
                        [month]: currencyFormat(months[month].totalExpenses),
                        total: currencyFormat(+reportExpensesTotal[0].TotalExpenses),
                    });
                } else {
                    expensesObject = {
                        ...expensesObject,
                        [month]: currencyFormat(months[month].totalExpenses),
                    };
                }
                return undefined;
            });
            let noiObject;
            let totalNoi = monthList.map((month, i) => {
                if (i === 0) {
                    noiObject = {
                        category: 'NOI:',
                        [month]: currencyFormat(months[month].totalIncome - months[month].totalExpenses),
                    };
                } else if (monthList.length - 1 === i) {
                    return (noiObject = {
                        ...noiObject,
                        [month]: currencyFormat(months[month].totalIncome - months[month].totalExpenses),
                        total: currencyFormat(+reportIncomeTotal[0].TOTALINCOME - +reportExpensesTotal[0].TotalExpenses),
                    });
                } else {
                    noiObject = {
                        ...noiObject,
                        [month]: currencyFormat(months[month].totalIncome - months[month].totalExpenses),
                    };
                }
                return undefined;
            });
            let nonOperatingObject;
            let totalNonOperating = monthList.map((month, i) => {
                if (i === 0) {
                    nonOperatingObject = {
                        category: 'Total Non Operating Expenses:',
                        [month]: currencyFormat(months[month].totalNonOperating),
                    };
                } else if (monthList.length - 1 === i) {
                    return (nonOperatingObject = {
                        ...nonOperatingObject,
                        [month]: currencyFormat(months[month].totalNonOperating),
                        total: currencyFormat(+reportNonOperatingTotal[0].TotalExpenses),
                    });
                } else {
                    nonOperatingObject = {
                        ...nonOperatingObject,
                        [month]: currencyFormat(months[month].totalNonOperating),
                    };
                }
                return undefined;
            });
            let netObject;
            let netIncome = monthList.map((month, i) => {
                if (i === 0) {
                    netObject = {
                        category: 'Net Income:',
                        [month]: currencyFormat(
                            months[month].totalIncome - months[month].totalExpenses - months[month].totalNonOperating,
                        ),
                    };
                } else if (monthList.length - 1 === i) {
                    return (netObject = {
                        ...netObject,
                        [month]: currencyFormat(
                            months[month].totalIncome - months[month].totalExpenses - months[month].totalNonOperating,
                        ),
                        total: currencyFormat(
                            +reportIncomeTotal[0].TOTALINCOME -
                                +reportExpensesTotal[0].TotalExpenses -
                                +reportNonOperatingTotal[0].TotalExpenses,
                        ),
                    });
                } else {
                    netObject = {
                        ...netObject,
                        [month]: currencyFormat(
                            months[month].totalIncome - months[month].totalExpenses - months[month].totalNonOperating,
                        ),
                    };
                }
                return undefined;
            });
            tenantIncome = tenantIncome.splice(monthList.length - 1);
            prospectIncome = prospectIncome.splice(monthList.length - 1);
            housingIncome = housingIncome.splice(monthList.length - 1);
            totalIncome = totalIncome.splice(monthList.length - 1);
            totalExpenses = totalExpenses.splice(monthList.length - 1);
            totalNoi = totalNoi.splice(monthList.length - 1);
            totalNonOperating = totalNonOperating.splice(monthList.length - 1);
            netIncome = netIncome.splice(monthList.length - 1);
            setIncomeData([...tenantIncome, ...prospectIncome, ...housingIncome, ...totalIncome]);
            setExpensesData([...totalExpenses, ...totalNoi]);
            setNonOperatingData([...totalNonOperating, ...netIncome]);
            setSummaryData([...totalIncome, ...totalExpenses, ...totalNonOperating, ...netIncome]);
            setLoading(false);
        }
        fetchData();
    }, []);
    if (loading) {
        return (
            <RctCollapsibleCard
                colClasses="col-xs-12 col-sm-12 col-md-12"
                heading={'Loading Reports... (May take up to 60 seconds)'}
            >
                <LinearProgress />
            </RctCollapsibleCard>
        );
    }

    return (
        <div>
            <div className="page-title d-flex justify-content-between align-items-center">
                <div className="page-title-wrap">
                    <h2>
                        <span>
                            {props.reportName +
                                ' - ' +
                                filteredName.PropertyName +
                                ' - Date Range of Report: ' +
                                props.startDate +
                                ' to ' +
                                props.endDate}
                        </span>
                    </h2>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-xl-12">
                    <MuiThemeProvider theme={Constants.getMuiTheme()}>
                        <MUIDataTable
                            title={'Income'}
                            data={incomeData}
                            columns={budgetColumns}
                            options={props.reportOptions}
                        />
                    </MuiThemeProvider>
                </div>
                <div className="col-sm-12 col-md-12 col-xl-12">
                    <MuiThemeProvider theme={Constants.getMuiTheme()}>
                        <MUIDataTable
                            title={'Expenses'}
                            data={expensesData}
                            columns={budgetColumns}
                            options={props.reportOptions}
                        />
                    </MuiThemeProvider>
                </div>
                <div className="col-sm-12 col-md-12 col-xl-12">
                    <MuiThemeProvider theme={Constants.getMuiTheme()}>
                        <MUIDataTable
                            title={'Non Operating Expenses'}
                            data={nonOperatingData}
                            columns={budgetColumns}
                            options={props.reportOptions}
                        />
                    </MuiThemeProvider>
                </div>
                <div className="col-sm-12 col-md-12 col-xl-12">
                    <MuiThemeProvider theme={Constants.getMuiTheme()}>
                        <MUIDataTable
                            title={'Summary'}
                            data={summaryData}
                            columns={budgetColumns}
                            options={props.reportOptions}
                        />
                    </MuiThemeProvider>
                </div>
            </div>
        </div>
    );
};

export default BudgetProject;
