import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import * as reportsAPI from "../../Api/reports";
import { currencyFormat, formatDate } from "./helperFunctions";
import RctCollapsibleCard from "../Helpers/RctCollapsibleCard/RctCollapsibleCard";
import LinearProgress from "../Util/LinearProgress";
import { useHistory } from "react-router-dom";

const NonAllocPayments = (props) => {
  const propertyName = useSelector((state) => state.login.properties);
  const [filteredName] = propertyName.filter(
    (property) => property.PropertyID === props.propertyId
  );

  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const history = useHistory();

  const [currPage, setCurrPage] = useState(1);
  const [paymentsList, setPaymentLists] = useState([]);

  const scrollInfo = useSelector((state) => state.report.scrollInfo);

  useEffect(() => {
    const { scrollTop, clientHeight, scrollHeight } = scrollInfo;
    if (Math.round(scrollTop + clientHeight) >= scrollHeight) {
      if (payments.length !== paymentsList.length) {
        setPaymentLists(payments.slice(0, (currPage + 1) * 3));
        setCurrPage(currPage + 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollInfo]);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const reportDetails = await reportsAPI.getNonAllocPayment(
        props.reportSearch
      );

      if (!reportDetails || !reportDetails.length) {
        setIsEmpty(true);
      }

      let previousTenantID = 0;
      let tableHeaders = [];
      let tableData = {};
      reportDetails.forEach((report) => {
        const newHeader = `${report.TenantFName} ${report.TenantLName}. Unit: ${report.UnitName}`;
        if (previousTenantID === 0 || previousTenantID !== report.TenantID) {
          tableHeaders = [...tableHeaders, newHeader];
        }

        if (!tableData[newHeader]) tableData[newHeader] = [];
        tableData[newHeader].push({
          transactionId: (
            <Button
              onClick={() => {
                const location = {
                  pathname: "/tenants/allocatedPayments",
                  state: {
                    tenantName: report.TenantFName + " " + report.TenantLName,
                    tenantTransactionID: report.TenantTransactionID,
                    propertyID: props.reportSearch.pID,
                  },
                };

                history.push(location);
              }}
            >
              <span className="text-primary">{report.TenantTransactionID}</span>
            </Button>
          ),
          transactionAmount: currencyFormat(+report.TransactionAmount),
          transactionDate: formatDate(report.TenantTransactionDate),
        });
        previousTenantID = report.TenantID;
      });

      setPayments(tableHeaders);
      setPaymentLists(tableHeaders.slice(0, 3));
      setPaymentDetails(tableData);
      setLoading(false);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <RctCollapsibleCard
        colClasses="col-xs-12 col-sm-12 col-md-12"
        heading={"Loading Reports..."}
      >
        <LinearProgress />
      </RctCollapsibleCard>
    );
  }
  if (isEmpty) {
    return (
      <div>
        <h3>All payments are allocated</h3>
      </div>
    );
  }

  const paymentsColumns = [
    { name: "transactionId", label: "Transaction ID" },
    { name: "transactionAmount", label: "Transaction Amount" },
    { name: "transactionDate", label: "Transaction Date" },
  ];

  return (
    <div>
      <div className="page-title d-flex justify-content-between align-items-center">
        <div className="page-title-wrap">
          <h2>
            <span>
              {props.reportName +
                " - " +
                filteredName.PropertyName +
                " - Date Range of Report: " +
                props.startDate +
                " to " +
                props.endDate}
            </span>
          </h2>
        </div>
      </div>
      <div className="row">
        {paymentsList.map((payment, key) => {
          return (
            <div className="col-sm-12 col-md-12 col-xl-12" key={key}>
              <MUIDataTable
                title={`${payment}`}
                data={paymentDetails[payment]}
                columns={paymentsColumns}
                options={props.reportOptions}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default NonAllocPayments;
