import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import * as Constants from "../Util/constants";
import * as reportsAPI from "../../Api/reports";
import * as unitInfo from "../../Api/units";
import * as userInfo from "../../Api/users";
import moment from "moment";
import RctCollapsibleCard from "../Helpers/RctCollapsibleCard/RctCollapsibleCard";
import LinearProgress from "../Util/LinearProgress";

const WorkOrderSummary = (props) => {
  const [loading, setLoading] = useState(false);
  const [summaryData, setSummaryData] = useState([]);
  const [totalData, setTotalData] = useState([]);
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      for (let i = 0; i < props.selectedValue.length; i++) {
        props.reportSearch.pID = props.selectedValue[i].id;
        const workOrderSumm = await reportsAPI.getWorkOrderSummary(
          props.reportSearch
        );
        if (!summaryData[props.selectedValue[i].name]) {
          for (let j = 0; j < workOrderSumm?.length; j++) {
            if (summaryData[props.selectedValue[i].name]) {
              summaryData[props.selectedValue[i].name].push({
                unit: workOrderSumm[j].unit?.UnitName,
                dateSubmitted: moment(workOrderSumm[j].WorkOrderSubmitDate)
                  .utc()
                  .format("MM-DD-YYYY"),
                description: workOrderSumm[j].WorkOrderDescription,
                staffComment: workOrderSumm[j].WorkOrderComment,
                commentDate: moment(workOrderSumm[j].WorkOrderCommentDate)
                  .utc()
                  .format("MM-DD-YYYY"),
                status: workOrderSumm[j].status.Status,
                completeDate:
                  workOrderSumm[j].status.id === 3
                    ? moment(workOrderSumm[j].WorkOrderCompleteDate)
                        .utc()
                        .format("MM-DD-YYYY")
                    : "",
                staffAssigned: workOrderSumm[j].user
                  ? workOrderSumm[j].user.UserFName +
                    " " +
                    workOrderSumm[j].user.UserLName
                  : "Not Assigned",
              });
              if (workOrderSumm[j].status.id === 3) {
                totalData[props.selectedValue[i].name][0][1].total += 1;
              }
              if (workOrderSumm[j].status.id === 1) {
                totalData[props.selectedValue[i].name][0][2].total += 1;
              }
              totalData[props.selectedValue[i].name][0][0].total += 1;
            } else {
              summaryData[props.selectedValue[i].name] = [
                {
                  unit: workOrderSumm[j].unit?.UnitName,
                  dateSubmitted: moment(workOrderSumm[j].WorkOrderSubmitDate)
                    .utc()
                    .format("MM-DD-YYYY"),
                  description: workOrderSumm[j].WorkOrderDescription,
                  staffComment: workOrderSumm[j].WorkOrderComment,
                  commentDate: moment(workOrderSumm[j].WorkOrderCommentDate)
                    .utc()
                    .format("MM-DD-YYYY"),
                  status: workOrderSumm[j].status.Status,
                  completeDate: moment(workOrderSumm[j].WorkOrderCompleteDate)
                    .utc()
                    .format("MM-DD-YYYY"),
                  staffAssigned: workOrderSumm[j].user
                    ? workOrderSumm[j].user.UserFName +
                      " " +
                      workOrderSumm[j].user.UserLName
                    : "Not Assigned",
                },
              ];
              totalData[props.selectedValue[i].name] = [
                [
                  { status: "Total Work Orders", total: 1 },
                  {
                    status: "Total Completed",
                    total: workOrderSumm[j].status.id === 3 ? 1 : 0,
                  },
                  {
                    status: "Total Still Open",
                    total: workOrderSumm[j].status.id === 1 ? 1 : 0,
                  },
                ],
              ];
            }
          }
        }
      }
      setLoading(false);
    }
    fetchData();
  }, [props.selectedValue]);
  if (loading) {
    return (
      <RctCollapsibleCard
        colClasses="col-xs-12 col-sm-12 col-md-12"
        heading={"Loading Reports..."}
      >
        <LinearProgress />
      </RctCollapsibleCard>
    );
  }
  const summaryColumns = [
    { name: "unit", label: "Unit" },
    { name: "dateSubmitted", label: "Date Submitted" },
    { name: "description", label: "Description" },
    { name: "staffComment", label: "Staff Comment" },
    { name: "commentDate", label: "Comment Date" },
    { name: "status", label: "Status" },
    { name: "completeDate", label: "Complete Date" },
    { name: "staffAssigned", label: "Staff Assigned" },
  ];
  const totalsColumns = [
    { name: "status", label: "Status" },
    { name: "total", label: "Total" },
  ];
  return (
    <div>
      <div className="page-title d-flex justify-content-between align-items-center">
        <div className="page-title-wrap">
          <h2>
            <span>
              {props.reportName +
                " - Date Range of Report: " +
                props.startDate +
                " to " +
                props.endDate}
            </span>
          </h2>
        </div>
      </div>
      <div className="row">
        {props.selectedValue.map((property) => {
          return (
            <div className="col-sm-12 col-md-12 col-xl-12">
              <MuiThemeProvider theme={Constants.getMuiTheme()}>
                <MUIDataTable
                  title={"Summary of Work Orders"}
                  data={
                    totalData[property.name] ? totalData[property.name][0] : []
                  }
                  columns={totalsColumns}
                  options={props.reportOptions}
                />
              </MuiThemeProvider>
              <MuiThemeProvider theme={Constants.getMuiTheme()}>
                <MUIDataTable
                  title={`${property.name} Work Order Summary`}
                  data={summaryData[property.name]}
                  columns={summaryColumns}
                  options={props.reportOptions}
                />
              </MuiThemeProvider>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WorkOrderSummary;
