import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import * as Constants from '../Util/constants';
import * as reportsAPI from '../../Api/reports';
import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import LinearProgress from '../Util/LinearProgress';
import { Link } from 'react-router-dom';
import ProfitLossLineItem from './ProfitLossLineItem';

const ProfitAndLoss = (props) => {
    const [loading, setLoading] = useState(false);
    // const [incomeData, setIncomeData] = useState([]);
    // const [expenseData, setExpenseData] = useState([]);
    // const [nonOpExpenseData, setNonOpExpenseData] = useState([]);
    const [reportType, setReportType] = useState('');
    // const [summaryData, setSummaryData] = useState([]);
    const [exID, setExId] = useState(null);
    const [dID, setItemDepositID] = useState(null);
    const [lineItem, setLineItem] = useState(false);
    const propertyName = useSelector((state) => state.login.properties);
    const [filteredName] = propertyName.filter((property) => property.PropertyID === props.propertyId);
    const [tableData, setTableData] = useState({});

    function currencyFormat(num) {
        return '$' + num?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    useEffect(() => {
        console.log("props.selectedValue",props.selectedValue)
        async function fetchData() {
            setLoading(true);
            var data = {};
            for (let i = 0; i < props.selectedValue.length; i++) {
                let incomeData = [],
                    expenseData = [],
                    nonOpExpenseData = [],
                    summaryData = [];
                const property = props.selectedValue[i];
                const reportDetails = await reportsAPI.getReportDetails({...props.reportSearch, pID: property.id});

                var tenantTotal = 0;
                var housingTotal = 0;
                var otherTotal = 0;
                var expenseTotal = 0;
                var nonOpExpenseTotal = 0;
                for (let i = 0; i < reportDetails.GetGLCategoryIncomeTenant.length; i++) {
                    incomeData.push({
                        category: reportDetails.GetGLCategoryIncomeTenant[i].Category,
                        incomeType: 'Tenant Payment',
                        amount: currencyFormat(+reportDetails.GetGLCategoryIncomeTenant[i].Total),
                        dID: 1,
                    });
                    tenantTotal += +reportDetails.GetGLCategoryIncomeTenant[i].Total;
                }
                incomeData.push({
                    category: 'Non-Allocated',
                    incomeType: 'Tenant Payment',
                    amount: currencyFormat(+reportDetails.defaultIncome[0].TotalTenantPayment - tenantTotal),
                    dID: 1,
                });
                otherTotal += +reportDetails.defaultIncome[0].TotalTenantPayment;
                for (let i = 0; i < reportDetails.GetGLCategoryIncomeHousing.length; i++) {
                    incomeData.push({
                        category: reportDetails.GetGLCategoryIncomeHousing[i].Category,
                        incomeType: 'Housing Payment',
                        amount: currencyFormat(+reportDetails.GetGLCategoryIncomeHousing[i].Total),
                        dID: 2,
                    });
                    housingTotal += +reportDetails.GetGLCategoryIncomeHousing[i].Total;
                }
                incomeData.push({
                    category: 'Non-Allocated',
                    incomeType: 'Housing Payment',
                    amount: currencyFormat(+reportDetails.defaultIncome[0].TotalHousingPayment - housingTotal),
                    dID: 2,
                });
                otherTotal += +reportDetails.defaultIncome[0].TotalHousingPayment;
                incomeData.push({
                    category: 'Other Income',
                    amount: currencyFormat(+reportDetails.defaultIncome[0].TotalOtherIncome),
                    dID: 3,
                });
                otherTotal += +reportDetails.defaultIncome[0].TotalOtherIncome;

                incomeData.push({
                    category: 'Prospect Payment',
                    amount: currencyFormat(+reportDetails.defaultIncome[0].TotalProspect),
                    dID: 4,
                });
                otherTotal += +reportDetails.defaultIncome[0].TotalProspect;

                incomeData.push({
                    category: 'Laundry Room',
                    amount: currencyFormat(+reportDetails.defaultIncome[0].TotalLaundryRoom),
                    dID: 11,
                });

                otherTotal += +reportDetails.defaultIncome[0].FormerTenantPayment;

                incomeData.push({
                    category: 'Former Tenant Payment',
                    amount: currencyFormat(+reportDetails.defaultIncome[0].FormerTenantPayment),
                    dID: 7,
                });

                otherTotal += +reportDetails.defaultIncome[0].TotalLaundryRoom;

                incomeData.push({
                    category: 'TOTAL INCOME',
                    amount: currencyFormat(otherTotal),
                });

                for (let i = 0; i < reportDetails.GetExpenseDetails.length; i++) {
                    expenseData.push({
                        category: reportDetails.GetExpenseDetails[i].ExpenseType,
                        amount: currencyFormat(+reportDetails.GetExpenseDetails[i].Total),
                        exID: reportDetails.GetExpenseDetails[i].ExpenseTypeID,
                    });
                    expenseTotal += +reportDetails.GetExpenseDetails[i].Total;
                }
                expenseData.push({
                    category: 'TOTAL EXPENSES',
                    amount: currencyFormat(expenseTotal),
                });

                for (let i = 0; i < reportDetails.GetNOIExpenseDetails.length; i++) {
                    if (reportDetails.GetNOIExpenseDetails[i].ExpenseType === 'Escrow Reimbursement') {
                        nonOpExpenseData.push({
                            category: reportDetails.GetNOIExpenseDetails[i].ExpenseType,
                            amount: '(' + currencyFormat(+reportDetails.GetNOIExpenseDetails[i].TotalCredit) + ')',
                            exID: 'noop' + reportDetails.GetNOIExpenseDetails[i].ExpenseTypeID,
                        });
                        nonOpExpenseTotal -= +reportDetails.GetNOIExpenseDetails[i].TotalCredit;
                    } else {
                        nonOpExpenseData.push({
                            category: reportDetails.GetNOIExpenseDetails[i].ExpenseType,
                            amount: currencyFormat(+reportDetails.GetNOIExpenseDetails[i].TotalDebit),
                            exID: 'noop' + reportDetails.GetNOIExpenseDetails[i].ExpenseTypeID,
                        });
                        nonOpExpenseTotal += +reportDetails.GetNOIExpenseDetails[i].TotalDebit;
                    }
                }
                nonOpExpenseData.push({
                    category: 'TOTAL NON OPERATING EXPENSES',
                    amount: currencyFormat(nonOpExpenseTotal),
                });

                summaryData.push({
                    category: 'Gross Income',
                    amount: currencyFormat(otherTotal),
                });
                summaryData.push({
                    category: 'Operating Expenses',
                    amount: currencyFormat(expenseTotal),
                });
                if (otherTotal - expenseTotal > 0) {
                    summaryData.push({
                        category: 'NOI',
                        amount: currencyFormat(otherTotal - expenseTotal),
                    });
                } else {
                    summaryData.push({
                        category: 'NOI',
                        amount: '(' + currencyFormat(expenseTotal - otherTotal) + ')',
                    });
                }
                summaryData.push({
                    category: 'Non Operating Expenses',
                    amount: currencyFormat(nonOpExpenseTotal),
                });
                if (otherTotal - expenseTotal - nonOpExpenseTotal > 0) {
                    summaryData.push({
                        category: 'Net Income',
                        amount: currencyFormat(otherTotal - expenseTotal - nonOpExpenseTotal),
                    });
                } else {
                    summaryData.push({
                        category: 'Net Income',
                        amount: '(' + currencyFormat(expenseTotal + nonOpExpenseTotal - otherTotal) + ')',
                    });
                }
                data[props.selectedValue[i].name] = [
                    { category: 'Income' },
                    ...incomeData,
                    { category: 'Expenses' },
                    ...expenseData,
                    { category: 'Non Operating Expenses' },
                    ...nonOpExpenseData,
                    { category: 'Summary' },
                    ...summaryData,
                ];
            }
            setTableData(data);
            setLoading(false);
        }
        fetchData();
    }, [props.selectedValue]);
    if (loading) {
        return (
            <RctCollapsibleCard colClasses="col-xs-12 col-sm-12 col-md-12" heading={'Loading Reports...'}>
                <LinearProgress />
            </RctCollapsibleCard>
        );
    }

    const data = props.selectedValue[0] ? tableData[props.selectedValue[0].name] : [];

    const incomeColumns = [
        {
            name: 'category',
            label: 'Category',
            options: {
                customBodyRender: (value, tableMeta) => {
                    const dID = data[tableMeta.rowIndex]?.dID;
                    const exId = data[tableMeta.rowIndex]?.exID;
                    const isTitle = ['Income', 'Expenses', 'Non Operating Expenses', 'Summary'].includes(value);
                    if (isTitle && !data[tableMeta.rowIndex]?.amount)
                        return <span style={{ fontWeight: 'bold', fontSize: '1.25rem' }}>{value}</span>;

                    if (dID)
                        return (
                            <Link
                                onClick={() => {
                                    setReportType('income');
                                    setLineItem(true);

                                    setItemDepositID(dID);
                                }}
                            >
                                {value}
                            </Link>
                        );

                    if (exId) {
                        return (
                            <Link
                                onClick={() => {
                                    setReportType(exId.toString().includes('noop') ? 'nonop' : 'expense');
                                    setLineItem(true);
                                    setExId(exId.toString().includes('noop') ? parseInt(exId.replace('noop', '')) : exId);
                                }}
                            >
                                {value}
                            </Link>
                        );
                    }

                    return value;
                },
            },
        },
        { name: 'incomeType', label: 'Income Type' },
        { name: 'amount', label: 'Amount' },
    ];

    return (
        <div>
            {lineItem ? (
                <ProfitLossLineItem
                    query={{ ...props.reportSearch, exID, dID }}
                    options={props.reportOptions}
                    type={reportType}
                    back={() => setLineItem(false)}
                />
            ) : (
                <div>
                    <div className="page-title d-flex justify-content-between align-items-center">
                        <div className="page-title-wrap">
                            <h2>
                                <span>
                                    {/* {props.reportName +
                                        ' - ' +
                                        filteredName.PropertyName +
                                        ' - Date Range of Report: ' +
                                        props.startDate +
                                        ' to ' +
                                        props.endDate} */}
                                    {props.reportName}
                                </span>
                            </h2>
                        </div>
                    </div>
                    <div className="row mb-4">
                        {props.selectedValue.map((property) => {
                            return (
                                <div className="col-sm-12 col-md-12 col-xl-12 mb-4">
                                    <MuiThemeProvider theme={Constants.getMuiTheme()}>
                                        <MUIDataTable
                                            title={property.name}
                                            data={tableData[property.name]}
                                            columns={incomeColumns}
                                            options={{
                                                ...props.reportOptions,
                                                responsive: false,
                                                downloadOptions: {
                                                    filename: 'P&L - ' + props.selectedValue.map((p) => p.name).join(', '),
                                                    separator: ',',
                                                    filterOptions: {
                                                        useDisplayedColumnsOnly: true,
                                                        useDisplayedRowsOnly: true,
                                                    },
                                                },

                                                onDownload: (buildHead, buildBody, columns, data) => {
                                                    // Additional rows to be added to the CSV
                                                    var additionalData = [];
                                                    for (let i = 0; i < Object.values(tableData).length; i++) {
                                                        const data = Object.values(tableData)[i];
                                                        additionalData = [
                                                            ...additionalData,
                                                            { category: Object.keys(tableData)[i] },
                                                        ];
                                                        additionalData.push({ category: '' });
                                                        additionalData = [...additionalData, ...data];
                                                        additionalData.push({ category: '' });
                                                    }

                                                    // Combine original data with additional rows
                                                    // const combinedData = additionalData.map((item) => {
                                                    //     return {
                                                    //         data: Object.values(item),
                                                    //     };
                                                    // });
                                                    const combinedData = [
                                                        ...additionalData.map((item) => ({
                                                            data: columns.map((col) => item[col.name]),
                                                        })),
                                                    ];
                                                    console.log('combinedData', combinedData);
                                                    // Create custom CSV head and body
                                                    const csvHead = buildHead(columns);
                                                    const csvBody = buildBody(combinedData, columns);

                                                    return `${csvHead}${csvBody}`; // Combine head and body to form the CSV content
                                                },

                                                // onDownload: (buildHead, buildBody, columns, data) => {
                                                //     const csv = buildHead(columns) + buildBody(data);
                                                //     const customHeading = `${props.reportName} - ${filteredName.PropertyName} - Date Range of Report: ${props.startDate} to ${props.endDate}`;
                                                //     const csvWithHeading = `\n${customHeading}\n\n${csv}`;
                                                //     return csvWithHeading;
                                                // },
                                            }}
                                        />
                                    </MuiThemeProvider>
                                </div>
                            );
                        })}
                    </div>
                    {/* <div className="row mb-4">
                        <div className="col-sm-12 col-md-12 col-xl-12">
                            <MuiThemeProvider theme={Constants.getMuiTheme()}>
                                <MUIDataTable
                                    title={'Income'}
                                    data={incomeData}
                                    columns={incomeColumns}
                                    options={props.reportOptions}
                                />
                            </MuiThemeProvider>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-sm-12 col-md-12 col-xl-12">
                            <MuiThemeProvider theme={Constants.getMuiTheme()}>
                                <MUIDataTable
                                    title={'Expenses'}
                                    data={expenseData}
                                    columns={expenseColumns}
                                    options={props.reportOptions}
                                />
                            </MuiThemeProvider>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-sm-12 col-md-12 col-xl-12">
                            <MuiThemeProvider theme={Constants.getMuiTheme()}>
                                <MUIDataTable
                                    title={'Non Operating Expenses'}
                                    data={nonOpExpenseData}
                                    columns={nonOpExpenseColumns}
                                    options={props.reportOptions}
                                />
                            </MuiThemeProvider>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-xl-12">
                            <MuiThemeProvider theme={Constants.getMuiTheme()}>
                                <MUIDataTable
                                    title={'Summary'}
                                    data={summaryData}
                                    columns={summaryColumns}
                                    options={props.reportOptions}
                                />
                            </MuiThemeProvider>
                        </div>
                    </div> */}
                </div>
            )}
        </div>
    );
};

export default ProfitAndLoss;
