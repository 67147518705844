import React, { useState, useRef, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../state/index';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SessionTimeoutDialog from './SessionTimeoutDialog';

let countdownInterval;
var timeout;
let idle_timeout = 600_000;
const promptBeforeIdle = 60_000;

const SessionTimeout = () => {
    const history = useHistory();

    const login = useSelector((state) => state.login);
    const dispatch = useDispatch();
    const { logout } = bindActionCreators(actionCreators, dispatch);

    const [remaining, setRemaining] = useState(idle_timeout);

    const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);

    const onIdle = () => {
        console.log('Idle');
        handleLogout();
    };

    const onActive = () => {
        console.log('Active');
        // setTimeoutModalOpen(false);
    };

    const onPrompt = () => {
        console.log('Prompted');
        setTimeoutModalOpen(true);
    };

    const { getRemainingTime, activate } = useIdleTimer({
        onIdle,
        onActive,
        onPrompt,
        timeout: idle_timeout,
        promptBeforeIdle: promptBeforeIdle,
        throttle: 500,
    });

    useEffect(() => {
        let interval;
        if (login.logged) {
            interval = setInterval(() => {
                setRemaining(Math.ceil(getRemainingTime() / 1000));
            }, 500);
        }

        return () => {
            clearInterval(interval);
        };
    });
    // const onAction = (e) => {
    //     // timer reset automatically.
    //     if (!timeoutModalOpen) {
    //         clearSessionInterval();
    //         clearSessionTimeout();
    //     }
    // };

    // const onIdle = () => {
    //     console.log('IDEL', new Date());
    //     const delay = 1000 * 5;
    //     if (login.logged && !timeoutModalOpen) {
    //         timeout = setTimeout(() => {
    //             let countDown = 60;
    //             setTimeoutModalOpen(true);
    //             setTimeoutCountdown(countDown);
    //             countdownInterval = setInterval(() => {
    //                 if (countDown > 0) {
    //                     setTimeoutCountdown(--countDown);
    //                 } else {
    //                     handleLogout();
    //                 }
    //             }, 1000);
    //         }, delay);
    //     }
    // };

    // const clearSessionTimeout = () => {
    //     clearTimeout(timeout);
    // };

    // const clearSessionInterval = () => {
    //     clearInterval(countdownInterval);
    // };

    const handleLogout = () => {
        setTimeoutModalOpen(false);
        logout();
        history.push('/login');
    };

    const handleContinue = () => {
        setTimeoutModalOpen(false);
    };

    useEffect(() => {
        const timeTillPrompt = Math.max(remaining - promptBeforeIdle / 1000, 0);
        if (login.logged && !timeoutModalOpen) {
            setTimeoutModalOpen(timeTillPrompt === 0);
        }
    }, [remaining]);

    return (
        <>
            {/* <IdleTimer ref={idleTimer} onAction={onAction} onIdle={onIdle} debounce={250} timeout={idle_timeout} /> */}

            <SessionTimeoutDialog
                countdown={remaining}
                onContinue={handleContinue}
                onLogout={handleLogout}
                open={timeoutModalOpen}
            />
        </>
    );
};

export default SessionTimeout;
