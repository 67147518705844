import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import LinearProgress from '../Util/LinearProgress';
import PDFViewer from '../Helpers/PDFViewer/PDFViewer';
import ReactDOMServer from 'react-dom/server';
import * as vendorsAPI from '../../Api/vendors';
import { ToWords } from 'to-words';

const pageStyle = {
    paddingLeft: '3%',
    paddingRight: '3%',
    paddingTop: '1%',
};

const PrintBill = (props) => {
    const history = useHistory();
    const toWords = new ToWords({
        localeCode: 'en-US',
        converterOptions: {
            currency: true,
            ignoreDecimal: false,
            ignoreZeroCurrency: false,
            doNotAddOnly: true,
            currencyOptions: {
                // can be used to override defaults for the selected locale
                name: 'Dollar',
                plural: 'Dollars',
                symbol: '$',
                fractionalUnit: {
                    name: 'Cent',
                    plural: 'Cents',
                    symbol: '',
                },
            },
        },
    });
    const [vendor, setVendor] = useState({});
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const load = async () => {
            setLoading(true);
            const bill = props.location.state?.vendor;
            if (!bill) history.push('/bills/unpaidBills');

            const vendor = await vendorsAPI.getByID(bill.vendorid);
            console.log('vendor', vendor);
            setVendor({ ...vendor, ...bill });
            setLoading(false);
        };
        load();
    }, [props]);

    if (loading) {
        return (
            <RctCollapsibleCard colClasses="col-xs-12 col-sm-12 col-md-12" heading={'Printing Bill...'}>
                <LinearProgress />
            </RctCollapsibleCard>
        );
    }
    const formatDate = (dateStr) => {
        const [year, month, day] = dateStr?.split('-');
        let newDate = `${month}/${day}/${year}`;
        return newDate;
    };

    const data = () => {
        return ReactDOMServer.renderToStaticMarkup(
            <div id="AllTenantsStatementDiv" style={pageStyle}>
                <div className="row">
                    <div className="col-sm-4 offset-md-4">
                        <p style={{ paddingTop: '20px', textAlign: 'right', fontSize: '22px' }}>
                            Date: {formatDate(vendor.invoiceDate.split('T')[0])}
                        </p>
                    </div>
                    <div className="col-sm-4 offset-md-4">
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                fontSize: '18px',
                                paddingTop: '35px',
                            }}
                        >
                            <div style={{ textAlign: 'left' }}>
                                <p>{vendor.vendorName}</p>
                                <p>{toWords.convert(vendor.amount, { currency: true })}</p>
                                <p>{vendor.VendorAddress1}</p>
                                <p>{vendor.VendorAddress2}</p>
                                <p>{`${vendor.VendorCity} ${vendor.VendorState} ${vendor.VendorZip}`}</p>
                            </div>
                            <div>
                                <p style={{ textAlign: 'right', fontSize: '20px' }}> ${vendor.amount}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>,
        );
    };
    const html = data();
    return <PDFViewer html={html} />;
};

export default PrintBill;
