import axios from 'axios';
import * as Constants from '../components/Util/constants';

const URL = Constants.REQ_URL;

export const get = async (propertyID) => {
    let output = [];
    await axios.get(`${URL}/units/get/${propertyID}`)
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const replicateUnit = async (data) => {
    let output = -1;
    await axios.post(`${URL}/units/replicateUnit`, data)
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const deleteUnit = async (data) => {
    let output = -1;
    await axios.post(`${URL}/units/delete`, data)
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const deleteUnitPhoto = async (unitPhotoID) => {
    let output = -1;
    await axios.get(`${URL}/units/deleteUnitPhoto/${unitPhotoID}`)
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getUnitPhotoVideos = async (unitTypeID) => {
    let output = null;
    await axios.get(`${URL}/units/getUnitPhotoVideos/${unitTypeID}`)
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getMarketingFileTypes = async () => {
    let output = null;
    await axios.get(`${URL}/units/getMarketingFileTypes`)
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const uploadUnitFile = async (data) => {
    let output = 0;
        
    var formData = new FormData();
    formData.append('propertyID', data.propertyID);
    formData.append('userID', data.userID);
    formData.append('file', data.file);
    formData.append('unitTypeID', data.unitTypeID);
    formData.append('marketingFileTypeID', data.marketingFileTypeID);
    formData.append('fileName', data.fileName);
        
    await axios({
        method: "post",
        url: `${URL}/units/uploadUnitFile`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (res) {
            output = res.data;
        });

    console.log('output', output);
    
    return output;
}

export const addUnit = async (data) => {
    let output = -1;
    await axios.post(`${URL}/units/addUnit`, data)
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getUnitSelectValues = async () => {
    let output = null;
    await axios.get(`${URL}/units/getUnitSelectValues`)
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getEditUnit = async (unitID) => {
    let output = null;
    await axios.get(`${URL}/units/getEditUnit/${unitID}`)
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const editUnit = async (data) => {
    let output = {};
    await axios.post(`${URL}/units/editUnit`, data)
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const confirmUpdateUnit = async (data) => {
    let output = {};
    await axios.post(`${URL}/units/confirmUpdateUnit`, data)
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getPostVacanciesUnits = async (propertyID) => {
    let output = [];
    await axios.get(`${URL}/units/getPostVacanciesUnits/${propertyID}`)
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const postVacancies = async (data) => {
    let output = {};
    await axios.post(`${URL}/units/postVacancies`, data)
        .then((res) => {
            output = res.data;
        });
    return output;
};
