import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import * as Constants from "../Util/constants";
import * as reportsAPI from "../../Api/reports";
import { currencyFormat } from "./helperFunctions";
import RctCollapsibleCard from "../Helpers/RctCollapsibleCard/RctCollapsibleCard";
import LinearProgress from "../Util/LinearProgress";

const DownUnit = (props) => {
  const [loading, setLoading] = useState(false);
  const [downData, setDownData] = useState([]);
  const propertyName = useSelector((state) => state.login.properties);
  const [filteredName] = propertyName.filter(
    (property) => property.PropertyID === props.propertyId
  );

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const reportDetails = await reportsAPI.getDownUnit(props.reportSearch);
      const reportTotals = await reportsAPI.getDownUnitTotal(
        props.reportSearch
      );
      for (let i = 0; i < reportDetails.length; i++) {
        let detailString = "";
        for (let j = 0; j < reportDetails[i].checkregisters.length; j++) {
          detailString +=
            reportDetails[i].checkregisters[j].vendor.VendorName +
            " " +
            currencyFormat(
              +reportDetails[i].checkregisters[j].Amount
            ).toString() +
            " Memo: " +
            reportDetails[i].checkregisters[j].Memo +
            (j !== reportDetails[i].checkregisters.length - 1 ? " --- " : "");
        }
        downData.push({
          unit: reportDetails[i].UnitName,
          typeOfUnit: reportDetails[i].unittype.UnitType,
          daysOnMarket: Math.floor(
            (new Date().getTime() -
              new Date(reportDetails[i].VacantDate).getTime()) /
              (24 * 60 * 60 * 1000)
          ),
          detail: detailString,
          totalCostSummary: currencyFormat(
            reportTotals[i].checkregisters.length === 0
              ? 0
              : +reportTotals[i].checkregisters[0].AmountTotal
          ),
        });
      }
      setLoading(false);
    }
    fetchData();
  }, []);
  if (loading) {
    return (
      <RctCollapsibleCard
        colClasses="col-xs-12 col-sm-12 col-md-12"
        heading={"Loading Reports..."}
      >
        <LinearProgress />
      </RctCollapsibleCard>
    );
  }
  const downColumns = [
    { name: "unit", label: "Unit" },
    { name: "typeOfUnit", label: "Type of Unit" },
    { name: "daysOnMarket", label: "Days on Market" },
    { name: "detail", label: "Detail" },
    { name: "totalCostSummary", label: "Total Cost Summary" },
  ];
  return (
    <div>
      <div className="page-title d-flex justify-content-between align-items-center">
        <div className="page-title-wrap">
          <h2>
            <span>{props.reportName + " - " + filteredName.PropertyName}</span>
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-xl-12">
          <MuiThemeProvider theme={Constants.getMuiTheme()}>
            <MUIDataTable
              title={"Application Summary"}
              data={downData}
              columns={downColumns}
              options={props.reportOptions}
            />
          </MuiThemeProvider>
        </div>
      </div>
    </div>
  );
};

export default DownUnit;
