import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import * as Constants from '../Util/constants';
import * as reportsAPI from '../../Api/reports';
import { currencyFormat } from './helperFunctions';
import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import LinearProgress from '../Util/LinearProgress';
import moment from 'moment';

const VacancyReport = (props) => {
    const [loading, setLoading] = useState(false);
    const [vacantUnits, setVacantUnits] = useState([]);
    const [lossVacancy, setLossVacancy] = useState([]);
    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            for (let i = 0; i < props.selectedValue.length; i++) {
                props.reportSearch.pID = props.selectedValue[i].id;
                const allUnits = await reportsAPI.getVacancy(props.reportSearch);
                var todaysDate = new Date();
                if (!vacantUnits[props.selectedValue[i].name]) {
                    for (let j = 0; j < allUnits.length; j++) {
                        const moveOutDate = moment(allUnits[j]?.VacantDate).format('MM-DD-yyyy');

                        if (todaysDate > new Date(allUnits[j].VacantDate)) {
                            if (vacantUnits[props.selectedValue[i].name]) {
                                lossVacancy[props.selectedValue[i].name] += -allUnits[j]?.unittype.UnitCharge;
                                vacantUnits[props.selectedValue[i].name].push({
                                    unit: allUnits[j]?.UnitName,
                                    unitType: allUnits[j]?.unittype.UnitType,
                                    moveOutDate: moveOutDate,
                                    daysOnMarket: Math.floor(
                                        (new Date().getTime() - new Date(allUnits[j]?.VacantDate).getTime()) /
                                            (24 * 60 * 60 * 1000),
                                    ),
                                    marketRent: currencyFormat(+allUnits[j]?.unittype.UnitCharge),
                                    lossToVacancy: currencyFormat(lossVacancy[props.selectedValue[i].name]),
                                });
                            } else {
                                lossVacancy[props.selectedValue[i].name] = -allUnits[j]?.unittype.UnitCharge;
                                vacantUnits[props.selectedValue[i].name] = [
                                    {
                                        unit: allUnits[j]?.UnitName,
                                        unitType: allUnits[j]?.unittype.UnitType,
                                        moveOutDate: moveOutDate,
                                        daysOnMarket: Math.floor(
                                            (new Date().getTime() - new Date(allUnits[j]?.VacantDate).getTime()) /
                                                (24 * 60 * 60 * 1000),
                                        ),
                                        marketRent: currencyFormat(+allUnits[j]?.unittype.UnitCharge),
                                        lossToVacancy: currencyFormat(lossVacancy[props.selectedValue[i].name]),
                                    },
                                ];
                            }
                        }
                    }
                }
            }
            setLoading(false);
        }
        fetchData();
    }, [props.selectedValue, props.reportSearch, vacantUnits]);
    const vacancyColumns = [
        { name: 'unit', label: 'Unit' },
        { name: 'unitType', label: 'Unit Type' },
        { name: 'moveOutDate', label: 'Move Out Date' },
        { name: 'daysOnMarket', label: 'Days on Market' },
        { name: 'marketRent', label: 'Market Rent' },
        { name: 'lossToVacancy', label: 'Loss to Vacancy' },
    ];
    if (loading) {
        return (
            <RctCollapsibleCard colClasses="col-xs-12 col-sm-12 col-md-12" heading={'Loading Reports...'}>
                <LinearProgress />
            </RctCollapsibleCard>
        );
    }
    return (
        <div>
            <div className="page-title d-flex justify-content-between align-items-center">
                <div className="page-title-wrap">
                    <h2>
                        <span>
                            {props.reportName + ' - Date Range of Report: ' + props.startDate + ' to ' + props.endDate}
                        </span>
                    </h2>
                </div>
            </div>
            <div className="row">
                {props.selectedValue.map((property) => {
                    const loss = vacantUnits[property.name]?.reduce(
                        (sum, item) => sum + parseFloat(item.lossToVacancy.replace(/[,$]/g, '').replace(/[()]/g, '')),
                        0,
                    ) || 0;
                    const title = `${property.name}: Vacant Units ${vacantUnits[property.name]?.length || 0} - ${currencyFormat(
                        loss,
                    )}`;

                    return (
                        <div className="col-sm-12 col-md-12 col-xl-12">
                            <MuiThemeProvider theme={Constants.getMuiTheme()}>
                                <MUIDataTable
                                    title={title}
                                    data={vacantUnits[property.name]}
                                    columns={vacancyColumns}
                                    options={{
                                        ...props.reportOptions,
                                        downloadOptions: {
                                            filename: `Vacancy Report - ${property.name} - ${
                                                props.startDate + ' to ' + props.endDate
                                            }`,
                                        },
                                        onDownload: (buildHead, buildBody, columns, data) => {
                                            // Additional rows to be added to the CSV
                                            const csv =
                                                buildHead(columns) +
                                                buildBody(data);
                                            const customHeading = title;
                                            const csvWithHeading = `\n${customHeading}\n\n${csv}`;
                                            return csvWithHeading; 
                                        },
                                    }}
                                />
                            </MuiThemeProvider>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default VacancyReport;

