import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import * as Constants from '../Util/constants';
import * as reportsAPI from '../../Api/reports';
import { currencyFormat, formatDate } from './helperFunctions';
import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import LinearProgress from '../Util/LinearProgress';
import { Print } from '@material-ui/icons';
import axios from 'axios';

const RentRoll = (props) => {
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState({});
    const propertyName = useSelector((state) => state.login.properties);
    const [filteredName] = propertyName.filter((property) => property.PropertyID === props.propertyId);

    var [tenantRentValue, setTenantRentValue] = useState(0);
    var [housingAmountValue, setHousingAmountValue] = useState(0);
    var [petRentValue, setPetRentValue] = useState(0);
    var [parkingChargesValue, setParkingChargesValue] = useState(0);
    var [utilitiesValue, setUtilitiesValue] = useState(0);
    var [storageRentValue, setStorageRentValue] = useState(0);
    var [rvRentValue, setRvRentValue] = useState(0);
    var [camValue, setCamValue] = useState(0);
    var [taxesFeeValue, setTaxesFeeValue] = useState(0);
    var [totalRentValue, setTotalRentValue] = useState(0);
    var [lossToVacancyValue, setLossToVacancyValue] = useState(0);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            console.log('props.reportSearch', props.reportSearch);
            var data = {};
            for (let i = 0; i < props.selectedValue.length; i++) {
                let units = [];
                props.reportSearch.pID = props.selectedValue[i].id;
                const allUnits = await reportsAPI.getRentRollUnits(props.reportSearch);
                const unitInfo = await reportsAPI.getRentRollUnitInfo(props.reportSearch);
                let unitIndex = 0;
                let infoIndex = 0;
                while (unitIndex < allUnits.length || infoIndex < unitInfo.length) {
                    if (unitInfo.length === 0 || allUnits.length === 0) {
                        if (unitInfo.length === 0) {
                            units.push({
                                unit: allUnits[unitIndex].UnitName,
                                unitType: allUnits[unitIndex].unittype.UnitType,
                                squareFootage: allUnits[unitIndex].unittype.SquareFootage,
                                tenant: 'Vacant',
                                lossToVacancy: currencyFormat(+allUnits[unitIndex].unittype.UnitCharge),
                            });
                            setLossToVacancyValue((lossToVacancyValue += +allUnits[unitIndex].unittype.UnitCharge));
                            unitIndex += 1;
                        }
                        if (allUnits.length === 0) {
                            units.push({
                                unit: unitInfo[infoIndex].UnitName,
                                unitType: unitInfo[infoIndex].unittype.UnitType,
                                squareFootage: unitInfo[infoIndex].unittype.SquareFootage,
                                tenant:
                                    unitInfo[infoIndex].tenants[0].TenantFName +
                                    ' ' +
                                    unitInfo[infoIndex].tenants[0].TenantLName,
                                originalMoveIn: formatDate(unitInfo[infoIndex].tenants[0].MoveInDate),
                                leaseStartDate: formatDate(unitInfo[infoIndex].tenants[0].LeaseStartDate),
                                leaseEndDate: formatDate(unitInfo[infoIndex].tenants[0].LeaseEndDate),
                                tenantRent: currencyFormat(+unitInfo[infoIndex].tenants[0].RentalAmount),
                                housingAmount: currencyFormat(+unitInfo[infoIndex].tenants[0].HousingAmount),
                                petRent: currencyFormat(+unitInfo[infoIndex].tenants[0].PetRent),
                                parkingCharges: currencyFormat(+unitInfo[infoIndex].tenants[0].ParkingCharge),
                                utilities: currencyFormat(+unitInfo[infoIndex].tenants[0].UtilityCharge),
                                storageRent: currencyFormat(+unitInfo[infoIndex].tenants[0].StorageCharge),
                                rvRent: currencyFormat(+unitInfo[infoIndex].tenants[0].RVCharge),
                                cam: currencyFormat(+unitInfo[infoIndex].tenants[0].CAM),
                                taxesFee: currencyFormat(+unitInfo[infoIndex].tenants[0].TaxesFee),
                                totalRent: currencyFormat(
                                    +unitInfo[infoIndex].tenants[0].RentalAmount +
                                        +unitInfo[infoIndex].tenants[0].HousingAmount +
                                        +unitInfo[infoIndex].tenants[0].PetRent +
                                        +unitInfo[infoIndex].tenants[0].ParkingCharge +
                                        +unitInfo[infoIndex].tenants[0].UtilityCharge +
                                        +unitInfo[infoIndex].tenants[0].StorageCharge +
                                        +unitInfo[infoIndex].tenants[0].RVCharge +
                                        +unitInfo[infoIndex].tenants[0].CAM +
                                        +unitInfo[infoIndex].tenants[0].TaxesFee,
                                ),
                            });
                            setTenantRentValue((tenantRentValue += +unitInfo[infoIndex].tenants[0].RentalAmount));
                            setHousingAmountValue((housingAmountValue += +unitInfo[infoIndex].tenants[0].HousingAmount));
                            setPetRentValue((petRentValue += +unitInfo[infoIndex].tenants[0].PetRent));
                            setParkingChargesValue((parkingChargesValue += +unitInfo[infoIndex].tenants[0].ParkingCharge));
                            setUtilitiesValue((utilitiesValue += +unitInfo[infoIndex].tenants[0].UtilityCharge));
                            setStorageRentValue((storageRentValue += +unitInfo[infoIndex].tenants[0].StorageCharge));
                            setRvRentValue((rvRentValue += +unitInfo[infoIndex].tenants[0].RVCharge));
                            setCamValue((camValue += +unitInfo[infoIndex].tenants[0].CAM));
                            setTaxesFeeValue((taxesFeeValue += +unitInfo[infoIndex].tenants[0].TaxesFee));
                            setTotalRentValue(
                                (totalRentValue +=
                                    +unitInfo[infoIndex].tenants[0].RentalAmount +
                                    +unitInfo[infoIndex].tenants[0].HousingAmount +
                                    +unitInfo[infoIndex].tenants[0].PetRent +
                                    +unitInfo[infoIndex].tenants[0].ParkingCharge +
                                    +unitInfo[infoIndex].tenants[0].UtilityCharge +
                                    +unitInfo[infoIndex].tenants[0].StorageCharge +
                                    +unitInfo[infoIndex].tenants[0].RVCharge +
                                    +unitInfo[infoIndex].tenants[0].CAM +
                                    +unitInfo[infoIndex].tenants[0].TaxesFee),
                            );
                            infoIndex += 1;
                        }
                    }
                    if (allUnits.length > 0 && unitInfo.length > 0) {
                        if (unitIndex === allUnits.length - 1 && infoIndex === unitInfo.length) {
                            units.push({
                                unit: allUnits[unitIndex].UnitName,
                                unitType: allUnits[unitIndex].unittype.UnitType,
                                squareFootage: allUnits[unitIndex].unittype.SquareFootage,
                                tenant: 'Vacant',
                                lossToVacancy: currencyFormat(+allUnits[unitIndex].unittype.UnitCharge),
                            });
                            setLossToVacancyValue((lossToVacancyValue += +allUnits[unitIndex].unittype.UnitCharge));
                            unitIndex += 1;
                        } else if (allUnits[unitIndex].UnitName === unitInfo[infoIndex].UnitName) {
                            const docs = unitInfo[infoIndex].tenants[0].documents.filter(
                                (d) => d.documenttypeId === 1 && d.DocumentName !== 'undefined',
                            );
                            const links = docs.map(
                                (doc) =>
                                    `https://myirent.com/rent/TenantFiles/${unitInfo[infoIndex].PropertyID}/${doc.TenantID}/${doc.DocumentName}`,
                            );

                            // const links = docs.map(
                            //     (doc) =>
                            //         `https://myirent.com/rent/TenantFiles/${props.propertyId}/${
                            //             unitInfo[infoIndex].tenants[0].id
                            //         }/${encodeURIComponent(doc.DocumentName, 'utf-8')}`,
                            // );

                            units.push({
                                unit: unitInfo[infoIndex].UnitName,
                                unitType: unitInfo[infoIndex].unittype.UnitType,
                                squareFootage: unitInfo[infoIndex].unittype.SquareFootage,
                                tenant:
                                    unitInfo[infoIndex].tenants[0].TenantFName +
                                    ' ' +
                                    unitInfo[infoIndex].tenants[0].TenantLName,
                                lease: links.join('\n'),
                                originalMoveIn: formatDate(unitInfo[infoIndex].tenants[0].MoveInDate),
                                leaseStartDate: formatDate(unitInfo[infoIndex].tenants[0].LeaseStartDate),
                                leaseEndDate: formatDate(unitInfo[infoIndex].tenants[0].LeaseEndDate),
                                tenantRent: currencyFormat(+unitInfo[infoIndex].tenants[0].RentalAmount),
                                housingAmount: currencyFormat(+unitInfo[infoIndex].tenants[0].HousingAmount),
                                petRent: currencyFormat(+unitInfo[infoIndex].tenants[0].PetRent),
                                parkingCharges: currencyFormat(+unitInfo[infoIndex].tenants[0].ParkingCharge),
                                utilities: currencyFormat(+unitInfo[infoIndex].tenants[0].UtilityCharge),
                                storageRent: currencyFormat(+unitInfo[infoIndex].tenants[0].StorageCharge),
                                rvRent: currencyFormat(+unitInfo[infoIndex].tenants[0].RVCharge),
                                cam: currencyFormat(+unitInfo[infoIndex].tenants[0].CAM),
                                taxesFee: currencyFormat(+unitInfo[infoIndex].tenants[0].TaxesFee),
                                totalRent: currencyFormat(
                                    +unitInfo[infoIndex].tenants[0].RentalAmount +
                                        +unitInfo[infoIndex].tenants[0].HousingAmount +
                                        +unitInfo[infoIndex].tenants[0].PetRent +
                                        +unitInfo[infoIndex].tenants[0].ParkingCharge +
                                        +unitInfo[infoIndex].tenants[0].UtilityCharge +
                                        +unitInfo[infoIndex].tenants[0].StorageCharge +
                                        +unitInfo[infoIndex].tenants[0].RVCharge +
                                        +unitInfo[infoIndex].tenants[0].CAM +
                                        +unitInfo[infoIndex].tenants[0].TaxesFee,
                                ),
                            });
                            setTenantRentValue((tenantRentValue += +unitInfo[infoIndex].tenants[0].RentalAmount));
                            setHousingAmountValue((housingAmountValue += +unitInfo[infoIndex].tenants[0].HousingAmount));
                            setPetRentValue((petRentValue += +unitInfo[infoIndex].tenants[0].PetRent));
                            setParkingChargesValue((parkingChargesValue += +unitInfo[infoIndex].tenants[0].ParkingCharge));
                            setUtilitiesValue((utilitiesValue += +unitInfo[infoIndex].tenants[0].UtilityCharge));
                            setStorageRentValue((storageRentValue += +unitInfo[infoIndex].tenants[0].StorageCharge));
                            setRvRentValue((rvRentValue += +unitInfo[infoIndex].tenants[0].RVCharge));
                            setCamValue((camValue += +unitInfo[infoIndex].tenants[0].CAM));
                            setTaxesFeeValue((taxesFeeValue += +unitInfo[infoIndex].tenants[0].TaxesFee));
                            setTotalRentValue(
                                (totalRentValue +=
                                    +unitInfo[infoIndex].tenants[0].RentalAmount +
                                    +unitInfo[infoIndex].tenants[0].HousingAmount +
                                    +unitInfo[infoIndex].tenants[0].PetRent +
                                    +unitInfo[infoIndex].tenants[0].ParkingCharge +
                                    +unitInfo[infoIndex].tenants[0].UtilityCharge +
                                    +unitInfo[infoIndex].tenants[0].StorageCharge +
                                    +unitInfo[infoIndex].tenants[0].RVCharge +
                                    +unitInfo[infoIndex].tenants[0].CAM +
                                    +unitInfo[infoIndex].tenants[0].TaxesFee),
                            );
                            unitIndex += 1;
                            infoIndex += 1;
                        } else {
                            units.push({
                                unit: allUnits[unitIndex].UnitName,
                                unitType: allUnits[unitIndex].unittype.UnitType,
                                squareFootage: allUnits[unitIndex].unittype.SquareFootage,
                                tenant: 'Vacant',
                                lossToVacancy: currencyFormat(+allUnits[unitIndex].unittype.UnitCharge),
                            });
                            setLossToVacancyValue((lossToVacancyValue += +allUnits[unitIndex].unittype.UnitCharge));
                            unitIndex += 1;
                        }
                    }
                }
                units.push({
                    unit: 'TOTALS',
                    squareFootage: `Non-Vacant Units: ${unitInfo.length}`,
                    tenant: `Vacant Units: ${allUnits.length - unitInfo.length}`,
                    tenantRent: currencyFormat(tenantRentValue),
                    housingAmount: currencyFormat(housingAmountValue),
                    petRent: currencyFormat(petRentValue),
                    parkingCharges: currencyFormat(parkingChargesValue),
                    utilities: currencyFormat(utilitiesValue),
                    storageRent: currencyFormat(storageRentValue),
                    rvRent: currencyFormat(rvRentValue),
                    cam: currencyFormat(camValue),
                    taxesFee: currencyFormat(taxesFeeValue),
                    totalRent: currencyFormat(totalRentValue),
                    lossToVacancy: currencyFormat(lossToVacancyValue),
                });
                data[props.selectedValue[i].name] = units;
            }

            setTableData(data);
            setLoading(false);
        }
        fetchData();
    }, [props.selectedValue]);
    if (loading) {
        return (
            <RctCollapsibleCard colClasses="col-xs-12 col-sm-12 col-md-12" heading={'Loading Reports...'}>
                <LinearProgress />
            </RctCollapsibleCard>
        );
    }

    const rentRollColumns = [
        { name: 'unit', label: 'Unit' },
        { name: 'unitType', label: 'Unit Type' },
        { name: 'squareFootage', label: 'Square Footage' },
        { name: 'tenant', label: 'Tenant' },
        {
            name: 'lease',
            label: 'Lease documents',
            options: {
                customBodyRender: (value) => {
                    if (value && value !== '') {
                        return value.split('\n').map((v) => (
                            <a target="_blank" style={{ display: 'block', marginBottom: '5px' }} href={`${v}`}>
                                {v.split('/').at(-1)}
                            </a>
                        ));
                    }
                },
            },
        },
        { name: 'originalMoveIn', label: 'Original Move In', options: { print: false } },
        { name: 'leaseStartDate', label: 'Lease Start Date', options: { print: false } },
        { name: 'leaseEndDate', label: 'Lease End Date', options: { print: false } },
        { name: 'tenantRent', label: 'Tenant Rent' },
        { name: 'housingAmount', label: 'Housing Amount' },
        { name: 'petRent', label: 'Pet Rent' },
        { name: 'parkingCharges', label: 'Parking Charges' },
        { name: 'utilities', label: 'Utilities' },
        { name: 'storageRent', label: 'Storage Rent', options: { print: false } },
        { name: 'rvRent', label: 'RV Rent' },
        { name: 'cam', label: 'CAM' },
        { name: 'taxesFee', label: 'Tax Fees' },
        { name: 'totalRent', label: 'Total Rent' },
        { name: 'lossToVacancy', label: 'Loss To Vacancy' },
    ];
    return (
        <div>
            <div className="page-title d-flex justify-content-between align-items-center">
                <div className="page-title-wrap">
                    <h2>
                        {/* <span>{props.reportName + ' - ' + filteredName.PropertyName}</span> */}
                        <span>{props.reportName}</span>
                    </h2>
                </div>
            </div>
            <div className="row">
                {props.selectedValue.map((property) => {
                    return (
                        <div className="col-sm-12 col-md-12 col-xl-12 mb-4">
                            <MuiThemeProvider theme={Constants.getMuiTheme()}>
                                <MUIDataTable
                                    title={property.name}
                                    data={tableData[property.name]}
                                    columns={rentRollColumns}
                                    options={{
                                        ...props.reportOptions,
                                        responsive: 'simple',
                                        downloadOptions: {
                                            filename:
                                                props.reportName +
                                                ' - ' +
                                                props.selectedValue.map((p) => p.name).join(', ') +
                                                ' - ' +
                                                props.reportSearch.sDt +
                                                ' ~ ' +
                                                props.reportSearch.eDt,
                                        },
                                        onDownload: (buildHead, buildBody, columns, data) => {
                                            // Additional rows to be added to the CSV
                                            var additionalData = [];
                                            for (let i = 0; i < Object.values(tableData).length; i++) {
                                                const data = Object.values(tableData)[i];
                                                const title =
                                                    'RENT ROLL - ' +
                                                    Object.keys(tableData)[i] +
                                                    ' - ' +
                                                    props.reportSearch.sDt +
                                                    ' ~ ' +
                                                    props.reportSearch.eDt;
                                                additionalData = [...additionalData, { unit: title }];
                                                additionalData.push({ unit: '' });
                                                additionalData = [...additionalData, ...data];
                                                additionalData.push({ unit: '' });
                                            }

                                            // Combine original data with additional rows
                                            const combinedData = additionalData.map((item) => {
                                                return {
                                                    data: Object.values(item),
                                                };
                                            });
                                            console.log('combinedData', combinedData.length);
                                            // Create custom CSV head and body
                                            const csvHead = buildHead(columns);
                                            const csvBody = buildBody(combinedData, columns);

                                            return `${csvHead}${csvBody}`; // Combine head and body to form the CSV content
                                        },
                                    }}
                                />
                            </MuiThemeProvider>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default RentRoll;
