import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import * as Constants from '../Util/constants';
import * as reportsAPI from '../../Api/reports';
import { currencyFormat } from './helperFunctions';
import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import LinearProgress from '../Util/LinearProgress';

const XVacancyReport = (props) => {
  const [loading, setLoading] = useState(false);
  const [vacantUnits, setVacantUnits] = useState([]);
  const properties = useSelector((state) => state.login.properties);
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const allUnits = await reportsAPI.getXVacancyReport(props.reportSearch);
      var todaysDate = new Date();
      for (let i = 0; i < allUnits.length; i++) {
        if (todaysDate > new Date(allUnits[i].VacantDate)) {
          const propertyName = allUnits[i].property.PropertyName;
          if (vacantUnits[propertyName]) {
            vacantUnits[propertyName].push({
              unit: allUnits[i]?.UnitName,
              unitType: allUnits[i]?.unittype.UnitType,
              moveOutDate: allUnits[i]?.VacantDate,
              daysOnMarket: Math.floor(
                (new Date().getTime() -
                  new Date(allUnits[i]?.VacantDate).getTime()) /
                  (24 * 60 * 60 * 1000)
              ),
              marketRent: currencyFormat(+allUnits[i]?.unittype.UnitCharge),
              lossToVacancy: currencyFormat(-allUnits[i]?.unittype.UnitCharge),
            });
          } else {
            vacantUnits[propertyName] = [
              {
                unit: allUnits[i]?.UnitName,
                unitType: allUnits[i]?.unittype.UnitType,
                moveOutDate: allUnits[i]?.VacantDate,
                daysOnMarket: Math.floor(
                  (new Date().getTime() -
                    new Date(allUnits[i]?.VacantDate).getTime()) /
                    (24 * 60 * 60 * 1000)
                ),
                marketRent: currencyFormat(+allUnits[i]?.unittype.UnitCharge),
                lossToVacancy: currencyFormat(
                  -allUnits[i]?.unittype.UnitCharge
                ),
              },
            ];
          }
        }
      }
      setLoading(false);
    }
    fetchData();
  }, []);
  if (loading) {
    return (
      <RctCollapsibleCard
        colClasses="col-xs-12 col-sm-12 col-md-12"
        heading={'Loading Reports...'}
      >
        <LinearProgress />
      </RctCollapsibleCard>
    );
  }
  const vacancyColumns = [
    { name: 'unit', label: 'Unit' },
    { name: 'unitType', label: 'Unit Type' },
    { name: 'moveOutDate', label: 'Move Out Date' },
    { name: 'daysOnMarket', label: 'Days on Market' },
    { name: 'marketRent', label: 'Market Rent' },
    { name: 'lossToVacancy', label: 'Loss to Vacancy' },
  ];
  return (
    <div>
      <div className="page-title d-flex justify-content-between align-items-center">
        <div className="page-title-wrap">
          <h2>
            <span>
              {props.reportName +
                ' - Date Range of Report up to: ' +
                props.endDate}
            </span>
          </h2>
        </div>
      </div>
      <div className="row">
        {properties.map((property) => {
          return (
            <div className="col-sm-12 col-md-12 col-xl-12">
              <MuiThemeProvider theme={Constants.getMuiTheme()}>
                <MUIDataTable
                  title={`${property.PropertyName} Vacancy`}
                  data={vacantUnits[property.PropertyName]}
                  columns={vacancyColumns}
                  options={props.reportOptions}
                />
              </MuiThemeProvider>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default XVacancyReport;
