import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import * as Constants from "../Util/constants";
import * as reportsAPI from "../../Api/reports";
import { currencyFormat } from "./helperFunctions";
import RctCollapsibleCard from "../Helpers/RctCollapsibleCard/RctCollapsibleCard";
import LinearProgress from "../Util/LinearProgress";

const NonRecCharges = (props) => {
  const [loading, setLoading] = useState(false);
  const [chargesData, setChargesData] = useState([]);
  const propertyName = useSelector((state) => state.login.properties);
  const [filteredName] = propertyName.filter(
    (property) => property.PropertyID === props.propertyId
  );
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const reportDetails = await reportsAPI.getNonRecCharge(
        props.reportSearch
      );
      const reportTotals = await reportsAPI.getNonRecChargeTotal(
        props.reportSearch
      );
      for (let i = 0; i < reportDetails.length; i++) {
        if (+reportDetails[i].TotalRent > 0)
          chargesData.push({
            unit: reportDetails[i].tenant.unit.UnitName,
            tenant:
              reportDetails[i].tenant.TenantFName +
              " " +
              reportDetails[i].tenant.TenantLName,
            electricCharges: currencyFormat(+reportDetails[i].TotalElectric),
            waterCharges: currencyFormat(+reportDetails[i].TotalWater),
            trashCharges: currencyFormat(+reportDetails[i].TotalTrash),
            sewerCharges: currencyFormat(+reportDetails[i].TotalSewer),
            gasCharges: currencyFormat(+reportDetails[i].TotalGas),
            lateCharges: currencyFormat(+reportDetails[i].TotalLateFee),
            nsfCharges: currencyFormat(+reportDetails[i].TotalNSF),
            totalTenant: currencyFormat(+reportDetails[i].TotalRent),
          });
      }
      chargesData.push({
        unit: "TOTAL",
        electricCharges: currencyFormat(+reportTotals[0].TotalElectric),
        waterCharges: currencyFormat(+reportTotals[0].TotalWater),
        trashCharges: currencyFormat(+reportTotals[0].TotalTrash),
        sewerCharges: currencyFormat(+reportTotals[0].TotalSewer),
        gasCharges: currencyFormat(+reportTotals[0].TotalGas),
        lateCharges: currencyFormat(+reportTotals[0].TotalLateFee),
        nsfCharges: currencyFormat(+reportTotals[0].TotalNSF),
        totalTenant: currencyFormat(+reportTotals[0].TotalRent),
      });
      setLoading(false);
    }
    fetchData();
  }, []);
  if (loading) {
    return (
      <RctCollapsibleCard
        colClasses="col-xs-12 col-sm-12 col-md-12"
        heading={"Loading Reports..."}
      >
        <LinearProgress />
      </RctCollapsibleCard>
    );
  }
  const chargesColumns = [
    { name: "unit", label: "Unit" },
    { name: "tenant", label: "Tenant" },
    { name: "electricCharges", label: "Electric Charges" },
    { name: "waterCharges", label: "Water Charges" },
    { name: "trashCharges", label: "Trash Charges" },
    { name: "sewerCharges", label: "Sewer Charges" },
    { name: "gasCharges", label: "Gas Charges" },
    { name: "lateCharges", label: "Late Charges" },
    { name: "nsfCharges", label: "NSF Charges" },
    { name: "totalTenant", label: "Total Tenant" },
  ];
  return (
    <div>
      <div className="page-title d-flex justify-content-between align-items-center">
        <div className="page-title-wrap">
          <h2>
            <span>
              {props.reportName +
                " - " +
                filteredName.PropertyName +
                " - Date Range of Report: " +
                props.startDate +
                " to " +
                props.endDate}
            </span>
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-xl-12">
          <MuiThemeProvider theme={Constants.getMuiTheme()}>
            <MUIDataTable
              title={"Non Recurring Charges"}
              data={chargesData}
              columns={chargesColumns}
              options={props.reportOptions}
            />
          </MuiThemeProvider>
        </div>
      </div>
    </div>
  );
};

export default NonRecCharges;
