import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import * as Constants from "../Util/constants";
import * as reportsAPI from "../../Api/reports";
import { currencyFormat, formatDate } from "./helperFunctions";
import RctCollapsibleCard from "../Helpers/RctCollapsibleCard/RctCollapsibleCard";
import LinearProgress from "../Util/LinearProgress";

const Concession = (props) => {
  const [loading, setLoading] = useState(false);
  const [concessionData, setConcessionData] = useState([]);
  const propertyName = useSelector((state) => state.login.properties);
  const [filteredName] = propertyName.filter(
    (property) => property.PropertyID === props.propertyId
  );
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const reportDetails = await reportsAPI.getConcession(props.reportSearch);
      for (let i = 0; i < reportDetails.length; i++) {
        if (reportDetails[i].TenantID !== 0) {
          concessionData.push({
            tenant:
              reportDetails[i].tenant.TenantFName +
              " " +
              reportDetails[i].tenant.TenantLName,
            date: formatDate(reportDetails[i].TenantTransactionDate),
            amount: currencyFormat(+reportDetails[i].TransactionAmount),
            reason: reportDetails[i].Comment,
            requestedBy: reportDetails[i].user
              ? reportDetails[i].user?.UserFName +
                " " +
                reportDetails[i].user?.UserLName
              : "",
            status:
              reportDetails[i].TransactionTypeID === 2 ? "Approved" : "Denied",
          });
        }
      }
      setLoading(false);
    }
    fetchData();
  }, []);
  if (loading) {
    return (
      <RctCollapsibleCard
        colClasses="col-xs-12 col-sm-12 col-md-12"
        heading={"Loading Reports..."}
      >
        <LinearProgress />
      </RctCollapsibleCard>
    );
  }
  const concessionColumns = [
    { name: "tenant", label: "Tenant" },
    { name: "date", label: "Date" },
    { name: "amount", label: "Amount" },
    { name: "reason", label: "Reason" },
    { name: "requestedBy", label: "Requested By" },
    { name: "status", label: "Status" },
  ];

  return (
    <div>
      <div className="page-title d-flex justify-content-between align-items-center">
        <div className="page-title-wrap">
          <h2>
            <span>
              {props.reportName +
                " - " +
                filteredName.PropertyName +
                " - Date Range of Report: " +
                props.startDate +
                " to " +
                props.endDate}
            </span>
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-xl-12">
          <MuiThemeProvider theme={Constants.getMuiTheme()}>
            <MUIDataTable
              title={"Concession Report"}
              data={concessionData}
              columns={concessionColumns}
              options={props.reportOptions}
            />
          </MuiThemeProvider>
        </div>
      </div>
    </div>
  );
};

export default Concession;
