import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import * as Constants from "../Util/constants";
import * as reportsAPI from "../../Api/reports";
import { currencyFormat } from "./helperFunctions";
import RctCollapsibleCard from "../Helpers/RctCollapsibleCard/RctCollapsibleCard";
import LinearProgress from "../Util/LinearProgress";

const XTenantPayments = (props) => {
  const [loading, setLoading] = useState(false);
  const [paymentData, setPaymentData] = useState([]);
  const properties = useSelector((state) => state.login.properties);
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      for (let i = 0; i < properties.length; i++) {
        props.reportSearch.pID = `${properties[i].PropertyID}`;
        const payments = await reportsAPI.getXTenantPaymentReport(
          props.reportSearch
        );
        for (let i = 0; i < payments.length; i++) {
          const propertyName = payments[i].tenant.property.PropertyName;
          if (paymentData[propertyName]) {
            paymentData[propertyName].push({
              unit: payments[i].tenant.unit?.UnitName,
              tenant:
                payments[i].tenant.TenantFName +
                " " +
                payments[i].tenant.TenantLName,
              amountPaid: currencyFormat(+payments[i].DEBIT),
              balance: currencyFormat(+payments[i].DEBIT - +payments[i].CREDIT),
            });
          } else {
            paymentData[propertyName] = [
              {
                unit: payments[i].tenant.unit?.UnitName,
                tenant:
                  payments[i].tenant.TenantFName +
                  " " +
                  payments[i].tenant.TenantLName,
                amountPaid: currencyFormat(+payments[i].DEBIT),
                balance: currencyFormat(
                  +payments[i].DEBIT - +payments[i].CREDIT
                ),
              },
            ];
          }
        }
      }
      setLoading(false);
    }
    fetchData();
  }, []);
  if (loading) {
    return (
      <RctCollapsibleCard
        colClasses="col-xs-12 col-sm-12 col-md-12"
        heading={"Loading Reports..."}
      >
        <LinearProgress />
      </RctCollapsibleCard>
    );
  }
  const paymentColumns = [
    { name: "unit", label: "unit" },
    { name: "tenant", label: "tenant" },
    { name: "amountPaid", label: "Amount Paid" },
    { name: "balance", label: "Balance" },
  ];
  return (
    <div>
      <div className="page-title d-flex justify-content-between align-items-center">
        <div className="page-title-wrap">
          <h2>
            <span>
              {props.reportName +
                " - Date Range of Report: " +
                props.startDate +
                " to " +
                props.endDate}
            </span>
          </h2>
        </div>
      </div>
      <div className="row">
        {properties.map((property) => {
          return (
            <div className="col-sm-12 col-md-12 col-xl-12">
              <MuiThemeProvider theme={Constants.getMuiTheme()}>
                <MUIDataTable
                  title={`X-Property ${property.PropertyName} Tenant Payments`}
                  data={paymentData[property.PropertyName]}
                  columns={paymentColumns}
                  options={props.reportOptions}
                />
              </MuiThemeProvider>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default XTenantPayments;
