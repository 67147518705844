import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import * as Constants from "../Util/constants";
import * as reportsAPI from "../../Api/reports";
import { currencyFormat, formatDate } from "./helperFunctions";
import RctCollapsibleCard from "../Helpers/RctCollapsibleCard/RctCollapsibleCard";
import LinearProgress from "../Util/LinearProgress";
import IconButton from "@material-ui/core/IconButton";
import Edit from "@material-ui/icons/Edit";
import { useHistory } from "react-router-dom";

const TransChangeLog = (props) => {
  const [loading, setLoading] = useState(false);
  const [logData, setLogData] = useState([]);
  const login = useSelector((state) => state.login);
  const propertyName = login.properties;
  const admin = login.user.securityLevel;
  const userID = login.user.id;
  const propertyID = login.selectedPropertyID;
  const companyID = login.company.id;
  const [filteredName] = propertyName.filter(
    (property) => property.PropertyID === props.propertyId
  );
  const history = useHistory();
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const reportDetails = await reportsAPI.getTransChangeLog(
        props.reportSearch
      );
      const reportTotal = await reportsAPI.getTransChangeLogTotal(
        props.reportSearch
      );
      for (let i = 0; i < reportDetails.length; i++) {
        logData.push({
          tenant:
            reportDetails[i].tenanttransaction.tenant.TenantFName +
            " " +
            reportDetails[i].tenanttransaction.tenant.TenantLName +
            " " +
            (reportDetails[i].tenanttransaction.tenant.unit
              ? reportDetails[i].tenanttransaction.tenant.unit.UnitName
              : ""),
          changeDate: formatDate(reportDetails[i].TransactionChangeDate),
          originalDate: formatDate(reportDetails[i].OriginalTransactionDate),
          newDate: formatDate(reportDetails[i].NewTransactionDate),
          originalAmt: currencyFormat(
            +reportDetails[i].OrignalTransactionAmount
          ),
          newAmt: currencyFormat(+reportDetails[i].NewTransactionAmount),
          originalComment: reportDetails[i].OriginalComment,
          newComment: reportDetails[i].NewComment,
          originalChargeType: reportDetails[i].tenanttransaction
            ? reportDetails[i].tenanttransaction.chargetype?.ChargeType
            : reportDetails[i].chargetype?.ChargeType,
          newChargeType: reportDetails[i].chargetype?.ChargeType,
          submittedBy:
            reportDetails[i].user.UserFName +
            " " +
            reportDetails[i].user.UserLName,
          changeAmt: currencyFormat(
            +reportDetails[i].NewTransactionAmount -
              +reportDetails[i].OrignalTransactionAmount
          ),
          edit: (
            <IconButton
              aria-label="Print"
              onClick={() => {
                const location = {
                  pathname: "/deposits/editTransaction",
                  state: {
                    tenantTransactionID: reportDetails[i].tenanttransactionId,
                    admin: admin,
                    userID: userID,
                    propertyID: propertyID,
                    companyID: companyID,
                  },
                };
                history.push(location);
              }}
            >
              <Edit />
            </IconButton>
          ),
        });
      }
      logData.push({
        submittedBy: "TOTAL CHANGE",
        changeAmt: currencyFormat(+reportTotal[0].TotalTransactionAmount),
      });
      setLoading(false);
    }
    fetchData();
  }, []);
  if (loading) {
    return (
      <RctCollapsibleCard
        colClasses="col-xs-12 col-sm-12 col-md-12"
        heading={"Loading Reports..."}
      >
        <LinearProgress />
      </RctCollapsibleCard>
    );
  }
  const logColumns = [
    { name: "tenant", label: "Tenant" },
    { name: "changeDate", label: "Change Date" },
    { name: "originalDate", label: "Original Date" },
    { name: "newDate", label: "New Date" },
    { name: "originalAmt", label: "Original Amt" },
    { name: "newAmt", label: "New Amt" },
    { name: "originalComment", label: "Original Comment" },
    { name: "newComment", label: "New Comment" },
    { name: "originalChargeType", label: "Original Charge Type" },
    { name: "newChargeType", label: "New Charge Type" },
    { name: "submittedBy", label: "Submitted By" },
    { name: "changeAmt", label: "Change Amt" },
    { name: "edit", label: "Edit" },
  ];
  return (
    <div>
      <div className="page-title d-flex justify-content-between align-items-center">
        <div className="page-title-wrap">
          <h2>
            <span>
              {props.reportName +
                " - " +
                filteredName.PropertyName +
                " - Date Range of Report: " +
                props.startDate +
                " to " +
                props.endDate}
            </span>
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-xl-12">
          <MuiThemeProvider theme={Constants.getMuiTheme()}>
            <MUIDataTable
              title={"Transaction Change Log"}
              data={logData}
              columns={logColumns}
              options={props.reportOptions}
            />
          </MuiThemeProvider>
        </div>
      </div>
    </div>
  );
};

export default TransChangeLog;
