import axios from 'axios';
import * as Constants from '../components/Util/constants';

const URL = Constants.REQ_URL;

export const getReports = async () => {
    let output = [];
    await axios.get(`${URL}/reports/getReports`).then((res) => {
        output = res.data;
    });
    return output;
};

export const getReportDetails = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/reports/getReportDetails`, {
            params: {
                rID: query.rID,
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
                uID: query.uID,
                compID: query.compID,
                exTypeID: query.exTypeID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getBalanceSheet = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/journal`, {
            params: {
                pID: query.pID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getLoanTotals = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/journal/lender/TOTAL`, {
            params: {
                pID: query.pID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getTenantDeposit = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/journal/tenantDeposit`, {
            params: {
                pID: query.pID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getGeneralLedgerExpense = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/checkregister/generalledger/expenses`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
                compID: query.compID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getGeneralLedgerExpenseTotal = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/checkregister/generalledger/expenses/TOTAL`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
                compID: query.compID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getGeneralLedgerIncome = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/transaction/generalledger/income`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getGeneralLedgerIncomeTotal = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/transaction/generalledger/income/TOTAL`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getTenantsDelinquent = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/tenant/promiss`, {
            params: {
                pID: query.pID,
                tID: query.tID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getDelinquency = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/tenant/delinquency`, {
            params: {
                pID: query.pID,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });

    return output;
};

export const getDelinquencyDate = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/tenant/delinquency/dateRange`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });

    return output;
};

export const getDelinquencyTotal = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/tenant/tenantTransaction/TOTAL`, {
            params: {
                pID: query.pID,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });

    return output;
};

export const getXDelinquency = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/tenant/tenantTransaction/dateRange`, {
            params: {
                pID: query.pID,
                compID: query.compID,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });

    return output;
};

export const getRentRollUnits = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/tenant/RentRoll`, {
            params: {
                pID: query.pID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getRentRollUnitInfo = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/tenant/unitInfo`, {
            params: {
                pID: query.pID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getRentRollTransactionsSum = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/transaction/rentRollActual`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getTenantOnlinePayments = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/transaction/epicTransaction`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getXVacancyReport = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/units/vacant`, {
            params: {
                sDt: query.sDt,
                eDt: query.eDt,
                compID: query.compID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getTenantPaymentReport = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/tenant/TenantPayments`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getTenantPaymentReportTotal = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/tenant/TenantPayments/TOTAL`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
                compID: query.compID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getTenantPaymentReportPaymentsTotal = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/tenant/TenantPayments/payments/TOTAL`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
                compID: query.compID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getTenantPaymentReportAllTotal = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/tenant/TenantPayments/all/TOTAL`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
                compID: query.compID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getXTenantPaymentReport = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/tenant/xTenantPayments`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
                compID: query.compID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getWorkOrderSummary = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/workorder`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const get1099Int = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/checkregister/1099int`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
                compID: query.compID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const get1099Misc = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/checkregister/1099misc`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
                compID: query.compID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const get1099Nec = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/checkregister/1099nec`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
                compID: query.compID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const get1099Vendor = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/vendor/1099`, {
            params: {
                vID: query.vID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getApplicationSummary = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/tenant/appsumm`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getConcession = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/transaction/concession`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getBackgroundCheck = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/transaction/backcheck`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getCreditCheckLog = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/transaction/creditcheck/log`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getReportCosts = async (query) => {
    let output = [];
    await axios.get(`${URL}/api/transaction/reportcost`).then((res) => {
        output = res.data;
    });
    return output;
};

export const getDelinquencyByDate = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/tenant/tenantTransaction/date`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getDelinquencyByDateUnits = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/tenant/tenantTransaction/date/units`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getDepositListUnit = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/units/depositunit`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getDepositList = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/units/deposit`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getDownUnit = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/units/downunit`, {
            params: {
                pID: query.pID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getDownUnitTotal = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/units/downunit/totals`, {
            params: {
                pID: query.pID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getEscrowItem = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/checkregister/escrowItem`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
                compID: query.compID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getEscrowLabor = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/checkregister/escrowLabor`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const sendEscrowEmail = async (data) => {
    let output = [];
    await axios.post(`${URL}/api/checkregister/sendEmail`, data).then((res) => {
        output = res.data;
    });
    return output;
};

export const getUserProperties = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/property/userprop`, {
            params: {
                uID: query.uID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getEvictionSummary = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/tenant/eviction`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getEvictionLate = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/tenant/latedate`, {
            params: {
                tenID: query.tenID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getExpenseUnit = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/checkregister/expenseUnit`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getLeaseExpiration = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/units/leaseExp`, {
            params: {
                pID: query.pID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getLeaseRenewals = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/units/renewals`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getLeaseRenewalsMonth = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/units/renewals/month`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getLeaseSummary = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/document`, {
            params: {
                pID: query.pID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getLeaseViolation = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/units/leaseviolation`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getMoveIn = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/units/movein`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getMoveOut = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/units/moveout`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getMoveOutReason = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/units/moveout/reason`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getNonAllocPayment = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/transaction/nonallocated`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getNonRecCharge = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/transaction/nonrecur`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getNonRecChargeTotal = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/transaction/nonrecur/TOTALS`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getNsfReport = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/transaction/nsfReport`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getPayables = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/checkregister/payables`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getPayablesTotal = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/checkregister/payables/TOTAL`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getPositiveCredit = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/transaction/positiveCredit`, {
            params: {
                pID: query.pID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getPrePaidRent = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/transaction/prepaidRent`, {
            params: {
                pID: query.pID,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getQuickbookExport = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/transaction/quickbook`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getSecurityDeposit = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/transaction/securityDeposit`, {
            params: {
                pID: query.pID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getSecurityDepositTotal = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/transaction/securityDeposit/TOTAL`, {
            params: {
                pID: query.pID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getPortalAccount = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/tenant/tenantPortal`, {
            params: {
                pID: query.pID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getTenantTenure = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/tenant/tenure`, {
            params: {
                pID: query.pID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getTenantInsurance = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/tenant/insurance`, {
            params: {
                pID: query.pID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getTrafficSummary = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/tenant/trafficSource/summary`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getTrafficDetails = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/tenant/trafficSource/details`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getAllTrafficSummary = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/tenant/trafficSourceAll/summary`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getAllTrafficDetails = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/tenant/trafficSourceAll/details`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getTransChangeLog = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/transaction/changelog`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getTransChangeLogTotal = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/transaction/changelog/TOTAL`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getUnitAvailList = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/units/unitAvail`, {
            params: {
                pID: query.pID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getUnitAvailNotice = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/units/unitAvail/gaveNotice`, {
            params: {
                pID: query.pID,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getUnitAvailEvictions = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/units/unitAvail/evictions`, {
            params: {
                pID: query.pID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getUnitAvailLeaseOccup = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/units/unitAvail/leaseOccup`, {
            params: {
                pID: query.pID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getUnitAvailLeaseVacant = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/units/unitAvail/leaseVacant`, {
            params: {
                pID: query.pID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getUnitListing = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/units/listing`, {
            params: {
                pID: query.pID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getVacancyMonth = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/tenant/vacancyMonth`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getVehicleReport = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/tenant/vehicle`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getVacancy = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/tenant/vacancy`, {
            params: {
                pID: query.pID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getUnitsQuantity = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/units/quantity`, {
            params: {
                pID: query.pID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getOccupancyMonth = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/units/occupancy`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getLeasingAgent = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/tenant/leasingAgent`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getLeasingUser = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/tenant/leasingAgent/users`, {
            params: {
                pID: query.pID,
                comID: query.comID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getLeadSources = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/tenant/leasingAgent/leadsources`, {
            params: {
                comID: query.comID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const addLeadSource = async ({ leadSource, companyID }) => {
    let output = [];
    await axios
        .post(`${URL}/api/tenant/leasingAgent/addleadsource`, {
            leadSource,
            companyID,
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getProjectedOccupancy = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/units/occupancy/projected`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getOccupancyWeek = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/units/occupancy/week`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getBudgetProjectIncome = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/transaction/budgetproject/income`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getBudgetProjectExpenses = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/transaction/budgetproject/expenses`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getBudgetProjectNonOperating = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/transaction/budgetproject/expenses/nonoperating`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getBudgetProjectIncomeTotal = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/transaction/budgetproject/income/TOTAL`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getBudgetProjectExpensesTotal = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/transaction/budgetproject/expenses/TOTAL`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getBudgetProjectNonOperatingTotal = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/transaction/budgetproject/expenses/nonoperating/TOTAL`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getBoxUnits = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/units/boxScore`, {
            params: {
                pID: query.pID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getBoxOccupiedNoNotice = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/units/boxScore/occupied`, {
            params: {
                pID: query.pID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getBoxTotalOccupied = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/units/boxScore/occupied/total`, {
            params: {
                pID: query.pID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getBoxVacantUnrent = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/units/boxScore/vacant/unrented`, {
            params: {
                pID: query.pID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getBoxNotice = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/units/boxScore/noticeGiven/rented`, {
            params: {
                pID: query.pID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getBoxTraffic = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/units/boxScore/traffic`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getBoxTrafficBackground = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/units/traffic/background`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getBoxOccupancy = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/units/boxscore/occupancy`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getBoxNoticeUnrent = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/units/boxScore/noticeGiven/unrented`, {
            params: {
                pID: query.pID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getBoxVacantRent = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/units/boxscore/vacantrent`, {
            params: {
                pID: query.pID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getProfitLossMonthIncome = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/transaction/profitlossmonth/income`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getProfitLossMonthExpenses = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/transaction/profitlossmonth/expenses`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getProfitLossMonthNonOperating = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/transaction/profitlossmonth/expenses/nonoperating`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getProfitLossMonthIncomeTotal = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/transaction/profitlossmonth/income/TOTAL`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getProfitLossMonthExpensesTotalRow = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/transaction/profitlossmonth/expenses/TOTAL/rows`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getProfitLossMonthNonOperatingTotalRow = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/transaction/profitlossmonth/expenses/nonoperating/TOTAL/rows`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getProfitLossMonthExpensesTotalMonth = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/transaction/profitlossmonth/expenses/TOTAL/months`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getProfitLossMonthNonOperatingTotalMonth = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/transaction/profitlossmonth/expenses/nonoperating/TOTAL/months`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getIncomeLineItem = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/transaction/profitlossmonth/income/lineItem`, {
            params: {
                pID: query.pID,
                sDt: query.firstDay,
                eDt: query.lastDay,
                dID: query.depositID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getPLIncomeLineItem = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/transaction/profitloss/income/lineItem`, {
            params: query,
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getIncomeLineItemTotal = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/transaction/profitlossmonth/income/lineItem/TOTAL`, {
            params: {
                pID: query.pID,
                sDt: query.firstDay,
                eDt: query.lastDay,
                dID: query.depositID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getPLIncomeLineItemTotal = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/transaction/profitloss/income/lineItem/TOTAL`, {
            params: query,
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getExpenseLineItem = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/transaction/profitlossmonth/expenses/lineItem`, {
            params: {
                pID: query.pID,
                sDt: query.firstDay,
                eDt: query.lastDay,
                ttID: query.transactionTypeID,
                exID: query.expenseTypeID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getPLExpenseLineItem = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/transaction/profitloss/expenses/lineItem`, {
            params: query,
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getExpenseLineItemTotal = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/transaction/profitlossmonth/expenses/lineItem/TOTAL`, {
            params: {
                pID: query.pID,
                sDt: query.firstDay,
                eDt: query.lastDay,
                exID: query.expenseTypeID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getPLExpenseLineItemTotal = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/transaction/profitloss/expenses/lineItem/TOTAL`, {
            params: query,
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const updateExpenseDate = async (data) => {
    let output = [];
    await axios.put(`${URL}/api/transaction/profitlossmonth/expenses/lineItem/updateDate`, data).then((res) => {
        output = res.data;
    });
    return output;
};

export const updateExpenseLineitem = async (data) => {
    let output = [];
    await axios.put(`${URL}/api/transaction/profitlossmonth/expenses/lineItem`, data).then((res) => {
        output = res.data;
    });
    return output;
};

export const updateIncomeLineitem = async (data) => {
    let output = [];
    await axios.put(`${URL}/api/transaction/profitlossmonth/income/lineItem`, data).then((res) => {
        output = res.data;
    });
    return output;
};

export const getNonOperatingLineItem = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/transaction/profitlossmonth/expenses/nonoperating/lineitem`, {
            params: {
                pID: query.pID,
                sDt: query.firstDay,
                eDt: query.lastDay,
                exID: query.expenseTypeID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getPLNonOperatingLineItem = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/transaction/profitlossmonth/expenses/nonoperating/lineitem`, {
            params: query,
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getNonOperatingLineItemTotal = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/transaction/profitlossmonth/expenses/nonoperating/lineitem/TOTAL`, {
            params: {
                pID: query.pID,
                sDt: query.firstDay,
                eDt: query.lastDay,
                exID: query.expenseTypeID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getPLNonOperatingLineItemTotal = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/transaction/profitlossmonth/expenses/nonoperating/lineitem/TOTAL`, {
            params: query,
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getEpicPayTransactions = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/epicpay/transactions`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const postEpicPayRefund = async (data) => {
    let output = [];
    await axios.post(`${URL}/api/epicpay/refund`, data).then((res) => {
        output = res.data;
    });
    return output;
};

export const getOnlinePaymentSummary = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/epicpay/summary`, {
            params: {
                pID: query.pID,
                sDt: query.sDt,
                eDt: query.eDt,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const deletePrelease = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/units/delete/prelease`, {
            params: {
                preID: query.preID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};

export const getVendors = async (query) => {
    let output = [];
    await axios
        .get(`${URL}/api/vendor/payable`, {
            params: {
                compID: query.compID,
            },
        })
        .then((res) => {
            output = res.data;
        });
    return output;
};
