import React, {useState, useCallback} from 'react';
import { Controller, useForm } from "react-hook-form";
import { Button, Form, Label, Input } from 'reactstrap';
import { FormGroup } from '@material-ui/core';
import { NotificationManager } from 'react-notifications';
import { useSelector } from "react-redux";

import Main from '../Main';
import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import LinearProgress from '../Util/LinearProgress';
import * as Util from '../Util/util';
import * as Constants from '../Util/constants';
import * as companyAPI from '../../Api/company';

const ProductSupport = () => {
    const login = useSelector((state) => state.login);
    const company = login.company
    const companyID = company.id;
    const properties = login.properties;
    const selectedProperty = login.selectedPropertyID;
    const user = login.user;

    const [loading, setLoading] = useState(false);
    const [updated, setUpdated] = useState(false);

    const { handleSubmit, control, formState: { errors }} = useForm();

    const propertyName = useCallback(() => {
        for(const p of properties) {
            if(parseInt(p.PropertyID) === parseInt(selectedProperty)) {
                return p.PropertyName;
            }
        }
        return "";
    }, [selectedProperty, properties])

    const submitForm = async (data) => {
        setLoading(true);
        const res = await companyAPI.sendSupportRequest({
            companyID,
            email: user.email,
            name: `${user.firstName} ${user.lastName}`,
            propertyName,
            message: data.message,
        });
        setLoading(false);
        if(res !== 0) {
            NotificationManager.error(Constants.DEFAULT_ERROR, "Error");
            return;
        }
        setLoading(false);
        setUpdated(!updated);
        NotificationManager.success("Support requested.", "Success")
    }

    if(loading) {
        return (
            <RctCollapsibleCard
                colClasses="col-xs-12 col-sm-12 col-md-12"
                heading={"Loading Support..."}
            >
                <LinearProgress />
            </RctCollapsibleCard>
        );
    }

    return (
        <Main>
            <div className="formelements-wrapper" style={Constants.margins}>
                <div className="page-title d-flex justify-content-between align-items-center">
                    <div className="page-title-wrap">
                        <h2>
                            <span>Get Product Support</span>
                        </h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-xl-12">
                        <RctCollapsibleCard heading="Company Details">
                            <Form onSubmit={handleSubmit(submitForm)}>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <FormGroup className="mr-10 mb-10">
                                            <Label for="message" className="mr-sm-10">Message</Label>
                                            <Controller
                                                name="message"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <Input {...field} type="textarea" rows={4} id="message" className="form-control" 
                                                        style={Util.setErrorStyle(errors.message)} /> 
                                                )}
                                            />
                                            {errors.message && (
                                                <span style={{ color: "red" }} role="alert">required</span>
                                            )}
                                        </FormGroup>
                                    </div>
                                </div> 
                                <Button type="submit" color="primary" style={{marginTop: '10px'}}>Submit</Button>
                            </Form>
                        </RctCollapsibleCard>
                    </div>
                </div>
            </div>
        </Main>
    )
}

export default ProductSupport;