import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, Form, Label, Input } from 'reactstrap';
import { FormGroup, Switch } from '@material-ui/core';
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import { MuiThemeProvider } from '@material-ui/core/styles';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import Update from '@material-ui/icons/Edit';
import { useHistory } from 'react-router-dom';

import Main from '../Main';
import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import LinearProgress from '../Util/LinearProgress';
import * as Util from '../Util/util';
import * as Constants from '../Util/constants';
import * as companyAPI from '../../Api/company';
import Done from '@material-ui/icons/Done';

const RegisteredCompanies = () => {
    const [companies, setCompanies] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showActiveOnly, setShowActiveOnly] = useState(false);

    const load = async () => {
        setLoading(true);
        const companies = await companyAPI.getAll(showActiveOnly);
        setCompanies(companies);
        setLoading(false);
    };

    useEffect(() => {
        load();
    }, [showActiveOnly]);

    const changeStatus = async (id, status) => {
        await companyAPI.updateStatus({ companyID: id, status });
    };

    const columns = [
        {
            name: 'CompanyID',
            label: 'CompanyID',
            options: {
                display: false,
            },
        },
        {
            name: 'CompanyName',
            label: 'Company Name',
            options: {
                filter: false,
            },
        },
        {
            name: 'ContactFName',
            label: 'ContactFName',
            options: {
                filter: false,
            },
        },
        {
            name: 'ContactLName',
            label: 'ContactLName',
            options: {
                filter: false,
            },
        },
        {
            name: 'ContactEmail',
            label: 'Contact Email',
            options: {
                filter: false,
            },
        },
        {
            name: 'ContactPhone',
            label: 'Contact Phone',
            options: {
                filter: false,
            },
        },

        {
            name: 'CompanyStartDate',
            label: 'Start Date',
            options: {
                filter: false,
                customBodyRender: (value) => moment.utc(value).format('MM/DD/YYYY'),
            },
        },

        {
            name: 'Active',
            label: 'Disable?',
            options: {
                filter: false,
                download: false,
                customBodyRender: (value, { rowData }) => (
                    <IconButton
                        aria-label="Print"
                        onClick={async () => {
                            console.log('rowData', rowData);
                            changeStatus(rowData.CompanyID, value === 1 ? 0 : 1);
                        }}
                        style={{ color: value === 1 ? 'red' : 'blue' }}
                    >
                        <Done />
                    </IconButton>
                ),
            },
        },
    ];

    if (loading) {
        return (
            <RctCollapsibleCard colClasses="col-xs-12 col-sm-12 col-md-12" heading={'Loading Enhancement Request...'}>
                <LinearProgress />
            </RctCollapsibleCard>
        );
    }

    return (
        <Main>
            <div className="formelements-wrapper" style={Constants.margins}>
                <div className="page-title d-flex justify-content-between align-items-center mt-4">
                    <div className="page-title-wrap">
                        <h2>
                            <span>Registered Companies</span>
                        </h2>
                    </div>
                </div>
            </div>
            <div className="data-table-wrapper" style={Constants.margins}>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-xl-12">
                        <MuiThemeProvider theme={Constants.getMuiTheme()}>
                            <MUIDataTable
                                columns={columns}
                                data={companies}
                                options={{
                                    viewColumns: false,
                                    filter: false,
                                    rowsPerPage: 100,
                                    customToolbar: () => (
                                        <>
                                            <Switch
                                                name="Active"
                                                checked={showActiveOnly}
                                                onChange={(e) => {
                                                    setShowActiveOnly(e.target.checked);
                                                }}
                                                aria-label="Active Only"
                                            />
                                            <Label for="Active" className="mr-sm-10">
                                                Active Only
                                            </Label>
                                        </>
                                    ),
                                }}
                            />
                        </MuiThemeProvider>
                    </div>
                </div>
            </div>
        </Main>
    );
};

export default RegisteredCompanies;
