import React from 'react';
import { useSelector } from 'react-redux';

import Main from '../Main';
import { ListGroup, ListGroupItem } from 'reactstrap';
import * as tenantsApi from '../../Api/tenants';
import { Link } from 'react-router-dom';
import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import { LinearProgress } from '@material-ui/core';

const SearchResults = (props) => {
    const login = useSelector((state) => state.login);
    const user = login.user;
    const userID = user.id;
    const propertyID = login.selectedPropertyID;
    const company = login.company;
    const companyID = company.id;
    const companyEmail = company.email;
    const companyName = company.name;
    const keyword = login.keyword;

    const [results, setResults] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    const load = async () => {
        setLoading(true);
        const ts = await tenantsApi.getTenants({ companyID, multiprop: false, userID, propertyID });
        const tenants = ts
            .filter((t) => `${t.TenantFName} ${t.TenantLName}`.toLowerCase().includes(keyword))
            .map((t) => ({
                type: 'tenant',
                name: `${t.TenantFName} ${t.TenantLName}`,
                id: t.TenantID,
                path: {
                    pathname: '/tenants/details',
                    state: {
                        tenantID: parseInt(t.TenantID),
                    },
                },
            }));

        const pts = await tenantsApi.getPreviousTenants({ companyID, multiprop: false, userID, propertyID });
        const ptenants = pts
            .filter((t) => `${t.TenantFName} ${t.TenantLName}`.toLowerCase().includes(keyword))
            .map((t) => ({
                type: 'previous tenant',
                name: `${t.TenantFName} ${t.TenantLName}`,
                id: t.TenantID,
                path: {
                    pathname: '/tenants/details',
                    state: {
                        tenantID: parseInt(t.TenantID),
                    },
                },
            }));

        const pros = await tenantsApi.getPreLeaseProspects(propertyID);

        const prospects = pros
            .filter((t) => `${t.TenantFName} ${t.TenantLName}`.toLowerCase().includes(keyword))
            .map((t) => ({
                type: 'prospect',
                name: `${t.TenantFName} ${t.TenantLName}`,
                id: t.TenantID,
                path: {
                    pathname: '/tenants/details',
                    state: {
                        tenantID: parseInt(t.TenantID),
                    },
                },
            }));

        setResults([...tenants, ...ptenants, ...prospects]);
        setLoading(false);
    };
    React.useEffect(() => {
        load();
    }, [keyword]);
    if (loading) {
        return (
            <RctCollapsibleCard colClasses="col-xs-12 col-sm-12 col-md-12" heading={`Loading Search Results...`}>
                <LinearProgress />
            </RctCollapsibleCard>
        );
    }

    return (
        <Main>
            <div className="formelements-wrapper" style={{ padding: '2rem' }}>
                <div className="page-title d-flex justify-content-between align-items-center">
                    <div className="page-title-wrap">
                        <h2>
                            <span>Search results for "{keyword}"</span>
                        </h2>
                    </div>
                </div>

                <ListGroup>
                    {results.map((r) => (
                        <ListGroupItem key={r.id}>
                            <Link to={r.path}>
                                {r.type.toUpperCase()} : {r.name}
                            </Link>
                        </ListGroupItem>
                    ))}
                </ListGroup>
                {results.length === 0 && <div>No search results found</div>}
            </div>
        </Main>
    );
};

export default SearchResults;
