import React, { useState, useEffect } from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import * as Constants from "../Util/constants";
import * as reportsAPI from "../../Api/reports";
import RctCollapsibleCard from "../Helpers/RctCollapsibleCard/RctCollapsibleCard";
import LinearProgress from "../Util/LinearProgress";

const ChartOfAccounts = (props) => {
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState([]);
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const reportDetails = await reportsAPI.getReportDetails(
        props.reportSearch
      );
      reportDetails.map((expense) =>
        chartData.push({
          id: expense.ExpenseTypeID,
          accountDescription: expense.ExpenseType,
          accountType: expense.AccountType,
        })
      );
      setLoading(false);
    }
    fetchData();
  }, []);
  if (loading) {
    return (
      <RctCollapsibleCard
        colClasses="col-xs-12 col-sm-12 col-md-12"
        heading={"Loading Reports..."}
      >
        <LinearProgress />
      </RctCollapsibleCard>
    );
  }
  const chartColumns = [
    { name: "id", label: "ID" },
    { name: "accountDescription", label: "Account Description" },
    { name: "accountType", label: "Account Type" },
  ];
  return (
    <div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-xl-12">
          <MuiThemeProvider theme={Constants.getMuiTheme()}>
            <MUIDataTable
              title={"Chart of Accounts"}
              data={chartData}
              columns={chartColumns}
              options={props.reportOptions}
            />
          </MuiThemeProvider>
        </div>
      </div>
    </div>
  );
};

export default ChartOfAccounts;
