import React from 'react';
import { useSelector } from 'react-redux';

import Main from '../Main';
import TopMainPage from './TopMainPage';
import VacancySnapshot from './VacancySnapshot';
import ActionItems from './ActionItems';
import WorkOrdersSnapshot from './WorkOrdersSnapshot';
import ConcessionRequest from './ConcessionRequest';
import LeaseAudit from './LeaseAudit';
import SecurityDeposit from './SecurityDeposit';
import MissedPromissPay from './MissedPromise';
import DelinquenciesOver from './DelinquenciesOver';
import * as Constants from '../Util/constants';

const Home = () => {
    const login = useSelector((state) => state.login);
    const menuItems = login?.user?.components || [];
    const secLevel = login?.user?.securityLevel || 0

    var accessibleMenuItems = []
    if (menuItems.length > 0) {
        accessibleMenuItems = Constants.Components.filter(c => menuItems.includes(c.ComponentID)).map(c => c.ComponentName);
    } else {
        accessibleMenuItems = Constants.Components.filter(c => c.SecurityLevel.includes(secLevel)).map(c => c.ComponentName);
    }

    return (
        <Main>
            <div className="rct-page-content" style={{ marginBottom: '30px' }}>
                <TopMainPage />

                <div className="blank-wrapper">
                    <div className="row">
                        {accessibleMenuItems.includes("Vacancy Snapshot") && <VacancySnapshot />}
                        {accessibleMenuItems.includes("Action Items") && <ActionItems />}
                    </div>

                    <div className="row">
                        {accessibleMenuItems.includes('Work Orders Snapshot') && <WorkOrdersSnapshot />}
                        {accessibleMenuItems.includes("Concession Request Review/Approve") && <ConcessionRequest />}
                    </div>

                    <div className="row">
                        {accessibleMenuItems.includes('Lease Audit Snapshot') && <LeaseAudit />}
                        {accessibleMenuItems.includes('Security Deposit Refunds to Process') && <SecurityDeposit />}
                    </div>

                    <div className="row">
                        {accessibleMenuItems.includes('Missed Promise to Pay') && <MissedPromissPay />}
                        {accessibleMenuItems.includes('Delinquency Snapshot') && <DelinquenciesOver />}
                    </div>

                    <div className="row" style={{ paddingBottom: '20px' }}></div>
                </div>
            </div>
        </Main>
    );
}

export default Home;