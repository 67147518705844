import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
  },
  text: {
    fontSize: 12,
  },
  section: {
    margin: 45,
    padding: 10,
    flexGrow: 1,
  },
});

const DocumentViewer = (props) => {
  const Type = props.location.state.type;
  const PropertyName = props.location.state.property;
  const PropertyAddress = props.location.state.address;
  const PropertyCity = props.location.state.city;
  const PropertyState = props.location.state.state;
  const PropertyZip = props.location.state.zip;
  const VendorEIN = props.location.state.ein ? props.location.state.ein : null;
  const VendorName = props.location.state.VendorName
    ? props.location.state.VendorName
    : null;
  const VendorAddress1 = props.location.state.vendorAddress1
    ? props.location.state.vendorAddress1
    : null;
  const VendorAddress2 = props.location.state.vendorAddress2
    ? props.location.state.vendorAddress2
    : null;
  const VendorCity = props.location.state.vendorCity
    ? props.location.state.vendorCity
    : null;
  const VendorState = props.location.state.vendorState
    ? props.location.state.vendorState
    : null;
  const VendorZip = props.location.state.vendorZip
    ? props.location.state.vendorZip
    : null;
  const AmountTotal = props.location.state.Amount
    ? props.location.state.Amount
    : null;
  const ContactFName = props.location.state.ContactFName
    ? props.location.state.ContactFName
    : null;
  const ContactLName = props.location.state.ContactLName
    ? props.location.state.ContactLName
    : null;
  const ContactPhone = props.location.state.ContactPhone
    ? props.location.state.ContactPhone
    : null;
  const ContactEmail = props.location.state.ContactEmail
    ? props.location.state.ContactEmail
    : null;

  let MyDocument;

  if (Type === "1099INT") {
    MyDocument = () => (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.text}>{PropertyName}</Text>
            <Text t style={styles.text}>
              {PropertyAddress}
            </Text>
            <Text style={styles.text}>
              {PropertyCity} {PropertyState} {PropertyZip}
            </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> {VendorEIN}</Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>{VendorName}</Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>{VendorAddress2}</Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>
              {VendorCity} {VendorState} {VendorZip}
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.text}></Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>{AmountTotal}</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.text}></Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.text}></Text>
          </View>
        </Page>
      </Document>
    );
  }
  if (Type === "1096INT") {
    MyDocument = () => (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>{PropertyName}</Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>{PropertyAddress}</Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>
              {PropertyCity} {PropertyState} {PropertyZip}
            </Text>
            <Text style={styles.text}>
              {ContactFName} {ContactLName}
            </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> {ContactEmail}</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.text}></Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>{ContactPhone}</Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>{AmountTotal}</Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>XXX</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.text}></Text>
          </View>
        </Page>
      </Document>
    );
  }
  if (Type === "1099MISC") {
    MyDocument = () => (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.text}>{PropertyName}</Text>
            <Text t style={styles.text}>
              {PropertyAddress}
            </Text>
            <Text style={styles.text}>
              {PropertyCity} {PropertyState} {PropertyZip}
            </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>{VendorName}</Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>{VendorAddress1}</Text>
            <Text style={styles.text}>{VendorAddress2} </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>
              {VendorCity} {VendorState} {VendorZip}
            </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}></Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.text}></Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>{AmountTotal}</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.text}></Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.text}></Text>
          </View>
        </Page>
      </Document>
    );
  }
  if (Type === "1096MISC") {
    MyDocument = () => (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>{PropertyName}</Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>{PropertyAddress}</Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>
              {PropertyCity} {PropertyState} {PropertyZip}
            </Text>
            <Text style={styles.text}>
              {ContactFName} {ContactLName}
            </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> {ContactEmail}</Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>XXX</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.text}></Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>{ContactPhone}</Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>{AmountTotal}</Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.text}></Text>
          </View>
        </Page>
      </Document>
    );
  }
  if (Type === "PayableINT") {
    MyDocument = () => (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.text}>{PropertyName}</Text>
            <Text t style={styles.text}>
              {PropertyAddress}
            </Text>
            <Text style={styles.text}>
              {PropertyCity} {PropertyState} {PropertyZip}
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.text}></Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>{AmountTotal}</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.text}></Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.text}></Text>
          </View>
        </Page>
      </Document>
    );
  }
  if (Type === "PayableMISC") {
    MyDocument = () => (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.text}>{PropertyName}</Text>
            <Text t style={styles.text}>
              {PropertyAddress}
            </Text>
            <Text style={styles.text}>
              {PropertyCity} {PropertyState} {PropertyZip}
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.text}></Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>{AmountTotal}</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.text}></Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.text}></Text>
          </View>
        </Page>
      </Document>
    );
  }
  return (
    <div>
      <PDFViewer>
        <MyDocument />
      </PDFViewer>
    </div>
  );
};

export default DocumentViewer;
