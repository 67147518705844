import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import * as Constants from '../Util/constants';
import * as reportsAPI from '../../Api/reports';
import { currencyFormat, dateRange } from './helperFunctions';
import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import LinearProgress from '../Util/LinearProgress';
import { Link } from 'react-router-dom';
import LineItem from './LineItem';
import ReactApexChart from 'react-apexcharts';
import { getExpenseTypes } from '../../Api/bills';

const ProfitAndLossByMonth = (props) => {
    const [loading, setLoading] = useState(false);
    const [lineItem, setLineItem] = useState(false);
    const [itemStartDate, setItemStartDate] = useState('');
    const [itemLastDate, setItemLastDate] = useState('');
    const [itemAccountTypeID, setItemAccountTypeID] = useState(0);
    const [itemExpenseTypeID, setItemExpenseTypeID] = useState(0);
    const [itemDepositID, setItemDepositID] = useState(0);
    const [incomeData, setIncomeData] = useState([]);
    const [expensesData, setExpensesData] = useState([]);
    const [nonOperatingData, setNonOperatingData] = useState([]);
    const [summaryData, setSummaryData] = useState([]);
    const [xAxisValues, setXAxisValues] = useState([]);
    const companyID = useSelector((state) => state.login.company.id);
    const propertyName = useSelector((state) => state.login.properties);
    const [filteredName] = propertyName.filter((property) => property.PropertyID === props.propertyId);
    const [profitLossColumns, setProfitLossColumns] = useState([]);
    const [totals, setTotals] = useState({
        expenses: 0,
        nonOperating: 0,
    });
    const [months, setMonths] = useState([]);
    const [monthList, setMonthList] = useState([]);
    const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    const [expenseTypes, setExpenseTypes] = useState([]);
    const [state, setState] = useState({
        series: [
            { name: 'Gross Income', data: [] },
            { name: 'Operating Expenses', data: [] },
            { name: 'Non Operating Expenses', data: [] },
            { name: 'Net Income', data: [] },
        ],
        options: {
            chart: {
                type: 'line',
                height: 350,
            },
            xaxis: {
                type: 'datetime',
                min: xAxisValues[0],
                max: xAxisValues[xAxisValues.length - 1],
            },
            stroke: {
                curve: 'straight',
            },
            dataLabels: {
                enabled: false,
            },
            title: {
                text: 'Profit and Loss by Month',
                align: 'left',
            },
            legend: {
                tooltipHoverFormatter: function (val, opts) {
                    return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '';
                },
            },
            markers: {
                hover: {
                    sizeOffset: 4,
                },
            },
        },
    });
    useEffect(() => {
        async function fetchData() {
            const expenses = await getExpenseTypes(companyID);
            setExpenseTypes(expenses);
            profitLossColumns.push({
                name: 'category',
                label: 'Category',
                options: {
                    customBodyRender: (value, { rowData }) => {
                        if (rowData[1] == undefined) return <h2>{value}</h2>;
                        else return value;
                    },
                },
            });
            setLoading(true);
            const reportIncome = await reportsAPI.getProfitLossMonthIncome(props.reportSearch);
            const reportExpenses = await reportsAPI.getProfitLossMonthExpenses(props.reportSearch);
            const reportNonOperating = await reportsAPI.getProfitLossMonthNonOperating(props.reportSearch);
            const reportIncomeTotal = await reportsAPI.getProfitLossMonthIncomeTotal(props.reportSearch);
            const reportExpensesTotal = await reportsAPI.getProfitLossMonthExpensesTotalRow(props.reportSearch);
            const reportNonOperatingTotal = await reportsAPI.getProfitLossMonthNonOperatingTotalRow(props.reportSearch);
            const reportExpensesMonthTotal = await reportsAPI.getProfitLossMonthExpensesTotalMonth(props.reportSearch);
            const reportNonOperatingMonthTotal = await reportsAPI.getProfitLossMonthNonOperatingTotalMonth(
                props.reportSearch,
            );
            const allMonths = dateRange(props.reportSearch.sDt, props.reportSearch.eDt);

            for (let i = 0; i < allMonths.length; i++) {
                let month = new Date(allMonths[i]).getUTCMonth();
                let year = new Date(allMonths[i]).getUTCFullYear().toString();
                xAxisValues.push(allMonths[i]);
                months[monthNames[month] + year] = {
                    date: allMonths[i],
                    tenantPayment: 0,
                    housingPayment: 0,
                    prospectPayment: 0,
                    otherIncome: 0,
                    formerTenantPayment: 0,
                    laundryRoom: 0,
                    totalIncome: 0,
                    indexMonth: month,
                    indexYear: year,
                };
                profitLossColumns.push({
                    name: monthNames[month] + year,
                    label: monthNames[month] + ' ' + year,
                });
                monthList.push(monthNames[month] + year);
            }
            profitLossColumns.push({ name: 'total', label: 'Total' });
            let incomeCase = true;
            let monthIndex = 0;
            let incomeIndex = 0;
            if (reportIncome.length !== 0) {
                while (incomeCase) {
                    if (monthIndex >= monthList.length) {
                        incomeCase = false;
                        monthIndex = 0;
                    } else {
                        if (reportIncome.length > 0 && reportIncome.length > incomeIndex && monthList[monthIndex]) {
                            let tenantDate = new Date(reportIncome[incomeIndex].TenantTransactionDate).getTime();
                            let monthLess = new Date(months[monthList[monthIndex]].date).getTime();
                            if (
                                (tenantDate >= monthLess && incomeIndex === reportIncome.length - 1) ||
                                (monthList[monthIndex + 1] &&
                                    tenantDate >= monthLess &&
                                    tenantDate < new Date(months[monthList[monthIndex + 1]].date).getTime())
                            ) {
                                months[monthList[monthIndex]].tenantPayment = +reportIncome[incomeIndex].TenantPayment;
                                months[monthList[monthIndex]].housingPayment = +reportIncome[incomeIndex].HousingPayment;
                                months[monthList[monthIndex]].prospectPayment = +reportIncome[incomeIndex].ProspectPayment;
                                months[monthList[monthIndex]].otherIncome = +reportIncome[incomeIndex].OtherIncome;
                                months[monthList[monthIndex]].laundryRoom = +reportIncome[incomeIndex].LaundryRoom;
                                months[monthList[monthIndex]].formerTenantPayment =
                                    +reportIncome[incomeIndex].FormerTenantPayment;
                                months[monthList[monthIndex]].totalIncome = +reportIncome[incomeIndex].TotalIncome;
                                monthIndex += 1;
                                incomeIndex += 1;
                            } else {
                                monthIndex += 1;
                            }
                        } else {
                            monthIndex += 1;
                        }
                    }
                }
            }
            let expenseCase = true;
            let expenseIndex = 0;
            while (expenseCase) {
                if (expenseIndex >= reportExpenses.length) {
                    expenseCase = false;
                    monthIndex = 0;
                } else {
                    if (reportExpenses.length > 0 && reportExpenses.length > expenseIndex && monthList[monthIndex]) {
                        let invoiceDate = new Date(reportExpenses[expenseIndex].InvoiceDate).getTime();
                        let monthLess = new Date(months[monthList[monthIndex]].date).getTime();
                        const monthListIndex = monthList[monthIndex + 1];

                        if (
                            (invoiceDate >= monthLess && monthIndex === monthList.length - 1) ||
                            (monthListIndex &&
                                invoiceDate >= monthLess &&
                                invoiceDate < new Date(months[monthListIndex].date).getTime())
                        ) {
                            console.log('Hello');
                            months[monthList[monthIndex]] = {
                                ...months[monthList[monthIndex]],
                                [reportExpenses[expenseIndex].expensetype.ExpenseType]:
                                    +reportExpenses[expenseIndex].TotalExpenses,
                            };
                            totals.expenses += +reportExpenses[expenseIndex].TotalExpenses;
                            expenseIndex += 1;
                        } else {
                            monthIndex += 1;
                        }
                    } else {
                        expenseCase = false;
                    }
                }
            }
            let operatingCase = true;
            let operatingIndex = 0;
            while (operatingCase) {
                if (operatingIndex >= reportNonOperating.length) {
                    operatingCase = false;
                    monthIndex = 0;
                } else {
                    if (
                        reportNonOperating.length > 0 &&
                        reportNonOperating.length > operatingIndex &&
                        monthList[monthIndex]
                    ) {
                        let invoiceDate = new Date(reportNonOperating[operatingIndex].InvoiceDate).getTime();
                        let monthLess = new Date(months[monthList[monthIndex]].date).getTime();
                        if (
                            (invoiceDate >= monthLess && monthIndex === monthList.length - 1) ||
                            (monthList[monthIndex + 1] &&
                                invoiceDate >= monthLess &&
                                invoiceDate < new Date(months[monthList[monthIndex + 1]].date).getTime())
                        ) {
                            months[monthList[monthIndex]] = {
                                ...months[monthList[monthIndex]],
                                [reportNonOperating[operatingIndex].expensetype.ExpenseType]:
                                    +reportNonOperating[operatingIndex].TotalExpenses,
                            };
                            totals.nonOperating += +reportNonOperating[operatingIndex].TotalExpenses;
                            operatingIndex += 1;
                        } else {
                            monthIndex += 1;
                        }
                    } else {
                        operatingCase = false;
                    }
                }
            }
            let tenantObject;
            let tenantIncome = monthList.map((month, i) => {
                if (i === 0) {
                    tenantObject = {
                        category: 'Tenant Payment:',
                        [month]: (
                            <Link onClick={() => lineItemButton(months[month].date, 1)}>
                                {currencyFormat(months[month].tenantPayment)}
                            </Link>
                        ),
                    };
                } else if (monthList.length - 1 === i) {
                    return (tenantObject = {
                        ...tenantObject,
                        [month]: (
                            <Link onClick={() => lineItemButton(months[month].date, 1)}>
                                {currencyFormat(months[month].tenantPayment)}
                            </Link>
                        ),
                        total: currencyFormat(+reportIncomeTotal[0].TenantPayment),
                    });
                } else {
                    tenantObject = {
                        ...tenantObject,
                        [month]: (
                            <Link onClick={() => lineItemButton(months[month].date, 1)}>
                                {currencyFormat(months[month].tenantPayment)}
                            </Link>
                        ),
                    };
                }
            });
            let housingObject;
            let housingIncome = monthList.map((month, i) => {
                if (i === 0) {
                    housingObject = {
                        category: 'Housing Payment:',
                        [month]: (
                            <Link onClick={() => lineItemButton(months[month].date, 2)}>
                                {currencyFormat(months[month].housingPayment)}
                            </Link>
                        ),
                    };
                } else if (monthList.length - 1 === i) {
                    return (housingObject = {
                        ...housingObject,
                        [month]: (
                            <Link onClick={() => lineItemButton(months[month].date, 2)}>
                                {currencyFormat(months[month].housingPayment)}
                            </Link>
                        ),
                        total: currencyFormat(+reportIncomeTotal[0].HousingPayment),
                    });
                } else {
                    housingObject = {
                        ...housingObject,
                        [month]: (
                            <Link onClick={() => lineItemButton(months[month].date, 2)}>
                                {currencyFormat(months[month].housingPayment)}
                            </Link>
                        ),
                    };
                }
            });
            let prospectObject;
            let prospectIncome = monthList.map((month, i) => {
                if (i === 0) {
                    prospectObject = {
                        category: 'Prospect Payment:',
                        [month]: (
                            <Link onClick={() => lineItemButton(months[month].date, 4)}>
                                {currencyFormat(months[month].prospectPayment)}
                            </Link>
                        ),
                    };
                } else if (monthList.length - 1 === i) {
                    return (prospectObject = {
                        ...prospectObject,
                        [month]: (
                            <Link onClick={() => lineItemButton(months[month].date, 4)}>
                                {currencyFormat(months[month].prospectPayment)}
                            </Link>
                        ),
                        total: currencyFormat(+reportIncomeTotal[0].ProspectPayment),
                    });
                } else {
                    prospectObject = {
                        ...prospectObject,
                        [month]: (
                            <Link onClick={() => lineItemButton(months[month].date, 4)}>
                                {currencyFormat(months[month].prospectPayment)}
                            </Link>
                        ),
                    };
                }
            });
            let otherObject;
            let otherIncome = monthList.map((month, i) => {
                if (i === 0) {
                    otherObject = {
                        category: 'Other Payment:',
                        [month]: (
                            <Link onClick={() => lineItemButton(months[month].date, 3)}>
                                {currencyFormat(months[month].otherIncome)}
                            </Link>
                        ),
                    };
                } else if (monthList.length - 1 === i) {
                    return (otherObject = {
                        ...otherObject,
                        [month]: (
                            <Link onClick={() => lineItemButton(months[month].date, 3)}>
                                {currencyFormat(months[month].otherIncome)}
                            </Link>
                        ),
                        total: currencyFormat(+reportIncomeTotal[0].OtherIncome),
                    });
                } else {
                    otherObject = {
                        ...otherObject,
                        [month]: (
                            <Link onClick={() => lineItemButton(months[month].date, 3)}>
                                {currencyFormat(months[month].otherIncome)}
                            </Link>
                        ),
                    };
                }
            });
            let laundryObject;
            let laundryRoom = monthList.map((month, i) => {
                if (i === 0) {
                    laundryObject = {
                        category: 'Laundry Room:',
                        [month]: (
                            <Link onClick={() => lineItemButton(months[month].date, 11)}>
                                {currencyFormat(months[month].laundryRoom)}
                            </Link>
                        ),
                    };
                } else if (monthList.length - 1 === i) {
                    return (laundryObject = {
                        ...laundryObject,
                        [month]: (
                            <Link onClick={() => lineItemButton(months[month].date, 11)}>
                                {currencyFormat(months[month].laundryRoom)}
                            </Link>
                        ),
                        total: currencyFormat(+reportIncomeTotal[0].LaundryRoom),
                    });
                } else {
                    laundryObject = {
                        ...laundryObject,
                        [month]: (
                            <Link onClick={() => lineItemButton(months[month].date, 11)}>
                                {currencyFormat(months[month].laundryRoom)}
                            </Link>
                        ),
                    };
                }
            });
            let formerTenantObject;
            let formerTenantPayment = monthList.map((month, i) => {
                if (i === 0) {
                    formerTenantObject = {
                        category: 'Former Tenant Payment:',
                        [month]: (
                            <Link onClick={() => lineItemButton(months[month].date, 7)}>
                                {currencyFormat(months[month].formerTenantPayment)}
                            </Link>
                        ),
                    };
                } else if (monthList.length - 1 === i) {
                    return (formerTenantObject = {
                        ...formerTenantObject,
                        [month]: (
                            <Link onClick={() => lineItemButton(months[month].date, 7)}>
                                {currencyFormat(months[month].formerTenantPayment)}
                            </Link>
                        ),
                        total: currencyFormat(+reportIncomeTotal[0].FormerTenantPayment),
                    });
                } else {
                    formerTenantObject = {
                        ...formerTenantObject,
                        [month]: (
                            <Link onClick={() => lineItemButton(months[month].date, 7)}>
                                {currencyFormat(months[month].formerTenantPayment)}
                            </Link>
                        ),
                    };
                }
            });
            let totalObject;
            let totalIncome = monthList.map((month, i) => {
                if (i === 0) {
                    totalObject = {
                        category: 'Gross Income:',
                        [month]: currencyFormat(months[month].totalIncome),
                    };
                } else if (monthList.length - 1 === i) {
                    return (totalObject = {
                        ...totalObject,
                        [month]: currencyFormat(months[month].totalIncome),
                        total: currencyFormat(+reportIncomeTotal[0].TotalIncome),
                    });
                } else {
                    totalObject = {
                        ...totalObject,
                        [month]: currencyFormat(months[month].totalIncome),
                    };
                }
            });
            let expensesArray = [];
            console.log('months', months);
            let totalExpenses = monthList.map((month, i) => {
                for (let j = 0; j < reportExpenses.length; j++) {
                    if (i === 0) {
                        expensesArray = [
                            ...expensesArray,
                            {
                                category: reportExpenses[j].expensetype.ExpenseType,
                                [month]: (
                                    <Link
                                        onClick={() =>
                                            lineItemButton(
                                                months[month].date,
                                                0,
                                                reportExpenses[j].expensetype.AccountTypeID,
                                                reportExpenses[j].expensetype.id,
                                            )
                                        }
                                    >
                                        {currencyFormat(
                                            months[month][reportExpenses[j].expensetype.ExpenseType]
                                                ? months[month][reportExpenses[j].expensetype.ExpenseType]
                                                : 0,
                                        )}
                                    </Link>
                                ),
                            },
                        ];
                    } else {
                        if (i === monthList.length - 1) {
                            expensesArray[j][month] = (
                                <Link
                                    onClick={() =>
                                        lineItemButton(
                                            months[month].date,
                                            0,
                                            reportExpenses[j].expensetype.AccountTypeID,
                                            reportExpenses[j].expensetype.id,
                                        )
                                    }
                                >
                                    {currencyFormat(
                                        months[month][reportExpenses[j].expensetype.ExpenseType]
                                            ? months[month][reportExpenses[j].expensetype.ExpenseType]
                                            : 0,
                                    )}
                                </Link>
                            );
                            const totalExpenses = reportExpensesTotal.filter(
                                (item) => item.expensetype.ExpenseType === reportExpenses[j].expensetype.ExpenseType,
                            );
                            expensesArray[j].total = currencyFormat(totalExpenses[0] ? +totalExpenses[0].TotalExpenses : 0);
                        } else {
                            expensesArray[j][month] = (
                                <Link
                                    onClick={() =>
                                        lineItemButton(
                                            months[month].date,
                                            0,
                                            reportExpenses[j].expensetype.AccountTypeID,
                                            reportExpenses[j].expensetype.id,
                                        )
                                    }
                                >
                                    {currencyFormat(
                                        months[month][reportExpenses[j].expensetype.ExpenseType]
                                            ? months[month][reportExpenses[j].expensetype.ExpenseType]
                                            : 0,
                                    )}
                                </Link>
                            );
                        }
                    }
                }
                if (monthList.length - 1 === i) {
                    return expensesArray;
                }
            });
            totalExpenses = totalExpenses[totalExpenses.length - 1]
                .filter((value, index, self) => index === self.findIndex((t) => t.category === value.category))
                .sort(function (a, b) {
                    var textA = a.category;
                    var textB = b.category;
                    return textA < textB ? -1 : textA > textB ? 1 : 0;
                });
            let monthTotalExpenses = {};
            for (let i = 0; i < reportExpensesMonthTotal.length; i++) {
                let month = new Date(reportExpensesMonthTotal[i].InvoiceDate).getUTCMonth();
                let year = new Date(reportExpensesMonthTotal[i].InvoiceDate).getUTCFullYear().toString();
                if (i === 0) {
                    monthTotalExpenses = {
                        category: 'Total Expenses:',
                        [monthNames[month] + year]: currencyFormat(+reportExpensesMonthTotal[i].TotalExpenses),
                    };
                    months[monthNames[month] + year].totalExpenses = +reportExpensesMonthTotal[i].TotalExpenses;
                } else {
                    monthTotalExpenses = {
                        ...monthTotalExpenses,
                        [monthNames[month] + year]: currencyFormat(+reportExpensesMonthTotal[i].TotalExpenses),
                    };
                    months[monthNames[month] + year].totalExpenses = +reportExpensesMonthTotal[i].TotalExpenses;
                }
            }
            totalExpenses.push({
                ...monthTotalExpenses,
                total: currencyFormat(totals.expenses),
            });
            let noiObject;
            for (let i = 0; i < reportExpensesMonthTotal.length; i++) {
                let month = new Date(reportExpensesMonthTotal[i].InvoiceDate).getUTCMonth();
                let year = new Date(reportExpensesMonthTotal[i].InvoiceDate).getUTCFullYear().toString();
                if (i === 0) {
                    noiObject = {
                        category: 'NOI:',
                        [monthNames[month] + year]: currencyFormat(
                            months[monthNames[month] + year].totalIncome - reportExpensesMonthTotal[i].TotalExpenses,
                        ),
                    };
                } else if (i === reportExpensesMonthTotal.length - 1) {
                    noiObject = {
                        ...noiObject,
                        [monthNames[month] + year]: currencyFormat(
                            months[monthNames[month] + year].totalIncome - reportExpensesMonthTotal[i].TotalExpenses,
                        ),
                        total: currencyFormat(+reportIncomeTotal[0].TotalIncome - totals.expenses),
                    };
                } else {
                    noiObject = {
                        ...noiObject,
                        [monthNames[month] + year]: currencyFormat(
                            months[monthNames[month] + year].totalIncome - reportExpensesMonthTotal[i]?.TotalExpenses,
                        ),
                    };
                }
            }
            totalExpenses.push(noiObject);
            let netObject;
            for (let i = 0; i < reportExpensesMonthTotal.length; i++) {
                let month = new Date(reportExpensesMonthTotal[i].InvoiceDate).getUTCMonth();
                let year = new Date(reportExpensesMonthTotal[i].InvoiceDate).getUTCFullYear().toString();
                if (i === 0) {
                    netObject = {
                        category: 'Net Income:',
                        [monthNames[month] + year]: currencyFormat(
                            months[monthNames[month] + year].totalIncome -
                                reportExpensesMonthTotal[i]?.TotalExpenses -
                                reportNonOperatingMonthTotal[i]?.TotalExpenses,
                        ),
                    };
                } else if (i === reportExpensesMonthTotal.length - 1) {
                    netObject = {
                        ...netObject,
                        [monthNames[month] + year]: currencyFormat(
                            months[monthNames[month] + year].totalIncome -
                                reportExpensesMonthTotal[i]?.TotalExpenses -
                                reportNonOperatingMonthTotal[i]?.TotalExpenses,
                        ),
                        total: currencyFormat(+reportIncomeTotal[0].TotalIncome - totals.expenses - totals.nonOperating),
                    };
                } else {
                    netObject = {
                        ...netObject,
                        [monthNames[month] + year]: currencyFormat(
                            months[monthNames[month] + year].totalIncome -
                                reportExpensesMonthTotal[i]?.TotalExpenses -
                                reportNonOperatingMonthTotal[i]?.TotalExpenses,
                        ),
                    };
                }
            }
            let nonOperatingArray = [];
            let totalNonOperating = monthList.map((month, i) => {
                for (let j = 0; j < reportNonOperating.length; j++) {
                    if (i === 0) {
                        nonOperatingArray = [
                            ...nonOperatingArray,
                            {
                                category: reportNonOperating[j].expensetype.ExpenseType,
                                [month]: (
                                    <Link
                                        onClick={() =>
                                            lineItemButton(
                                                months[month].date,
                                                0,
                                                reportNonOperating[j].expensetype.AccountTypeID,
                                                reportNonOperating[j].expensetype.id,
                                            )
                                        }
                                    >
                                        {currencyFormat(
                                            months[month][reportNonOperating[j].expensetype.ExpenseType]
                                                ? months[month][reportNonOperating[j].expensetype.ExpenseType]
                                                : 0,
                                        )}
                                    </Link>
                                ),
                            },
                        ];
                    } else {
                        if (i === monthList.length - 1) {
                            nonOperatingArray[j][month] = (
                                <Link
                                    onClick={() =>
                                        lineItemButton(
                                            months[month].date,
                                            0,
                                            reportNonOperating[j].expensetype.AccountTypeID,
                                            reportNonOperating[j].expensetype.id,
                                        )
                                    }
                                >
                                    {currencyFormat(
                                        months[month][reportNonOperating[j].expensetype.ExpenseType]
                                            ? months[month][reportNonOperating[j].expensetype.ExpenseType]
                                            : 0,
                                    )}
                                </Link>
                            );
                            const totalNonOperating = reportNonOperatingTotal.filter(
                                (item) => item.expensetype.ExpenseType === reportNonOperating[j].expensetype.ExpenseType,
                            );
                            nonOperatingArray[j].total = currencyFormat(
                                totalNonOperating[0] ? +totalNonOperating[0].TotalExpenses : 0,
                            );
                        } else {
                            nonOperatingArray[j][month] = (
                                <Link
                                    onClick={() =>
                                        lineItemButton(
                                            months[month].date,
                                            0,
                                            reportNonOperating[j].expensetype.AccountTypeID,
                                            reportNonOperating[j].expensetype.id,
                                        )
                                    }
                                >
                                    {currencyFormat(
                                        months[month][reportNonOperating[j].expensetype.ExpenseType]
                                            ? months[month][reportNonOperating[j].expensetype.ExpenseType]
                                            : 0,
                                    )}
                                </Link>
                            );
                        }
                    }
                }
                if (monthList.length - 1 === i) {
                    nonOperatingArray = [...nonOperatingArray];
                    return nonOperatingArray;
                }
            });
            totalNonOperating = totalNonOperating[totalNonOperating.length - 1]
                .filter((value, index, self) => index === self.findIndex((t) => t.category === value.category))
                .sort(function (a, b) {
                    var textA = a.category;
                    var textB = b.category;
                    return textA < textB ? -1 : textA > textB ? 1 : 0;
                });
            let monthTotalNonOperating = {};
            for (let i = 0; i < reportNonOperatingMonthTotal.length; i++) {
                let month = new Date(reportNonOperatingMonthTotal[i].InvoiceDate).getUTCMonth();
                let year = new Date(reportNonOperatingMonthTotal[i].InvoiceDate).getUTCFullYear().toString();
                if (i === 0) {
                    monthTotalNonOperating = {
                        category: 'Total Non Operating Expenses:',
                        [monthNames[month] + year]: currencyFormat(+reportNonOperatingMonthTotal[i].TotalExpenses),
                    };
                    months[monthNames[month] + year].totalNonOperating = +reportNonOperatingMonthTotal[i].TotalExpenses;
                } else {
                    monthTotalNonOperating = {
                        ...monthTotalNonOperating,
                        [monthNames[month] + year]: currencyFormat(+reportNonOperatingMonthTotal[i].TotalExpenses),
                    };
                    months[monthNames[month] + year].totalNonOperating = +reportNonOperatingMonthTotal[i].TotalExpenses;
                }
            }
            totalNonOperating.push({
                ...monthTotalNonOperating,
                total: currencyFormat(totals.nonOperating),
            });
            tenantIncome = tenantIncome.splice(monthList.length - 1);
            prospectIncome = prospectIncome.splice(monthList.length - 1);
            housingIncome = housingIncome.splice(monthList.length - 1);
            otherIncome = otherIncome.splice(monthList.length - 1);
            laundryRoom = laundryRoom.splice(monthList.length - 1);
            formerTenantPayment = formerTenantPayment.splice(monthList.length - 1);
            totalIncome = totalIncome.splice(monthList.length - 1);
            reportIncome.length === 1
                ? setIncomeData([
                      tenantObject,
                      housingObject,
                      prospectObject,
                      otherObject,
                      laundryObject,
                      formerTenantObject,
                  ])
                : setIncomeData([
                      ...tenantIncome,
                      ...prospectIncome,
                      ...housingIncome,
                      ...otherIncome,
                      ...laundryRoom,
                      ...formerTenantPayment,
                      ...totalIncome,
                  ]);
            setExpensesData([...totalExpenses]);
            setNonOperatingData([...totalNonOperating, netObject]);
            setSummaryData([
                ...totalIncome,
                {
                    ...monthTotalExpenses,
                    total: currencyFormat(totals.expenses),
                },
                {
                    ...monthTotalNonOperating,
                    total: currencyFormat(totals.nonOperating),
                },
                netObject,
                {
                    category: 'DSR',
                    total:
                        (totals.nonOperating + totals.expenses) /
                            (+reportIncomeTotal[0].TotalIncome - totals.expenses - totals.nonOperating) <
                        0
                            ? ((totals.nonOperating + totals.expenses) /
                                  (+reportIncomeTotal[0].TotalIncome - totals.expenses - totals.nonOperating)) *
                              -1
                            : (totals.nonOperating + totals.expenses) /
                              (+reportIncomeTotal[0].TotalIncome - totals.expenses - totals.nonOperating),
                },
            ]);
            setLoading(false);
        }
        fetchData();
    }, []);
    if (loading) {
        return (
            <RctCollapsibleCard
                colClasses="col-xs-12 col-sm-12 col-md-12"
                heading={'Loading Reports... (May take up to 60 seconds)'}
            >
                <LinearProgress />
            </RctCollapsibleCard>
        );
    }
    state.series[0].data = monthList.map((month) => {
        return [months[month].date, months[month].totalIncome];
    });
    state.series[1].data = monthList.map((month) => {
        return [months[month].date, months[month].totalExpenses ? months[month].totalExpenses : 0];
    });
    state.series[2].data = monthList.map((month) => {
        return [months[month].date, months[month].totalNonOperating ? months[month].totalNonOperating : 0];
    });
    state.series[3].data = monthList.map((month) => {
        return [
            months[month].date,
            Math.round(
                (+months[month].totalIncome -
                    (months[month].totalExpenses ? +months[month].totalExpenses : 0) -
                    (months[month].totalNonOperating ? +months[month].totalNonOperating : 0)) *
                    100,
            ) / 100,
        ];
    });
    const lineItemButton = (date, depositId, accountTypeID, expenseTypeID) => {
        let itemDate = new Date(date);
        let startDate = new Date(props.reportSearch.sDt);
        let endDate = new Date(props.reportSearch.eDt);
        if (itemDate.getUTCMonth() === startDate.getUTCMonth()) {
            setItemStartDate(props.reportSearch.sDt);
        } else {
            setItemStartDate(date);
        }
        if (itemDate.getUTCMonth() === endDate.getUTCMonth()) {
            setItemLastDate(props.reportSearch.eDt);
        } else {
            var lastDay = new Date(itemDate.getFullYear(), itemDate.getUTCMonth() + 1, 0).toISOString().substring(0, 10);
            setItemLastDate(lastDay);
        }
        setItemDepositID(depositId);
        setItemAccountTypeID(accountTypeID);
        setItemExpenseTypeID(expenseTypeID);
        setLineItem((state) => !state);
    };

    const data = [
        { category: 'Income' },
        ...incomeData,
        { category: 'Expenses' },
        ...expensesData,
        { category: 'Non Operating Expenses' },
        ...nonOperatingData,
        { category: 'Summary' },
        ...summaryData,
    ];

    return (
        <div>
            {lineItem ? (
                <LineItem
                    firstDay={itemStartDate}
                    lastDay={itemLastDate}
                    depositId={itemDepositID}
                    propertyID={props.reportSearch.pID}
                    accountTypeID={itemAccountTypeID}
                    expenseTypeID={itemExpenseTypeID}
                    options={props.reportOptions}
                    expenseTypes={expenseTypes.filter((et) => et.AccountTypeID === itemAccountTypeID)}
                    back={() => setLineItem(false)}
                />
            ) : (
                <div>
                    <div className="page-title d-flex justify-content-between align-items-center">
                        <div className="page-title-wrap">
                            <h2>
                                <span>
                                    {props.reportName +
                                        ' - ' +
                                        filteredName.PropertyName +
                                        ' - Date Range of Report: ' +
                                        props.startDate +
                                        ' to ' +
                                        props.endDate}
                                </span>
                            </h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-xl-12">
                            <MuiThemeProvider theme={Constants.getMuiTheme()}>
                                <MUIDataTable
                                    title={'Profit And Loss By Month'}
                                    data={data}
                                    columns={profitLossColumns}
                                    options={{
                                        ...props.reportOptions,
                                        responsive: false,
                                        downloadOptions: {
                                            filename:
                                                'P&L ' +
                                                filteredName.PropertyName +
                                                ` - Date Range of Report: ${props.startDate} to ${props.endDate}`,
                                            separator: ',',
                                        },
                                        onDownload: (buildHead, buildBody, columns, data) => {
                                            const csv =
                                                buildHead(columns) +
                                                buildBody(
                                                    data.map((d) => {
                                                        const data = d.data.map((_) => {
                                                            if (_ === undefined || typeof _ === 'string') return _;
                                                            else {
                                                                return _.props?.children || '';
                                                            }
                                                        });
                                                        return { data };
                                                    }),
                                                );
                                            const customHeading = `${props.reportName} - ${filteredName.PropertyName} - Date Range of Report: ${props.startDate} to ${props.endDate}`;
                                            const csvWithHeading = `\n${customHeading}\n\n${csv}`;
                                            return csvWithHeading;
                                        },
                                    }}
                                />
                            </MuiThemeProvider>
                        </div>
                        {/* <div className="col-sm-12 col-md-12 col-xl-12">
                            <MuiThemeProvider theme={Constants.getMuiTheme()}>
                                <MUIDataTable
                                    title={"Expenses"}
                                    data={expensesData}
                                    columns={profitLossColumns}
                                    options={props.reportOptions}
                                />
                            </MuiThemeProvider>
                        </div>
                        <div className="col-sm-12 col-md-12 col-xl-12">
                            <MuiThemeProvider theme={Constants.getMuiTheme()}>
                                <MUIDataTable
                                    title={"Non Operating Expenses"}
                                    data={nonOperatingData}
                                    columns={profitLossColumns}
                                    options={props.reportOptions}
                                />
                            </MuiThemeProvider>
                        </div>
                        <div className="col-sm-12 col-md-12 col-xl-12">
                            <MuiThemeProvider theme={Constants.getMuiTheme()}>
                                <MUIDataTable
                                    title={"Summary"}
                                    data={summaryData}
                                    columns={profitLossColumns}
                                    options={props.reportOptions}
                                />
                            </MuiThemeProvider>
                        </div> */}
                    </div>
                    <ReactApexChart options={state.options} series={state.series} type="line" height={350} />
                </div>
            )}
        </div>
    );
};

export default ProfitAndLossByMonth;
