import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import * as Constants from "../Util/constants";
import * as reportsAPI from "../../Api/reports";
import { currencyFormat, formatDate } from "./helperFunctions";
import RctCollapsibleCard from "../Helpers/RctCollapsibleCard/RctCollapsibleCard";
import LinearProgress from "../Util/LinearProgress";
import IconButton from "@material-ui/core/IconButton";
import Print from "@material-ui/icons/Print";
import { useHistory } from "react-router-dom";

const TenNinetyNineInt = (props) => {
  const [loading, setLoading] = useState(false);
  const [intData, setIntData] = useState([]);
  const propertyName = useSelector((state) => state.login.properties);
  const [filteredName] = propertyName.filter(
    (property) => property.PropertyID === props.propertyId
  );
  const history = useHistory();
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const reportDetails = await reportsAPI.get1099Int(props.reportSearch);
      for (let i = 0; i < reportDetails.length; i++) {
        const reportVendor = await reportsAPI.get1099Vendor({
          vID: reportDetails[i].vendor.id,
        });
        intData.push({
          payee: reportDetails[i].vendor.VendorName,
          amtPaid: currencyFormat(+reportDetails[i].AmountTotal),
          action1: (
            <IconButton
              aria-label="Print"
              onClick={() => {
                const location = {
                  pathname: "/printable/IntMiscForms",
                  state: {
                    type: "1099INT",
                    property: reportDetails[i].property.PropertyName,
                    address: reportDetails[i].property.PropertyAddress1,
                    city: reportDetails[i].property.PropertyCity,
                    state: reportDetails[i].property.PropertyState,
                    zip: reportDetails[i].property.PropertyZip,
                    ein: reportDetails[i].vendor.VendorEIN,
                    name: reportDetails[i].vendor.VendorName,
                    vendorAddress: reportDetails[i].vendor.VendorAddress2,
                    vendorCity: reportDetails[i].vendor.VendorCity,
                    vendorState: reportDetails[i].vendor.VendorState,
                    vendorZip: reportDetails[i].vendor.VendorZip,
                    Amount: currencyFormat(+reportDetails[i].AmountTotal),
                  },
                };
                history.push(location, "_blank");
              }}
            >
              <Print />
            </IconButton>
          ),
          tenPrinted1: reportVendor["1099histories"][0]
            ? formatDate(reportVendor["1099histories"][0].DateSent)
            : "Not Printed",
          action2: (
            <IconButton
              aria-label="Print"
              onClick={() => {
                const location = {
                  pathname: "/printable/IntMiscForms",
                  state: {
                    type: "1096INT",
                    property: reportDetails[i].property.PropertyName,
                    address: reportDetails[i].property.PropertyAddress1,
                    city: reportDetails[i].property.PropertyCity,
                    state: reportDetails[i].property.PropertyState,
                    zip: reportDetails[i].property.PropertyZip,
                    ein: reportDetails[i].vendor.VendorEIN,
                    name: reportDetails[i].vendor.VendorName,
                    vendorAddress: reportDetails[i].vendor.VendorAddress2,
                    vendorCity: reportDetails[i].vendor.VendorCity,
                    vendorState: reportDetails[i].vendor.VendorState,
                    vendorZip: reportDetails[i].vendor.VendorZip,
                    Amount: currencyFormat(+reportDetails[i].AmountTotal),
                    ContactFName: reportDetails[i].vendor.company.ContactFName,
                    ContactLName: reportDetails[i].vendor.company.ContactLName,
                    ContactPhone: reportDetails[i].vendor.company.ContactPhone,
                    ContactEmail: reportDetails[i].vendor.company.ContactEmail,
                  },
                };
                history.push(location, "_blank");
              }}
            >
              <Print />
            </IconButton>
          ),
          tenPrinted2: reportVendor["1096histories"][0]
            ? formatDate(reportVendor["1096histories"][0].DateSent)
            : "Not Printed",
        });
      }
      setLoading(false);
    }
    fetchData();
  }, []);
  if (loading) {
    return (
      <RctCollapsibleCard
        colClasses="col-xs-12 col-sm-12 col-md-12"
        heading={"Loading Reports..."}
      >
        <LinearProgress />
      </RctCollapsibleCard>
    );
  }
  const intColumns = [
    { name: "payee", label: "ID" },
    { name: "amtPaid", label: "Amt Paid" },
    { name: "action1", label: "Action" },
    { name: "tenPrinted1", label: "1099 Printed?" },
    { name: "action2", label: "Action" },
    { name: "tenPrinted2", label: "1096 Printed?" },
  ];
  return (
    <div>
      <div className="page-title d-flex justify-content-between align-items-center">
        <div className="page-title-wrap">
          <h2>
            <span>
              {props.reportName +
                " - " +
                filteredName.PropertyName +
                " - Date Range of Report: " +
                props.startDate +
                " to " +
                props.endDate}
            </span>
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-xl-12">
          <MuiThemeProvider theme={Constants.getMuiTheme()}>
            <MUIDataTable
              title={"1099-INT"}
              data={intData}
              columns={intColumns}
              options={props.reportOptions}
            />
          </MuiThemeProvider>
        </div>
      </div>
    </div>
  );
};

export default TenNinetyNineInt;
