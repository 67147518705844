import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import * as Constants from '../Util/constants';
import * as reportsAPI from '../../Api/reports';
import { currencyFormat } from './helperFunctions';
import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import { Link } from 'react-router-dom';
import LinearProgress from '../Util/LinearProgress';
import Edit from '@material-ui/icons/Edit';

const DelinquencyDate = (props) => {
    const [loading, setLoading] = useState(false);
    const [delinqData, setDelinqData] = useState([]);
    var [tenantTotal, setTenantTotal] = useState(0);
    var [housingTotal, setHousingTotal] = useState(0);
    const propertyName = useSelector((state) => state.login.properties);
    const [filteredName] = propertyName.filter((property) => property.PropertyID === props.propertyId);
    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            const delinq = await reportsAPI.getDelinquencyDate(props.reportSearch);
            const delinqData = [];
            for (let j = 0; j < delinq.length; j++) {
                if (0 < +delinq[j].TotalTenant + +delinq[j].TotalHousing) {
                    const promiss = await reportsAPI.getTenantsDelinquent({
                        pID: props.reportSearch.pID,
                        tID: delinq[j].tenant.id.toString(),
                    });
                    delinqData.push({
                        unit: delinq[j].tenant.unit?.UnitName,
                        tenant: (
                            <Link
                                to={{
                                    pathname: '/tenants/details',
                                    state: {
                                        tenantID: delinq[j].tenant.id,
                                    },
                                }}
                            >
                                {delinq[j].tenant.TenantFName + ' ' + delinq[j].tenant.TenantLName}
                            </Link>
                        ),
                        delinquencyComments: (
                            <Link
                                to={{
                                    pathname: '/editDelinquencyComment',
                                    state: {
                                        tenantID: delinq[j].tenant.id,
                                        reportSearch: props.reportSearch,
                                        selectedValue: props.selectedValue,
                                    },
                                }}
                            >
                                {delinq[j].tenant.DelinquencyComments}
                                <Edit />
                            </Link>
                        ),
                        tenantPromise: promiss?.Promiss,
                        staffComments: promiss?.StaffComment,
                        missedP2p: promiss?.Success !== 1 ? ' ' : 'Yes',
                        tenantPortion: currencyFormat(+delinq[j].TotalTenant),
                        housingPortion: currencyFormat(+delinq[j].TotalHousing),
                        balanceOwed: currencyFormat(+delinq[j].TotalTenant + +delinq[j].TotalHousing),
                    });
                    tenantTotal += +delinq[j].TotalTenant;
                    housingTotal += +delinq[j].TotalHousing;
                }
            }
            delinqData.push({
                delinquencyComments: `Total Delinquent Tenants: ${delinqData.length}`,
                tenantPortion: currencyFormat(tenantTotal),
                housingPortion: currencyFormat(housingTotal),
                balanceOwed: currencyFormat(tenantTotal + housingTotal),
            });
            setDelinqData(delinqData);
            setLoading(false);
        }
        fetchData();
    }, []);
    if (loading) {
        return (
            <RctCollapsibleCard colClasses="col-xs-12 col-sm-12 col-md-12" heading={'Loading Reports...'}>
                <LinearProgress />
            </RctCollapsibleCard>
        );
    }
    const delinqColumns = [
        { name: 'unit', label: 'Unit' },
        { name: 'tenant', label: 'Tenant' },
        { name: 'delinquencyComments', label: 'Delinquency Comments' },
        { name: 'tenantPromise', label: 'Tenant Promise' },
        { name: 'staffComments', label: 'Staff Comments' },
        { name: 'missedP2p', label: 'Missed P2P' },
        { name: 'tenantPortion', label: 'Tenant Portion' },
        { name: 'housingPortion', label: 'Housing Portion' },
        { name: 'balanceOwed', label: 'Balance Owed' },
    ];
    return (
        <div>
            <div className="page-title d-flex justify-content-between align-items-center">
                <div className="page-title-wrap">
                    <h2>
                        <span>
                            {props.reportName +
                                ' - ' +
                                filteredName.PropertyName +
                                ' - ' +
                                props.startDate +
                                ' to ' +
                                props.endDate}
                        </span>
                    </h2>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-xl-12">
                    <MuiThemeProvider theme={Constants.getMuiTheme()}>
                        <MUIDataTable
                            title={'Delinquency'}
                            data={delinqData}
                            columns={delinqColumns}
                            options={{
                                ...props.reportOptions,
                                responsive: 'standard',
                            }}
                        />
                    </MuiThemeProvider>
                </div>
            </div>
        </div>
    );
};

export default DelinquencyDate;

