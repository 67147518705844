import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, Form, Label, Input } from 'reactstrap';
import { FormGroup } from '@material-ui/core';
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import { MuiThemeProvider } from '@material-ui/core/styles';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import Update from '@material-ui/icons/Edit';
import { useHistory } from 'react-router-dom';

import Main from '../Main';
import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import LinearProgress from '../Util/LinearProgress';
import * as Util from '../Util/util';
import * as Constants from '../Util/constants';
import * as companyAPI from '../../Api/company';

const EnhancementRequest = () => {
    const history = useHistory();
    const login = useSelector((state) => state.login);
    const company = login.company;
    const companyID = company.id;
    const user = login.user;
    const userID = user.id;
    const userEmail = user.email;

    const [loading, setLoading] = useState(false);
    const [updated, setUpdated] = useState(false);
    const [priority, setPriority] = useState([]);
    const [enhancements, setEnhancements] = useState([]);

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        async function fetchData() {
            setPriority(await companyAPI.getPriorities());
            const arr = [];
            const getData = await companyAPI.getOpenEnhancementRequests({
                userID,
                companyID,
            });
            console.log(getData);
            for (const er of getData) {
                arr.push({
                    priority: er.Priority,
                    dateSubmited: moment.utc(er.EnhancementRequestSubmissionDate).format('MM/DD/YYYY'),
                    description: er.EnhancementRequest,
                    staffComments: er.EnhancementRequestComment ? er.EnhancementRequestComment : '',
                    commentDate: er.EnhancementRequestComment
                        ? moment.utc(er.EnhancementRequestCommentDate).format('MM/DD/YYYY')
                        : '',
                    status: er.Status,
                    requestedBy: `${er.UserFName} ${er.UserLName}`,
                    update: er,
                });
            }
            setEnhancements(arr);
        }
        fetchData();
    }, [userID, companyID, updated]);

    const columns = [
        { name: 'priority', label: 'Priority' },
        { name: 'dateSubmited', label: 'Date Submited' },
        { name: 'description', label: 'Description' },
        { name: 'staffComments', label: 'Staff Comments' },
        { name: 'commentDate', label: 'Comment Date' },
        { name: 'status', label: 'Status' },
        { name: 'requestedBy', label: 'Requested By' },
        {
            name: 'update',
            label: 'Update',
            options: {
                customBodyRender: (value) => {
                    return (
                        <IconButton
                            aria-label="Print"
                            onClick={() => {
                                const location = {
                                    pathname: '/help/reditRequestHistory',
                                    request: {
                                        UserFName: value.UserFName,
                                        UserLName: value.UserLName,
                                        EnhancementRequestSubmissionDate: value.EnhancementRequestSubmissionDate,
                                        EnhancementRequest: value.EnhancementRequest,
                                        EnhancementRequestComplete: value.EnhancementRequestComplete,
                                        EnhancementRequestComment: value.EnhancementRequestComment,
                                        EnhancementRequestID: value.EnhancementRequestID,
                                    },
                                };
                                history.push(location);
                            }}
                        >
                            <Update />
                        </IconButton>
                    );
                },
            },
        },
    ];
    const options = {
        filterType: 'dropdown',
        selectableRows: 'none',
        rowsPerPage: 10,
    };

    const submitForm = async (data) => {
        setLoading(true);
        const res = await companyAPI.addEnhancementRequest({
            enhancementRequest: data.message,
            priorityID: parseInt(data.priority || priority[0].PriorityID),
            userID,
            companyID,
            userEmail,
        });
        setLoading(false);
        if (res !== 0) {
            NotificationManager.error(Constants.DEFAULT_ERROR, 'Error');
            return;
        }
        setLoading(false);
        setUpdated(!updated);
        NotificationManager.success('Enhancement requested.', 'Success');
    };

    if (loading) {
        return (
            <RctCollapsibleCard colClasses="col-xs-12 col-sm-12 col-md-12" heading={'Loading Enhancement Request...'}>
                <LinearProgress />
            </RctCollapsibleCard>
        );
    }

    return (
        <Main>
            <div className="formelements-wrapper" style={Constants.margins}>
                <div className="page-title d-flex justify-content-between align-items-center">
                    <div className="page-title-wrap">
                        <h2>
                            <span>Enhancement Request</span>
                        </h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-xl-12">
                        <RctCollapsibleCard heading="Company Details">
                            <Form onSubmit={handleSubmit(submitForm)}>
                                <div className="row">
                                    <div className="col-sm-2">
                                        <Label for="priority" className="mr-sm-10">
                                            Priority
                                        </Label>
                                        <Controller
                                            name="priority"
                                            control={control}
                                            render={({ field }) => (
                                                <Input {...field} type="select" id="priority">
                                                    {priority.map((obj, idx) => (
                                                        <option key={idx} value={obj.PriorityID}>
                                                            {obj.Priority}
                                                        </option>
                                                    ))}
                                                </Input>
                                            )}
                                        />
                                    </div>
                                    <div className="col-sm-10">
                                        <FormGroup className="mr-10 mb-10">
                                            <Label for="message" className="mr-sm-10">
                                                Message
                                            </Label>
                                            <Controller
                                                name="message"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <Input
                                                        {...field}
                                                        type="textarea"
                                                        rows={4}
                                                        id="message"
                                                        className="form-control"
                                                        style={Util.setErrorStyle(errors.message)}
                                                    />
                                                )}
                                            />
                                            {errors.message && (
                                                <span style={{ color: 'red' }} role="alert">
                                                    required
                                                </span>
                                            )}
                                        </FormGroup>
                                    </div>
                                </div>
                                <Button type="submit" color="primary" style={{ marginTop: '10px' }}>
                                    Submit
                                </Button>
                            </Form>
                        </RctCollapsibleCard>
                    </div>
                </div>
            </div>
            <div className="data-table-wrapper" style={Constants.margins}>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-xl-12">
                        <MuiThemeProvider theme={Constants.getMuiTheme()}>
                            <MUIDataTable
                                title={`${enhancements.length} Open Enhancement Requests`}
                                data={enhancements}
                                columns={columns}
                                options={options}
                            />
                        </MuiThemeProvider>
                    </div>
                </div>
            </div>
        </Main>
    );
};

export default EnhancementRequest;

