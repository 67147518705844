import React, {useState, useEffect} from 'react';
import { Controller, useForm } from "react-hook-form";
import { Button, Form, Label, Input } from 'reactstrap';
import { FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import { NotificationManager } from 'react-notifications';
import { MuiThemeProvider } from '@material-ui/core/styles';
import MUIDataTable from "mui-datatables";
import moment from 'moment';
import { useSelector } from "react-redux";

import Main from '../Main';
import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import LinearProgress from '../Util/LinearProgress';
import * as Util from '../Util/util';
import * as Constants from '../Util/constants';
import * as tenantsAPI from '../../Api/tenants';
import { getDelinquency, getTenantsDelinquent } from '../../Api/reports';

const SendLedger = () => {
    const login = useSelector((state) => state.login);
    const company = login.company
    const companyID = company.id;
    const propertyID = login.selectedPropertyID;
    const user = login.user;
    const userID = user.id;
    const multiprop = user.notifications.multiProp;

    const [loading, setLoading] = useState(false);
    const [delinquencies, setDelinquencies] = useState([]);
    const [updated, setUpdated] = useState(false);


    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            const delinq = await getDelinquency({pID: propertyID, eDt: new Date()})
            const delinqTenants = delinq.filter(d=>0 < +d.TotalTenant + +d.TotalHousing)
            const ds = delinqTenants.map(d=>({
                ...d,
                TenantName: d.tenant.TenantFName + " " + d.tenant.TenantLName,
                UnitName: d.tenant.unit.UnitName,
                TenantID: d.tenant.id,
                UnitID: d.tenant.UnitID
            }))
            setDelinquencies(ds)
            console.log("delinqTenants", ds)
            
            setLoading(false);
        }
        fetchData()
    }, [propertyID, multiprop, companyID, updated]);

    const columns = [
        { name: 'TenantName', label: 'Tenant', },
        { name: 'UnitName', label: 'Unit', },
        { name: 'TotalHousing', label: 'Total Housing', },
        { name: 'TotalTenant', label: 'Total Tenant', },
    ];

    const options = {
        filterType: 'dropdown',
        pagination: false,
        selectableRows: "none",
    }

    const handleSend = async () => {
        setLoading(true);
        console.log("userID",userID)
        const res = await Promise.all(delinquencies.map(d=>tenantsAPI.sendLedgerToTenant({
            tenantID: d.TenantID,
            userID
        })))
       
        setLoading(false);
        setUpdated(!updated);
        NotificationManager.success("Successfully sent ledger to tenants!", "Success")
    }


    if(loading) {
        return (
            <RctCollapsibleCard
                colClasses="col-xs-12 col-sm-12 col-md-12"
                heading={"Loading Tenants..."}
            >
                <LinearProgress />
            </RctCollapsibleCard>
        );
    }

    return (
        <Main>
            <div className="formelements-wrapper" style={Constants.margins}>
                <div className="page-title d-flex justify-content-between align-items-center">
                    <div className="page-title-wrap">
                        <h2>
                            <span>Send Ledgers to Delinquent Tenants</span>
                        </h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-xl-12">
                        <RctCollapsibleCard heading="">
                            <Button onClick={handleSend} color="primary" style={{ marginBottom: '10px'}}>Send Ledger to tenants</Button>
                            <MuiThemeProvider theme={Constants.getMuiTheme()}>                                                    
                                <MUIDataTable
                                    title={`Delinquent Tenants`}
                                    data={delinquencies}
                                    columns={columns}
                                    options={options}
                                />
                            </MuiThemeProvider>
                        </RctCollapsibleCard>
                    </div>
                </div>
               
            </div>
        </Main>
    )
}

export default SendLedger;