import { Types } from "../constants";

export const setScrollInfo = (payload) => {
  return (dispatch) => {
    dispatch({
      type: Types.SCROLLINFO,
      payload,
    });
  };
};
